import { TextField, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import React from "react";
import { Controller } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { applicationManifestState, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { formatCurrency } from "../../../../../services/currencyService";
import { getRules } from "../../../../../services/ruleService";
import { ConnectForm } from "../../../form/ConnectForm";
import { ReadOnlyTextBoxField } from "../../ReadOnlyTextBoxField";
import { buildFormPropertyName } from "../../../../../services/fieldService";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { cloneDeep } from "lodash";

export interface CurrencyFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const CurrencyField: React.FC<CurrencyFieldProps> = (props: CurrencyFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const textBoxId = useId("textBox");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textBoxId}`
    };

    return (
        <ConnectForm>
            {(methods: any) =>
                <Controller
                    control={methods.control}
                    rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                    name={buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField)}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <>
                            {!props.readOnly &&
                                <TooltipHost
                                    content={props.control.ToolTipText}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <TextField
                                        id={textBoxId}
                                        label={props.control.LabelText}
                                        prefix="$"
                                        value={value}
                                        disabled={props.disabled}
                                        readOnly={props.readOnly}
                                        required={props.isRequired}
                                        onBlur={onBlur}
                                        errorMessage={
                                            methods.formState.errors != null &&
                                                methods.formState.errors[props.control.Id!] != null &&
                                                methods.formState.errors[props.control.Id!].message != null ?
                                                methods.formState.errors[props.control.Id!].message :
                                                undefined}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                                            var formattedNewValue = formatCurrency(event, newValue);
                                             //React Hook Form requires you call the array Update method when editing an array as well as the onChange method.
                                             if (props.subFormField != null) {
                                                var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                var updateableField = cloneDeep(myCurrentValue);
                                                updateableField[props.control.Id!] = formattedNewValue;
                                                props.subFormField.update(props.subFormField.Index, updateableField);
                                            }
                                            onChange(formattedNewValue);
                                        }} />
                                </TooltipHost>
                            }
                            {props.readOnly &&
                                <ReadOnlyTextBoxField
                                    control={props.control}
                                    value={value}>
                                </ReadOnlyTextBoxField>
                            }
                        </>
                    )} />
            }
        </ConnectForm>
    )
}