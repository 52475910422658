import { IStackItemStyles, IStackStyles, Stack } from "@fluentui/react";
import React, { useEffect } from "react";
import { Outlet } from 'react-router-dom';
import { useSetRecoilState } from "recoil";
import { navPageSelectedState, shimmerNavState } from "../../../recoil/atoms";

export const HelpPageIndex: React.FC = () => {
    const setNavPageSelected = useSetRecoilState(navPageSelectedState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);

    useEffect(() => {
        setNavPageSelected(undefined);
        setShimmerNav(true);
    }, [setNavPageSelected, setShimmerNav]);

    const stackStyle: IStackStyles = {
        root: {
            paddingTop: "25px",
            transition: "0.3s",
        },
    };

    const stackItemStyle: IStackItemStyles = {
        root: {
            alignItems: "center",
            transition: "0.3s",
            flex: 1
        },
    };

    return (
        <Stack horizontal styles={stackStyle} className="main-region">
            <Stack.Item styles={stackItemStyle}>
                <Outlet></Outlet>
            </Stack.Item>
        </Stack >
    );
}