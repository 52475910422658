import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { IStackTokens, Link, Stack } from "@fluentui/react";
import "./CommentContent.css";
import { CommentModel } from "../../../../model/reaccessData/CommentModel";
import { CommentThreadField } from "./fields/CommentThreadField";
import { useParams } from "react-router-dom";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface NewCommentProps {
    control: ControlData;
    comment: CommentModel;
    comments: CommentModel[];
    index: number;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    cancelNewComment: (indexOfComment: number) => void;
    update: (index: number, value: unknown) => void;
    subFormField: ISubFormField | undefined;
}

export const NewComment: React.FC<NewCommentProps> = (props: NewCommentProps) => {
    var { entityId } = useParams();

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: 'm',
        padding: props.comment.ParentId == null ? "m" : "10px 0px 0px 60px"
    };

    const showNewCommentArea = (): boolean => {
        return props.comment.Id == null || entityId == null ? true : false;
    }

    return (
        <>
            <Stack horizontal={false} tokens={themedMediumStackTokens}>
                {showNewCommentArea() &&
                    <Stack>
                        <Stack.Item>
                            <CommentThreadField
                                control={props.control}
                                shouldValidate={props.shouldValidate}
                                disabled={props.disabled}
                                readOnly={props.readOnly}
                                isRequired={props.isRequired}
                                entityId={props.entityId}
                                index={props.index}
                                update={props.update}
                                subFormField={props.subFormField}
                            />
                        </Stack.Item>
                        {(entityId != null && !props.readOnly) &&
                            <Stack.Item>
                                <Link
                                    onClick={() => { props.cancelNewComment(props.index) }}
                                    style={{ "paddingLeft": "8px" }}>
                                    Cancel
                                </Link>
                            </Stack.Item>
                        }
                    </Stack>
                }
            </Stack>
        </>
    );
}