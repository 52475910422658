import { IPublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { CustumActionMessage } from "../enmus/CustumActionMessages";
import { custumActionMessageState } from "../recoil/atoms";

export function ResponseInterceptor(
  navigate: any,
  instance: IPublicClientApplication
) {
  const setCustumActionMessage = useSetRecoilState(custumActionMessageState);

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      var accountInfo = instance.getActiveAccount();

      if (
        error.response.status === 401 &&
        !error.response.config.url.startsWith("https://graph.microsoft.")
      ) {
        await instance.logoutRedirect({
          account: accountInfo,
          postLogoutRedirectUri: "/",
        });
      }
      if (error.response.status === 409) {
        if (error.response.data === "Trial Ended") {
          navigate("/TrialEnded", { replace: true });
        }
        if (error.response.data === "Need Permissions or Register") {
          navigate("/NoAccess", { replace: true });
        }
        if (error.response.data === "Register") {
          navigate("/Register", { replace: true });
        }
        if (error.response.data === "App Templates") {
          navigate("/NewApplication/Templates", { replace: true });
        }
        if (error.response.data === "No Apps Found") {
          navigate("/NewApplication/Templates", { replace: true });
        }
        if (error.response.data === "App Not Found") {
          navigate("/NotFound", { replace: true });
        }
        if (error.response.data === "Administrator Privilege Required" ||
            error.response.data === "Owner/Administrator Privilege Required") {
          navigate("/AppCatalog", { replace: true });
        }
        if (error.response.data === "Administrator Or Builder Privilege Required") {
          navigate("/AppCatalog", { replace: true });
        }
        if (error.response.data === "Application Subscription") {
          setCustumActionMessage(CustumActionMessage.ApplicationSubscription);
        }
        if (error.response.data === "Version Mismatch - Update App") {
          setCustumActionMessage(CustumActionMessage.UpdateApp);
        }
        if (error.response.data === "Version Mismatch - PowerLine Deployment") {
          setCustumActionMessage(CustumActionMessage.DeploymentInProgress);
        }
        if (error.response.data === "Manifest is Publishing") {
          setCustumActionMessage(CustumActionMessage.ManifestIsPublishing);
        }
      }
      return Promise.reject(error);
    }
  );
}
