import { PartialTheme } from "@fluentui/react";

export const lightTheme: PartialTheme = {
  palette: {
    themePrimary: "#0078d4",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#106ebe",
    themeDark: "#005a9e",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
    red: "#e81123",
    redDark: "#a80000",
  },
};

export const darkTheme: PartialTheme = {
  palette: {
    themePrimary: "#0086ed",
    themeLighterAlt: "#000509",
    themeLighter: "#001626",
    themeLight: "#002847",
    themeTertiary: "#00518e",
    themeSecondary: "#0076d1",
    themeDarkAlt: "#1892ef",
    themeDark: "#3aa2f1",
    themeDarker: "#6cbaf5",
    neutralLighterAlt: "#1b1b1b",
    neutralLighter: "#1b1b1b",
    neutralLight: "#1a1a1a",
    neutralQuaternaryAlt: "#181818",
    neutralQuaternary: "#171717",
    neutralTertiaryAlt: "#161616",
    neutralTertiary: "#dfdfdf",
    neutralSecondary: "#e4e4e4",
    neutralPrimaryAlt: "#e9e9e9",
    neutralPrimary: "#cfcfcf",
    neutralDark: "#f4f4f4",
    black: "#f9f9f9",
    white: "#1d1d1d",
    red: "#e81123",
    redDark: "#a80000",
  },
};