import React from "react";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";

export interface ItemsPerPageProps {
    isLoading: boolean;
    searchItemsPerPage: number;
    setSearchItemsPerPage: (searchItemsPerPage: number) => void;
}

export const ItemsPerPage: React.FC<ItemsPerPageProps> = (props: ItemsPerPageProps) => {
    const options: IDropdownOption[] = [
        { key: 5, text: "5" },
        { key: 10, text: "10" },
        { key: 25, text: "25" },
        { key: 50, text: "50" }
    ];

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 100 },
    };

    return (
        <Dropdown
            selectedKey={props.searchItemsPerPage}
            options={options}
            styles={dropdownStyles}
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                props.setSearchItemsPerPage(parseInt(option!.key.toString()));
            }}
        />
    );
}