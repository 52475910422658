import React, { } from "react";
import { DocumentCard, IDocumentCardStyles, Shimmer, ShimmerElementType, Stack, useTheme } from "@fluentui/react";
import { CustomImageShimmer } from "../controls/file/fields/helpers/CustomImageShimmer";

export const ShimmerAppCard: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <DocumentCard
                aria-label={"Loading Application Tiles"}
                styles={cardStyles}
            >
                <CustomImageShimmer></CustomImageShimmer>
                <Stack.Item style={{ paddingTop: "15px" }}>
                    <Shimmer
                        shimmerElements={shimmerWithElementAppName}
                        shimmerColors={{
                            shimmer: theme.palette.themeTertiary,
                            shimmerWave: theme.palette.themeSecondary,
                            background: theme.semanticColors.bodyBackground,
                        }} />
                </Stack.Item>
                <Stack.Item style={{ paddingTop: "15px" }}>
                    <Shimmer
                        shimmerElements={shimmerWithElementDescription}
                        shimmerColors={{
                            shimmer: theme.palette.themeTertiary,
                            shimmerWave: theme.palette.themeSecondary,
                            background: theme.semanticColors.bodyBackground,
                        }} />
                </Stack.Item>
                <Stack.Item style={{ paddingTop: "25px", paddingBottom: "20px" }}>
                    <Shimmer
                        shimmerElements={shimmerWithElementFooter}
                        shimmerColors={{
                            shimmer: theme.palette.themeTertiary,
                            shimmerWave: theme.palette.themeSecondary,
                            background: theme.semanticColors.bodyBackground,
                        }} />
                </Stack.Item>
            </DocumentCard>
        </>
    );
}

const cardStyles: IDocumentCardStyles = {
    root: { display: 'inline-block', marginRight: 20, marginBottom: 20, width: 320 },
};

const shimmerWithElementAppName = [
    { type: ShimmerElementType.gap, width: "25%" },
    { type: ShimmerElementType.line, width: "50%" },
    { type: ShimmerElementType.gap, width: "25%" }
];

const shimmerWithElementDescription = [
    { type: ShimmerElementType.gap, width: "10%" },
    { type: ShimmerElementType.line, width: "90%" },
    { type: ShimmerElementType.gap, width: "10%" }
];

const shimmerWithElementFooter = [
    { type: ShimmerElementType.gap, width: "2.5%" },
    { type: ShimmerElementType.line, width: "25%" },
    { type: ShimmerElementType.gap, width: "60%" },
    { type: ShimmerElementType.line, width: "10%" },
    { type: ShimmerElementType.gap, width: "2.5%" },
];