import React from "react";
import { DefaultButton, useTheme } from "@fluentui/react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { dialogModalWrapperPropsState, searchResultState } from "../../../recoil/atoms";
import { ConnectForm } from "./ConnectForm";
import { useNavigate, useParams } from "react-router-dom";
import { removeButtonStyles } from "../../../services/styleService";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import axios from "axios";
import { IEntityListResult } from "../../../interfaces/EntityListResult";

export interface DeleteFormButtonProps {
    formId: string;
    entityId: string;
    shimmerControlsOnSubmit: boolean;
    setShimmerControlsOnSubmit: (shimmerControlsOnSubmit: boolean) => void;
    hideModal?: () => void;
}

export const DeleteFormButton: React.FC<DeleteFormButtonProps> = (props: DeleteFormButtonProps) => {
    const [searchResult, setSearchResultState]: [IEntityListResult, (searchResult: IEntityListResult | undefined) => void] = useRecoilState(searchResultState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const theme = useTheme();
    const navigate = useNavigate();
    var { appId, formId, entityId } = useParams();

    const deleteEntity = () => {
        props.setShimmerControlsOnSubmit(false);
        setDialogModalWrapperPropsState(undefined);
        axios.delete(`/ApplicationEntityDelete?applicationId=${appId}&formId=${props.formId}&entityId=${props.entityId}`,)
            .then((result: any) => {
                //we know we are inside a panel if these two do not match.
                if (props.entityId !== entityId) {
                    props.hideModal!();
                    //need to refresh current route.
                    navigate(`/Application/${appId}/Form/${formId}/Entity/${entityId}`, { replace: true });
                    navigate(0);
                    return;
                }
                if (searchResult !== undefined) {
                    var entityList: IEntityListResult = { ...searchResult };
                    entityList.RecordCount -= 1;
                    entityList.ApplicationEntityResults = entityList.ApplicationEntityResults.filter(i => i.EntityId !== entityId);
                    setSearchResultState(entityList);
                    setTimeout(() => {
                        navigate(`/Application/${appId}/Form/${formId}/Search`, { replace: true });
                    }, 150);
                } else {
                    navigate(`/Application/${appId}/Form/${formId}/Search`, { replace: true });
                }
            }).catch((error) => {
                props.setShimmerControlsOnSubmit(true);
                if (error.response == null) {
                    console.log("Request canceled", "Delete Form Button Component cleaned up");
                    return;
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps = {
                        isVisible: true,
                        title: "Uh oh",
                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                        isBlocking: true,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
    }

    return (
        <ConnectForm>
            {(methods: any) =>
                <DefaultButton
                    text="Delete"
                    iconProps={{ iconName: "Delete" }}
                    styles={removeButtonStyles(theme)}
                    disabled={props.shimmerControlsOnSubmit === false ? true : false}
                    onClick={() => {
                        var dialogProps: IDialogModalWrapperProps = {
                            isVisible: true,
                            title: "Delete",
                            subText: "Are you sure you want to delete this record?",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: "Cancel",
                            onDismiss: () => {
                                deleteEntity();
                            },
                            onSecondaryButtonDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }}
                />
            }
        </ConnectForm>
    );
}