import React, { useEffect, useState } from "react";
import './SideNavbar.css';
import { NavPages } from "../sideNavbar/NavPages";
import { NavSettings } from "../sideNavbar/NavSettings";
import { StringConstants } from "../../constants/StringConstants";
import { IStackItemStyles, IStackStyles, Stack, useTheme } from "@fluentui/react";
import { halfScreenModeState } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

export const SideNavbar: React.FC = () => {
    const halfScreenMode: boolean = useRecoilValue(halfScreenModeState);
    const [expanded, setExpanded] = useState<boolean>(localStorage.getItem(StringConstants.SideNavExpanded) === "true" ? true : false);
    const theme = useTheme();

    useEffect(() => {
        if (halfScreenMode) {
            setExpanded(false);
        } else {
            var usersSavedExpandedState = localStorage.getItem(StringConstants.SideNavExpanded);
            setExpanded(usersSavedExpandedState === "true" ? true : false);
        }
    }, [halfScreenMode]);

    const separatorStyles: IStackItemStyles = {
        root: {
            display: "flex",
            backgroundColor: theme.semanticColors.menuDivider,
            height: "1px",
            marginLeft: "10px",
            marginRight: "10px",
        }
    };

    return (
        <>
            <Stack styles={mainSideNavStyle} className={`region-navigation ${expanded ? 'expanded' : 'collapsed'}`}>
                <Stack.Item styles={sideNavStackItemStyles}>
                    <NavPages
                        expanded={expanded}>
                    </NavPages>
                </Stack.Item>
                <Stack.Item styles={settingsStackItemStyles}>
                    <Stack>
                        <Stack.Item styles={separatorStyles}>
                            <div></div>
                        </Stack.Item>
                        <Stack.Item>
                            <NavSettings
                                expanded={expanded}
                                setExpanded={(expanded: boolean) => {
                                    setExpanded(expanded)
                                }}>
                            </NavSettings>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
}

const mainSideNavStyle: IStackStyles = {
    root: {
        display: "flex"
    },
};
const sideNavStackItemStyles: IStackItemStyles = {
    root: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "auto"
    },
};
const settingsStackItemStyles: IStackItemStyles = {
    root: {
        display: "flex",
        flexDirection: "column"
    }
}