import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { applicationManifestState, dialogModalWrapperPropsState, navPageSelectedState } from "../../../recoil/atoms";
import { useParams, Outlet, useNavigate } from 'react-router-dom';
import { IStackStyles, Stack } from "@fluentui/react";
import './FormIndex.css';
import { useSetRecoilState } from "recoil";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";

export const FormIndex: React.FC = () => {
    const [applicationManifest, setApplicationManifest]: [ApplicationManifest, (applicationManifest: ApplicationManifest | null) => void] =useRecoilState(applicationManifestState);
    const [navPageSelected, setNavPageSelected] = useRecoilState(navPageSelectedState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [displayOutlet, setDisplayOutlet] = useState<boolean>(false);
    var { appId, formId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (applicationManifest == null) {
            setDisplayOutlet(false);
            return;
        }

        const noAccessMessage = (): void => {
            var dialogProps: IDialogModalWrapperProps = {
                isVisible: true,
                title: "Hmm",
                subText: "It appears that this page does not exist or you do not have the correct permission.  Please contact the application's administrator.",
                isBlocking: true,
                primaryButtonText: "OK",
                secondaryButtonText: undefined,
                onDismiss: () => {
                    setDialogModalWrapperPropsState(undefined);
                    setApplicationManifest(null);
                    navigate(`/AppCatalog`, { replace: true });
                },
                onSecondaryButtonDismiss: () => { }
            }
            setDialogModalWrapperPropsState(dialogProps);
        }

        if (applicationManifest.Pages == null || applicationManifest.Pages.length === 0) {
            noAccessMessage();
            return;
        }

        var page = applicationManifest.Pages.filter(i => !i.HideFormFromNavigation).find(ii => ii.Id === formId);

        //either page does not exist or user does not have rights to know about it
        if (page == null) {
            noAccessMessage();
            return;
        }

        //always set the nav page when the form idex runs
        setNavPageSelected(page!.Id);
        setDisplayOutlet(true);
    }, [appId, applicationManifest, formId, navPageSelected, navigate, setApplicationManifest, setDialogModalWrapperPropsState, setNavPageSelected]);

    const stackStyle: IStackStyles = {
        root: {
            paddingTop: "25px",
            transition: "0.3s",
        },
    };

    return (
        <Stack horizontal={false} styles={stackStyle} className="main-region">
            {displayOutlet &&
                <>
                    <Outlet />
                </>
            }
        </Stack>
    );
}
