import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { CommentModel } from "../../../../model/reaccessData/CommentModel";
import { DefaultButton, Label, Stack } from "@fluentui/react";
import { CommentWrapper } from "./CommentWrapper";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { buildFormPropertyName } from "../../../../services/fieldService";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface CommentArrayProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const CommentArray: React.FC<CommentArrayProps> = (props: CommentArrayProps) => {
    const methods = useFormContext();
    const propertyName = buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField);
    const { fields, append, remove, update } = useFieldArray({
        name: propertyName,
    });


    const addNewComment = (parentId: string | undefined): void => {
        var comment: CommentModel = {
            Id: undefined,
            ParentId: parentId,
            Text: "",
            IsDeleted: false,
            InsertedByEmail: "",
            InsertedByName: "",
            InsertedByImage: "",
            InsertedUtc: undefined
        }
        append(comment);
    };

    const cancelNewComment = (indexOfComment: number): void => {
        remove(indexOfComment);
    };

    const showNewCommentButton = (): boolean => {
        if (props.readOnly) {
            return false;
        }
        var comments = fields as unknown as CommentModel[];
        return comments.findIndex(i => i.Id == null && i.ParentId == null) === - 1 ? true : false;
    }

    return (
        <>
            {fields != null && fields.length > 0 &&
                <>
                    <Label>{props.control.LabelText}</Label>
                    {fields.map((field, index) => {
                        var comment = field as unknown as CommentModel;
                        var comments = fields as unknown as CommentModel[];
                        if (comment.ParentId == null) {
                            return (
                                <CommentWrapper
                                    key={"CommentParent" + index}
                                    control={props.control}
                                    comment={comment}
                                    comments={comments}
                                    index={index}
                                    shouldValidate={props.shouldValidate}
                                    disabled={props.disabled}
                                    readOnly={props.readOnly}
                                    isRequired={props.isRequired}
                                    entityId={props.entityId}
                                    addNewComment={(parentId: string | undefined) => { addNewComment(parentId); }}
                                    cancelNewComment={(indexOfComment: number) => { cancelNewComment(indexOfComment); }}
                                    update={update}
                                    subFormField={props.subFormField}
                                />
                            )
                        } else {
                            return (<div key={"CommentParent" + index}></div>)
                        }
                    })}
                    <br />
                    <div className="CustomError">
                        {/* This error is on the control so it comes from the server.  Other errors are isolated to their array index. */}
                        <ErrorMessage errors={methods.formState.errors} name={propertyName} />
                        <br />
                    </div>
                    {showNewCommentButton() &&
                        <Stack.Item>
                            <DefaultButton
                                text="Add New Comment"
                                onClick={() => { addNewComment(undefined); }}
                            />
                        </Stack.Item>
                    }
                </>
            }
        </>
    );
}