import React, { useEffect, useState } from "react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Controller, FieldValues, UseFormReturn, useFormContext, useWatch } from "react-hook-form";
import { ConnectForm } from "../../../form/ConnectForm";
import { useRecoilValue } from "recoil";
import { applicationManifestState, globalBusinessRulesState, resetFieldSpecificState } from "../../../../../recoil/atoms";
import { getRules } from "../../../../../services/ruleService";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { DocumentUpload } from "../../../DocumentUpload";
import { ReadOnlyDocumentListField } from "../../ReadOnlyDocumentListField";
import { AttachmentModel } from "../../../../../model/reaccessData/AttachmentModel";
import { DocumentListField } from "./DocumentListField";
import { Label, PrimaryButton, Stack, useTheme } from "@fluentui/react";
import { IDialogModalWrapperProps } from "../../../../../interfaces/DialogModal";
import { DialogModalWrapper } from "../../../DialogModalWrapper";
import { ErrorMessage } from '@hookform/error-message';
import { ImageField } from "./ImageField";
import { removeButtonStyles } from "../../../../../services/styleService";
import { Accept } from "react-dropzone";
import { VideoField } from "./VideoField";
import axios from "axios";
import { ControlProgressIndicator } from "./helpers/ControlProgressIndicator";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { cloneDeep } from "lodash";
import { useParams } from "react-router-dom";
import { buildFormPropertyName } from "../../../../../services/fieldService";

export interface FileUploadFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

type AddAttachmentState = {
    attachment: AttachmentModel;
    fileName: string;
    fileId: string;
    file?: File;
    onChange: (attachments: AttachmentModel[]) => void;
    clearFakeError: () => void;
    triggerOnBlur: () => void;
    getValues: (name: string) => any;
};

type StartAddState = {
    fileName: string,
    fileId: string,
    onChange: (attachments: AttachmentModel[]) => void;
};

type RemoveAttachmentState = {
    fileName: string;
    fileId: string;
    onChange: (attachments: AttachmentModel[] | null) => void;
    clearFakeError: () => void;
    triggerOnBlur: () => void;
    getValues: (name: string) => any;
}

export const FileUploadField: React.FC<FileUploadFieldProps> = (props: FileUploadFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const [initialAttachmentList, setInitialAttachmentList] = useState<AttachmentModel[] | null>(null);
    const [attachments, setAttachments] = useState<AttachmentModel[] | null>(null);
    const resetFieldSpecific: boolean = useRecoilValue(resetFieldSpecificState);

    const [addAttachment, setAddAttachment] = useState<AddAttachmentState | null>(null);
    const [startAdd, setStartAdd] = useState<StartAddState | null>(null);
    const [removeAttachment, setRemoveAttachment] = useState<RemoveAttachmentState | null>(null);
    const [dialogModalWrapperProps, setDialogModalWrapperProps] = useState<IDialogModalWrapperProps>();
    const [hideRefreshVideo, setHideRefreshVideo] = useState<boolean>(true);
    const [shouldResetField, setShouldResetField] = useState<boolean>(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const theme = useTheme();
    const methods = useFormContext();
    var { appId } = useParams();
    const propertyName = buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField);
    const myFormValues: AttachmentModel[] | undefined = useWatch({ name: propertyName });

    useEffect(() => {
        if (!shouldResetField) {
            return;
        }
        var initialAttachments: AttachmentModel[] | null = null;
        if (props.subFormField == null) {
            if (myFormValues != null && myFormValues.length > 0) {
                initialAttachments = myFormValues;
            }
        } else {
            var myCurrentField = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
            initialAttachments = myCurrentField[props.control.Id!];
        }
        setAttachments(initialAttachments);
        setInitialAttachmentList(initialAttachments);

        if (props.control.FieldType === 40 &&
            initialAttachments != null &&
            initialAttachments.length === 1 &&
            initialAttachments[0].SasUriExpirationInSeconds !== undefined &&
            initialAttachments[0].SasUriExpirationInSeconds > 0) {
            setTimeout(() => {
                setHideRefreshVideo(false);
            }, initialAttachments[0].SasUriExpirationInSeconds! * 1000);
        }
        setShouldResetField(false);

        return function cleanup() {
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.control.Id, shouldResetField]);

    useEffect(() => {
        if (!resetFieldSpecific) {
            return;
        }
        //reset the field at the local level since we have control over setting it back to false
        setShouldResetField(true);
    }, [resetFieldSpecific]);

    useEffect(() => {
        if (addAttachment === null || attachments === null) {
            return;
        }

        var newAttachmentList: AttachmentModel[] = cloneDeep(attachments.filter(m => m.FileName !== addAttachment.fileName));

        var currentFile = newAttachmentList.find(i => i.FileId !== "" && i.FileId === addAttachment.fileId);

        if (currentFile != null) {
            currentFile.FileId = addAttachment.attachment.FileId;
            currentFile.FileName = addAttachment.attachment.FileName;
            currentFile.FileExtension = addAttachment.attachment.FileExtension;
            currentFile.FileSize = addAttachment.attachment.FileSize;
            currentFile.Version = addAttachment.attachment.Version;
            currentFile.UpdatedByName = addAttachment.attachment.UpdatedByName;
            currentFile.UpdatedUtc = addAttachment.attachment.UpdatedUtc;
        } else {
            newAttachmentList.push(addAttachment.attachment);
        }

        setAttachments(newAttachmentList);
        if (!newAttachmentList.some((i: { FileId: string; }) => i.FileId === "")) {
            addAttachment.clearFakeError();
        }

        //React Hook Form requires you call the array Update method when editing an array as well as the onChange method.
        if (props.subFormField != null) {
            var myCurrentValue = addAttachment.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
            var updateableField = cloneDeep(myCurrentValue);
            updateableField[props.control.Id!] = newAttachmentList;
            props.subFormField.update(props.subFormField.Index, updateableField);
        }

        addAttachment.onChange(newAttachmentList);
        addAttachment.triggerOnBlur();
        setAddAttachment(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addAttachment, attachments, props.control.FieldType]);

    useEffect(() => {
        if (startAdd === null) {
            return;
        }

        var newAttachmentList = attachments ? [...attachments] : [];
        var file = startAdd.fileId !== "" ?
            newAttachmentList.find(i => i.FileId === startAdd.fileId) :
            newAttachmentList.find(i => i.FileName + i.FileExtension === startAdd.fileName);

        if (file != null) {
            //0 out the file size so the indeterminate spinner kicks off
            var temp = JSON.parse(JSON.stringify(file));
            temp.FileSize = 0;
            newAttachmentList.splice(newAttachmentList.indexOf(file), 1, temp);
        } else {
            var attachment: AttachmentModel = {
                FileId: startAdd.fileId,
                EntityId: "",
                ControlId: "",
                FileName: startAdd.fileName,
                FileExtension: "",
                FileSize: 0,
                SasUri: "",
                SasUriExpirationInSeconds: undefined,
                Version: 0,
                UpdatedByName: undefined,
                UpdatedUtc: undefined
            };

            newAttachmentList.push(attachment);
        }

        setAttachments(newAttachmentList);
        setStartAdd(null);
    }, [startAdd, attachments]);

    useEffect(() => {
        if (attachments === null) {
            return;
        }

        const isNewDocListSameAsInitial = (attachmentList: AttachmentModel[] | null): boolean => {
            if (attachmentList === null && initialAttachmentList === null) {
                return true;
            }
            if (attachmentList === null || initialAttachmentList === null) {
                return false;
            }

            if (attachmentList.length !== initialAttachmentList.length) {
                return false;
            }

            for (const attachment of initialAttachmentList) {
                if (!attachmentList.some(i => i.FileId === attachment.FileId)) {
                    return false;
                }
            }
            return true;
        }

        if (removeAttachment === null) {
            return;
        }

        var newAttachmentList: AttachmentModel[] | null = removeAttachment.fileId !== "" ?
            attachments.filter((model: AttachmentModel) => model.FileId !== removeAttachment.fileId) :
            attachments.filter((model: AttachmentModel) => model.FileName !== removeAttachment.fileName);

        if (newAttachmentList.length === 0) {
            newAttachmentList = null;
        }

        // Setting back to initial attachment list will clear is dirty flag for this field
        const noNetChanges = isNewDocListSameAsInitial(newAttachmentList);

        var myCurrentValue: any;
        var updateableField: any;
        if (noNetChanges) {
            //React Hook Form requires you call the array Update method when editing an array as well as the onChange method.
            if (props.subFormField != null) {
                myCurrentValue = removeAttachment.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                updateableField = cloneDeep(myCurrentValue);
                updateableField[props.control.Id!] = initialAttachmentList;
                props.subFormField.update(props.subFormField.Index, updateableField);
            }

            removeAttachment.onChange(initialAttachmentList);
            removeAttachment.clearFakeError();
            setAttachments(initialAttachmentList);
        }
        else {
            // Wait to call onChange until all uploads are complete. Once all are complete then clear the fake error
            // that is preventing users from saving before uploads are complete. This would only be called in the case of a failed
            // upload but this could occur even with simultaneous outstanding uploads - and it could be the last one.
            if (newAttachmentList === null || !newAttachmentList.some(i => i.FileId === "")) {
                removeAttachment.clearFakeError();
            }

            //React Hook Form requires you call the array Update method when editing an array as well as the onChange method.
            if (props.subFormField != null) {
                myCurrentValue = removeAttachment.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                updateableField = cloneDeep(myCurrentValue);
                updateableField[props.control.Id!] = newAttachmentList;
                props.subFormField.update(props.subFormField.Index, updateableField);
            }

            removeAttachment.onChange(newAttachmentList);
            removeAttachment.triggerOnBlur();
            setAttachments(newAttachmentList);
        }

        setRemoveAttachment(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeAttachment, attachments, initialAttachmentList]);

    const getUpdatedSasUri = (): void => {
        if (props.control.FieldType !== 40 ||
            attachments == null ||
            attachments.length !== 1 ||
            attachments[0].SasUriExpirationInSeconds === undefined ||
            attachments[0].SasUriExpirationInSeconds <= 0) {
            return;
        }
        axios.get(`/Attachment?entityId=${attachments[0].EntityId}&fieldId=${attachments[0].ControlId}&fileId=${attachments[0].FileId}`,
            {
                params: { applicationId: appId },
                cancelToken: source.token
            }).then((result) => {
                var attachmentArray: AttachmentModel[] = [];
                var attachment: AttachmentModel = result.data;
                attachmentArray.push(attachment);
                setAttachments(attachmentArray);
                setHideRefreshVideo(true);
                setTimeout(() => {
                    setHideRefreshVideo(false);
                }, attachment.SasUriExpirationInSeconds! * 1000);
            })
            .catch(() => {
                setHideRefreshVideo(false);
            });
    }

    const rules = getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null);

    const getButtonLabel = (): string => {
        if (props.control.FieldType === 32) {
            return "Upload Image";
        }
        if (props.control.FieldType === 25) {
            return "Add Files";
        }
        if (props.control.FieldType === 40) {
            return "Add Video"
        }

        return "Add File";
    }

    const imageFieldTypes: Accept = {
        "image/jpeg": [],
        "image/png": [],
        "image/bmp": [],
        "image/gif": [],
        "image/x-icon": [],
        "image/tiff": []
    };

    const videoFieldTypes: Accept = {
        "video/mp4": []
    };

    const getAcceptedMimeTypes = (): Accept | undefined => {
        if (props.control.FieldType === 32) {
            return imageFieldTypes;
        }
        if (props.control.FieldType === 40) {
            return videoFieldTypes
        }

        return undefined;
    }

    return (
        <>
            {!props.readOnly &&
                <>
                    <ConnectForm>
                        {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                            <Controller
                                control={methods.control}
                                rules={rules}
                                name={propertyName}
                                render={(renderObject: any) => {
                                    const { field: { onChange, onBlur } } = renderObject;

                                    // Using a key not equal to control ID so onChange will not clear fake error
                                    const fakeErrorKey = props.control.Id! + "FAKE";
                                    const setFakeError = () => {
                                        methods.setError(fakeErrorKey, {
                                            type: "manual",
                                            message: "Fake Error"
                                        });
                                    };

                                    const clearFakeError = () => {
                                        methods.clearErrors(fakeErrorKey);
                                    }

                                    const listHasError = (): boolean => {
                                        return methods.formState.errors != null && methods.formState.errors[props.control.Id!] != null ? true : false;
                                    }

                                    const triggerOnBlur = (): void => {
                                        onBlur();
                                    }

                                    const handleFailedUploadErrorMessage = (error: any): void => {
                                        var validationMessage: string;
                                        if (error === "File size cannot exceed 100MB" ||
                                            (error.response.status !== 401 && error.response.status !== 403)) {
                                            if (error != null && error.response != null && error.response.data != null && error.response.data !== "") {
                                                validationMessage = error.response.data;
                                            } else if (error === "File size cannot exceed 100MB") {
                                                validationMessage = error;
                                            } else {
                                                validationMessage = "An unknown error has occurred.  Please try your request again.";
                                            }
                                            var dialogProps: IDialogModalWrapperProps = {
                                                isVisible: true,
                                                title: "Upload Failed",
                                                subText: validationMessage,
                                                isBlocking: false,
                                                primaryButtonText: "OK",
                                                secondaryButtonText: undefined,
                                                onDismiss: () => {
                                                    setDialogModalWrapperProps(undefined);
                                                },
                                                onSecondaryButtonDismiss: () => { }
                                            }
                                            setDialogModalWrapperProps(dialogProps);
                                        }
                                    }
                                    return (
                                        <>
                                            <Stack horizontal horizontalAlign="space-between" style={{ "paddingBottom": "5px" }}>
                                                <Stack.Item>
                                                    <Label className={props.isRequired ? "CustomErrorLabel" : ""}>{props.control.LabelText}</Label>
                                                </Stack.Item>
                                                <Stack horizontal horizontalAlign="end">
                                                    {(props.entityId != null &&
                                                        props.control.FieldType === 40 &&
                                                        attachments != null &&
                                                        attachments.length > 0 &&
                                                        !hideRefreshVideo) &&
                                                        <Stack.Item style={{ "paddingRight": "10px" }}>
                                                            <PrimaryButton
                                                                text="Refresh Video"
                                                                iconProps={{ iconName: "Refresh" }}
                                                                onClick={() => {
                                                                    getUpdatedSasUri();
                                                                }}>
                                                            </PrimaryButton>
                                                        </Stack.Item>
                                                    }
                                                    {((props.control.FieldType === 32 || props.control.FieldType === 40) && attachments != null && attachments?.length > 0) &&
                                                        <Stack.Item>
                                                            <PrimaryButton
                                                                styles={removeButtonStyles(theme)}
                                                                onClick={() => {
                                                                    const fileId: string = attachments[0].FileId;
                                                                    const fileName: string = "";
                                                                    setRemoveAttachment({
                                                                        fileName,
                                                                        fileId,
                                                                        onChange,
                                                                        clearFakeError,
                                                                        triggerOnBlur,
                                                                        getValues: methods.getValues
                                                                    });
                                                                }}>
                                                                Remove
                                                            </PrimaryButton>
                                                        </Stack.Item>
                                                    }
                                                </Stack>
                                                {(props.control.FieldType === 25 || attachments == null || attachments?.length === 0) &&
                                                    <Stack.Item>
                                                        <DocumentUpload
                                                            acceptedMimeTypes={getAcceptedMimeTypes()}
                                                            applicationId={appId!}
                                                            pageId={props.subFormField == null ? props.control.PageId : props.subFormField.ParentFormId}
                                                            subFormId={props.subFormField == null ? undefined : props.control.PageId}
                                                            controlId={props.control.Id!}
                                                            description={undefined}
                                                            importData={undefined}
                                                            allowMultipleDocuments={props.control.FieldType === 25}
                                                            buttonLabel={getButtonLabel()}
                                                            onSuccessfulUpload={(result: any, file: File) => {
                                                                const fileId: string = "";
                                                                setAddAttachment({
                                                                    fileName: file.name,
                                                                    fileId: fileId,
                                                                    attachment: result.data,
                                                                    file: props.control.FieldType === 32 || props.control.FieldType === 40 ? file : undefined,
                                                                    onChange,
                                                                    clearFakeError,
                                                                    triggerOnBlur,
                                                                    getValues: methods.getValues
                                                                });
                                                            }}
                                                            onFailedUpload={(error: any, fileName?: string) => {
                                                                //fileName is only null if we have not started making the HTTP request
                                                                if (fileName != null) {
                                                                    const fileId: string = "";
                                                                    setRemoveAttachment({
                                                                        fileName,
                                                                        fileId,
                                                                        onChange,
                                                                        clearFakeError,
                                                                        triggerOnBlur,
                                                                        getValues: methods.getValues
                                                                    });
                                                                }
                                                                handleFailedUploadErrorMessage(error);
                                                            }}
                                                            startingHttpRequest={(fileName: string) => {
                                                                setFakeError();
                                                                const fileId: string = "";
                                                                setStartAdd({
                                                                    fileName,
                                                                    fileId,
                                                                    onChange
                                                                });
                                                            }}
                                                            source={source}>
                                                        </DocumentUpload>
                                                    </Stack.Item>
                                                }
                                            </Stack>
                                            <Stack>
                                                {
                                                    (props.control.FieldType === 24 || props.control.FieldType === 25) &&
                                                    <Stack.Item>
                                                        <DocumentListField
                                                            control={props.control}
                                                            subFormField={props.subFormField}
                                                            attachments={attachments || []}
                                                            hasError={listHasError()}
                                                            removeAttachment={(fileId: string) => {
                                                                const fileName: string = "";
                                                                setRemoveAttachment({
                                                                    fileName,
                                                                    fileId,
                                                                    onChange,
                                                                    clearFakeError,
                                                                    triggerOnBlur,
                                                                    getValues: methods.getValues
                                                                });
                                                            }}
                                                            onSuccessfulUpload={(result: any, fileId: string, file: File) => {
                                                                setAddAttachment({
                                                                    fileName: file.name,
                                                                    fileId: fileId,
                                                                    attachment: result.data,
                                                                    onChange,
                                                                    clearFakeError,
                                                                    triggerOnBlur,
                                                                    getValues: methods.getValues
                                                                });
                                                            }}
                                                            onFailedUpload={(error: any, fileId: string) => {
                                                                const fileName: string = "";
                                                                setRemoveAttachment({
                                                                    fileName,
                                                                    fileId,
                                                                    onChange,
                                                                    clearFakeError,
                                                                    triggerOnBlur,
                                                                    getValues: methods.getValues
                                                                });
                                                                handleFailedUploadErrorMessage(error);
                                                            }}
                                                            startingHttpRequest={(fileId: string) => {
                                                                setFakeError();
                                                                const fileName: string = "";
                                                                setStartAdd({
                                                                    fileName,
                                                                    fileId,
                                                                    onChange
                                                                });
                                                            }}>
                                                        </DocumentListField>
                                                    </Stack.Item>
                                                }
                                                {(props.control.FieldType === 32 &&
                                                    attachments != null &&
                                                    attachments.length > 0 &&
                                                    attachments[0].SasUri != null &&
                                                    attachments[0].SasUri !== "" &&
                                                    attachments[0].SasUriExpirationInSeconds !== undefined) &&
                                                    <Stack.Item>
                                                        <ImageField
                                                            attachmentModel={attachments[0]}>
                                                        </ImageField>
                                                    </Stack.Item>
                                                }
                                                {props.control.FieldType === 40 &&
                                                    <ControlProgressIndicator
                                                        controlId={props.control.Id!}
                                                        label="Video Upload Percentage" />
                                                }
                                                {(props.control.FieldType === 40 &&
                                                    attachments != null &&
                                                    attachments.length > 0 &&
                                                    attachments[0].SasUri != null &&
                                                    attachments[0].SasUri !== "" &&
                                                    attachments[0].SasUriExpirationInSeconds !== undefined) &&
                                                    <Stack.Item>
                                                        <VideoField attachmentModel={attachments[0]}></VideoField>
                                                    </Stack.Item>
                                                }
                                                <Stack.Item>
                                                    <div className="CustomError">
                                                        <ErrorMessage errors={methods.formState.errors} name={props.control.Id!} />
                                                    </div>
                                                </Stack.Item>
                                            </Stack>
                                        </>
                                    )
                                }
                                } />
                        }
                    </ConnectForm>
                    {
                        dialogModalWrapperProps != null &&
                        <DialogModalWrapper dialogModalWrapperProps={dialogModalWrapperProps}></DialogModalWrapper>
                    }
                </>
            }
            {
                props.readOnly &&
                <>
                    <Stack>
                        <Stack horizontal={true} horizontalAlign="space-between" style={{ "paddingBottom": "5px" }}>
                            <Stack.Item>
                                <Label>{props.control.LabelText}</Label>
                            </Stack.Item>
                            <Stack.Item style={{ "paddingRight": "10px" }}>
                                {(props.control.FieldType === 40 &&
                                    attachments != null &&
                                    attachments.length > 0 &&
                                    !hideRefreshVideo) &&
                                    <PrimaryButton
                                        text="Refresh Video"
                                        iconProps={{ iconName: "Refresh" }}
                                        onClick={() => {
                                            getUpdatedSasUri();
                                        }}>
                                    </PrimaryButton>
                                }
                            </Stack.Item>
                        </Stack>
                        {(props.control.FieldType === 24 || props.control.FieldType === 25) &&
                            <Stack.Item>
                                <ReadOnlyDocumentListField
                                    control={props.control}
                                    subFormField={props.subFormField}
                                    attachments={attachments || []}>
                                </ReadOnlyDocumentListField>
                            </Stack.Item>
                        }
                        {(props.control.FieldType === 32 && attachments != null && attachments.length > 0) &&
                            <Stack.Item>
                                <ImageField
                                    attachmentModel={attachments[0]}>
                                </ImageField>
                            </Stack.Item>
                        }
                        {(props.control.FieldType === 40 && attachments != null && attachments.length > 0) &&
                            <Stack.Item>
                                <VideoField attachmentModel={attachments[0]}></VideoField>
                            </Stack.Item>
                        }
                    </Stack>
                </>
            }
        </>
    )
}