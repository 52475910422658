import React from "react";
import { IBasePickerSuggestionsProps, ITag, Label, Stack, TagPicker, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useId } from "@fluentui/react-hooks";
import { Group } from "../../../model/manifest/Group";
import { ConnectForm } from "../form/ConnectForm"

export interface GroupSettingsControlsProps {
    group: Group;
    groups: Group[];
}

export const GroupSettingsControls: React.FC<GroupSettingsControlsProps> = (props: GroupSettingsControlsProps) => {
    const tagPickerId = useId("tagPicker");
    const getTextFromItem = (item: ITag) => item.name;

    const pickerSuggestionsProps = (): IBasePickerSuggestionsProps => {
        var basePickerSuggestions: IBasePickerSuggestionsProps = {
            suggestionsHeaderText: "Suggested items"
        };
        return basePickerSuggestions;
    };

    const onResolveSuggestions = (filterText: string, tagList: ITag[]) => {
        var suggestedTags: ITag[] = []
        if (filterText === "") {
            var sortedList = suggestedTags.sort(function (a, b) {
                var textA = a.name;
                var textB = b.name;
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            // D
            return sortedList.map((i) => {
                return { ...i };
            });
        } else {
            const existingMatches = filterText
                ? suggestedTags
                    .filter(
                        (tag) =>
                            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
                    )
                    .filter((tag) => !listContainsTag(tag, tagList))
                : [];

            return existingMatches.some((a) => a.name === filterText)
                ? existingMatches
                : [
                    { key: filterText, name: filterText, isNewItem: true } as ITag,
                ].concat(existingMatches);
        }
    };

    const listContainsTag = (tag: ITag, tagList?: ITag[]) => {
        if (
            !tagList ||
            !tagList.length ||
            tagList.length === 0
        ) {
            return false;
        }
        return (
            tagList.filter((compareTag) => compareTag.key === tag.key).length > 0
        );
    };

    return (
        <ConnectForm>
            {(methods: any) =>
                <>
                    <Stack.Item>
                        <Controller
                            control={methods.control}
                            name="Name"
                            rules={{
                                required: "Name is required.",
                                maxLength: { value: 100, message: "The max length of Name is 50 characters." },
                                validate: {
                                    unique: (value: string): string | undefined => {
                                        if (value == null || value.length === 0) {
                                            return undefined;
                                        } else {
                                            var foundGroup = props.groups.find(i => i.GroupId !== props.group.GroupId && i.Name === value);
                                            if (foundGroup != null) {
                                                return "The group name must be unique.";
                                            }
                                        }
                                        return undefined;
                                    },
                                },
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextField
                                    label="Name"
                                    required={true}
                                    placeholder="Group name"
                                    value={value}
                                    errorMessage={methods.formState.errors != null && methods.formState.errors["Name"]?.message != null ? methods.formState.errors["Name"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        onChange(newValue);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Controller
                            control={methods.control}
                            name="Description"
                            rules={{
                                required: "Description is required.",
                                maxLength: { value: 2000, message: "The max length of Description is 2000 characters." }
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextField
                                    label="Description"
                                    required={true}
                                    multiline={true}
                                    rows={5}
                                    placeholder="Provides an overview for what the group represents"
                                    value={value}
                                    errorMessage={methods.formState.errors != null && methods.formState.errors["Description"]?.message != null ? methods.formState.errors["Description"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        onChange(newValue);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Controller
                            control={methods.control}
                            name="Tags"
                            rules={{
                                validate: {
                                    maxTagLength: (value: string[]): string | undefined => {
                                        if (value == null || value.length === 0) {
                                            return "Tags are required.";
                                        } else {
                                            var invalidTag = value.find((t) => t.length > 64);
                                            if (invalidTag == null) {
                                                return undefined;
                                            }
                                            return `Max length of ${invalidTag} Tag is 64 characters`;
                                        }
                                    },
                                },
                            }}
                            render={({ field: { onChange, onBlur, value } }) => {
                                var selectedTags = value
                                    ? value.map((i: string) => {
                                        return { key: i, name: i };
                                    })
                                    : [];

                                return (
                                    <>
                                        <Label htmlFor={tagPickerId} className="CustomErrorLabel">Tags</Label>
                                        <div id={tagPickerId}>
                                            <TagPicker
                                                className={methods.formState.errors != null && methods.formState.errors["Tags"] != null ? "PickerFieldErrorBorder" : undefined}
                                                onResolveSuggestions={(filter: string, selectedItems?: ITag[] | undefined) => {
                                                    return onResolveSuggestions(filter, selectedItems !== undefined ? selectedItems : []);
                                                }}
                                                getTextFromItem={getTextFromItem}
                                                pickerSuggestionsProps={pickerSuggestionsProps()}
                                                //inputProps={inputProps}
                                                selectedItems={selectedTags}
                                                onChange={(items?: ITag[] | undefined) => {
                                                    if (items != null && items.length > 0) {
                                                        var itemArray = items.map((i: ITag) => {
                                                            return i.key;
                                                        })
                                                        onChange(itemArray);
                                                    } else {
                                                        onChange([]);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="CustomError">
                                            <ErrorMessage errors={methods.formState.errors} name={"tags"} />
                                        </div>
                                    </>
                                );
                            }}
                        />
                    </Stack.Item>
                </>
            }
        </ConnectForm>
    );
}