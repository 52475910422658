import React, { useEffect, useState } from "react";
import { Checkbox, DefaultButton, DetailsList, DetailsListLayoutMode, DocumentCard, Dropdown, IColumn, IDocumentCardStyles, IDropdownOption, IIconProps, IStackStyles, IStackTokens, Panel, PanelType, SearchBox, SelectionMode, Stack, Text, useTheme } from "@fluentui/react";
import axios, { AxiosRequestConfig } from "axios";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { ICustomerUserGridItem } from "../../../interfaces/users/ICustomerUserGridItem";
import { useParams } from "react-router-dom";
import { removeButtonStyles } from "../../../services/styleService";
import { ManifestUserAuthorizationDto } from "../../../dtos/User/ManifestUserAuthorizationDto";
import { IManifestUserAuthorizationGridItem } from "../../../interfaces/users/IManifestUserAuthoriztionGridItem";
import moment from "moment";
import { getUserRoleOptions } from "../../../services/roleService";
import { UserPersonaGridItem } from "../gridLayout/UserPersonaGridItem";
import { ManifestUserDeleteDto } from "../../../dtos/User/ManifestUserDeleteDto";
import { Pagination } from "@fluentui/react-experiments";
import { RemoveUserFromCustomerModal } from "./RemoveUserFromCustomerModal";

export interface ViewCustomerUserPanelProps {
    customerUser: ICustomerUserGridItem;
    deleteCustomerUser: () => void;
    dismissPanel: () => void;
}

export const ViewCustomerUserPanel: React.FC<ViewCustomerUserPanelProps> = (props: ViewCustomerUserPanelProps) => {
    const [manifestUserAuthorizationGridItems, setManifestUserAuthorizationGridItems] = useState<IManifestUserAuthorizationGridItem[]>([]);
    const [manifestUserPermissionGridItemsToDisplay, setManifestUserPermissionGridItemsToDisplay] = useState<IManifestUserAuthorizationGridItem[]>([]);
    const [filteredManifestUserAuthorizationGridItems, setFilteredManifestUserAuthorizationGridItems] = useState<IManifestUserAuthorizationGridItem[]>([]);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
    const [isRemoveUserModalHidden, setIsRemoveUserModalHidden] = useState(true);
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const [searchTextFilter, setSearchTextFilter] = useState<string>("");
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var { customerId } = useParams();
    const theme = useTheme();
    const itemsPerPage = 5;
    const filterIcon: IIconProps = { iconName: "Filter" };

    useEffect(() => {
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/ManifestUserAuthorization/GetAllForUser",
            params: {
                customerId: customerId,
                emailAddress: props.customerUser.Email
            },
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                var manifestUserAuthorizationDtos: ManifestUserAuthorizationDto[] = result.data;
                var manifestUserAuthorizationGridItems: IManifestUserAuthorizationGridItem[] = [];
                manifestUserAuthorizationDtos.forEach(manifestUserAuthorizationDto => {
                    var manifestUserAuthorizationGridItem: IManifestUserAuthorizationGridItem = {
                        ApplicationId: manifestUserAuthorizationDto.ApplicationId,
                        ApplicationName: manifestUserAuthorizationDto.ApplicationName,
                        CustomerId: manifestUserAuthorizationDto.CustomerId,
                        LastAccessed: manifestUserAuthorizationDto.LastAccessed,
                        Role: manifestUserAuthorizationDto.Role,
                        PreviousRole: manifestUserAuthorizationDto.Role,
                        Disabled: false,
                        Selected: false
                    };
                    manifestUserAuthorizationGridItems.push(manifestUserAuthorizationGridItem);
                });
                setManifestUserAuthorizationGridItems(manifestUserAuthorizationGridItems);
            })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "View Customer Users Component cleaned up");
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //text search on items.
        var matchingItems: any[] = [];
        var items = [...manifestUserAuthorizationGridItems];
        for (var item of items) {
            let textMatch = false;
            var lastAccessedString = moment(item.LastAccessed).format("M/D/YYYY h:mm A");
            if (searchTextFilter === "" ||
                (item.ApplicationName != null && item.ApplicationName.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1) ||
                (lastAccessedString != null && lastAccessedString.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1) ||
                (item.Role != null && item.Role.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1)) {
                textMatch = true;
            }
            if (textMatch) {
                matchingItems.push(item);
            }
        }
        setFilteredManifestUserAuthorizationGridItems(matchingItems);

        //calculate which items to display
        var skipToIndex = selectedPageIndex * itemsPerPage;
        var takeToIndex = skipToIndex + itemsPerPage;
        setManifestUserPermissionGridItemsToDisplay(matchingItems.slice(skipToIndex, takeToIndex));

        //check to see if page count is less than current selection.  if so adjust it down accordingly
        var pageCount = Math.ceil(matchingItems.length / itemsPerPage);
        var pageCountIndex = pageCount - 1;
        if (pageCountIndex < 0) {
            setSelectedPageIndex(0);
        } else if (selectedPageIndex + 1 > pageCountIndex) {
            setSelectedPageIndex(pageCountIndex);
        }
    }, [manifestUserAuthorizationGridItems, selectedPageIndex, searchTextFilter]);

    const columns: IColumn[] = [
        {
            key: "Selected",
            name: "",
            fieldName: "selected",
            minWidth: 25,
            maxWidth: 25,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "boolean",
            onRender: (item: IManifestUserAuthorizationGridItem) => {
                var isDisabled = item.Disabled ||
                    props.customerUser.CustomerUserRoleType === "Owner" ||
                    props.customerUser.CustomerUserRoleType === "Global Application Administrator" ||
                    props.customerUser.CustomerUserRoleType === "Global Application Builder";

                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        {!isDisabled
                            ?
                            <Checkbox
                                checked={item.Selected}
                                disabled={isDisabled}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                    var authorizations = [...manifestUserAuthorizationGridItems];
                                    var rowItem = authorizations.find(i => i.ApplicationId === item.ApplicationId)!;
                                    rowItem.Selected = isChecked ? true : false;
                                    setManifestUserAuthorizationGridItems(authorizations);
                                }} />
                            :
                            <></>
                        }

                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "ApplicationName",
            name: "Application Name",
            fieldName: "applicationName",
            minWidth: 225,
            maxWidth: 525,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            // isSorted: true,
            // isSortedDescending: false,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: this._onColumnClick,
            data: "string",
            onRender: (item: IManifestUserAuthorizationGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {item.ApplicationName}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Role",
            name: "Role",
            fieldName: "role",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IManifestUserAuthorizationGridItem) => {
                var isDisabled = item.Disabled ||
                    props.customerUser.CustomerUserRoleType === "Owner" ||
                    props.customerUser.CustomerUserRoleType === "Global Application Administrator" ||
                    props.customerUser.CustomerUserRoleType === "Global Application Builder";
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Dropdown
                            selectedKey={item.Role}
                            disabled={isDisabled}//TODO: do disable logic above
                            options={getUserRoleOptions()}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                var authorizations = [...manifestUserAuthorizationGridItems];
                                var rowItem = authorizations.find(i => i.ApplicationId === item.ApplicationId)!;
                                rowItem.PreviousRole = rowItem.Role;
                                rowItem.Role = option!.key.toString();
                                rowItem.Disabled = true;
                                setManifestUserAuthorizationGridItems(authorizations);

                                const CancelToken = axios.CancelToken;
                                const source = CancelToken.source();
                                const options: AxiosRequestConfig = {
                                    method: "put",
                                    url: "/ManifestUserPermission/Put",
                                    params: {
                                        applicationId: item.ApplicationId
                                    },
                                    data: {
                                        emailAddress: props.customerUser.Email,
                                        role: rowItem.Role
                                    },
                                    cancelToken: source.token
                                };
                                axios(options)
                                    .then((result) => {
                                        authorizations = [...manifestUserAuthorizationGridItems];
                                        rowItem = authorizations.find(i => i.ApplicationId === item.ApplicationId)!;
                                        rowItem.PreviousRole = "";
                                        rowItem.Disabled = false;
                                        setManifestUserAuthorizationGridItems(authorizations);
                                    })
                                    .catch((error) => {
                                        if (error.response != null) {
                                            authorizations = [...manifestUserAuthorizationGridItems];
                                            rowItem = authorizations.find(i => i.ApplicationId === item.ApplicationId)!;
                                            rowItem.Role = rowItem.PreviousRole;
                                            rowItem.Disabled = false;
                                            setManifestUserAuthorizationGridItems(authorizations);
                                        }
                                    });
                            }} />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "LastAccessed",
            name: "Last Accessed",
            fieldName: "lastAccessed",
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IManifestUserAuthorizationGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {item.LastAccessed != null ? moment(item.LastAccessed).format("M/D/YYYY h:mm A") : ""}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        }
    ];

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack horizontal={true} horizontalAlign={"end"}>
                <DefaultButton
                    text="Remove User From Customer Group"
                    ariaDescription="Remove User From Customer Group button."
                    styles={removeButtonStyles(theme)}
                    onClick={() => setIsRemoveUserModalHidden(false)}
                    disabled={false}
                    allowDisabledFocus />
            </Stack>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const anySelected = (): boolean => {
        return manifestUserAuthorizationGridItems.findIndex(i => i.Selected) === -1 ? false : true;
    };

    const deleteSelected = (): void => {
        setIsDeleteButtonDisabled(true);
        var selected = manifestUserAuthorizationGridItems.filter(i => i.Selected);
        var manifestUserDeleteDtos: ManifestUserDeleteDto[] = selected.map((i) => ({ ApplicationId: i.ApplicationId, EmailAddress: props.customerUser.Email }));

        const options: AxiosRequestConfig = {
            method: "delete",
            url: "/ManifestUserAuthorization/Delete",
            params: {
                customerId: customerId
            },
            data: manifestUserDeleteDtos,
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {
                var userAuths = [...manifestUserAuthorizationGridItems];
                manifestUserDeleteDtos.forEach(manifestUserDeleteDto => {
                    userAuths = userAuths.filter(i => i.ApplicationId !== manifestUserDeleteDto.ApplicationId);
                });
                setManifestUserAuthorizationGridItems(userAuths);
                setIsDeleteButtonDisabled(false);
            })
            .catch((error) => {
                setIsDeleteButtonDisabled(false);
                if (error.response == null) {
                    console.log("Request canceled", "View Customer User Panel Component cleaned up");
                } else {
                    //TODO: Handle validation errors
                    var dialogProps = {
                        isVisible: true,
                        title: "Uh oh",
                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                        isBlocking: true,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
    };

    const getPageCount = (): number => {
        return Math.ceil(filteredManifestUserAuthorizationGridItems.length / itemsPerPage);
    }

    const pageChange = (index: number): void => {
        setSelectedPageIndex(index);
    }

    return (
        <Panel
            headerText="Customer User"
            isOpen={true}
            onDismiss={props.dismissPanel}
            isBlocking={true}
            type={PanelType.large}
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <Stack tokens={themedMediumStackTokens}>
                <Stack.Item>
                    <UserPersonaGridItem
                        avatar={props.customerUser.Avatar}
                        fullName={props.customerUser.FullName}
                        emailAddress={props.customerUser.Email}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Text variant={"xLarge"}>
                        Application Roles
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack tokens={themedSmallStackTokens}>
                        <DocumentCard styles={documentCardStyles}>
                            <Stack.Item>
                                <Stack horizontal={true} horizontalAlign="space-between" tokens={themedSmallStackTokens} wrap>
                                    <Stack.Item>
                                        <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                            <Text variant={"mediumPlus"}>
                                                Total
                                            </Text>
                                            <div className="bolt-pill">
                                                <div className="bolt-pill-content">
                                                    {manifestUserAuthorizationGridItems != null ? manifestUserAuthorizationGridItems.length : ""}
                                                </div>
                                            </div>
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                            <Stack.Item>
                                                <SearchBox
                                                    placeholder="Filter"
                                                    iconProps={filterIcon}
                                                    value={searchTextFilter}
                                                    onChange={(_, newValue) => {
                                                        setSearchTextFilter(newValue || "");
                                                    }}
                                                />
                                            </Stack.Item>
                                            {anySelected() &&
                                                <Stack.Item>
                                                    <DefaultButton
                                                        text="Remove from Application(s)"
                                                        ariaDescription="Remove from applications button."
                                                        styles={removeButtonStyles(theme)}
                                                        onClick={() => deleteSelected()}
                                                        disabled={isDeleteButtonDisabled}
                                                        allowDisabledFocus />
                                                </Stack.Item>
                                            }
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack tokens={themedSmallStackTokens}>
                                <Stack.Item>
                                    <DetailsList
                                        items={manifestUserPermissionGridItemsToDisplay != null ? manifestUserPermissionGridItemsToDisplay : []}
                                        compact={true}
                                        columns={columns}
                                        selectionMode={SelectionMode.none}
                                        setKey="none"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Pagination
                                        selectedPageIndex={selectedPageIndex}
                                        pageCount={getPageCount()}
                                        itemsPerPage={itemsPerPage}
                                        totalItemCount={filteredManifestUserAuthorizationGridItems.length}
                                        format={"buttons"}
                                        previousPageAriaLabel={"previous page"}
                                        nextPageAriaLabel={"next page"}
                                        firstPageAriaLabel={"first page"}
                                        lastPageAriaLabel={"last page"}
                                        pageAriaLabel={"page"}
                                        selectedAriaLabel={"selected"}
                                        onPageChange={pageChange} />
                                </Stack.Item>
                            </Stack>
                        </DocumentCard>
                    </Stack>
                </Stack.Item>
            </Stack>
            <RemoveUserFromCustomerModal
                customerUser={props.customerUser}
                isHidden={isRemoveUserModalHidden}
                deleteCustomerUser={() => {
                    setIsRemoveUserModalHidden(true);
                    props.deleteCustomerUser();
                }}
                cancel={() => setIsRemoveUserModalHidden(true)}>
            </RemoveUserFromCustomerModal>
        </Panel>
    );
}

const themedMediumStackTokens: IStackTokens = {
    childrenGap: "m",
    padding: "m",
};

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};
const columnStackStyles: IStackStyles = {
    root: {
        height: "100%"
    }
};
const documentCardStyles: IDocumentCardStyles = {
    root: {
        boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
        borderRadius: "4px",
        maxWidth: "",
        minWidth: ""
    }
};