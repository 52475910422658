import React from "react";
import { applicationManifestState } from "../../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { useParams } from "react-router-dom";
import { ITextProps, IStackItemStyles, Stack, Text } from "@fluentui/react";

export const CustomFormHeader: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    var { formId } = useParams();

    const getFormHeader = (): string => {
        var form = applicationManifest.Pages.find(i => i.Id === formId);
        return form!.HeaderText;
    }

    const stackItemStyles: IStackItemStyles = {
        root: {
            paddingTop: "10px",
            paddingBottom: "15px"
        }
    };

    return (
        <Stack.Item styles={stackItemStyles}>
            <Text variant={"xLarge" as ITextProps['variant']}>
                {getFormHeader()}
            </Text>
        </Stack.Item>
    );
}