import React from "react";
import { DefaultButton } from "@fluentui/react";
import { resetFieldSpecificState } from "../../../recoil/atoms";
import { ConnectForm } from "./ConnectForm";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { FieldValues, UseFormReturn } from "react-hook-form";

export interface CancelFormButtonProps {
    applicationEntity: ApplicationEntity | undefined;
    displayBackToSearchButtonIfAvailable: boolean;
    shimmerControlsOnSubmit: boolean;
    onEditModeChange: (isEditMode: boolean) => void;
    /** Hide modal is required when using a panel or the functionality will not work */
    hideModal?: () => void;
}

export const CancelFormButton: React.FC<CancelFormButtonProps> = (props: CancelFormButtonProps) => {
    const setResetFieldSpecificState = useSetRecoilState(resetFieldSpecificState);
    const navigate = useNavigate();
    var { appId, formId } = useParams();

    return (
        <ConnectForm>
            {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                <DefaultButton
                    text="Cancel"
                    iconProps={{ iconName: "Cancel" }}
                    disabled={props.shimmerControlsOnSubmit === false ? true : false}
                    onClick={() => {
                        //if the application entity does not have an ID we know its an add.
                        if (props.applicationEntity?.Id == null) {
                            //if the back to search button is available we know we are on a route (page) so navigate back to search
                            if (props.displayBackToSearchButtonIfAvailable) {
                                //in add mode so sending back to search and resetting fields (if not it would act like Reset)
                                navigate(`/Application/${appId}/Form/${formId}/Search`, { replace: true });
                            } else {
                                //if the back to search button is missing we know we are in a modal so cancel and close modal.
                                props.hideModal!();
                            }
                        } else {
                            //in edit mode on a current entity
                            methods.reset();
                            props.onEditModeChange(false);
                            setResetFieldSpecificState(true);
                            setTimeout(() => {
                                setResetFieldSpecificState(false);
                            }, 50);
                        }
                    }}
                />
            }
        </ConnectForm>
    );
}