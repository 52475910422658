import React from "react";
import { TextField, TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../model/manifest/ControlData";
import { useId } from "@fluentui/react-hooks";
import { ViewDropdownRecord } from "./list/helpers/ViewDropdownRecord";

export interface ReadOnlyDropdownFieldProps {
    control: ControlData;
    textValue: string;
    value: string;
}

export const ReadOnlyDropdownField: React.FC<ReadOnlyDropdownFieldProps> = (props: ReadOnlyDropdownFieldProps) => {
    const textFieldId = useId("textField");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textFieldId}`
    };

    return (
        <>
            <TooltipHost
                content={props.control.ToolTipText}
                id={tooltipId}
                calloutProps={calloutProps}
            >
                <ViewDropdownRecord
                    control={props.control}
                    value={props.value}
                    dropdownId={textFieldId}
                    isRequired={false}
                />
                <TextField
                    id={textFieldId}
                    multiline={false}
                    borderless
                    value={props.textValue}
                    readOnly={true} />
            </TooltipHost>
        </>
    )
}