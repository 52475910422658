import React from "react";
import { Breadcrumb, IBreadcrumbItem } from "@fluentui/react";
import { useRecoilValue } from "recoil";
import { breadcrumbsState } from "../../recoil/atoms";
import getScreenSize from "../../services/getScreenSize";

export const NavBreadcrumb: React.FC = () => {
    const breadcrumbs: IBreadcrumbItem[] = useRecoilValue(breadcrumbsState);
    const screenSize: number = getScreenSize();

    const maxItems = (): number => {
        if (screenSize < 600) {
            return 0;
        }
        return 5;
    }

    function getStyle(): React.CSSProperties {
        if (screenSize < 600) {
            return { "paddingLeft": "25px", "marginTop": "12px" };
        };
        return { "paddingLeft": "25px", "marginTop": "5px" };
    }

return (
    <>
        {breadcrumbs != null && breadcrumbs.length > 0 &&
            <Breadcrumb
                items={breadcrumbs}
                maxDisplayedItems={maxItems()}
                ariaLabel="Breadcrumb with items rendered as links"
                overflowAriaLabel="More links"
                style={getStyle()}
            />
        }
    </>
);
}