import React from "react";
import { SignOut } from "./SignOut";
import { ThemeToggle } from "./ThemeToggle";
import {
    Callout,
    DirectionalHint,
    FontSizes,
    FontWeights,
    IPersonaSharedProps,
    IStackItemStyles,
    IStackStyles,
    mergeStyleSets,
    Persona,
    PersonaSize,
    Stack,
    Text
} from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { CustomerSummaryDto } from "../../dtos/User/CustomerSummaryDto";
import { CustomerSubscriptions } from "./CustomerSubscriptions";

export interface IMeCalloutProps {
    myPersona: IPersonaSharedProps;
    companyName: string;
    jobTitle: string;
    customerSummaries: CustomerSummaryDto[] | undefined;
    close: () => void;
}

export const MeCallout: React.FC<IMeCalloutProps> = (props: IMeCalloutProps) => {
    const { instance } = useMsal();
    const accountInfo = instance.getActiveAccount();

    return (
        <>
            <Callout
                className={styles.callout}
                gapSpace={6}
                target={document.getElementById("myPersona")}
                isBeakVisible={false}
                onDismiss={() => props.close()}
                directionalHint={DirectionalHint.bottomAutoEdge}
                setInitialFocus={true}
            >
                <Stack horizontal={true} styles={headerCalloutStyles}>
                    <Stack.Item styles={titleItemStyles}>
                        <Text>
                            {/* Intact Partners Inc */}
                        </Text>
                    </Stack.Item>
                    <Stack.Item styles={signOutItemStyles}>
                        <SignOut></SignOut>
                    </Stack.Item>
                </Stack>
                <Stack horizontal={true} styles={innerCalloutStyles}>
                    <Stack.Item styles={userImageItemStyles}>
                        <Persona
                            {...props.myPersona}
                            size={PersonaSize.size72}
                            imageAlt="User image"
                        />
                    </Stack.Item>
                    <Stack horizontal={false} styles={userInfoStackStyles}>
                        <Stack.Item styles={stackItemStyles}>
                            {accountInfo?.name != null &&
                                <Text styles={boldSubTextStyles}>
                                    {accountInfo.name}
                                </Text>
                            }
                        </Stack.Item>
                        {props.jobTitle !== "" &&
                            <Stack.Item styles={stackItemStyles}>
                                <Text>
                                    {props.jobTitle}
                                </Text>
                            </Stack.Item>
                        }
                        <Stack.Item styles={stackItemStyles}>
                            <Text>
                                {accountInfo?.username}
                            </Text>
                        </Stack.Item>
                    </Stack>
                </Stack>
                <CustomerSubscriptions 
                    customerSummaries={props.customerSummaries}
                    close={props.close} />
                <Stack horizontal={true} styles={footerCalloutStyles}>
                    <Stack.Item styles={signOutItemStyles}>
                        <Text>
                            Theme:&nbsp;&nbsp;
                        </Text>
                        <ThemeToggle></ThemeToggle>
                    </Stack.Item>
                </Stack>
            </Callout>
        </>
    );
}

const styles = mergeStyleSets({
    callout: {
        display: "flex",
        width: "450px"
    }
});
const headerCalloutStyles: IStackStyles = {
    root: {
        padding: "18px 24px 12px",
        display: "flex"
    }
}
const titleItemStyles: IStackItemStyles = {
    root: {
        display: "flex",
        fontWeight: FontWeights.semilight
    }
};
const signOutItemStyles: IStackItemStyles = {
    root: {
        display: "flex",
        marginLeft: "auto !important"
    }
};
const innerCalloutStyles: IStackStyles = {
    root: {
        padding: "0 24px 20px",
    }
}
const userImageItemStyles: IStackItemStyles = {
    root: {
        display: "flex",
    }
};
const userInfoStackStyles: IStackStyles = {
    root: {
        paddingTop: "2px",
    }
};
const stackItemStyles: IStackItemStyles = {
    root: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        fontSize: FontSizes.size18
    }
};
const boldSubTextStyles: IStackItemStyles = {
    root: {
        fontSize: FontSizes.size18,
        fontWeight: FontWeights.bold
    }
};
const footerCalloutStyles: IStackStyles = {
    root: {
        display: "flex"
    }
}