import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { DefaultButton, IStackTokens, PrimaryButton, Stack, useTheme } from "@fluentui/react";
import { applicationManifestState, dialogModalWrapperPropsState, groupSettingsState } from "../../../recoil/atoms";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import axios, { AxiosRequestConfig } from "axios";
import { Group } from "../../../model/manifest/Group";
import { FormProvider, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { ValidationError } from "../../../model/error/ValidationError";
import { GroupSettingsControls } from "./GroupSettingsControls";
import { useRecoilState } from "recoil";
import { removeButtonStyles } from "../../../services/styleService";
import { useNavigate } from "react-router-dom";
import { GroupUpdateDto } from "../../../dtos/ApplicationSettings/GroupUpdateDto";

export interface GroupSettingsFormProps {
    group: Group;
}

export const GroupSettingsForm: React.FC<GroupSettingsFormProps> = (props: GroupSettingsFormProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [groups, setGroupsState]: [Group[], (groups: Group[]) => void] = useRecoilState(groupSettingsState);
    const defaultValues = {
        Name: props.group.Name,
        Description: props.group.Description,
        Tags: props.group.Tags
    }
    const methods = useForm({ defaultValues: defaultValues, mode: "onChange" });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const theme = useTheme();
    const navigate = useNavigate();

    const themedSmallStackTokens: IStackTokens = {
        childrenGap: "s1",
        padding: "s1",
    };

    const submitForm = async () => {
        setIsSubmitting(true);
        var data = methods.getValues();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        var groupUpdateDto: GroupUpdateDto = {
            GroupId: props.group.GroupId,
            ApplicationId: applicationManifest.Id!,
            Description: data.Description,
            Inactive: false,//TODO: no inactive yet
            Name: data.Name,
            Tags: data.Tags,
            Type: "Native"
        }
        const options: AxiosRequestConfig = {
            method: "PUT",
            data: groupUpdateDto,
            url: "/Group/Put",
            params: {
                applicationId: applicationManifest.Id
            },
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                var groupsArray = [...groups];
                var group = groupsArray.find(i => i.GroupId === groupUpdateDto.GroupId)!;
                group.Description = groupUpdateDto.Description;
                group.Inactive = groupUpdateDto.Inactive;
                group.Name = groupUpdateDto.Name;
                group.Tags = groupUpdateDto.Tags;
                group.Type = groupUpdateDto.Type;
                setGroupsState(groupsArray);
                setIsSubmitting(false);
            }).catch((error) => {
                setIsSubmitting(false);
                if (error.response == null) {
                    console.log("Request canceled", "New Group Panel Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        //set errors manually
                        validationErrors.forEach((validationError: ValidationError) => {
                            methods.setError(validationError.GroupIdentifier as any, {
                                type: "manual",
                                message: validationError.Message
                            })
                        });
                    } else {
                        var dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
    }

    const deleteSelected = (): void => {
        var dialogProps = {
            isVisible: true,
            title: "Delete Group",
            subText: `By deleting ${props.group.Name} will remove all members associated to this group as well.`,
            isBlocking: true,
            primaryButtonText: "OK",
            secondaryButtonText: "Cancel",
            onDismiss: () => {
                setDialogModalWrapperPropsState(undefined);
                setIsSubmitting(true);

                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                const options: AxiosRequestConfig = {
                    method: "delete",
                    url: "/Group/Delete",
                    params: {
                        applicationId: applicationManifest.Id,
                        groupId: props.group.GroupId
                    },
                    cancelToken: source.token
                };

                axios(options)
                    .then((result) => {
                        setIsSubmitting(false);
                        var groupArray = [...groups];
                        groupArray = groupArray.filter(i => i.GroupId !== props.group.GroupId);
                        setGroupsState(groupArray);
                        navigate(`/Application/${applicationManifest.Id}/ApplicationSettings`, { replace: true });
                    })
                    .catch((error) => {
                        setIsSubmitting(false);
                        if (error.response == null) {
                            console.log("Request canceled", "Group Settings Form Component cleaned up");
                        } else {
                            var dialogProps = {
                                isVisible: true,
                                title: "Uh oh",
                                subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                                isBlocking: true,
                                primaryButtonText: "OK",
                                secondaryButtonText: undefined,
                                onDismiss: () => {
                                    setDialogModalWrapperPropsState(undefined);
                                },
                                onSecondaryButtonDismiss: () => { }
                            }
                            setDialogModalWrapperPropsState(dialogProps);
                        }
                    });
            },
            onSecondaryButtonDismiss: () => {
                setDialogModalWrapperPropsState(undefined);
            }
        }
        setDialogModalWrapperPropsState(dialogProps);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submitForm)}>
                <Stack tokens={themedSmallStackTokens}>
                    <GroupSettingsControls
                        group={props.group}
                        groups={groups}>
                    </GroupSettingsControls>
                </Stack>
                <Stack horizontal={true} horizontalAlign={"space-between"}>
                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                        <DefaultButton
                            text="Delete"
                            ariaDescription="Delete group button."
                            styles={removeButtonStyles(theme)}
                            onClick={deleteSelected}
                            disabled={isSubmitting}
                            allowDisabledFocus />
                    </Stack>
                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                        <PrimaryButton
                            styles={buttonStyles}
                            onClick={submitForm}
                            disabled={!methods.formState.isValid || !methods.formState.isDirty || isSubmitting}>
                            Save
                        </PrimaryButton>
                        <DefaultButton
                            onClick={() => { methods.reset() }}
                            disabled={isSubmitting}>
                            Cancel
                        </DefaultButton>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
}

const buttonStyles = { root: { marginRight: 8 } };