import moment from "moment";

export const hasTrialEnded = (billingStatus: string, trialEndDate: Date): boolean => {
    if (billingStatus !== "Trial") {
        return false;
    }

    var utcNow = moment().utc();
    var endDate = moment(trialEndDate);
    if (utcNow > endDate) {
        return true;
    }

    return false;
};