import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { IIconProps, IPersonaSharedProps, IStackTokens, IconButton, Persona, PersonaSize, Stack, Text, TextField, getInitials } from "@fluentui/react";
import moment from "moment";
import "./CommentContent.css";
import { useId } from "@fluentui/react-hooks";
import { CommentModel } from "../../../../model/reaccessData/CommentModel";
import { useParams } from "react-router-dom";
import { NewComment } from "./NewComment";
import { useMsal } from "@azure/msal-react";
import { DeleteCommentButton } from "./DeleteCommentButton";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface CommentContentProps {
    control: ControlData;
    comment: CommentModel;
    comments: CommentModel[];
    index: number;
    cascadingLevel: number;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    addNewComment: (parentId: string | undefined) => void;
    cancelNewComment: (indexOfComment: number) => void;
    update: (index: number, value: unknown) => void;
    subFormField: ISubFormField | undefined;
}

export const CommentContent: React.FC<CommentContentProps> = (props: CommentContentProps) => {
    const textFieldId = useId("textField");
    var { entityId } = useParams();
    const { instance } = useMsal();
    const accountInfo = instance.getActiveAccount();
    const replyIcon: IIconProps = { iconName: "Reply" };

    const getPersona = (): IPersonaSharedProps => {
        return {
            imageUrl: props.comment.InsertedByImage,
            imageInitials: getInitials(props.comment.InsertedByName, false),
            imageShouldFadeIn: true
        }
    };

    const themedSmallStackTokens: IStackTokens = {
        childrenGap: 's',
        padding: 's',
    };

    const themedSmallChildStackTokens: IStackTokens = {
        childrenGap: 's',
        padding: `10px 0px 0px 60px`,
    };

    const themedActionStackTokens: IStackTokens = {
        padding: `0px 0px 0px 20px`,
    };

    const calculateMessageTime = (): string => {
        var commentTime = props.comment.InsertedUtc;
        var momentMessageDateTime = moment(commentTime);
        var now = moment();
        if (now.startOf("day") < momentMessageDateTime) {
            return "Today at " + momentMessageDateTime.format("h:mm A");
        }
        now = moment();
        if (now.add(-1, "day").startOf("day") < momentMessageDateTime) {
            return "Yesterday at " + momentMessageDateTime.format("h:mm A");
        }
        now = moment();
        var temp = now.diff(momentMessageDateTime, "days") + " days ago"
        return temp;
    }

    const replyToComment = (): void => {
        props.addNewComment(props.comment.Id);
    }

    const myReplies = (): CommentModel[] => {
        if (props.cascadingLevel <= props.control.ReplyLevels!) {
            return props.comments.filter(i => i.ParentId === props.comment.Id && i.Id != null).slice(0, props.control.ReplyLevels! - props.cascadingLevel);
        }
        return [];
    }

    const indexOfComment = (): number => {
        return props.comments.findIndex(i => i.Id === props.comment.Id);
    }

    const indexOfNewComment = (): number => {
        return props.comments.findIndex(i => i.ParentId === props.comment.Id && i.Id == null);
    }

    const newComment = (): CommentModel | undefined => {
        var replies = props.comments.filter(i => i.ParentId === props.comment.Id && i.Id == null);
        return replies.find(i => i.Id == null);
    }

    const showReplyToCommentLink = (): boolean => {
        return newComment() == null && !props.readOnly && props.cascadingLevel < props.control.ReplyLevels!;
    }

    const showDeleteCommentLink = (): boolean => {
        return !props.readOnly && !props.comment.IsDeleted && props.comment.InsertedByEmail === accountInfo?.username.toLowerCase();
    }

    return (
        <>
            {entityId != null &&
                <>
                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                        <Stack.Item align="center">
                            <Persona
                                {...getPersona()}
                                id={"myPersona"}
                                size={PersonaSize.size48}
                                imageAlt={"Initials of user: " + props.comment.InsertedByName}
                            />
                        </Stack.Item>
                        <Stack.Item style={{ "width": "100%" }}>
                            <Stack horizontal={false} verticalAlign="center" style={{ "width": "100%" }}>
                                <Stack horizontal={true} tokens={themedSmallStackTokens} verticalAlign="center">
                                    <Stack.Item>
                                        <Text variant={"mediumPlus"} style={{ "fontWeight": "bold" }}>
                                            {props.comment.InsertedByName}
                                        </Text>
                                    </Stack.Item>
                                    <Stack.Item style={{ "paddingLeft": "15px" }}>
                                        <Text variant={"smallPlus"} style={{ "color": "grey" }}>
                                            {calculateMessageTime()}
                                        </Text>
                                    </Stack.Item>
                                </Stack>
                                <Stack.Item>
                                    <TextField
                                        id={textFieldId}
                                        className="comment-content"
                                        multiline={props.comment.Text.length > 1000 ? true : false}
                                        rows={5}
                                        borderless
                                        value={props.comment.Text}
                                        readOnly={true} />
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedActionStackTokens}>
                                        {(showReplyToCommentLink()) &&
                                            <Stack.Item>
                                                <IconButton 
                                                    iconProps={replyIcon} 
                                                    aria-label="Reply to comment"
                                                    onClick={replyToComment} />
                                            </Stack.Item>
                                        }
                                        {(showDeleteCommentLink()) &&
                                            <Stack.Item>
                                                <DeleteCommentButton
                                                    control={props.control}
                                                    readOnly={props.readOnly}
                                                    index={indexOfComment()}
                                                    update={props.update}
                                                    subFormField={props.subFormField} />
                                            </Stack.Item>
                                        }
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    {myReplies().map((replyComment, index) => {
                        return (
                            <Stack
                                tokens={themedSmallChildStackTokens}
                                key={`CommentChildren-${replyComment.ParentId}-${index}`}>
                                <Stack.Item>
                                    <CommentContent
                                        control={props.control}
                                        comment={replyComment}
                                        comments={props.comments}
                                        index={props.index}
                                        cascadingLevel={props.cascadingLevel + 1}
                                        shouldValidate={props.shouldValidate}
                                        disabled={props.disabled}
                                        readOnly={props.readOnly}
                                        isRequired={props.isRequired}
                                        entityId={props.entityId}
                                        addNewComment={(parentId: string | undefined) => { props.addNewComment(parentId); }}
                                        cancelNewComment={(indexOfComment: number) => { props.cancelNewComment(indexOfComment); }}
                                        update={props.update}
                                        subFormField={props.subFormField}
                                    />
                                </Stack.Item>
                            </Stack>
                        )
                    })}
                    <Stack>
                        {newComment() != null &&
                            <Stack.Item>
                                <NewComment
                                    control={props.control}
                                    comment={newComment()!}
                                    comments={props.comments}
                                    index={indexOfNewComment()}
                                    shouldValidate={props.shouldValidate}
                                    disabled={props.disabled}
                                    readOnly={props.readOnly}
                                    entityId={props.entityId}
                                    isRequired={props.isRequired}
                                    cancelNewComment={(indexOfComment: number) => { props.cancelNewComment(indexOfComment); }}
                                    update={props.update}
                                    subFormField={props.subFormField}
                                />
                            </Stack.Item>
                        }
                    </Stack>
                </>
            }
        </>
    );
}