import React, { useEffect, useState } from "react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { IBasePickerStyles, IBasePickerSuggestionsProps, IInputProps, ITag, TagPicker, TooltipHost, useTheme } from "@fluentui/react";
import { ConnectForm } from "../../../form/ConnectForm";
import { Controller, FieldValues, UseFormReturn, useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { applicationManifestState, globalBusinessRulesState, resetFieldSpecificState } from "../../../../../recoil/atoms";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { getRules } from "../../../../../services/ruleService";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { useId } from "@fluentui/react-hooks";
import { removeDashFromGuid } from "../../../../../services/stringManipulationService";
import { ErrorMessage } from "@hookform/error-message";
import axios, { CancelTokenSource } from "axios";
import { useParams } from "react-router-dom";
import { DataSourceItem } from "../../../../../model/manifest/DataSourceItem";
import { addDefaultSearchTextValue } from "../../../../../services/dataSourceService";
import { ViewDropdownRecord } from "../helpers/ViewDropdownRecord";
import { ReadOnlyDropdownField } from "../../ReadOnlyDropdownField";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { buildFormPropertyName } from "../../../../../services/fieldService";
import { cloneDeep } from "lodash";

export interface TypeAndSearchOnServerFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    isDropDown: boolean;
    isSearch: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const TypeAndSearchOnServerField: React.FC<TypeAndSearchOnServerFieldProps> = (props: TypeAndSearchOnServerFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const resetFieldSpecific : boolean = useRecoilValue(resetFieldSpecificState);
    const [selectableTags, setSelectableTags] = useState<ITag[] | undefined>([]);
    const [textFilterLength, setTextFilterLength] = useState<number>(0);
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>();
    const methods = useFormContext();
    const theme = useTheme();
    var { appId } = useParams();
    const CancelToken = axios.CancelToken;
    const dropdownId = useId("dropdown");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${dropdownId}`
    };

    useEffect(() => {
        if (selectableTags != null) { return; }

        var myCurrentValue = methods.getValues(buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField));
        if (myCurrentValue == null || myCurrentValue === "") return;

        var tags: ITag[] = [];
        if (props.isSearch && props.control.DefaultSearchTextValue != null && props.control.DefaultSearchTextValue !== "") {
            var tag: ITag = {
                key: props.control.DefaultSearchTextValue,
                name: props.control.DefaultSearchTextValue
            }
            tags.push(tag);
            setSelectableTags(tags);
        } else {
            const source = CancelToken.source();
            setCancelTokenSource(source);
            axios.get(`/DataSource/GetByControlId?ApplicationId=${appId}&PageId=${props.control.PageId}&ControlId=${props.control.Id}&IsDropDown=${props.isDropDown}&Id=${myCurrentValue}&UniqueTextValues=false`,
                { cancelToken: source.token })
                .then((result) => {
                    if (result.data == null) {
                        setSelectableTags([]);
                    }

                    var tag: ITag = {
                        key: result.data.Value,
                        name: result.data.Text
                    }
                    tags.push(tag);
                    setSelectableTags(tags);
                })
                .catch((error) => {
                    setSelectableTags([]);
                    return [];
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectableTags]);

    useEffect(() => {
        if (!resetFieldSpecific) {
            return;
        }
        //reset the selectable tags so that the default value can be set again in the useeffect above
        setSelectableTags(undefined);
    }, [resetFieldSpecific]);

    const getTextValue = (value: string): string => {
        if (selectableTags == null || selectableTags.length === 0 || value == null || value === "") {
            return "";
        }
        return selectableTags.find(i => i.key === value)?.name ?? "";
    };

    const filterSuggestedTags = async (filterText: string, tagList: ITag[]): Promise<ITag[]> => {
        if (cancelTokenSource != null) {
            cancelTokenSource.cancel();
        }
        setTextFilterLength(filterText == null ? 0 : filterText.length);
        if (filterText == null || filterText.length < 3) {
            return [];
        }
        const source = CancelToken.source();
        setCancelTokenSource(source);
        return axios.get(`/DataSource/GetBySearchValue?ApplicationId=${appId}&PageId=${props.control.PageId}&FieldId=${props.control.Id}&IsDropDown=${props.isDropDown}&UniqueTextValues=${props.isSearch}&SearchValue=${filterText}`,
            { cancelToken: source.token })
            .then((result) => {
                setCancelTokenSource(undefined);
                var dataSourceItems: DataSourceItem[] = result.data;
                var formattedTags: ITag[] = [];

                //Get both types of data sources
                var dataSource = applicationManifest.DataSources.find(i => i.Name === props.control.DataSourceName);
                var grid = applicationManifest.Grids.find(i => i.Id === props.control.GridId);

                //if datasource has a page id or a grid is found we know these are from the server
                if ((dataSource != null && dataSource.PageId != null && dataSource.PageId !== "") || grid != null) {
                    if (props.entityId != null && props.entityId !== "") {
                        var textEntityGuid = removeDashFromGuid(props.entityId);
                        formattedTags = dataSourceItems.filter(i => i.Value !== textEntityGuid).map(i => ({ key: i.Value, name: i.Text }));
                    } else {
                        formattedTags = dataSourceItems.map(i => ({ key: i.Value, name: i.Text }));
                    }
                } else {
                    formattedTags = dataSourceItems.map(i => ({ key: i.Value, name: i.Text }));
                }

                //add default search text value if needed
                formattedTags = addDefaultSearchTextValue(formattedTags, props.control, props.isSearch);

                setSelectableTags(formattedTags);
                return formattedTags;
            })
            .catch((error) => {
                setSelectableTags([]);
                return [];
            });
    };

    const getDefaultValue = (value: string | undefined): ITag[] | undefined => {
        if (selectableTags == null || selectableTags.length === 0 || value == null || value === "") {
            return [];
        }
        var selectedTag = selectableTags.find(i => i.key === value);
        if (selectedTag == null) {
            return [];
        }
        var tags: ITag[] = [];
        var tag: ITag = {
            key: value,
            name: selectedTag.name
        }
        tags.push(tag);
        return tags;
    };

    const getTextFromItem = (item: ITag) => item.name;

    const pickerSuggestionsProps = (): IBasePickerSuggestionsProps => {
        var basePickerSuggestions: IBasePickerSuggestionsProps = {
            suggestionsHeaderText: "Suggested items",
            noResultsFoundText: textFilterLength >= 3 ? "No matching items found" : "Minimum of three characters needed",
        };
        return basePickerSuggestions;
    };

    const inputProps: IInputProps = {
        onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onBlur called'),
        onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),
        "aria-label": "Tag picker",
    };

    const pickerStyles: IBasePickerStyles = {
        root: {
            background: theme.semanticColors.bodyBackground,
        },
        text: {},
        itemsWrapper: {},
        input: {},
        screenReaderText: {}
    };

    return (
        <ConnectForm>
            {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                <Controller
                    control={methods.control}
                    rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                    name={buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField)}
                    render={({ field: { onChange, value } }) => (
                        <>
                            {!props.readOnly &&
                                <TooltipHost
                                    content={props.control.ToolTipText}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <ViewDropdownRecord
                                        control={props.control}
                                        value={value}
                                        dropdownId={dropdownId}
                                        isRequired={props.isRequired}
                                    />
                                    <div id={dropdownId}>
                                        <TagPicker
                                            className={methods.formState.errors[props.control.Id!]?.message != null ? "PickerFieldErrorBorder" : ""}
                                            disabled={props.disabled}
                                            styles={pickerStyles}
                                            resolveDelay={props.control.TypeAndSearchOnServer ? 300 : undefined}
                                            onResolveSuggestions={(filter: string, selectedItems?: ITag[] | undefined) => {
                                                return filterSuggestedTags(filter, selectedItems !== undefined ? selectedItems : []);
                                            }}
                                            getTextFromItem={getTextFromItem}
                                            pickerSuggestionsProps={pickerSuggestionsProps()}
                                            itemLimit={1}
                                            inputProps={inputProps}
                                            selectedItems={getDefaultValue(value)}
                                            onChange={(items?: ITag[] | undefined) => {
                                                var myValue: string = "";
                                                if (items != null && items.length > 0) {
                                                    setSelectableTags(items);
                                                    myValue = items[0].key.toString();
                                                } else {
                                                    setSelectableTags([]);
                                                    myValue = "";
                                                }
                                                if (props.subFormField != null) {
                                                    var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                    var updateableField = cloneDeep(myCurrentValue);
                                                    updateableField[props.control.Id!] = myValue;
                                                    props.subFormField.update(props.subFormField.Index, updateableField);
                                                }
                                                onChange(myValue);
                                            }}
                                        />
                                    </div>
                                    <div className="CustomError">
                                        <ErrorMessage errors={methods.formState.errors} name={props.control.Id!} />
                                    </div>
                                </TooltipHost>
                            }
                            {props.readOnly &&
                                <ReadOnlyDropdownField
                                    control={props.control}
                                    textValue={getTextValue(value)}
                                    value={value}
                                />
                            }
                        </>
                    )} />
            }
        </ConnectForm>
    );
}