import React from "react";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import "./NavCommandBar.css";
import { useNavigate } from "react-router-dom";
import getScreenSize from "../../services/getScreenSize";

export const NavCommandBar: React.FC = () => {
    const screenSize: number = getScreenSize();
    const navigate = useNavigate();

    // this set of items is for larger screen sizes
    const items: ICommandBarItemProps[] = [
        {
            key: "helpIcon",
            iconProps: { iconName: "question-circle-svg" },
            className: "custom-command-bar-item",
            subMenuProps: {
                items: [
                    {
                        key: "helpTourGuid",
                        text: "Tour Guide",
                        iconProps: { iconName: "Bus" },
                        ariaLabel: "Tour Help Page",
                        onClick: (() => {
                            navigate(`/Help/Tour`, { replace: false });
                        })
                    },
                    {
                        key: "helpYouTubeVideos",
                        text: "Videos",
                        ariaLabel: "Video Help Page",
                        iconProps: { iconName: "Video" },
                        onClick: (() => {
                            navigate(`/Help/Videos`, { replace: false });
                        })
                    }
                ],
            },
        }
    ];

    // this set of items is a more linear set for mobile
    const overflowItems: ICommandBarItemProps[] = [
        {
            key: "moreNavCommandBarItems",
            iconProps: { iconName: "MoreVertical" },
            className: "custom-command-bar-item",
            subMenuProps: {
                items: [
                    {
                        key: "helpTourGuid",
                        text: "Tour Guide",
                        iconProps: { iconName: "Bus" },
                        ariaLabel: "Tour Help Page",
                        onClick: (() => {
                            navigate(`/Help/Tour`, { replace: false });
                        })
                    },
                    {
                        key: "helpYouTubeVideos",
                        text: "Videos",
                        ariaLabel: "Video Help Page",
                        iconProps: { iconName: "Video" },
                        onClick: (() => {
                            navigate(`/Help/Videos`, { replace: false });
                        })
                    }
                ],
            },
        }
    ];

    return (
        <>
            {screenSize >= 600
                ?
                <CommandBar
                    items={items}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    className="custom-command-bar"
                />
                :
                <CommandBar
                    items={overflowItems}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    className="custom-command-bar"
                />
            }
        </>
    );
}