import React, { useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { IStackItemStyles, Pivot, PivotItem, Stack, useTheme } from "@fluentui/react";
import { applicationManifestState, navHeaderState, shimmerNavState } from "../../../recoil/atoms";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { ResourceSettings } from "./ResourceSettings";
import { ManifestGroups } from "./ManifestGroups";
import { UserPermissions } from "./UserPermissions";

export const ApplicationSettings: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const theme = useTheme();

    useEffect(() => {
        setNavHeader(`${applicationManifest.ApplicationName} Settings`);
        setShimmerNav(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    return (
        <>
            <Stack styles={bodyStackItemStyles}>
                <Pivot
                    aria-label="Tab region for Manifest Settings"
                    linkSize="large"
                    overflowBehavior="menu">
                    <PivotItem
                        key={"UserSettings"}
                        headerText={"Users"}
                        alwaysRender={true}>
                        <UserPermissions></UserPermissions>
                    </PivotItem>
                    <PivotItem
                        key={"GroupSettings"}
                        headerText={"Groups"}
                        alwaysRender={true}>
                        <ManifestGroups
                            appId={applicationManifest.Id!}>
                        </ManifestGroups>
                    </PivotItem>
                    <PivotItem
                        key="ResourceSettings"
                        headerText={"Resources"}
                        alwaysRender={true}>
                        <ResourceSettings></ResourceSettings>
                    </PivotItem>
                </Pivot>
            </Stack>
        </>
    );
}