import React from "react";
import { IStackItemStyles, IStackStyles, Stack } from "@fluentui/react";
import { Me } from "../navbar/Me";
import { AppNameHeader } from "../navbar/AppNameHeader";
import { NavCommandBar } from "../navbar/NavCommandBar";
import { TopLeftIcon } from "../navbar/TopLeftIcon";
import { NavBreadcrumb } from "../navbar/NavBreadcrumb";

export interface INavbarProps {
    color: string;
    bodyBackground: string,
    menuDividerColor: string;
}

export const Navbar: React.FC<INavbarProps> = (props: INavbarProps) => {
    const navBarStackStyles: IStackStyles = {
        root: {
            height: "48px",
            zIndex: 10,
            color: props.color,
            background: props.bodyBackground,
            borderBottom: `1px solid ${props.menuDividerColor}`
        }
    };

    return (
        <Stack horizontal styles={navBarStackStyles}>
            <Stack.Item disableShrink styles={headerItemStyles}>
                <TopLeftIcon></TopLeftIcon>
            </Stack.Item>
            <Stack.Item disableShrink styles={headerItemStyles}>
                <AppNameHeader></AppNameHeader>
            </Stack.Item>
            <Stack.Item disableShrink>
                <NavBreadcrumb></NavBreadcrumb>
            </Stack.Item>
            <Stack.Item grow={1}>
                <></>
            </Stack.Item>
            <Stack.Item styles={navBarStyles}>
                <NavCommandBar></NavCommandBar>
            </Stack.Item>
            <Stack.Item styles={meStyles}>
                <Me></Me>
            </Stack.Item>
        </Stack>
    );
}
const headerItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        height: 48,
        justifyContent: 'center',
    },
};
const navBarStyles: IStackItemStyles = {
    root: {
        alignItems: "center",
        display: "flex",
        height: 48
    },
};
const meStyles: IStackItemStyles = {
    root: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: 48,
    },
};