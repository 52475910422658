import React from "react";
import { TextField, TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../model/manifest/ControlData";
import { useId } from "@fluentui/react-hooks";
import { getPrefix, getSuffix } from "../../../helpers/TextBoxHelpers";

export interface ReadOnlyTextBoxFieldProps {
    control: ControlData;
    value: string;
}

export const ReadOnlyTextBoxField: React.FC<ReadOnlyTextBoxFieldProps> = (props: ReadOnlyTextBoxFieldProps) => {
    const textFieldId = useId("textField");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textFieldId}`
    };

    const isCurrency = (): boolean => {
        return props.control.FieldType === 22 ? true : false;
    }

    return (
        <>
            <TooltipHost
                content={props.control.ToolTipText}
                id={tooltipId}
                calloutProps={calloutProps}
            >
                <TextField
                    id={textFieldId}
                    label={props.control.LabelText}
                    prefix={isCurrency() ? "$" : getPrefix(props.control)}
                    suffix={getSuffix(props.control)}
                    multiline={props.control.FieldType === 14 ? true : false}
                    rows={5}
                    borderless
                    value={props.value}
                    readOnly={true} />
            </TooltipHost>
        </>
    )
}