import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from "@microsoft/signalr";
import { IPublicClientApplication } from "@azure/msal-browser";
import { getRecoil, setRecoil } from "recoil-nexus";
import { appEntityUpdateSignalR, pendingSignalRMessages } from "./../recoil/atoms";
import { ApplicationEntity } from "../model/reaccessData/ApplicationEntity";

const baseUrl = `${window.ReAccessWeb.process.env.REACCESS_SIGNAL_R_FUNCTIONS_URL}/api`;
let connection: HubConnection;
//let azureAdOid: string = "";

export const setupSignalRConnection = (msalInstance: IPublicClientApplication) => {
  //azureAdOid = msalInstance.getActiveAccount()?.localAccountId || "";

  var options: IHttpConnectionOptions = {
    accessTokenFactory: () => accessTokenFactory(msalInstance),
  };

  connection = new HubConnectionBuilder().withUrl(baseUrl, options).withAutomaticReconnect().build();

  connection
    .start()
    .then(() => {
      console.log("Connected to SignalR Service");
      //connection.invoke("send", "Hello");
    })
    .catch((error: any) => {
      console.error(error);
    });
};

// const isNotMe = (
//   updatedById: string | null | undefined,
//   insertedById: string | null | undefined
// ) => {
//   if (
//     updatedById !== undefined &&
//     updatedById !== null &&
//     updatedById.trim() !== ""
//   ) {
//     return azureAdOid !== updatedById;
//   }
//   return azureAdOid !== insertedById;
// };

export const registerUpdateApplicationEntity = () => {
  connection.on("update-application-entity", (message: ApplicationEntity) => {
    var pendingUpdates = getRecoil<string[]>(pendingSignalRMessages);
    var id = message.EntityId || "-13";
    if (pendingUpdates.indexOf(id) === -1) {
      setRecoil(appEntityUpdateSignalR, message);
    } else {
      // This change was triggered by me - remove from pending changes
      setRecoil(
        pendingSignalRMessages,
        pendingUpdates.filter((i) => i !== message.Id)
      );
    }
  });
};

export const unRegisterUpdateApplicationEntity = () => {
  connection.off("update-application-entity");
};

const accessTokenFactory = async (msalInstance: IPublicClientApplication): Promise<string> => {
  const scopes: string[] = [window.ReAccessWeb.process.env.AZURE_SIGNAL_R_API_SCOPE];

  const token = await msalInstance.acquireTokenSilent({
    scopes: scopes,
  });

  return token.accessToken;
};
