import React from "react";
import getScreenSize from "../../services/getScreenSize";
import { IStackStyles, Stack, Text, ITextStyles, Icon } from "@fluentui/react";
import './MainBackgroundLeft.css';

export const MainBackgroundLeft: React.FC = () => {
    const screenSize: number = getScreenSize();

    const leftStackItemStyles: IStackStyles = {
        root: {
            display: "flex",
            justifyContent: 'center',
            width: "60%"
        }
    };

    const tagLineItemStyles: ITextStyles = {
        root: {
            color: "#FFFFFF"
        }
    };

    const checkmarkItemStyles: ITextStyles = {
        root: {
            color: "#FFFFFF",
            paddingLeft: "10px"
        }
    };

    return (
        <Stack.Item className="mobile-hidden landing-left-background" styles={leftStackItemStyles}>
            <Stack horizontal={false} verticalAlign="center">
                <Stack.Item align="start">
                    <Text variant={screenSize < 1200 ? "xxLarge" : "superLarge"} styles={tagLineItemStyles}>
                        Microsoft Access
                    </Text>
                </Stack.Item>
                <Stack.Item align="start">
                    <Text variant={screenSize < 1200 ? "xxLarge" : "superLarge"} styles={tagLineItemStyles}>
                        for the Cloud - An Azure,
                    </Text>
                </Stack.Item>
                <Stack.Item align="start">
                    <Text variant={screenSize < 1200 ? "xxLarge" : "superLarge"} styles={tagLineItemStyles}>
                        No-Code Development Platform
                    </Text>
                </Stack.Item>
                <Stack>
                    <Stack.Item style={{ paddingTop: "40px" }}>
                        <Stack horizontal={true}>
                            <Stack.Item align="center">
                                <Icon iconName="circle-checkmark-svg" />
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant={screenSize < 1200 ? "mediumPlus" : "large"} styles={checkmarkItemStyles}>
                                    No-code, secure application development
                                </Text>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item style={{ paddingTop: "20px" }}>
                        <Stack horizontal={true}>
                            <Stack.Item align="center">
                                <Icon iconName="circle-checkmark-svg" />
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant={screenSize < 1200 ? "mediumPlus" : "large"} styles={checkmarkItemStyles}>
                                    Simple, powerful, quick start templates
                                </Text>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack.Item>
    );
}