import React, { useEffect } from "react";
import { IStackItemStyles, PrimaryButton, Stack, useTheme } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { navHeaderState, shimmerNavState } from "../../recoil/atoms";
import "./NotFound.css";

export const NotFound: React.FC = () => {
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        setNavHeader("Not Found");
        setShimmerNav(true);
    }, [setNavHeader, setShimmerNav]);

    const navigateToAppCatalog = () => {
        setShimmerNav(false);
        navigate("/AppCatalog", { replace: true });
    }

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    return (
        <Stack styles={bodyStackItemStyles}>
            <div id="not-found">
                <div className="not-found">
                    <div className="not-found-404">
                        <h1>4<span></span>4</h1>
                    </div>
                    <h2>Oops! Page Not Found</h2>
                    <p>Sorry but the page you are looking for does not exist, has moved, or is temporarily unavailable</p>
                    <PrimaryButton
                        text="Back to Home"
                        ariaDescription="Back to home button."
                        onClick={navigateToAppCatalog}
                        allowDisabledFocus />
                </div>
            </div>
        </Stack>
    );
}