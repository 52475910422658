import { Configuration, LogLevel } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfigGoogle: Configuration = {
  auth: {
    clientId: window.ReAccessWeb.process.env.AZURE_AD_CLIENT_ID,
    authority: window.ReAccessWeb.process.env.AZURE_AD_Tii4_LOGIN_URL,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

export const msalConfigMicrosoft: Configuration = {
  auth: {
    clientId: window.ReAccessWeb.process.env.AZURE_AD_CLIENT_ID,
    authority: window.ReAccessWeb.process.env.AZURE_AD_COMMON_LOGIN_URL,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.

// The previous implementation of the above was making 'loginRequest' a PopupRequest object:
//     export const loginRequest: PopupRequest = {
// This was causing the access token request to be malformed. It did not affect all directories,
// for example: the Intact AAD was able to handle the request, another test AAD (bizbanc) did not.
// The request failed with a 500 error! Something failed while trying to generate the v2 token
// due to the value used in the request's scope.
