import React, { useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { applicationManifestState, navPageSelectedState } from "../../recoil/atoms";
import { ApplicationManifest } from "../../model/manifest/ApplicationManifest";
import { PowerBiReport } from "./PowerBiReport";
import { HomePageImage } from "./HomePageImage";

export const HomePage: React.FC = () => {
    const [navPageSelected, setNavPageSelected]: [string, (navPageSelected: string) => void] = useRecoilState(navPageSelectedState);
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);

    useEffect(() => {
        if (applicationManifest == null) {
            return;
        }
        //if the nav page selected is not the manifest Id then set it
        if (navPageSelected !== applicationManifest.Id) {
            setNavPageSelected(applicationManifest.Id!);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationManifest]);

    return (
        <>
            {applicationManifest.PowerBi != null
                ?
                <PowerBiReport
                    powerBi={applicationManifest.PowerBi}>
                </PowerBiReport>
                :
                <HomePageImage></HomePageImage>
            }
        </>
    );
}