import React, { useEffect, useState } from "react";
import { customerDtoState, dialogModalWrapperPropsState, navHeaderState } from "../../../recoil/atoms";
import { Stack, IStackItemStyles, IStackTokens, Dropdown, IDropdownOption, Text, useTheme } from "@fluentui/react";
import axios from 'axios';
import { ITemplateManifest, ITemplates } from "../../../interfaces/Templates";
import { useSetRecoilState } from "recoil";
import { TemplateCard } from "./TemplateCard";
import Tour from "reactour";
import { useRecoilValue } from "recoil";
import { CustomerDto } from "../../../dtos/Customer/CustomerDto";

export const NewApp: React.FC = () => {
    const setNavHeader = useSetRecoilState(navHeaderState);
    const [templates, setTemplates] = useState<ITemplates>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [viewTemplateManifests, setViewTemplateManifests] = useState<ITemplateManifest[]>();
    const customerDtos: CustomerDto[] = useRecoilValue(customerDtoState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const theme = useTheme();
    const accentColor = theme.semanticColors.primaryButtonBackground;

    useEffect(() => {
        setNavHeader("New Application");
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios.get("/ApplicationTemplate", {
            cancelToken: source.token,
        })
            .then((result) => {
                var templateData: ITemplates = JSON.parse(result.data.Templates);
                setTemplates(templateData);

                var templateManifests: ITemplateManifest[] = [];
                templateData.Manifest.forEach(manifest => {
                    templateManifests.push(manifest);
                });
                setViewTemplateManifests(templateManifests);

                setIsLoading(false);
                var hasTakenTour = localStorage.getItem("tour_templates_1");
                if (hasTakenTour?.toLowerCase() !== "true") {
                    setIsTourOpen(true);
                    localStorage.setItem("tour_templates_1", "true");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response == null) {
                    console.log("Request canceled", "Create Basic Application Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps = {
                        isVisible: true,
                        title: "Uh oh",
                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                        isBlocking: true,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });

            return function cleanup() {
                source.cancel();
            }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // watch for selected key changes and update viewable templates based on it
    useEffect(() => {
        if (templates == null) return;

        var templateManifests: ITemplateManifest[] = [];

        if (selectedKeys.length === 0) {
            var t: ITemplates = JSON.parse(JSON.stringify(templates));
            t.Manifest.forEach(manifest => {
                templateManifests.push(manifest);
            });
            setViewTemplateManifests(templateManifests);
            return;
        }

        templates.Manifest.forEach(manifest => {
            var hasTag = manifest.Tags.some(i => selectedKeys.includes(i));
            if (hasTag) {
                templateManifests.push(manifest);
            }
        });
        setViewTemplateManifests(templateManifests);
        // templates does not need to be in the hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedKeys]);

    const wrapStackTokens: IStackTokens = { childrenGap: 30 };

    const itemStyles: IStackItemStyles = {
        root: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        }
    };

    const getTemplateTagOptions = (): IDropdownOption[] => {
        if (templates == null || templates.Manifest.length === 0) { return [] };

        var tags: string[] = [];
        templates.Manifest.forEach((manifest) => {
            manifest.Tags.forEach((tag) => {
                if (tags.indexOf(tag) === -1) {
                    tags.push(tag);
                }
            })
        });

        tags = tags.sort((a, b) => a.localeCompare(b));

        return tags.map(i => ({ key: i, text: i }));
    }

    const onChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            setSelectedKeys(
                item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter(key => key !== item.key),
            );
        }
    };

    return (
        <>
            <Stack horizontal={false}>
                <Stack horizontal={true} horizontalAlign="space-between">
                    <Stack.Item>
                        <Text variant="xxLarge" data-tut="templates">
                            Templates
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown
                            placeholder="Select options"
                            label="View by popular tags"
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            multiSelect
                            options={getTemplateTagOptions()}
                        />
                    </Stack.Item>
                </Stack>
                {(!isLoading && templates != null && viewTemplateManifests != null && viewTemplateManifests.length > 0) &&
                    <Stack
                        horizontal
                        verticalFill
                        wrap
                        horizontalAlign="space-evenly"
                        verticalAlign="start"
                        tokens={wrapStackTokens}
                    >
                        {selectedKeys.length === 0 &&
                            <Stack.Item key={"templates-new"} styles={itemStyles}>
                                <TemplateCard
                                    version={undefined}
                                    name={"Blank Template"}
                                    description={"Build your own masterpiece"}
                                    customers={customerDtos}>
                                </TemplateCard>
                            </Stack.Item>
                        }
                        {
                            viewTemplateManifests.map((value, index) => {
                                return (
                                    <Stack.Item key={"templates" + index} styles={itemStyles} data-tut={"templates" + index}>
                                        <TemplateCard
                                            version={templates.Version}
                                            name={value.Name}
                                            description={value.Description}
                                            customers={customerDtos}>
                                        </TemplateCard>
                                    </Stack.Item>
                                )
                            })
                        }
                    </Stack>
                }
            </Stack>
            <Tour
                onRequestClose={() => setIsTourOpen(false)}
                steps={steps}
                isOpen={isTourOpen}
                maskClassName="Tour"
                rounded={5}
                accentColor={accentColor}
            />
        </>
    );
}

const steps = [
    {
        selector: '[data-tut="templates"]',
        content: 'Welcome, this is the New Application Screen.  From here you can create a blank app or select from one of our template apps.',
    },
    {
        selector: '[data-tut="Blank Template"]',
        content: () => (
            <div>
                A blank app will let you design all of the pages yourself.
                <br />< br />
            </div>
        )
    },
    {
        selector: '[data-tut="Maintenance Operation Solutions"]',
        content: () => (
            <div>
                Also, you can select from any of our templates such as Maintenance Operation Solutions
                <br />< br />
                <span aria-label="airplane" role="img" style={{ fontSize: "30px" }}>
                    🛩
                </span>
            </div>
        )
    },
    {
        selector: '[data-tut="Business Trip Tracking and Reporting"]',
        content: () => (
            <div>
                Or to track your business trip expense try the Business Trip Reports Template
                <br />< br />
                <span aria-label="shirt and tie" role="img" style={{ fontSize: "30px" }}>
                    👔
                </span>
                <br /><br />
                You can still modify any of the templates to suit your needs
            </div>
        )
    },
    {
        content: () => (
            <div>
                If you ever need to get back here just click the New Application button on the App Catalog page
                <br />< br />
                <span aria-label="home" role="img" style={{ fontSize: "30px" }}>
                    🏠
                </span>
            </div>
        )
    }
];