import React, { useEffect, useState } from "react";
import { ITextProps, IStackItemStyles, Stack, Text } from "@fluentui/react";
import { HeadersAndDefinitions } from "./HeadersAndDefinitions";
import { ValidateImport } from "./ValidateImport";
import { UploadHeader } from "./UploadHeader";
import { IDataImportHistoryResult } from "../../../interfaces/DataImportHistoryResult";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ImportHistoryGrid } from "./ImportHistoryGrid";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { ValidationError } from "../../../model/error/ValidationError";
import { useSetRecoilState } from "recoil";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";

export const ImportPage: React.FC = () => {
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [dataImportHistory, setDataImportHistory] = useState<IDataImportHistoryResult>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    var { appId, formId } = useParams();

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios.get(`/DataImport?ApplicationId=${appId}&FormId=${formId}`, {
          // TODO: Put back once DataImport is back in function app
          //axios.get(`/DataImportGet?ApplicationId=${appId}&SlotId=${slotId}&FormId=${formId}`, {
            cancelToken: source.token,
        })
            .then((result) => {
                if (result.data != null && result.data !== "") {
                    var resultData: IDataImportHistoryResult = result.data;
                    setDataImportHistory(resultData);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response == null) {
                    console.log("Request canceled", "Form Container Component cleaned up");
                    return;
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps: IDialogModalWrapperProps | undefined;
                    if (error.response.status === 408) {
                        dialogProps = {
                            isVisible: true,
                            title: "Request timeout",
                            subText: "Please try your request again",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => { },
                            onSecondaryButtonDismiss: () => { }
                        }
                    } else if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        dialogProps = {
                            isVisible: true,
                            title: validationErrors[0].Title,
                            subText: validationErrors[0].Message,
                            isBlocking: false,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                    } else {
                        dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
        return function cleanup() {
            source.cancel();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId, formId]);

  const updateDataImportHistoryResult = (
    resultData: IDataImportHistoryResult
  ) => {
    var history: IDataImportHistoryResult =
      dataImportHistory !== undefined
        ? JSON.parse(JSON.stringify(dataImportHistory))
        : { DataImportHistory: [], RecordCount: 0 };
    history.DataImportHistory = history.DataImportHistory.concat(
      resultData.DataImportHistory
    );
    history.DataImportHistory.sort((a, b) => b.ImportNumber - a.ImportNumber);
    history.RecordCount += history.DataImportHistory.length;

    setDataImportHistory(history);
  };

  const stackItemStyles: IStackItemStyles = {
    root: {
      paddingTop: "10px",
      paddingBottom: "15px",
    },
  };

  return (
    <>
      <Stack horizontal={false}>
        <Stack.Item styles={stackItemStyles}>
          <Text variant={"xLarge" as ITextProps["variant"]}>Import Data</Text>
        </Stack.Item>
        <Stack.Item>
          <HeadersAndDefinitions></HeadersAndDefinitions>
        </Stack.Item>
        <Stack.Item>
          <ValidateImport></ValidateImport>
        </Stack.Item>
        <Stack.Item>
          <UploadHeader
            onChangeDataImportHistoryResultValue={(
              resultData: IDataImportHistoryResult
            ) => {
              updateDataImportHistoryResult(resultData);
            }}
          ></UploadHeader>
        </Stack.Item>
        <Stack.Item>
          {dataImportHistory != null && (
            <ImportHistoryGrid
              dataImportHistoryResult={dataImportHistory}
              isLoading={isLoading}
            ></ImportHistoryGrid>
          )}
        </Stack.Item>
      </Stack>
    </>
  );
};
