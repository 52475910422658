import React from "react";
import { TextField, TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import { ConnectForm } from "../../../form/ConnectForm";
import { useRecoilValue } from "recoil";
import { applicationManifestState, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { getRules } from "../../../../../services/ruleService";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { ReadOnlyTextBoxField } from "../../ReadOnlyTextBoxField";
import { useId } from "@fluentui/react-hooks";
import { getPrefix, getSuffix } from "../../../../../helpers/TextBoxHelpers";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { buildFormPropertyName } from "../../../../../services/fieldService";
import { cloneDeep } from "lodash";

export interface GuidFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const GuidField: React.FC<GuidFieldProps> = (props: GuidFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const textBoxId = useId("textBox");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textBoxId}`
    };

    return (
        <ConnectForm>
            {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                <Controller
                    control={methods.control}
                    rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null || props.subFormField?.isNew || false)}
                    name={buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField)}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <>
                            {!props.readOnly &&
                                <TooltipHost
                                    content={props.control.ToolTipText}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <TextField
                                        id={textBoxId}
                                        label={props.control.LabelText}
                                        prefix={getPrefix(props.control)}
                                        suffix={getSuffix(props.control)}
                                        value={value}
                                        disabled={props.disabled}
                                        required={props.entityId == null || props.subFormField?.isNew ? false : props.isRequired}
                                        errorMessage={error != null && error.message != null ? error.message : undefined}
                                        onBlur={onBlur}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            if (props.subFormField != null) {
                                                var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                var updateableField = cloneDeep(myCurrentValue);
                                                updateableField[props.control.Id!] = newValue;
                                                props.subFormField.update(props.subFormField.Index, updateableField);
                                            }
                                            onChange(newValue);
                                        }} />
                                </TooltipHost>
                            }
                            {props.readOnly &&
                                <ReadOnlyTextBoxField
                                    control={props.control}
                                    value={value}>
                                </ReadOnlyTextBoxField>
                            }
                        </>
                    )} />
            }
        </ConnectForm>
    )
}