import React from "react";
import { DefaultButton } from "@fluentui/react";
import { useRecoilValue } from "recoil";
import { applicationManifestState } from "../../../recoil/atoms";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";

export const ImportButton: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const navigate = useNavigate();
    var { appId, formId } = useParams();

    return (
        <>
            {applicationManifest.IsBuilderOrAdmin &&
                <DefaultButton
                    text="Import"
                    iconProps={{ iconName: "ImportAllMirrored" }}
                    onClick={() => {
                        navigate(`/Application/${appId}/Form/${formId}/Import`, { replace: false });
                    }}
                />
            }
        </>
    );
}