import React, { useEffect } from "react";
import { Shimmer, useTheme } from "@fluentui/react";
import { getButtonShimmer } from "../../../services/shimmerService";
import { DocumentUpload } from "../DocumentUpload";
import { useParams } from "react-router-dom";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { useSetRecoilState } from "recoil";
import { IDataImportHistoryResult } from "../../../interfaces/DataImportHistoryResult";
import { ValidationError } from "../../../model/error/ValidationError";
import { createImageUrlFromArray, downloadFileFromImageUrl } from "../../../services/documentService";
import axios from "axios";

export interface ValidateAndImportButtonProps {
    description: string;
    isLoading: boolean;
    importData: boolean;
    isUploadingFile: (isUploadingFile: boolean) => void;
    onChangeDescriptionValue: (value: string) => void;
    onChangeDataImportHistoryResultValue: (value: IDataImportHistoryResult) => void;
}

export const ValidateAndImportButton: React.FC<ValidateAndImportButtonProps> = (props: ValidateAndImportButtonProps) => {
    const theme = useTheme();
    var { appId, formId } = useParams();
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        return function cleanup() {
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getButtonText = () => {
        return props.importData ? "Import Data" : "Validate Data";
    }

    const successfulUpload = (result: any, file: File) => {
        props.isUploadingFile(false);

        var dialogProps: IDialogModalWrapperProps;
        if (!props.importData) {
            dialogProps = {
                isVisible: true,
                title: "Success!",
                subText: "The validation process completed successfully, and your file is ready for import.",
                isBlocking: true,
                primaryButtonText: "OK",
                secondaryButtonText: undefined,
                onDismiss: () => {
                    setDialogModalWrapperPropsState(undefined);
                },
                onSecondaryButtonDismiss: () => { }
            }
            setDialogModalWrapperPropsState(dialogProps);
        } else {
            // refresh the import result
            var resultData: IDataImportHistoryResult = result.data;
            props.onChangeDataImportHistoryResultValue(resultData);

            props.onChangeDescriptionValue("");

            dialogProps = {
                isVisible: true,
                title: "Success!",
                subText: "The data import has been submitted, and will be processed.",
                isBlocking: true,
                primaryButtonText: "OK",
                secondaryButtonText: undefined,
                onDismiss: () => {
                    setDialogModalWrapperPropsState(undefined);
                },
                onSecondaryButtonDismiss: () => { }
            }
            setDialogModalWrapperPropsState(dialogProps);
        }
    }

    const failedUpload = (error: any, fileName?: string) => {
        props.isUploadingFile(false);

        if (error.response == null) {
            console.log("Request canceled", "Form Container Component cleaned up");
        } else {
            if (error.response.status === 401 || error.response.status === 409) {
                return;
            }
            var dialogProps: IDialogModalWrapperProps | undefined;
            if (error.response.status === 408) {
                dialogProps = {
                    isVisible: true,
                    title: "Request timeout",
                    subText: "Please try your request again",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => { },
                    onSecondaryButtonDismiss: () => { }
                }
            } else if (error.response.status === 422 && error.response.data.error != null) {
                var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                dialogProps = {
                    isVisible: true,
                    title: validationErrors[0].Title,
                    subText: validationErrors[0].Message,
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        if (validationErrors[0].ErrorCode === "5405" && validationErrors[0].ValidationFile != null) {
                            var imageUrl = createImageUrlFromArray(validationErrors[0].ValidationFile.Bytes, validationErrors[0].ValidationFile.MimeType);
                            downloadFileFromImageUrl(imageUrl, validationErrors[0].ValidationFile.Name);
                        }
                        setDialogModalWrapperPropsState(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            } else {
                dialogProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapperPropsState(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            }
            setDialogModalWrapperPropsState(dialogProps);
        }
    }

    // for the mimeType, use ".csv" instead of "text/csv", more here:
    // https://stackoverflow.com/questions/54047236/dropzone-does-not-pick-up-csv-files-on-windows-10-and-chromeos-with-mime-text-c

    return (
        <>
            <Shimmer
                customElementsGroup={getButtonShimmer()}
                width={206}
                isDataLoaded={!props.isLoading}
                shimmerColors={{
                    shimmer: theme.palette.themeTertiary,
                    shimmerWave: theme.palette.themeSecondary,
                    background: theme.semanticColors.bodyBackground,
                }}>
                <DocumentUpload
                    acceptedMimeTypes={{ "text/csv": [] }}// pls read comment above
                    applicationId={appId!}
                    pageId={formId!}
                    subFormId={undefined}
                    controlId={undefined}
                    description={props.description}
                    importData={props.importData}
                    allowMultipleDocuments={false}
                    buttonLabel={getButtonText()}
                    onSuccessfulUpload={(result: any, file: File) => {
                        successfulUpload(result, file);
                    }}
                    onFailedUpload={(error: any, fileName?: string) => {
                        failedUpload(error, fileName);
                    }}
                    startingHttpRequest={(fileName: string) => {
                        props.isUploadingFile(true);
                    }}
                    source={source}>
                </DocumentUpload>
            </Shimmer>
        </>
    );
}