import axios, { InternalAxiosRequestConfig } from "axios";
import { InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";
const packageJsonVersion = require("./../../package.json").version;

export function RequestInterceptor(msalInstance: IPublicClientApplication) {
  axios.interceptors.request.use(
    async (axiosConfig: InternalAxiosRequestConfig) => {
      // exclusions (non authenticated requests)
      if (axiosConfig.url === "/Register" && axiosConfig.method === "post") {
        axiosConfig.headers["Content-Type"] = "application/json";
        axiosConfig.baseURL = window.ReAccessWeb.process.env.POWERLINE_API_URL;
        return axiosConfig;
      }

      if (
        (axiosConfig.url?.startsWith("/ApplicationEntityDelete") && axiosConfig.method === "delete") ||
        (axiosConfig.url?.startsWith("/ApplicationEntityGet") && axiosConfig.method === "get") ||
        (axiosConfig.url?.startsWith("/ApplicationEntityPost") && axiosConfig.method === "post") ||
        (axiosConfig.url?.startsWith("/ApplicationEntityPrint") && axiosConfig.method === "get") ||
        (axiosConfig.url?.startsWith("/ApplicationEntityPut") && axiosConfig.method === "put") ||
        (axiosConfig.url?.startsWith("/ApplicationEntitySearch") && axiosConfig.method === "post") ||
        (axiosConfig.url?.startsWith("/PurgeCustomer") && axiosConfig.method === "post") ||
        (axiosConfig.url?.startsWith("/ReAccessApplicationManifest") && axiosConfig.method === "get") ||
        (axiosConfig.url?.startsWith("/SearchResultsExport") && axiosConfig.method === "post") ||
        (axiosConfig.url === "/PurgeApplication" && axiosConfig.method === "post") ||
        (axiosConfig.url === "/PurgeApplicationData" && axiosConfig.method === "post") ||
        (axiosConfig.url === "/IsManifestPublishing" && axiosConfig.method === "get") ||
        (axiosConfig.url === "/GetManifestResource" && axiosConfig.method === "get") ||
        (axiosConfig.url === "/GetPowerBiAccessToken" && axiosConfig.method === "get") ||
        (axiosConfig.url === "/GetPowerBiRowLevelSecurityAccessToken" && axiosConfig.method === "get") ||
        (axiosConfig.url === "/NewCustomerRequest" && axiosConfig.method === "post") ||
        (axiosConfig.url === "/FavoriteManifest" && axiosConfig.method === "put") ||
        (axiosConfig.url === "/GetGridItems" && axiosConfig.method === "post")
      ) {
        axiosConfig.headers["Content-Type"] = "application/json";
        axiosConfig.baseURL = window.ReAccessWeb.process.env.REACCESS_FUNCTIONS_URL;
      }

      var accountInfo = msalInstance.getActiveAccount();

      const scopes: string[] = [];

      if (axiosConfig.url?.startsWith("https://graph.microsoft.")) {
        scopes.push("User.Read");
      } else if (axiosConfig.url?.startsWith(window.ReAccessWeb.process.env.REACCESS_SIGNAL_R_FUNCTIONS_URL)) {
        scopes.push(window.ReAccessWeb.process.env.AZURE_SIGNAL_R_API_SCOPE);
      } else {
        scopes.push(window.ReAccessWeb.process.env.AZURE_ACCESS_AS_USER_SCOPE);
      }

      const token = await msalInstance
        .acquireTokenSilent({
          scopes: scopes,
          account: accountInfo !== null ? accountInfo : undefined,
        })
        .catch(async (error) => {
          if (error instanceof InteractionRequiredAuthError) {
            var fallbackRequest = {
              scopes: scopes,
              loginHint: accountInfo?.username, // For v1 endpoints, use upn from idToken claims
            };
            // fallback to interaction when silent call fails
            return await msalInstance.acquireTokenRedirect(fallbackRequest);
          }
        });

      if (token == null) {
        return axiosConfig;
      }

      if (axiosConfig.headers && axiosConfig.headers["Content-Type"]) {
        axiosConfig.headers.Authorization = `Bearer ${token.accessToken}`;
      } else {
        axiosConfig.headers["Content-Type"] = "application/json";
        axiosConfig.headers.Authorization = `Bearer ${token.accessToken}`;
      }

      if (
        !axiosConfig.baseURL?.startsWith(window.ReAccessWeb.process.env.REACCESS_FUNCTIONS_URL) &&
        !axiosConfig.url?.startsWith(window.ReAccessWeb.process.env.REACCESS_SIGNAL_R_FUNCTIONS_URL)
      ) {
        axiosConfig.baseURL = window.ReAccessWeb.process.env.POWERLINE_API_URL;
      }

      if (!axiosConfig.url?.startsWith("https://graph.microsoft.")) {
        if (axiosConfig.params == null) {
          axiosConfig.params = {};
        }
        if (!axiosConfig.params.hasOwnProperty("AppVersion")) {
          axiosConfig.params["AppName"] = "ReAccess";
          axiosConfig.params["AppVersion"] = packageJsonVersion;
        }
      }

      return axiosConfig;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
