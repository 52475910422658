import "./App.css";
import { UnAuthenticated } from "./components/layout/UnAuthenticated";
import { Authenticated } from "./components/layout/Authenticated";
import { ElectronTest } from "./components/electron/ElectronTest";
import { DialogModalWrapper } from "./components/main/DialogModalWrapper";
import { IDialogModalWrapperProps } from "./interfaces/DialogModal";
import { dialogModalWrapperPropsState, loadingModalState } from "./recoil/atoms";
import { useRecoilValue } from "recoil";
import { RequestInterceptor } from "./interceptors/RequestInterceptor";
import { ResponseInterceptor } from "./interceptors/ResponseInterceptor";
import { useNavigate } from "react-router-dom";
import { ILoadingModalProps } from "./interfaces/ILoadingModal";
import { LoadingModal } from "./components/main/LoadingModal";
import { IStackStyles, Stack } from "@fluentui/react";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

export interface AppProps {
  msalInstance: IPublicClientApplication;
}

export const App: React.FC<AppProps> = (props: AppProps) => {
  const dialogModalWrapperProps: IDialogModalWrapperProps = useRecoilValue(dialogModalWrapperPropsState);
  const loadingModalProps: ILoadingModalProps = useRecoilValue(loadingModalState);

  const navigate = useNavigate();
  RequestInterceptor(props.msalInstance);
  ResponseInterceptor(navigate, props.msalInstance);

  return (
    <Stack grow={1} styles={rootStackStyles}>
      <ElectronTest></ElectronTest>
      <MsalProvider instance={props.msalInstance}>
        <AuthenticatedTemplate>
          <Authenticated></Authenticated>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <UnAuthenticated></UnAuthenticated>
        </UnauthenticatedTemplate>
      </MsalProvider>
      {dialogModalWrapperProps != null && <DialogModalWrapper dialogModalWrapperProps={dialogModalWrapperProps}></DialogModalWrapper>}
      {loadingModalProps != null && <LoadingModal loadingModalProps={loadingModalProps}></LoadingModal>}
    </Stack>
  );
};

const rootStackStyles: IStackStyles = {
  root: {
    overflow: "hidden",
  },
};
