import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { PowerBiReport } from "../../PowerBiReport";

export interface PowerBiControlProps {
    control: ControlData;
}

export const PowerBiControl: React.FC<PowerBiControlProps> = (props: PowerBiControlProps) => {

    return (
        <>
            {props.control.FieldType === 38 &&
                <>
                    <PowerBiReport
                        powerBi={props.control.PowerBi}>
                    </PowerBiReport>
                </>
            }
        </>
    );
}