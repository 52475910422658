import React, { useEffect, useState } from "react";
import { Link, ProgressIndicator } from "@fluentui/react";
import { AttachmentModel } from "../../../model/reaccessData/AttachmentModel";
import { downloadFileFromImageUrl, getDocument } from "../../../services/documentService";
import { useParams } from "react-router-dom";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { useSetRecoilState } from "recoil";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";

export interface DocumentFileLinkProps {
    pageId: string;
    subFormId: string | undefined;
    attachmentModel: AttachmentModel;
}

export const DocumentFileLink: React.FC<DocumentFileLinkProps> = (props: DocumentFileLinkProps) => {
    const [showProgressIndicator, setShowProgressIndicator] = useState<boolean>(false);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    var { appId } = useParams();

    useEffect(() => {
        if (props.attachmentModel.FileId != null) {
            setShowProgressIndicator(false);
        } else {
            setShowProgressIndicator(true);
        }
    }, [props.attachmentModel.FileId]);

    const downloadFile = () => {
        setShowProgressIndicator(true);
        getDocument(
            appId!,
            props.attachmentModel.EntityId,
            props.attachmentModel.FileId,
            props.attachmentModel.Version.toString(),
            props.attachmentModel.FileName + props.attachmentModel.FileExtension)
            .then((imageUrl) => {
                setShowProgressIndicator(false);
                if (imageUrl !== "") {
                    downloadFileFromImageUrl(imageUrl, props.attachmentModel.FileName + props.attachmentModel.FileExtension);
                } else {
                    var dialogProps: IDialogModalWrapperProps = {
                        isVisible: true,
                        title: "Document not found",
                        subText: "This document appears to be missing.  Please try your request again.",
                        isBlocking: false,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => {}
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            })
            .catch((error) => {
                setShowProgressIndicator(false);
            })
    }

    return (
        <span>
            {showProgressIndicator
                ? (
                    <ProgressIndicator label="" description="" />
                )
                : (
                    <Link onClick={() => { downloadFile() }}>{props.attachmentModel.FileName + props.attachmentModel.FileExtension}</Link>
                )
            }
        </span>
    )
}