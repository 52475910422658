export class StringConstants {
  public static readonly AppCatalogMenuItem: string = "Intact App Catalog";
  public static readonly AppCatalogSortingSelection: string = "AppCatalogSortingSelection";
  public static readonly AppSettingsMenuItem: string = "App Settings Menu Item";
  public static readonly DataFilters: string = "Data Filters";
  public static readonly Members: string = "Members";
  public static readonly NewApplicationMenuItem: string = "Intact New Application";
  public static readonly ReportsMenuItem: string = "Reports Menu Item";
  public static readonly Settings: string = "Settings";
  public static readonly SideNavExpanded: string = "SideNavExpanded";

  public static readonly ExtactValues: string = "Exact Values";
  public static readonly TextStartsWith: string = "Text Starts With";
  public static readonly TextContains: string = "Text Contains";
  public static readonly TextEndsWith: string = "Text Ends With";

  public static readonly FromDateSearchParameter: string = "FromDate-";
  public static readonly ToDateSearchParameter: string = "ToDate-";
  public static readonly TimePickerId: string = "TimePicker-";
  public static readonly FromDateTimeSearchParameter: string = "FromDateTime-";
  public static readonly ToDateTimeSearchParameter: string = "ToDateTime-";
  public static readonly FromTimeSearchParameter: string = "FromTime-";
  public static readonly ToTimeSearchParameter: string = "ToTime-";
  public static readonly FromCurrencySearchParameter: string = "FromCurrency-";
  public static readonly ToCurrencySearchParameter: string = "ToCurrency-";

  public static readonly LoggedInUser: string = "@LoggedInUser"
}