import React, { useContext, useEffect, useState } from "react";
import getScreenSize from "../../services/getScreenSize";
import { IImageProps, IStackStyles, IStackTokens, Image, Stack, Text, ITextStyles } from "@fluentui/react";
import { SignIn } from "./SignIn";
import { RegisterButton } from "./RegisterButton";
import { MainBackgroundLeft } from "./MainBackgroundLeft";
import { GoogleSignInButton } from "./GoogleSignInButton";
import { AuthenticationProvider } from "../../enmus/AuthenticationProvider";
import { MsalContext } from "../../LoggingWrapper";
import { useRecoilState } from "recoil";
import { authenticationProviderState, signInButtonDisabledState } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";

export const Landing: React.FC = () => {
  const screenSize: number = getScreenSize();

  const logoProps: IImageProps = {
    src: "./1x/Logo + by intact_1 3.png",
    width: "100%",
  };

  const bodyStackItemStyles: IStackStyles = {
    root: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
  };

  const rightSideStackStyles: IStackStyles = {
    root: {
      minWidth: "400px",
    },
  };

  const rightSideStackTokens: IStackTokens = {
    childrenGap: 20,
  };

  const rightStackItemStyles: IStackStyles = {
    root: {
      display: "flex",
      justifyContent: "center",
      width: screenSize >= 768 ? "40%" : "100%",
      backgroundColor: "#F4F6F8",
    },
  };

  const powredByItemStyles: ITextStyles = {
    root: {
      color: "#AAB8C7",
      paddingBottom: "30px",
    },
  };

  const instance = useContext(MsalContext);

  const [signInQueued, setSignInQueued] = useState(false);

  const [signInButtonDisabled, setSignInButtonDisabled]: [boolean, (signInButtonDisabled: boolean) => void] =
    useRecoilState(signInButtonDisabledState);

  const setAuthenticationProvider = useSetRecoilState(authenticationProviderState);

  useEffect(() => {
    if (!signInQueued) {
      return;
    }

    setSignInButtonDisabled(true);
    setTimeout(async () => {
      await instance.handleRedirectPromise();
      await instance.loginRedirect();
      setSignInButtonDisabled(false);
    });
    setSignInQueued(false);
  }, [signInQueued, instance, setSignInButtonDisabled]);

  const signIn = async (authenticationProvider: AuthenticationProvider): Promise<any> => {
    if (authenticationProvider === AuthenticationProvider.Google && window.ReAccessWeb.process.env.ALLOW_GOOGLE_AUTH) {
      setAuthenticationProvider(AuthenticationProvider.Google);
    } else {
      setAuthenticationProvider(AuthenticationProvider.Microsoft);
    }

    setSignInQueued(true);
  };

  return (
    <>
      <Stack horizontal={true} styles={bodyStackItemStyles}>
        <MainBackgroundLeft></MainBackgroundLeft>
        <Stack.Item styles={rightStackItemStyles}>
          <Stack horizontal={false} verticalAlign="space-between" styles={rightSideStackStyles}>
            <Stack.Item>
              <Stack></Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal={false} tokens={rightSideStackTokens}>
                <Stack.Item align="center">
                  <Image {...logoProps} />
                </Stack.Item>
                <Stack.Item align="center">
                  <SignIn signInCallback={() => signIn(AuthenticationProvider.Microsoft)} signInButtonDisabled={signInButtonDisabled}></SignIn>
                </Stack.Item>
                <Stack.Item align="center">
                  <RegisterButton></RegisterButton>
                </Stack.Item>
                <Stack.Item align="center">
                  {window.ReAccessWeb.process.env.ALLOW_GOOGLE_AUTH && (
                    <GoogleSignInButton
                    signInCallback={() => signIn(AuthenticationProvider.Google)}
                    signInButtonDisabled={signInButtonDisabled}
                  ></GoogleSignInButton>
                  )}
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item style={{ marginBottom: "30px" }}>
              <Stack horizontal={false}>
                <Stack.Item align="center">
                  <Text variant={screenSize < 1200 ? "mediumPlus" : "large"} styles={powredByItemStyles}>
                    Powered by Intact
                  </Text>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};
