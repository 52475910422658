import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { navHeaderState, shimmerNavState } from "../../recoil/atoms";
import { IImageProps, IStackTokens, Image, PrimaryButton, Stack, Text, IStackStyles } from "@fluentui/react";
import getScreenSize from "../../services/getScreenSize";
import { useNavigate } from "react-router-dom";
import './Welcome.css';

export const Welcome: React.FC = () => {
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const screenSize: number = getScreenSize();
    const navigate = useNavigate();

    useEffect(() => {
        setNavHeader("Welcome");
        setShimmerNav(true);
    }, [setNavHeader, setShimmerNav]);

    const logoProps: IImageProps = {
        src: './1x/Logo + by intact_1 3.png',
        width: "100%"
    };

    const computerProps: IImageProps = {
        src: './1x/Computer.png',
        width: "100%"
    };

    const bodyStackItemStyles: IStackStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%"
        }
    };

    const rightSideStackTokens: IStackTokens = {
        childrenGap: 35,
    };

    const finalizeTrialActivation = (): void => {
        navigate(`/NewApplication/Templates`, { replace: true });
    }

    const leftStackItemStyles: IStackStyles = {
        root: {
            display: "flex",
            justifyContent: 'center',
            width: screenSize > 1200 ? "70%" : "100%",
            backgroundColor: "#F4F6F8",
        }
    };

    const rightStackItemStyles: IStackStyles = {
        root: {
            display: "flex",
            justifyContent: 'center',
            width: "30%"
        }
    };

    return (
        <Stack horizontal={true} styles={bodyStackItemStyles}>
            <Stack.Item styles={leftStackItemStyles}>
                <Stack horizontal={false} verticalAlign="center" style={{ maxWidth: "350px", marginLeft: screenSize > 1200 ? "-300px" : "" }}>
                    <Stack.Item style={{marginBottom: "25"}}>
                        <Stack horizontal={false} tokens={rightSideStackTokens}>
                            <Stack.Item align="start" style={{marginBottom: "50px"}}>
                                <Image {...logoProps} />
                            </Stack.Item>
                            <Stack.Item align="start">
                                <Text variant={screenSize < 1200 ? "xxLarge" : "superLarge"}>
                                    Your trial is ready!
                                </Text>
                            </Stack.Item>
                            <Stack.Item align="start">
                                <Text variant={"mediumPlus"}>
                                    Boost your business with 10x faster than traditional app development.
                                </Text>
                            </Stack.Item>
                            <Stack.Item align="start">
                                <Text variant={"mediumPlus"}>
                                    If you ever get stuck, reach out to us for help.  Click below to get started on your first app
                                </Text>
                            </Stack.Item>
                            <Stack.Item align="start">
                                <PrimaryButton
                                    text="Get Started"
                                    ariaDescription="Get started button."
                                    style={{ width: "250px" }}
                                    onClick={finalizeTrialActivation} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item className="medium-small-screen-hidden welcome-right-background" styles={rightStackItemStyles}>
                <>
                    <Stack horizontal={false} verticalAlign="center">
                        <Stack.Item style={{ marginLeft: "-50%", width: "100%", display: "flex" }}>
                            <Image {...computerProps} />
                        </Stack.Item>
                    </Stack>
                </>
            </Stack.Item>
        </Stack>
    );
}