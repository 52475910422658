import React from "react";
import { TextField, TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../model/manifest/ControlData";
import { Controller } from "react-hook-form";
import { ConnectForm } from "../form/ConnectForm";
import { useId } from "@fluentui/react-hooks";
import { buildFormPropertyName } from "../../../services/fieldService";

export interface DocumentSearchTextFieldProps {
    control: ControlData;
    shimmerControlsOnSubmit: boolean;
}

export const DocumentSearchTextField: React.FC<DocumentSearchTextFieldProps> = (props: DocumentSearchTextFieldProps) => {
    const textBoxId = useId("textBox");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textBoxId}`
    };

    return (
        <ConnectForm>
            {(methods: any) =>
                <Controller
                    control={methods.control}
                    name={buildFormPropertyName(props.control.Id!, false, undefined, undefined)}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <>
                            <TooltipHost
                                content={props.control.ToolTipText}
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <TextField
                                    id={textBoxId}
                                    label={props.control.LabelText}
                                    multiline={false}
                                    value={value}
                                    aria-describedby={tooltipId}
                                    errorMessage={error != null && error.message != null ? error.message : undefined}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        onChange(newValue);
                                    }} />
                            </TooltipHost>
                        </>
                    )} />
            }
        </ConnectForm>
    )
}