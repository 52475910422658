import { AppInsightsContext, AppInsightsErrorBoundary, withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/ApplicationInsightsService";
import UnhandledError from "./UnhandledError";
import { App } from "./App";
import { useContext } from "react";
import { MsalContext } from "./LoggingWrapper";

export const AppInsightsApp: React.FC = () => {
  const msalInstance = useContext(MsalContext);
  return (
    <>
      {window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY != null &&
        window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY !== "" && (
          <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary onError={UnhandledError} appInsights={reactPlugin}>
              <App msalInstance={msalInstance} />
            </AppInsightsErrorBoundary>
          </AppInsightsContext.Provider>
        )}
    </>
  );
};

if (
  window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY != null &&
  window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY !== ""
) {
  withAITracking(reactPlugin, AppInsightsApp, undefined, "app-insights");
}
