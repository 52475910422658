import React from "react";
import { DefaultButton } from "@fluentui/react";

export interface EditFormButtonProps {
    shimmerControlsOnSubmit: boolean;
    onEditModeChange: (isEditMode: boolean) => void;
}

export const EditFormButton: React.FC<EditFormButtonProps> = (props: EditFormButtonProps) => {

    return (
        <DefaultButton
            text="Edit"
            iconProps={{ iconName: "Edit" }}
            disabled={props.shimmerControlsOnSubmit === false ? true : false}
            onClick={() => {
                props.onEditModeChange(true);
            }}
        />
    );
}