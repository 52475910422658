import React, { } from "react";
import { Dialog, DialogFooter, PrimaryButton, DialogType, DefaultButton } from "@fluentui/react";
import { IDialogModalWrapperProps } from "../../interfaces/DialogModal";
import { useId } from "@fluentui/react-hooks";

export interface DialogModalWrapperProps {
    dialogModalWrapperProps: IDialogModalWrapperProps
}

export const DialogModalWrapper: React.FC<DialogModalWrapperProps> = (props: DialogModalWrapperProps) => {
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogContentProps = {
        type: DialogType.normal,
        title: props.dialogModalWrapperProps.title,
        subText: props.dialogModalWrapperProps.subText,
    };
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: props.dialogModalWrapperProps.isBlocking,
            styles: { main: { maxWidth: 450 } },
            //dragOptions: false
        }),
        [labelId, props.dialogModalWrapperProps.isBlocking, subTextId],
    );

    return (
        <>
            <Dialog
                hidden={!props.dialogModalWrapperProps.isVisible}
                onDismiss={props.dialogModalWrapperProps.onDismiss}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    {props.dialogModalWrapperProps.secondaryButtonText != null &&
                        <DefaultButton onClick={props.dialogModalWrapperProps.onSecondaryButtonDismiss} text={props.dialogModalWrapperProps.secondaryButtonText} />
                    }
                    <PrimaryButton onClick={props.dialogModalWrapperProps.onDismiss} text={props.dialogModalWrapperProps.primaryButtonText} />
                </DialogFooter>
            </Dialog>
        </>
    );
}