import React, { useEffect, useState } from "react";
import { ActionButton, Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, IIconProps, IStackTokens, Stack, Text, useTheme } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { removeButtonStyles } from "../../../services/styleService";
import { ManifestSummaryDetailDto } from "../../../dtos/ManifestSummary/ManifestSummaryDetailDto";
import axios, { AxiosRequestConfig } from "axios";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { ValidationError } from "../../../model/error/ValidationError";
import { useSetRecoilState } from "recoil";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";

export interface DeleteApplicationButtonProps {
    customerId: string;
    manifestSummaryDetailDtos: ManifestSummaryDetailDto[];
    buttonType: string;
    deleteSuccessful: () => void;
}

export const DeleteApplicationButton: React.FC<DeleteApplicationButtonProps> = (props: DeleteApplicationButtonProps) => {
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [appApprovals, setAppApprovals] = useState<IAppApprove[]>([]);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
    const [isDeleteModalHidden, setIsDeleteModalHidden] = useState(true);
    const deleteIcon: IIconProps = { iconName: "Delete" };
    const labelId: string = useId("dialogLabel");
    const subTextId: string = useId("subTextLabel");
    const theme = useTheme();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        var approvals: IAppApprove[] = [];
        props.manifestSummaryDetailDtos.forEach(summary => {
            var approval: IAppApprove = {
                applicationId: summary.ApplicationId,
                name: summary.Name,
                approved: false
            };
            approvals.push(approval);
        });
        setAppApprovals(approvals);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.manifestSummaryDetailDtos]);

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: { main: { minWidth: "450px !important", maxWidth: "500px !important" } }
        }),
        [labelId, subTextId],
    );

    const deleteSelected = (): void => {
        setIsDeleteModalHidden(true);
        setIsDeleteButtonDisabled(true);

        var purgeApplicationDto = {
            ApplicationIds: props.manifestSummaryDetailDtos.map(i => i.ApplicationId),
            CustomerId: props.customerId
        }

        const options: AxiosRequestConfig = {
            method: "post",
            url: "/PurgeApplication",
            data: purgeApplicationDto,
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {
                setIsDeleteButtonDisabled(false);
                props.deleteSuccessful();
            })
            .catch((error) => {
                setIsDeleteButtonDisabled(false);
                if (error.response == null) {
                    console.log("Request canceled", "Delete Application Button Component cleaned up");
                } else {
                    var dialogProps: IDialogModalWrapperProps | undefined;
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    } else if (error.response.status === 422) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        dialogProps = {
                            isVisible: true,
                            title: validationErrors[0].Title,
                            subText: validationErrors[0].Message,
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                              setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                          };
                          setDialogModalWrapperPropsState(dialogProps);
                    } else {
                        dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                              setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                          };
                          setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
    };

    const cancel = (): void => {
        var approvals = [...appApprovals];
        approvals.forEach(approval => {
            approval.approved = false;
        });
        setAppApprovals(appApprovals);
        setIsDeleteModalHidden(true);
    };

    const isApproved = (approvals: IAppApprove[]): boolean => {
        return approvals.every(i => i.approved);
    };

    return (
        <>
            {props.buttonType === "DefaultButton" &&
                <DefaultButton
                    text="Delete Application"
                    ariaDescription="Delete application button."
                    styles={removeButtonStyles(theme)}
                    onClick={() => setIsDeleteModalHidden(false)}
                    disabled={isDeleteButtonDisabled}
                    allowDisabledFocus />
            }
            {props.buttonType === "ActionButton" &&
                <ActionButton
                    iconProps={deleteIcon}
                    onClick={() => setIsDeleteModalHidden(false)}>
                    Delete
                </ActionButton>
            }
            <Dialog
                hidden={isDeleteModalHidden}
                onDismiss={cancel}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <Stack tokens={themedMediumStackTokens}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            You are about to perform an action that cannot be undone.  ReAccess will delete the application structure and data by acknowledging and clicking Delete below.
                        </Text>
                    </Stack.Item>
                    {appApprovals.map(appApproval => {
                        return <Stack.Item>
                            <Checkbox
                                label={appApproval.name}
                                checked={appApproval.approved}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                    var approvals = [...appApprovals];
                                    var app = approvals.find(i => i.applicationId === appApproval.applicationId)!;
                                    app.approved = isChecked!;
                                    setAppApprovals(approvals);
                                }} />
                        </Stack.Item>
                    })}
                </Stack>
                <DialogFooter>
                    <DefaultButton
                        onClick={cancel}
                        text="Cancel" />
                    <DefaultButton
                        text="Delete"
                        ariaDescription="Approve delete button."
                        styles={removeButtonStyles(theme)}
                        onClick={deleteSelected}
                        disabled={!isApproved([...appApprovals])}
                        allowDisabledFocus />
                </DialogFooter>
            </Dialog>
        </>
    );
}

const dialogContentProps = {
    type: DialogType.normal,
    title: "Delete Application"
};

const themedMediumStackTokens: IStackTokens = {
    childrenGap: 'm',
    padding: 'm',
};

interface IAppApprove {
    applicationId: string;
    name: string;
    approved: boolean;
}