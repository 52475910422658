import React from "react";
import { PrimaryButton } from "@fluentui/react";
import { ConnectForm } from "../form/ConnectForm";

export interface SearchButtonProps {
    shimmerControlsOnSubmit: boolean;
    onSearchClicked: () => void;
}

export const SearchButton: React.FC<SearchButtonProps> = (props: SearchButtonProps) => {
    return (
        <ConnectForm>
            {(methods: any) =>
                <PrimaryButton
                    text="Search"
                    iconProps={{ iconName: "Search" }}
                    //type="submit"
                    //name="btnSearch"
                    disabled={props.shimmerControlsOnSubmit === false ? true : false}
                    onClick={() => {
                        props.onSearchClicked();
                    }}
                />
            }
        </ConnectForm>
    );
}