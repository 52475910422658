import React from "react";
import { Stack, IStackTokens } from "@fluentui/react";
import { ControlData } from "../../../model/manifest/ControlData";
import { FormRowItem } from "../form/FormRowItem";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { FromToDatePicker } from "./FromToDatePicker";
import { FromToDateTimePicker } from "./FromToDateTimePicker";
import { FromToCurrencyPicker } from "./FromToCurrencyPicker";
import { DocumentSearchTextField } from "./DocumentSearchTextFields";
import { isAuditDateTimeField } from "../../../services/fieldService";
import { useId } from "@fluentui/react-hooks";

export interface SearchRowProps {
    controls: ControlData[];
    applicationEntity: ApplicationEntity | undefined;
    shouldValidate: boolean;
    disableControls: boolean;
    isDetailsView: boolean;
    shimmerControlsOnSubmit: boolean;
}

export const SearchRow: React.FC<SearchRowProps> = (props: SearchRowProps) => {
    const searchRowId: string = useId('search-row');
    const themedMediumStackTokens: IStackTokens = {
        childrenGap: 'm',
        padding: 'm',
    };

    return (
        <>
            <Stack horizontal wrap horizontalAlign="space-between" tokens={themedMediumStackTokens}>
                {
                    props.controls.map((value, index) => {
                        return (
                            <Stack.Item
                                grow
                                disableShrink
                                key={searchRowId + value.Id + index}>
                                {(value.FieldType !== 5 && value.FieldType !== 7 && value.FieldType !== 19 &&
                                    value.FieldType !== 21 && value.FieldType !== 22 && value.FieldType !== 24 &&
                                    value.FieldType !== 25 && value.FieldType !== 32 && value.FieldType !== 40) &&
                                    <Stack.Item
                                        grow
                                        disableShrink>
                                        <FormRowItem
                                            key={"SearchControlFormRowItem" + value.Id + index}
                                            control={value}
                                            applicationEntity={props.applicationEntity}
                                            shouldValidate={props.shouldValidate}
                                            isSearch={true}
                                            disableControls={props.disableControls}
                                            isDetailsView={props.isDetailsView}
                                            editMode={false}
                                            shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                            entityId={undefined}
                                            subFormField={undefined}>
                                        </FormRowItem>
                                    </Stack.Item>
                                }
                                {value.FieldType === 5 &&
                                    <Stack horizontal={true}>
                                        <Stack.Item
                                            grow
                                            disableShrink>
                                            <FromToDatePicker
                                                key={"SearchControlFormRowItem" + value.Id + index}
                                                control={value}
                                                isFromDate={true}
                                                shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                                            </FromToDatePicker>
                                        </Stack.Item>
                                        <Stack.Item
                                            style={{paddingLeft: "10px" }}
                                            grow
                                            disableShrink>
                                            <FromToDatePicker
                                                key={"SearchControlFormRowItem" + value.Id + index}
                                                control={value}
                                                isFromDate={false}
                                                shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                                            </FromToDatePicker>
                                        </Stack.Item>
                                    </Stack>
                                }
                                {(value.FieldType === 7 || isAuditDateTimeField(value)) &&
                                    <Stack horizontal={true}>
                                        <Stack.Item
                                            grow
                                            disableShrink>
                                            <FromToDateTimePicker
                                                key={"SearchControlFormRowItem" + value.Id + index}
                                                control={value}
                                                isFromDate={true}
                                                shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                                            </FromToDateTimePicker>
                                        </Stack.Item>
                                        <Stack.Item
                                            style={{paddingLeft: "10px" }}
                                            grow
                                            disableShrink>
                                            <FromToDateTimePicker
                                                key={"SearchControlFormRowItem" + value.Id + index}
                                                control={value}
                                                isFromDate={false}
                                                shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                                            </FromToDateTimePicker>
                                        </Stack.Item>
                                    </Stack>
                                }
                                {value.FieldType === 22 &&
                                    <Stack horizontal={true}>
                                        <Stack.Item
                                            grow
                                            disableShrink>
                                            <FromToCurrencyPicker
                                                key={"SearchControlFormRowItem" + value.Id + index}
                                                control={value}
                                                isFromCurrency={true}
                                                shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                                            </FromToCurrencyPicker>
                                        </Stack.Item>
                                        <Stack.Item
                                            style={{paddingLeft: "10px" }}
                                            grow
                                            disableShrink>
                                            <FromToCurrencyPicker
                                                key={"SearchControlFormRowItem" + value.Id + index}
                                                control={value}
                                                isFromCurrency={false}
                                                shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                                            </FromToCurrencyPicker>
                                        </Stack.Item>
                                    </Stack>
                                }
                                {(value.FieldType === 24 || value.FieldType === 25 || value.FieldType === 32 || value.FieldType === 40) &&
                                    <Stack.Item
                                        grow
                                        disableShrink>
                                        <DocumentSearchTextField
                                            key={"SearchControlItem" + value.Id + index}
                                            control={value}
                                            shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                                        </DocumentSearchTextField>
                                    </Stack.Item>
                                }
                            </Stack.Item>
                        )
                    })
                }
            </Stack>
        </>
    );
}