import React from "react";
import { DefaultButton } from "@fluentui/react";
import { ConnectForm } from "../form/ConnectForm";

export interface ResetSearchButtonProps {
    shimmerControlsOnSubmit: boolean;
}

/**
 * Resets the search page by setting the search form to it's last searched state.
 */
export const ResetSearchButton: React.FC<ResetSearchButtonProps> = (props: ResetSearchButtonProps) => {

    return (
        <>
            <ConnectForm>
                {(methods: any) =>
                    <DefaultButton
                        text="Reset"
                        iconProps={{ iconName: "Refresh" }}
                        disabled={props.shimmerControlsOnSubmit === false ? true : false}
                        onClick={() => {
                            methods.reset();
                        }}
                    />
                }
            </ConnectForm>
        </>
    );
}