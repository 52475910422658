import React, {  } from "react";
import { Dialog, DialogFooter, PrimaryButton, DialogType } from "@fluentui/react";
import { useParams, useNavigate } from "react-router";
import { useId } from "@fluentui/react-hooks";

export interface EntityNotFoundModalProps {
    isHidden: boolean;
}

export const EntityNotFoundModal: React.FC<EntityNotFoundModalProps> = (props: EntityNotFoundModalProps) => {
    var { appId, formId } = useParams();
    const navigate = useNavigate();

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Entity Not Found',
        subText: 'This Entity does not exist.  You will be redirected to the search page.',
    };
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
            //dragOptions: false
        }),
        [labelId, subTextId],
    );

    function onDismiss() {
        navigate(`/Application/${appId}/Form/${formId}/Search`, { replace: true });
    }

    return (
        <>
            <Dialog
                hidden={props.isHidden}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton onClick={onDismiss} text="OK" />
                </DialogFooter>
            </Dialog>
        </>
    );
}