import React from "react";
import { IContextualMenuProps, PrimaryButton } from "@fluentui/react";

export interface NewAddExistingButtonProps {
    hideGridAddExistingButton: boolean;
    hideGridAddNewButton: boolean;
    disableAddNewExistingButton: boolean;
    onAddNewClicked: (addNew: boolean) => void;
}

export const NewAddExistingButton: React.FC<NewAddExistingButtonProps> = (props: NewAddExistingButtonProps) => {

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'new',
                text: 'Add New',
                iconProps: { iconName: 'Add' },
                onClick: (() => {
                    props.onAddNewClicked(true);
                })
            },
            {
                key: 'existing',
                text: 'Add Existing',
                iconProps: { iconName: 'AddLink' },
                onClick: (() => {
                    props.onAddNewClicked(false);
                })
            }
        ],
    };

    return (
        <>
            {(!props.hideGridAddExistingButton && !props.hideGridAddNewButton) &&
                <PrimaryButton
                    text="Add New"
                    primary
                    split
                    splitButtonAriaLabel="See 2 options"
                    aria-roledescription="split button"
                    disabled={props.disableAddNewExistingButton}
                    menuProps={menuProps}
                    onClick={() => {
                        //Needs an onClick for the split button to work even though calling submit
                        props.onAddNewClicked(true);
                    }}
                />
            }
            {(props.hideGridAddExistingButton && !props.hideGridAddNewButton) &&
                <PrimaryButton
                    text="Add New"
                    primary
                    disabled={props.disableAddNewExistingButton}
                    onClick={() => {
                        props.onAddNewClicked(true);
                    }}
                />
            }
            {(!props.hideGridAddExistingButton && props.hideGridAddNewButton) &&
                <PrimaryButton
                    text="Add Existing"
                    primary
                    disabled={props.disableAddNewExistingButton}
                    onClick={() => {
                        props.onAddNewClicked(false);
                    }}
                />
            }
        </>

    );
}