import React from "react";
import { useRecoilValue } from "recoil";
import { navHeaderState, shimmerNavState } from "../../recoil/atoms";
import { ITextStyles, Shimmer, ShimmerElementsGroup, ShimmerElementType, Text, useTheme } from "@fluentui/react";

export const AppNameHeader: React.FC = () => {
    const navHeader = useRecoilValue(navHeaderState);
    const shimmerNav = useRecoilValue(shimmerNavState);
    const theme = useTheme();

    const getCustomElements = (): JSX.Element => {
        return (
            <div>
                <ShimmerElementsGroup
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: 15 },
                        { type: ShimmerElementType.line, height: 27 }
                    ]}
                />
            </div>
        );
    };

    return (
        <Shimmer
            customElementsGroup={getCustomElements()}
            width={225}
            isDataLoaded={shimmerNav}
            shimmerColors={{
                shimmer: theme.palette.themeTertiary,
                shimmerWave: theme.palette.themeSecondary,
                background: theme.semanticColors.bodyBackground,
            }}>
            <Text styles={navBarBrandTextStyle}>
                {navHeader != null && navHeader !== "" ? navHeader : ""}
            </Text>
        </Shimmer>
    );
}
const navBarBrandTextStyle: ITextStyles = {
    root: {
        paddingTop: ".75rem",
        paddingBottom: ".75rem",
        fontSize: "1rem",
        paddingLeft: "15px"
    }
};