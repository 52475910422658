import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Checkbox, DefaultButton, DetailsList, DetailsListLayoutMode, DocumentCard, Dropdown, IColumn, IDocumentCardStyles, IDropdownOption, IIconProps, IStackStyles, IStackTokens, PrimaryButton, SearchBox, SelectionMode, Stack, Text, useTheme } from "@fluentui/react";
import { applicationManifestState } from "../../../recoil/atoms";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import axios, { AxiosRequestConfig } from "axios";
import { ManifestUserPermissionDto } from "../../../dtos/Manifest/ManifestUserPermissionDto";
import moment from "moment";
import { NewUserPanel } from "./NewUserPanel";
import { getCustomerUserRoleOptions, getUserRoleOptions } from "../../../services/roleService";
import { IManifestUserPermissionGridItem } from "../../../interfaces/users/IManifestUserPermissionGridItem";
import { removeButtonStyles } from "../../../services/styleService";
import { Pagination } from "@fluentui/react-experiments";
import { useMsal } from "@azure/msal-react";
import { UserPersonaGridItem } from "../gridLayout/UserPersonaGridItem";
import { ManifestUserDeleteDto } from "../../../dtos/User/ManifestUserDeleteDto";

export const UserPermissions: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [manifestUserPermissionGridItems, setManifestUserPermissionGridItems] = useState<IManifestUserPermissionGridItem[]>([]);
    const [filteredManifestUserPermissionGridItems, setFilteredManifestUserPermissionGridItems] = useState<IManifestUserPermissionGridItem[]>([]);
    const [manifestUserPermissionGridItemsToDisplay, setManifestUserPermissionGridItemsToDisplay] = useState<IManifestUserPermissionGridItem[]>([]);
    const [isAddUserOpen, setAddUserOpen] = useState(false);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const [searchTextFilter, setSearchTextFilter] = useState<string>("");
    const { instance } = useMsal();
    const accountInfo = instance.getActiveAccount();
    const theme = useTheme();
    const itemsPerPage = 10;
    const filterIcon: IIconProps = { iconName: "Filter" };

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/ManifestUserPermission/GetAll",
            params: {
                applicationId: applicationManifest.Id
            },
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {
                var manifestUserPermissionDto: ManifestUserPermissionDto[] = result.data;
                var manifestUserPermissionGridItems: IManifestUserPermissionGridItem[] = [];
                manifestUserPermissionDto.forEach((item) => {
                    var manifestUserPermissionGridItem: IManifestUserPermissionGridItem = {
                        ApplicationId: item.ApplicationId,
                        Avatar: item.Avatar,
                        CustomerId: item.CustomerId,
                        CustomerUserRoleType: item.CustomerUserRoleType,
                        EmailAddress: item.EmailAddress,
                        FullName: item.FullName,
                        LastAccessed: item.LastAccessed,
                        Role: item.Role,
                        PreviousRole: "",
                        Disabled: false,
                        Selected: false
                    };
                    manifestUserPermissionGridItems.push(manifestUserPermissionGridItem);
                })
                manifestUserPermissionGridItems = sortUsers(manifestUserPermissionGridItems);
                setManifestUserPermissionGridItems(manifestUserPermissionGridItems);
            })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "User Permissions Component cleaned up");
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //text search on items.
        var matchingItems: any[] = [];
        var items = [...manifestUserPermissionGridItems];
        for (var item of items) {
            let textMatch = false;
            var lastAccessedString = moment(item.LastAccessed).format("M/D/YYYY h:mm A");
            if (searchTextFilter === "" || 
                (item.EmailAddress != null && item.EmailAddress.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1) ||
                (item.FullName != null && item.FullName.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1) ||
                (lastAccessedString != null && lastAccessedString.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1) ||
                (item.Role != null && item.Role.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1)) {
                textMatch = true;
            }
            if (textMatch) {
                matchingItems.push(item);
            }
        }
        setFilteredManifestUserPermissionGridItems(matchingItems);

        //calculate which items to display
        var skipToIndex = selectedPageIndex * itemsPerPage;
        var takeToIndex = skipToIndex + itemsPerPage;
        setManifestUserPermissionGridItemsToDisplay(matchingItems.slice(skipToIndex, takeToIndex));

        //check to see if page count is less than current selection.  if so adjust it down accordingly
        var pageCount = Math.ceil(matchingItems.length / itemsPerPage);
        var pageCountIndex = pageCount - 1;
        if (pageCountIndex < 0) {
            setSelectedPageIndex(0);
        } else if (selectedPageIndex + 1 > pageCountIndex) {
            setSelectedPageIndex(pageCountIndex);
        }
    }, [manifestUserPermissionGridItems, selectedPageIndex, searchTextFilter]);

    const documentCardStyles: IDocumentCardStyles = {
        root: {
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            maxWidth: "",
            minWidth: ""
        }
    };

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };

    const themedSmallStackTokens: IStackTokens = {
        childrenGap: "s1",
        padding: "s1",
    };

    const sortUsers = (manifestUserPermissionArray: IManifestUserPermissionGridItem[]): IManifestUserPermissionGridItem[] => {
        return manifestUserPermissionArray.sort((a, b) => a.EmailAddress.localeCompare(b.EmailAddress));
    }

    const createdUser = (emailAddress: string, role: string, avatar: string, fullName: string): void => {
        var permissions = [...manifestUserPermissionGridItems];
        var permission: IManifestUserPermissionGridItem = {
            ApplicationId: applicationManifest.Id!,
            Avatar: avatar,
            CustomerId: "",
            CustomerUserRoleType: "User",
            EmailAddress: emailAddress,
            FullName: fullName,
            LastAccessed: undefined,
            Role: role,
            PreviousRole: "",
            Disabled: false,
            Selected: false
        }
        permissions.push(permission);
        permissions = sortUsers(permissions);
        setManifestUserPermissionGridItems(permissions);
        setAddUserOpen(false);
    };

    const anySelected = (): boolean => {
        return manifestUserPermissionGridItems.findIndex(i => i.Selected) === -1 ? false : true;
    };

    const isGlobalOrOwnerRole = (customerUserRoleType: string): boolean => {
        return customerUserRoleType === "Global Application Administrator" ||
               customerUserRoleType === "Global Application Builder" ||
               customerUserRoleType === "Owner" ? true : false;
    };

    const deleteSelected = (): void => {
        setIsDeleteButtonDisabled(true);
        var selected = manifestUserPermissionGridItems.filter(i => i.Selected);
        var manifestUserDeleteDtos: ManifestUserDeleteDto[] = selected.map((i) => ({ ApplicationId: i.ApplicationId, EmailAddress: i.EmailAddress }));

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const options: AxiosRequestConfig = {
            method: "delete",
            url: "/ManifestUserAuthorization/Delete",
            params: {
                customerId: selected[0].CustomerId,
                applicationId: selected[0].ApplicationId
            },
            data: manifestUserDeleteDtos,
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {
                var permissions = [...manifestUserPermissionGridItems];
                manifestUserDeleteDtos.forEach(manifestUserDeleteDto => {
                    permissions = permissions.filter(i => i.EmailAddress !== manifestUserDeleteDto.EmailAddress);
                });
                setManifestUserPermissionGridItems(permissions);
                setIsDeleteButtonDisabled(false);
            })
            .catch((error) => {
                setIsDeleteButtonDisabled(false);
                if (error.response == null) {
                    console.log("Request canceled", "User Permissions Component cleaned up");
                } else {

                }
            });
    };

    const columns: IColumn[] = [
        {
            key: "Selected",
            name: "",
            fieldName: "selected",
            minWidth: 25,
            maxWidth: 25,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "boolean",
            onRender: (item: IManifestUserPermissionGridItem) => {
                var administratorCount = filteredManifestUserPermissionGridItems.filter(i => i.Role === "Administrator").length;
                var administratorSelected = filteredManifestUserPermissionGridItems.filter(i => i.Role === "Administrator" && i.Selected).length;
                var isDisabled = item.Role === "Administrator" && ((administratorCount === 1) 
                    || (administratorCount - administratorSelected === 1 && !item.Selected));

                // now check global roles
                if (isGlobalOrOwnerRole(item.CustomerUserRoleType))
                    isDisabled = true;

                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Checkbox
                            checked={item.Selected}
                            disabled={isDisabled}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                var permissions = [...manifestUserPermissionGridItems];
                                var rowItem = permissions.find(i => i.EmailAddress === item.EmailAddress)!;
                                rowItem.Selected = isChecked ? true : false;
                                setManifestUserPermissionGridItems(permissions);
                            }} />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 225,
            maxWidth: 525,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            // isSorted: true,
            // isSortedDescending: false,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: this._onColumnClick,
            data: "string",
            onRender: (item: IManifestUserPermissionGridItem) => {
                return <UserPersonaGridItem
                            avatar={item.Avatar}
                            fullName={item.FullName}
                            emailAddress={item.EmailAddress}
                        />
            },
            isPadded: true,
        },
        {
            key: "Role",
            name: "Role",
            fieldName: "role",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IManifestUserPermissionGridItem) => {
                var administratorCount = filteredManifestUserPermissionGridItems.filter(i => i.Role === "Administrator").length;
                var isDisabled = item.Disabled || isGlobalOrOwnerRole(item.CustomerUserRoleType) || (item.Role === "Administrator" && administratorCount === 1) || item.EmailAddress === accountInfo!.username.toLowerCase();
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Dropdown
                            selectedKey={isGlobalOrOwnerRole(item.CustomerUserRoleType) ? item.CustomerUserRoleType : item.Role}
                            disabled={isDisabled}
                            options={isGlobalOrOwnerRole(item.CustomerUserRoleType) ? getCustomerUserRoleOptions() : getUserRoleOptions()}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                var permissions = [...manifestUserPermissionGridItems];
                                var rowItem = permissions.find(i => i.EmailAddress === item.EmailAddress)!;
                                rowItem.PreviousRole = rowItem.Role;
                                rowItem.Role = option!.key.toString();
                                rowItem.Disabled = true;
                                setManifestUserPermissionGridItems(permissions);

                                const CancelToken = axios.CancelToken;
                                const source = CancelToken.source();
                                const options: AxiosRequestConfig = {
                                    method: "put",
                                    url: "/ManifestUserPermission/Put",
                                    params: {
                                        applicationId: applicationManifest.Id
                                    },
                                    data: {
                                        emailAddress: rowItem.EmailAddress,
                                        role: rowItem.Role
                                    },
                                    cancelToken: source.token
                                };
                                axios(options)
                                    .then((result) => {
                                        permissions = [...manifestUserPermissionGridItems];
                                        rowItem = permissions.find(i => i.EmailAddress === item.EmailAddress)!;
                                        rowItem.PreviousRole = "";
                                        rowItem.Disabled = false;
                                        setManifestUserPermissionGridItems(permissions);
                                    })
                                    .catch((error) => {
                                        if (error.response != null) {
                                            permissions = [...manifestUserPermissionGridItems];
                                            rowItem = permissions.find(i => i.EmailAddress === item.EmailAddress)!;
                                            rowItem.Role = rowItem.PreviousRole;
                                            rowItem.Disabled = false;
                                            setManifestUserPermissionGridItems(permissions);
                                        }
                                    });
                            }} />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "LastAccessed",
            name: "Last Accessed",
            fieldName: "lastAccessed",
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IManifestUserPermissionGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {item.LastAccessed != null ? moment(item.LastAccessed).format("M/D/YYYY h:mm A") : ""}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        }
    ];

    const getPageCount = (): number => {
        return Math.ceil(filteredManifestUserPermissionGridItems.length / itemsPerPage);
    }

    const pageChange = (index: number): void => {
        setSelectedPageIndex(index);
    }

    return (
        <Stack.Item>
            <Stack tokens={themedMediumStackTokens}>
                <Stack.Item>
                    <Text variant={"xxLarge"}>
                        User Permissions
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack tokens={themedSmallStackTokens}>
                        <DocumentCard styles={documentCardStyles}>
                            <Stack.Item>
                                <Stack horizontal={true} horizontalAlign="space-between" tokens={themedSmallStackTokens} wrap>
                                    <Stack.Item>
                                        <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                            <Text variant={"mediumPlus"}>
                                                Total
                                            </Text>
                                            <div className="bolt-pill">
                                                <div className="bolt-pill-content">
                                                    {manifestUserPermissionGridItems != null ? manifestUserPermissionGridItems.length : ""}
                                                </div>
                                            </div>
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                            <Stack.Item>
                                                <SearchBox
                                                    placeholder="Filter"
                                                    iconProps={filterIcon}
                                                    value={searchTextFilter}
                                                    onChange={(_, newValue) => {
                                                        setSearchTextFilter(newValue || "");
                                                    }}
                                                />
                                            </Stack.Item>
                                            {anySelected()
                                                ?
                                                <DefaultButton
                                                    text="Remove"
                                                    ariaDescription="Remove user button."
                                                    styles={removeButtonStyles(theme)}
                                                    onClick={deleteSelected}
                                                    disabled={isDeleteButtonDisabled}
                                                    allowDisabledFocus />
                                                :
                                                <PrimaryButton
                                                    text="Add User"
                                                    ariaDescription="Add user button."
                                                    onClick={() => { setAddUserOpen(true) }}
                                                    allowDisabledFocus />
                                            }
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack tokens={themedSmallStackTokens}>
                                <Stack.Item>
                                    <DetailsList
                                        items={manifestUserPermissionGridItemsToDisplay != null ? manifestUserPermissionGridItemsToDisplay : []}
                                        compact={true}
                                        columns={columns}
                                        selectionMode={SelectionMode.none}
                                        setKey="none"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Pagination
                                        selectedPageIndex={selectedPageIndex}
                                        pageCount={getPageCount()}
                                        itemsPerPage={itemsPerPage}
                                        totalItemCount={filteredManifestUserPermissionGridItems.length}
                                        format={"buttons"}
                                        previousPageAriaLabel={"previous page"}
                                        nextPageAriaLabel={"next page"}
                                        firstPageAriaLabel={"first page"}
                                        lastPageAriaLabel={"last page"}
                                        pageAriaLabel={"page"}
                                        selectedAriaLabel={"selected"}
                                        onPageChange={pageChange} />
                                </Stack.Item>
                            </Stack>
                        </DocumentCard>
                    </Stack>
                </Stack.Item>
            </Stack>
            {isAddUserOpen &&
                <NewUserPanel
                    appId={applicationManifest.Id!}
                    manifestUserPermissions={manifestUserPermissionGridItems}
                    createdUser={createdUser}
                    dismissPanel={() => { setAddUserOpen(false) }}>
                </NewUserPanel>
            }
        </Stack.Item>
    );
}

const columnStackStyles: IStackStyles = {
    root: {
        height: "100%"
    }
};