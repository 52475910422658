import React, { useState } from "react";
import { DefaultButton, Dropdown, IDropdownOption, IDropdownStyles, IStackTokens, Shimmer, ShimmerElementsGroup, ShimmerElementType, Stack, useTheme } from "@fluentui/react";
import { useRecoilValue } from "recoil";
import { applicationManifestState, dialogModalWrapperPropsState } from "../../../recoil/atoms";
import axios, { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { createImageUrlFromArray, downloadFileFromImageUrl } from "../../../services/documentService";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { useSetRecoilState } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { PageData } from "../../../model/manifest/PageData";
import { getPage } from "../../../services/manifestService";
import { ValidationError } from "../../../model/error/ValidationError";

export interface PrintFormButtonProps {
    applicationEntity: ApplicationEntity | undefined;
    shimmerControlsOnSubmit: boolean;
    formId: string;
}

export const PrintFormButton: React.FC<PrintFormButtonProps> = (props: PrintFormButtonProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [generatingDocuments, setGeneratingDocuments] = useState<boolean>(false);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const theme = useTheme();
    var { appId } = useParams();
    const pageSelected: PageData = getPage(applicationManifest, props.formId);

    const getButtonShimmer = (): JSX.Element => {
        return (
            <div>
                <ShimmerElementsGroup
                    shimmerElements={[
                        { type: ShimmerElementType.line, height: 32 }
                    ]}
                />
            </div>
        );
    };

    const stackTokens: IStackTokens = { childrenGap: 20 };

    const getPrintOptions = (): IDropdownOption[] => {
        if (pageSelected.PageDocuments == null || pageSelected.PageDocuments.length === 0) { return [] };
        return pageSelected.PageDocuments.map(i => ({ key: i.Id!, text: i.Name }));
    }

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 250 },
    };

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item) {
            setSelectedKeys(
                item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter(key => key !== item.key),
            );
        }
    };

    const print = () => {
        setGeneratingDocuments(true);
        var printUrls: Promise<AxiosResponse<any>>[] = [];
        selectedKeys.forEach((key) => {
            printUrls.push(axios.get(`/ApplicationEntityPrint?applicationId=${appId}&formId=${props.formId}&entityId=${props.applicationEntity!.EntityId}&pageDocumentId=${key}&manifestVersion=${applicationManifest.Version}&applicationEntityVersion=${props.applicationEntity!.Version !== undefined ? props.applicationEntity!.Version : 0}`));
        });
        Promise.all(printUrls)
            .then(axios.spread(function (...res: any) {
                res.forEach((result: any) => {
                    var imageUrl = createImageUrlFromArray(result.data.FileBytes, result.data.MimeType);
                    downloadFileFromImageUrl(imageUrl, result.data.FileName);
                });
                setGeneratingDocuments(false);
            }))
            .catch((error) => {
                setGeneratingDocuments(false);
                if (error.response == null) {
                    console.log("Request canceled", "Form Container Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps: IDialogModalWrapperProps | undefined;
                    if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        dialogProps = {
                            isVisible: true,
                            title: validationErrors[0].Title,
                            subText: validationErrors[0].Message,
                            isBlocking: false,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                    } else {
                        dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
    }

    return (
        <Stack horizontal wrap tokens={stackTokens}>
            <Stack.Item>
                <Shimmer
                    customElementsGroup={getButtonShimmer()}
                    width={250}
                    isDataLoaded={!generatingDocuments}
                    shimmerColors={{
                        shimmer: theme.palette.themeTertiary,
                        shimmerWave: theme.palette.themeSecondary,
                        background: theme.semanticColors.bodyBackground,
                    }}>
                    <>
                        <Dropdown
                            placeholder="Select file download"
                            multiSelect
                            options={getPrintOptions()}
                            styles={dropdownStyles}
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                        />
                    </>
                </Shimmer>
            </Stack.Item>
            <Stack.Item>
                <Shimmer
                    customElementsGroup={getButtonShimmer()}
                    width={96}
                    isDataLoaded={!generatingDocuments}
                    shimmerColors={{
                        shimmer: theme.palette.themeTertiary,
                        shimmerWave: theme.palette.themeSecondary,
                        background: theme.semanticColors.bodyBackground,
                    }}>
                    <>
                        <DefaultButton
                            text="Download"
                            iconProps={{ iconName: "DownloadDocument" }}
                            disabled={selectedKeys == null || selectedKeys.length === 0}
                            onClick={print}
                        />
                    </>
                </Shimmer>
            </Stack.Item>
        </Stack>
    );
}