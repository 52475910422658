import React from "react";
import { DefaultButton } from "@fluentui/react";
import { ConnectForm } from "../form/ConnectForm";

export interface ExportButtonProps {
    shimmerControlsOnSubmit: boolean;
    onSearchClicked: () => void;
}

export const ExportButton: React.FC<ExportButtonProps> = (props: ExportButtonProps) => {

    return (
        <>
            <ConnectForm>
                {(methods: any) => {
                    return (
                            <DefaultButton
                                text="Export"
                                iconProps={{ iconName: "Export" }}
                                //type="submit"
                                disabled={props.shimmerControlsOnSubmit === false ? true : false}
                                onClick={() => {
                                    props.onSearchClicked();
                                }}
                            />
                    )
                }}
            </ConnectForm>
        </>
    );
}