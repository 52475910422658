import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { CommentArray } from "./CommentArray";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface CommentControlProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const CommentControl: React.FC<CommentControlProps> = (props: CommentControlProps) => {
    return (
        <>
            <CommentArray
                control={props.control}
                shouldValidate={props.shouldValidate}
                disabled={props.disabled}
                readOnly={props.readOnly}
                isRequired={props.isRequired}
                entityId={props.entityId}
                subFormField={props.subFormField}>
            </CommentArray>
            {/* //TODO: Add shimmer control */}
        </>
    );
}