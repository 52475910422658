import React, {  } from "react";
import { AttachmentModel } from "../../../../../model/reaccessData/AttachmentModel";
import { Image, ImageFit } from "@fluentui/react";

export interface ImageFieldProps {
    attachmentModel: AttachmentModel;
}

export const ImageField: React.FC<ImageFieldProps> = (props: ImageFieldProps) => {
    return (
        <Image
            src={props.attachmentModel.SasUri}
            alt='Example of the image fit value "centerContain" on an image smaller than the frame.'
            imageFit={ImageFit.contain}
        />
    )
}
