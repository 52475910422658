import React, { useEffect, useState } from "react";
import { DetailsList, DetailsListLayoutMode, DocumentCard, IColumn, Icon, IDocumentCardStyles, IIconProps, IPersonaSharedProps, IStackStyles, IStackTokens, Persona, PersonaSize, PrimaryButton, SearchBox, SelectionMode, Stack, Text } from "@fluentui/react";
import { Group } from "../../../model/manifest/Group";
import { NewGroupPanel } from "./NewGroupPanel";
import { useNavigate } from "react-router-dom";
import { groupSettingsState } from "../../../recoil/atoms";
import { IGroupGridItem } from "../../../interfaces/users/IGroupGridItem";
import { useRecoilValue } from "recoil";
import { Pagination } from "@fluentui/react-experiments";

export interface ManifestGroupsProps {
    appId: string;
}

export const ManifestGroups: React.FC<ManifestGroupsProps> = (props: ManifestGroupsProps) => {
    const groups: Group[] = useRecoilValue(groupSettingsState);
    const [groupGridItems, setGroupGridItems] = useState<IGroupGridItem[]>([]);
    const [filteredGroupGridItems, setFilteredGroupGridItems] = useState<IGroupGridItem[]>([]);
    const [filteredGroupGridItemsToDisplay, setFilteredGroupGridItemsToDisplay] = useState<IGroupGridItem[]>([]);
    const [isAddGroupOpen, setAddGroupOpen] = useState(false);
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const [searchTextFilter, setSearchTextFilter] = useState<string>("");
    const itemsPerPage = 10;
    const filterIcon: IIconProps = { iconName: "Filter" };
    const navigate = useNavigate();

    useEffect(() => {
        var groupArray = [...groups];
        var gridItems: IGroupGridItem[] = [];
        groupArray.forEach(group => {
            var groupGridItem: IGroupGridItem = {
                GroupId: group.GroupId,
                ApplicationId: props.appId,
                Description: group.Description,
                Inactive: group.Inactive,
                Name: group.Name,
                Tags: group.Tags,
                Type: group.Type,
                Disabled: false,
                Selected: false,
                GroupUsers: group.GroupUsers
            };
            gridItems.push(groupGridItem);
        })
        gridItems = sortGroups(gridItems);
        setGroupGridItems(gridItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups]);

    useEffect(() => {
        //text search on items.
        var matchingItems: any[] = [];
        var items = [...groupGridItems];
        for (var item of items) {
            let textMatch = false;
            if (searchTextFilter === "" ||
                (item.Name != null && item.Name.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1) ||
                (item.Description != null && item.Description.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1) ||
                (item.Type != null && item.Type.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1)) {
                textMatch = true;
            }
            if (textMatch) {
                matchingItems.push(item);
            }
        }
        setFilteredGroupGridItems(matchingItems);

        //calculate which items to display
        var skipToIndex = selectedPageIndex * itemsPerPage;
        var takeToIndex = skipToIndex + itemsPerPage;
        setFilteredGroupGridItemsToDisplay(matchingItems.slice(skipToIndex, takeToIndex));

        //check to see if page count is less than current selection.  if so adjust it down accordingly
        var pageCount = Math.ceil(matchingItems.length / itemsPerPage);
        var pageCountIndex = pageCount - 1;
        if (pageCountIndex < 0) {
            setSelectedPageIndex(0);
        } else if (selectedPageIndex + 1 > pageCountIndex) {
            setSelectedPageIndex(pageCountIndex);
        }
    }, [groupGridItems, selectedPageIndex, searchTextFilter]);

    const documentCardStyles: IDocumentCardStyles = {
        root: {
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            maxWidth: "",
            minWidth: ""
        }
    };

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };

    const themedSmallStackTokens: IStackTokens = {
        childrenGap: "s1",
        padding: "s1",
    };

    const getInitials = (fullName: string) => {
        if (fullName == null) {
            return "";
        }
        const allNames = fullName.trim().split(' ');
        const initials = allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === allNames.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, "");
        return initials;
    }

    const sortGroups = (groupArray: IGroupGridItem[]): IGroupGridItem[] => {
        return groupArray.sort((a, b) => a.Name.localeCompare(b.Name));
    }

    const onItemInvoked = (item: Group, index?: number, ev?: Event) => {
        navigate(`/Application/${props.appId}/ApplicationSettings/Group/${item.GroupId}`, { replace: false });
    }

    const columns: IColumn[] = [
        {
            key: 'Name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 225,
            maxWidth: 500,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: 'string',
            onRender: (group: IGroupGridItem) => {
                var persona: IPersonaSharedProps = {
                    imageInitials: getInitials(group.Name),
                    imageShouldFadeIn: false
                };

                return <Stack horizontal={true}>
                    <Stack.Item>
                        <Persona
                            {...persona}
                            id={"myPersona"}
                            size={PersonaSize.size48}
                            imageAlt={"Initials of group: " + group.Name}
                        />
                    </Stack.Item>
                    <Stack.Item align="center">
                        <Text variant={"mediumPlus"} style={{ "fontWeight": "bold" }}>
                            {group.Name}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: 'Description',
            name: 'Description',
            fieldName: 'description',
            minWidth: 225,
            maxWidth: 525,
            isResizable: true,
            isCollapsible: false,
            data: 'string',
            onRender: (group: IGroupGridItem) => {
                return <Stack horizontal={true} styles={columnStackStyles}>
                    <Stack.Item align="center">
                        <Text variant={"mediumPlus"}>
                            {group.Description}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: 'Type',
            name: 'Type',
            fieldName: 'type',
            minWidth: 100,
            maxWidth: 185,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onRender: (group: IGroupGridItem) => {
                return <Stack horizontal={true} styles={columnStackStyles}>
                    <Stack.Item align="center">
                        <Text variant={"mediumPlus"}>
                            {group.Type}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: 'Members',
            name: 'Members',
            fieldName: 'members',
            minWidth: 200,
            maxWidth: 250,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onRender: (group: IGroupGridItem) => {
                var personas: IPersonaSharedProps[] = [];
                var maxLength: number = group.GroupUsers.length > 4 ? 4 : group.GroupUsers.length;
                for (var i = 0; i < maxLength; i++) {
                    var persona: IPersonaSharedProps = {
                        imageUrl: group.GroupUsers[i].Avatar == null && group.GroupUsers[i].FullName == null ? "./default_user_image.jpg" : group.GroupUsers[i].Avatar,
                        imageInitials: getInitials(group.GroupUsers[i].FullName),
                        imageShouldFadeIn: true
                    };
                    personas.push(persona);
                }

                return <Stack horizontal={true} styles={columnStackStyles}>
                    <Stack.Item align="center" style={{ paddingTop: "5px", paddingRight: "5px"}}>
                        <Icon iconName="people" />
                    </Stack.Item>
                    <Stack.Item align="center" style={{paddingRight: "15px"}}>
                        <Text variant={"mediumPlus"}>
                            {group.GroupUsers.length}
                        </Text>
                    </Stack.Item>
                    {personas.map(persona => {
                        return (
                            <Stack.Item align="center">
                                <Persona
                                    {...persona}
                                    id={"myPersona"}
                                    size={PersonaSize.size32}
                                    imageAlt={"Initials of group: " + group.Name}
                                />
                            </Stack.Item>
                        );
                    })}
                </Stack>
            },
            isPadded: true,
        }
    ];

    const getPageCount = (): number => {
        return Math.ceil(filteredGroupGridItems.length / itemsPerPage);
    }

    const pageChange = (index: number): void => {
        setSelectedPageIndex(index);
    }

    return (
        <Stack.Item>
            <Stack tokens={themedMediumStackTokens}>
                <Stack.Item>
                    <Text variant={"xxLarge"}>
                        Group Settings
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack tokens={themedSmallStackTokens} horizontal={false}>
                        <Stack.Item>
                            <DocumentCard styles={documentCardStyles}>
                                <Stack tokens={themedSmallStackTokens}>
                                    <Stack.Item>
                                        <Stack horizontal={true} horizontalAlign="space-between" tokens={themedSmallStackTokens} wrap>
                                            <Stack.Item>
                                                <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                                    <Text variant={"mediumPlus"}>
                                                        Total
                                                    </Text>
                                                    <div className="bolt-pill">
                                                        <div className="bolt-pill-content">
                                                            {groups != null ? groups.length : ""}
                                                        </div>
                                                    </div>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                                    <Stack.Item>
                                                        <SearchBox
                                                            placeholder="Filter"
                                                            iconProps={filterIcon}
                                                            value={searchTextFilter}
                                                            onChange={(_, newValue) => {
                                                                setSearchTextFilter(newValue || "");
                                                            }}
                                                        />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <PrimaryButton
                                                            text="New Group"
                                                            ariaDescription="New Group button."
                                                            onClick={() => { setAddGroupOpen(true) }}
                                                            allowDisabledFocus />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <DetailsList
                                            items={filteredGroupGridItemsToDisplay != null ? filteredGroupGridItemsToDisplay : []}
                                            compact={true}
                                            columns={columns}
                                            selectionMode={SelectionMode.none}
                                            //getKey={getKey}
                                            setKey="none"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            onItemInvoked={onItemInvoked}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Pagination
                                            selectedPageIndex={selectedPageIndex}
                                            pageCount={getPageCount()}
                                            itemsPerPage={itemsPerPage}
                                            totalItemCount={filteredGroupGridItems.length}
                                            format={"buttons"}
                                            previousPageAriaLabel={"previous page"}
                                            nextPageAriaLabel={"next page"}
                                            firstPageAriaLabel={"first page"}
                                            lastPageAriaLabel={"last page"}
                                            pageAriaLabel={"page"}
                                            selectedAriaLabel={"selected"}
                                            onPageChange={pageChange} />
                                    </Stack.Item>
                                </Stack>
                            </DocumentCard>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                {isAddGroupOpen &&
                    <NewGroupPanel
                        appId={props.appId}
                        dismissPanel={() => { setAddGroupOpen(false) }}>
                    </NewGroupPanel>
                }
            </Stack>
        </Stack.Item>
    );
}

const columnStackStyles: IStackStyles = {
    root: {
        height: "100%"
    }
};