import React, { useEffect, useState } from "react";
import { DefaultButton, Dropdown, IDropdownOption, IStackTokens, Panel, PanelType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { Controller, useForm, useWatch } from "react-hook-form";
import axios, { AxiosRequestConfig } from "axios";
import { applicationManifestState, dialogModalWrapperPropsState } from "../../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { ValidationError } from "../../../model/error/ValidationError";
import { PageData } from "../../../model/manifest/PageData";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { StringConstants } from "../../../constants/StringConstants";
import { DataSourceItem } from "../../../model/manifest/DataSourceItem";
import { getFormattedOptions } from "../../../services/fieldService";
import { useParams } from "react-router-dom";
import { GroupDataFilterDto } from "../../../dtos/Manifest/GroupDataFilterDto";
import { IGroupDataFilterGridItem } from "../../../interfaces/users/IGroupDataFilterGridItem";
import { GroupDataFilterUpsertDto } from "../../../dtos/Manifest/GroupDataFilterUpsertDto";
import { ErrorMessage } from "@hookform/error-message";

export interface GroupDataFilterPanelProps {
    groupDataFilters: IGroupDataFilterGridItem[];
    groupDataFilter: IGroupDataFilterGridItem | undefined;
    createdGroupDataFilter: (groupDataFilterDto: GroupDataFilterDto) => void;
    editGroupDataFilter: (groupDataFilterDto: GroupDataFilterDto) => void;
    dismissPanel: () => void;
}

export const GroupDataFilterPanel: React.FC<GroupDataFilterPanelProps> = (props: GroupDataFilterPanelProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const defaultValues = {
        pageId: props.groupDataFilter !== undefined ? props.groupDataFilter.PageId : "",
        fieldId: props.groupDataFilter !== undefined ? props.groupDataFilter.FieldId : "",
        filterType: props.groupDataFilter !== undefined ? props.groupDataFilter.FilterType : "",
        values: props.groupDataFilter !== undefined ? props.groupDataFilter.DataSourceItemDtos.map(i => i.Value) : [],
        text: props.groupDataFilter !== undefined ? props.groupDataFilter.Text : ""
    }
    const { handleSubmit, control, formState: { errors, isDirty, isValid }, getValues, setError, setValue } = useForm({ defaultValues: defaultValues, mode: "onChange" });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isLoadingDataSources, setIsLoadingDataSources] = useState<boolean>(false);
    const [dataSourceItems, setDataSourceItems] = useState<DataSourceItem[]>();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var { appId, groupId } = useParams();

    // a watch on the following fields is needed to refresh cacading dropdowns
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pageIdValue: string | undefined = useWatch({ name: "pageId", control: control });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fieldIdValue: string | undefined = useWatch({ name: "fieldId", control: control });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filterTypeValue: string | undefined = useWatch({ name: "filterType", control: control });

    useEffect(() => {
        if (pageIdValue == null || pageIdValue === "" || fieldIdValue == null || fieldIdValue === "") { return; }

        setIsLoadingDataSources(true);
        axios.get(`/DataSourceAdmin/Get?ApplicationId=${applicationManifest.Id}&PageId=${pageIdValue}&FieldId=${fieldIdValue}&UniqueTextValues=false`,
            {
                cancelToken: source.token
            }).then((result) => {
                var dataSourceItems: DataSourceItem[] = result.data;
                setDataSourceItems(dataSourceItems);
                setIsLoadingDataSources(false);
            })
            .catch((error) => {
                setIsLoadingDataSources(false);
                //TODO: What type of error do we want to show?  Force a refresh on OK click in modal?  Will only need to show one modal if multiple fail.
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldIdValue]);

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack horizontal={true} horizontalAlign={"end"}>
                <PrimaryButton
                    styles={buttonStyles}
                    onClick={submitForm}
                    disabled={!isValid || !isDirty || isSubmitting}>
                    Save
                </PrimaryButton>
                <DefaultButton
                    onClick={props.dismissPanel}
                    disabled={isSubmitting}>
                    Cancel
                </DefaultButton>
            </Stack>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDirty, isSubmitting, isValid, props.dismissPanel],
    );

    const submitForm = async () => {
        setIsSubmitting(true);
        var data = getValues();
        var groupDataFilterUpsertDto: GroupDataFilterUpsertDto = {
            GroupId: groupId!,
            PageId: data.pageId,
            ControlId: data.fieldId,
            FilterType: data.filterType,
            Text: data.text,
            Values: data.values
        }
        const options: AxiosRequestConfig = {
            method: isEdit() ? "PUT" : "POST",
            data: groupDataFilterUpsertDto,
            url: `/GroupDataFilter/${isEdit() ? "Put" : "Post"}`,
            params: {
                applicationId: appId
            },
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                var groupDataFilterDto: GroupDataFilterDto = result.data;
                if (isEdit()) {
                    props.editGroupDataFilter(groupDataFilterDto);
                } else {
                    props.createdGroupDataFilter(groupDataFilterDto);
                }
                setIsSubmitting(false);
            }).catch((error) => {
                setIsSubmitting(false);
                if (error.response == null) {
                    console.log("Request canceled", "New Group Panel Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        //set errors manually
                        validationErrors.forEach((validationError: ValidationError) => {
                            setError(validationError.GroupIdentifier as any, {
                                type: "manual",
                                message: validationError.Message
                            })
                        });
                    } else {
                        var dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
        return function cleanup() {
            source.cancel();
        }
    }

    const getPageOptions = (): IDropdownOption[] => {
        var selectablePages: PageData[] = [];
        applicationManifest.Pages.forEach((page) => {
            if (page.Controls.findIndex(i => i.ControlType === 2) >= 0) {
                selectablePages.push(page);
            }
        })
        return selectablePages.map(i => ({ key: i.Id!, text: i.Name }));
    }

    const getFieldOptions = (): IDropdownOption[] => {
        var selectedPageId = pageIdValue;
        if (selectedPageId == null || selectedPageId === "") {
            return [];
        }
        var page = applicationManifest.Pages.find(i => i.Id === selectedPageId)!;
        var selectableControls = page.Controls.filter(i => i.ControlType === 2);
        return selectableControls.map(i => ({ key: i.Id!, text: i.LabelText }));
    }

    const getGroupDataFilterTypes = (): IDropdownOption[] => {
        return [
            { key: StringConstants.ExtactValues, text: StringConstants.ExtactValues },
            { key: StringConstants.TextStartsWith, text: StringConstants.TextStartsWith },
            { key: StringConstants.TextContains, text: StringConstants.TextContains },
            { key: StringConstants.TextEndsWith, text: StringConstants.TextEndsWith }
        ];
    }

    const isEdit = (): boolean => {
        return props.groupDataFilter !== undefined ? true : false;
    }

    const placeHolderTextforFilter = (): string => {
        if (filterTypeValue === StringConstants.TextStartsWith) {
            return "start with";
        } else if (filterTypeValue === StringConstants.TextContains) {
            return "contain";
        } else {
            return "end with";
        }
    }

    return (
        <Panel
            headerText={`${isEdit() ? "Edit" : "Add"} group data filter`}
            isOpen={true}
            onDismiss={props.dismissPanel}
            type={PanelType.custom}
            customWidth="500px"
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <form onSubmit={handleSubmit(submitForm)}>
                <Stack horizontal={false} tokens={themedSmallStackTokens} style={{ "paddingTop": "25px" }}>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="pageId"
                            rules={{
                                required: "Page is required."
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Dropdown
                                    label={"Page"}
                                    selectedKey={value}
                                    required={true}
                                    disabled={isEdit()}
                                    placeholder="Select an option"
                                    options={getPageOptions()}
                                    errorMessage={errors != null && errors["pageId"]?.message != null ? errors["pageId"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                        setValue("fieldId", "");
                                        onChange(option?.key);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="fieldId"
                            rules={{
                                validate: {
                                    unique: (value: string): string | undefined => {
                                        if (value == null || value === "") {
                                            return "Field is required";
                                        } else {
                                            var foundFilters = props.groupDataFilters.findIndex(i => i.PageId !== defaultValues.pageId && i.FieldId !== defaultValues.fieldId &&
                                                i.PageId === pageIdValue && i.FieldId === value);
                                            if (foundFilters >= 0) {
                                                return "This page and field combination already have a group filter applied";
                                            }
                                        }
                                        return undefined;
                                    },
                                },
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Dropdown
                                    label={"Field"}
                                    selectedKey={value}
                                    required={true}
                                    disabled={isEdit()}
                                    placeholder="Select an option"
                                    options={getFieldOptions()}
                                    errorMessage={errors != null && errors["fieldId"]?.message != null ? errors["fieldId"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                        if (option != null && option.key != null) {
                                            //getFilterValueOptions(option.key.toString());
                                        } else {
                                            setDataSourceItems([]);
                                        }
                                        onChange(option?.key);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="filterType"
                            rules={{
                                required: "Filter Type is required"
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Dropdown
                                    label={"Filter Type"}
                                    selectedKey={value}
                                    required={true}
                                    placeholder="Select an option"
                                    options={getGroupDataFilterTypes()}
                                    errorMessage={errors != null && errors["filterType"]?.message != null ? errors["filterType"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                        if (option?.key !== StringConstants.ExtactValues) {
                                            setValue("values", [], {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }
                                        //delay validation for field to render
                                        setTimeout(() => {
                                            setValue("text", "", {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }, 50);
                                        onChange(option?.key);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                    {(filterTypeValue !== "" && fieldIdValue !== "") &&
                        <>
                            {filterTypeValue === StringConstants.ExtactValues
                                ?
                                <Stack.Item>
                                    <Controller
                                        control={control}
                                        name="values"
                                        rules={{
                                            validate: {
                                                requred: (value: string[]): string | undefined => {
                                                    if (value == null || value.length === 0) {
                                                        return "Values are required";
                                                    }
                                                    return undefined;
                                                },
                                            },
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Dropdown
                                                label={"Filter Values"}
                                                selectedKeys={value}
                                                required={true}
                                                placeholder="Select an option"
                                                disabled={isLoadingDataSources}
                                                options={getFormattedOptions(dataSourceItems,
                                                    applicationManifest.DataSources.find(i => i.Name === applicationManifest.Pages
                                                        .find(p => p.Id === pageIdValue!)!.Controls
                                                        .find(c => c.Id === fieldIdValue!)!.DataSourceName)!,
                                                    undefined,
                                                    undefined,
                                                    true,
                                                    false)}
                                                //errorMessage={errors != null && errors["values"]?.message != null ? errors["values"].message : undefined}
                                                multiSelect={true}
                                                onBlur={onBlur}
                                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                                    const currentOption: string = option!.key as string;

                                                    if (option?.selected) {
                                                        const newSelected = value.concat([currentOption]);
                                                        setValue("values", newSelected, {
                                                            shouldValidate: true,
                                                            shouldDirty: true,
                                                        });
                                                        onChange(newSelected);
                                                    } else {
                                                        const newSelected = value.filter((i: string) => i !== currentOption);
                                                        setValue("values", newSelected, {
                                                            shouldValidate: true,
                                                            shouldDirty: true,
                                                        });
                                                        onChange(newSelected);
                                                    }
                                                }} />
                                        )}
                                    />
                                    <div className="CustomError">
                                        <ErrorMessage errors={errors} name={"values"} />
                                    </div>
                                </Stack.Item>
                                :
                                <Stack.Item>
                                    <Controller
                                        control={control}
                                        name="text"
                                        rules={{
                                            required: "Filter Text is required.",
                                            maxLength: { value: 200, message: "The max length of Filter Text is 200 characters" }
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <TextField
                                                label="Filter Text"
                                                required={true}
                                                placeholder={`Text must ${placeHolderTextforFilter()} this value`}
                                                value={value}
                                                errorMessage={errors != null && errors["text"]?.message != null ? errors["text"].message : undefined}
                                                onBlur={onBlur}
                                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                    onChange(newValue);
                                                }} />
                                        )}
                                    />
                                </Stack.Item>
                            }
                        </>
                    }
                </Stack>
            </form>
        </Panel>
    );
}

const buttonStyles = { root: { marginRight: 8 } };

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};