import React from "react";
import { DefaultButton, IContextualMenuProps } from "@fluentui/react";
import { ConnectForm } from "./ConnectForm";
import { FieldValues, UseFormReturn } from "react-hook-form";

export interface SaveFormButtonProps {
    isAddingEntity: boolean;
    displaySaveAddNew: boolean;
    shimmerControlsOnSubmit: boolean;
    canInsert: boolean;
    onSaveClicked: (isSaveOnly: boolean) => void;
}

export const SaveFormButton: React.FC<SaveFormButtonProps> = (props: SaveFormButtonProps) => {
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'save',
                text: 'Save',
                iconProps: { iconName: 'Save' },
                onClick: (() => {
                    props.onSaveClicked(true);
                })
            },
            {
                key: 'saveAndAddNew',
                text: 'Save & Add New',
                iconProps: { iconName: 'Add' },
                onClick: (() => {
                    props.onSaveClicked(false);
                })
            }
        ],
    };

    /*
    * Determines when the save button is disabled for a form.
    * @param methods 
    * @returns a boolean indicating if the save button should be disabled
    */
    const isDisabled = (methods: UseFormReturn<FieldValues, any, undefined>): boolean => {
        //if the controls are shimmering we disable the form
        if (props.shimmerControlsOnSubmit === false) {
            return true;
        }
        //if we are adding a record and the form is valid (example: customers have default values in forms), we can enable the save button
        if (props.isAddingEntity && methods.formState.isValid) {
            return false;
        }

        //BUG:1719
        //accessing the isDirty flag causes the value to update which on first render after typing is false when it should be true 
        //the regular form state variables work fine except when setting values manually such as in the grid field.
        var isDirty = methods.formState.isDirty;
        return !methods.formState.isValid || !isDirty || methods.formState.isSubmitting;
    }

    return (
        <ConnectForm>
            {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                <DefaultButton
                    text="Save"
                    primary
                    split={props.displaySaveAddNew ? true : false}
                    splitButtonAriaLabel="See 2 options"
                    aria-roledescription="split button"
                    menuProps={props.displaySaveAddNew && props.canInsert ? menuProps : undefined}
                    iconProps={props.displaySaveAddNew ? undefined : { iconName: "Save" }}
                    onClick={() => {
                        //Needs an onClick for the split button to work even though calling submit
                        props.onSaveClicked(true);
                    }}
                    disabled={isDisabled(methods)}
                />
            }
        </ConnectForm>
    );
}