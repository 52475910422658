import React, { useState } from "react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Label, Link, Stack } from "@fluentui/react";
import { PanelFormContainer } from "../../../form/PanelFormContainer";
import { applicationManifestState } from "../../../../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { isDropdownWithDetails } from "../../../../../services/dataSourceService";
import { convertStringToGuid } from "../../../../../services/stringManipulationService";
import { useParams } from "react-router-dom";

export interface ViewDropdownRecordProps {
    control: ControlData;
    value: string | undefined; //This is the entity id without the dashes
    dropdownId: string | undefined;
    isRequired: boolean;
}

export const ViewDropdownRecord: React.FC<ViewDropdownRecordProps> = (props: ViewDropdownRecordProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [displayEntityPanel, setDisplayEntityPanel] = useState<boolean>();
    var { entityId } = useParams();

    const dataSourcePageId = (name: string): string => {
        var dataSource = applicationManifest.DataSources.find(i => i.Name === name);
        return dataSource!.PageId!;
    }

    const isLinkDisabled = (): boolean => {
        var pageId = dataSourcePageId(props.control.DataSourceName);
        var page = applicationManifest.Pages.find(i => i.Id === pageId);
        return (props.value == null || props.value === "" || page == null) ? true : false;
    }

    const displayDropdownWithDetailsLink = (): boolean => {
        if (!isDropdownWithDetails(props.control)) return false;

        return entityId !== convertStringToGuid(props.value);
    }

    return (
        <Stack horizontal={true} horizontalAlign={"space-between"}>
            <Stack.Item>
                <Label 
                    htmlFor={props.dropdownId}
                    className={props.isRequired ? "CustomErrorLabel" : ""}>
                        {props.control.LabelText}
                </Label>
            </Stack.Item>
            {(displayDropdownWithDetailsLink()) &&
                <Stack.Item align="center">
                    <Link
                        href=""
                        underline
                        disabled={isLinkDisabled()}
                        onClick={() => {
                            setDisplayEntityPanel(true);
                        }}>
                        View
                    </Link>
                    {displayEntityPanel &&
                        <PanelFormContainer
                            formId={dataSourcePageId(props.control.DataSourceName)}
                            entityId={props.value != null ? convertStringToGuid(props.value) : undefined}
                            hideModal={() => {
                                setDisplayEntityPanel(false);
                            }} />
                    }
                </Stack.Item>
            }
        </Stack>
    );
}