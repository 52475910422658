import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { registerIcons } from "@fluentui/react/lib/Styling";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import RecoilNexus from "recoil-nexus";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import packageJson from "../package.json";
import { LoggingWrapper } from "./LoggingWrapper";

initializeIcons();
initializeFileTypeIcons();

registerIcons({
  icons: {
    "question-circle-svg": (
      <svg id="question-circle-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12 3.667C7.405 3.667 3.667 7.405 3.667 12C3.667 16.595 7.405 20.333 12 20.333C16.595 20.333 20.333 16.595 20.333 12C20.333 7.405 16.595 3.667 12 3.667ZM12 15.5C12.5523 15.5 13 15.9477 13 16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5C11 15.9477 11.4477 15.5 12 15.5ZM12 6.75C13.5188 6.75 14.75 7.98122 14.75 9.5C14.75 10.5108 14.4525 11.074 13.6989 11.8586L13.5303 12.0303C12.9084 12.6522 12.75 12.9163 12.75 13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5C11.25 12.4892 11.5475 11.926 12.3011 11.1414L12.4697 10.9697C13.0916 10.3478 13.25 10.0837 13.25 9.5C13.25 8.80964 12.6904 8.25 12 8.25C11.3528 8.25 10.8205 8.74187 10.7565 9.37219L10.75 9.5C10.75 9.91421 10.4142 10.25 10 10.25C9.58579 10.25 9.25 9.91421 9.25 9.5C9.25 7.98122 10.4812 6.75 12 6.75Z"
          fill="rgb(0, 120, 212)"
        />
      </svg>
    ),
    "microsoft-logo-svg": (
      <svg width="20" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.50333 9.50333H0V0H9.50333V9.50333Z" fill="#F1511B" />
        <path d="M19.996 9.50333H10.4929V0H19.996V9.50333Z" fill="#80CC28" />
        <path d="M9.50296 20.0002H0V10.4971H9.50296V20.0002Z" fill="#00ADEF" />
        <path d="M19.996 20.0002H10.4929V10.4971H19.996V20.0002Z" fill="#FBBC09" />
      </svg>
    ),
    "circle-checkmark-svg": (
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.83337 9.5835L8.33337 12.0835L12.5 7.0835" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M9.16671 17.5002C13.7691 17.5002 17.5 13.7692 17.5 9.16683C17.5 4.56446 13.7691 0.833496 9.16671 0.833496C4.56433 0.833496 0.833374 4.56446 0.833374 9.16683C0.833374 13.7692 4.56433 17.5002 9.16671 17.5002Z"
          stroke="white"
          strokeWidth="1.66667"
        />
      </svg>
    ),
    "google-logo-svg": (
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
        <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
      </svg>
    ),
  },
});

if (window.ReAccessWeb.process.env.SENTRY_DSN != null && window.ReAccessWeb.process.env.SENTRY_DSN !== "") {
  const release = `ReAccessWeb@${packageJson.version}`;
  Sentry.init({
    dsn: window.ReAccessWeb.process.env.SENTRY_DSN,
    release,
    environment: window.ReAccessWeb.process.env.ENVIRONMENT,
    integrations: [
      new Sentry.Replay({
        blockAllMedia: true,
      }),
      new BrowserTracing({
        tracingOrigins: [
          "localhost",
          "powerlinereaccessapi.azurewebsites.net",
          "powerlinereaccessapi-development.azurewebsites.net",
          "powerlinereaccessapi-stage.azurewebsites.net",
          "powerlinereaccessapi-blue.azurewebsites.net",
          "powerlinereaccessapi.azurewebsites.gov",
          /^\//,
        ],
        beforeNavigate: (context) => {
          return {
            ...context,
            name: window.location.pathname.replace(/[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/g, "<guid>"),
          };
        },
      }),
    ],
    tracesSampleRate: 0.2,
    sampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <Router>
        <LoggingWrapper />
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
