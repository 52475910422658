import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { resetSaveAddNewState } from "../../../recoil/atoms";
import { FormContainer } from "./FormContainer";

export const AddEntity: React.FC = () => {
    const [resetSaveAddNew, setResetSaveAddNew]: [boolean, (resetSaveAddNew: boolean) => void] = useRecoilState(resetSaveAddNewState);

    useEffect(() => {
        if (!resetSaveAddNew) {return}

        setResetSaveAddNew(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSaveAddNew]);

    return (
        <>
            {!resetSaveAddNew &&
                <FormContainer isAddingEntity={true}></FormContainer>
            }
        </>
    );
}