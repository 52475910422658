import React, { useEffect, useState } from "react";
import { ActionButton, DocumentCard, DocumentCardDetails, DocumentCardImage, DocumentCardTitle, FontIcon, IDocumentCardStyles, IDocumentCardTitleStyles, IIconProps, ImageFit, IStackTokens, mergeStyles, mergeStyleSets, SharedColors, Stack, Text } from "@fluentui/react";
import { getMimeTypeByFileName, base64ToArrayBuffer } from "../../../services/logoService";
import { appCatalogCustomersState, applicationManifestState, shimmerNavState } from "../../../recoil/atoms";
import { useSetRecoilState } from 'recoil';
import { useNavigate } from "react-router";
import axios from 'axios';
import ReAccessIconLogo from "../../../images/reaccess-symbol-blue-background.svg";
import { AppCatalogTile } from "../../../dtos/AppCatalogTile";
import { AppCatalogCustomer } from "../../../dtos/AppCatalogCustomer";
import { useRecoilState } from "recoil";
import { cloneDeep } from "lodash";

export interface IAppCardProps {
    appCatalogTile: AppCatalogTile;
}

export const AppCard: React.FC<IAppCardProps> = (props: IAppCardProps) => {
    const [logoImageUrl, setLogoImageUrl] = useState<string | undefined>(undefined);
    const [appCatalogCustomers, setAppCatalogCustomers]: [AppCatalogCustomer[], (appCatalogCustomers: AppCatalogCustomer[]) => void] = useRecoilState(appCatalogCustomersState);
    const setApplicationManifest = useSetRecoilState(applicationManifestState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const navigate = useNavigate();
    const favoriteStarIcon: IIconProps = { iconName: "FavoriteStar" };
    const favoriteStarFilledIcon: IIconProps = { iconName: "FavoriteStarFill" };
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if (logoImageUrl != null) {
            return;
        }
        else if (props.appCatalogTile.LogoFileName != null && props.appCatalogTile.LogoFileName !== "") {
            axios({
                method: "get",
                url: `/Logo/${props.appCatalogTile.Id}/${props.appCatalogTile.LogoFileName}`,
                cancelToken: source.token,
            }).then((result) => {
                const mimeType = getMimeTypeByFileName(props.appCatalogTile.LogoFileName);
                const arrayBuffer = base64ToArrayBuffer(result.data);
                var byteArray = new Uint8Array(arrayBuffer);
                var blob = new Blob([byteArray], { type: mimeType });
                var imageUrl = URL.createObjectURL(blob);
                setLogoImageUrl(imageUrl);
            }).catch((error) => {
                if (error.response == null) {
                    console.log('Request canceled', "App Card Component cleaned up");
                    setLogoImageUrl(ReAccessIconLogo);
                } else {
                    // handle error
                    setLogoImageUrl(ReAccessIconLogo);
                }
            });
        } else {
            setLogoImageUrl(ReAccessIconLogo);
        }
        return function cleanup() {
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appCatalogTile]);

    const cardClick = () => {
        if (props.appCatalogTile.ApplicationStatus !== "Online") {
            return;
        }
        setApplicationManifest(null);
        setShimmerNav(false);
        navigate(`/Application/${props.appCatalogTile.Id}/Home`, { replace: false });
    }

    const isFavoriteClick = () => {
        var customers = cloneDeep(appCatalogCustomers);
        var customer = customers.find(i => i.Id === props.appCatalogTile.CustomerId)!;
        var tile = customer?.AppCatalogTiles.find(i => i.Id === props.appCatalogTile.Id)!;
        tile.IsFavorite = !tile.IsFavorite;
        setAppCatalogCustomers(customers);

        var updateCustomerSummaryDto = {
            ApplicationId: props.appCatalogTile.Id,
            IsFavorite: tile.IsFavorite
        }
        axios.put("/FavoriteManifest", updateCustomerSummaryDto, { cancelToken: source.token })
            .then((result) => { })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "Favorite Request canceled");
                } else {
                    var customers = cloneDeep(appCatalogCustomers);
                    var customer = customers.find(i => i.Id === props.appCatalogTile.CustomerId)!;
                    var tile = customer?.AppCatalogTiles.find(i => i.Id === props.appCatalogTile.Id)!;
                    tile.IsFavorite = !tile.IsFavorite;
                    setAppCatalogCustomers(customers);
                }
            });;
    }

    const cardStyles: IDocumentCardStyles = {
        root: { display: 'inline-block', marginRight: 20, marginBottom: 20, width: 320 },
    };

    const cardTitleStyles: IDocumentCardTitleStyles = {
        root: { display: 'flex', justifyContent: "center", paddingTop: "10px" },
    };

    const themedSmallStackTokens: IStackTokens = {
        childrenGap: "s",
        padding: "s",
    };

    const iconClass = mergeStyles({
        margin: "10px",
    });

    const classNames = mergeStyleSets({
        good: [{ color: "#6bb700" }, iconClass],
        warning: [{ color: SharedColors.orange10 }, iconClass],
        error: [{ color: SharedColors.red10 }, iconClass],
    });

    const getClassName = (): string => {
        if (props.appCatalogTile.ApplicationStatus === "Online") {
            return classNames.good;
        } else if (props.appCatalogTile.ApplicationStatus === "Publishing") {
            return classNames.warning;
        } else {
            return classNames.error;
        }
    }

    const getStatusText = (): string => {
        if (props.appCatalogTile.ApplicationStatus === "Online") {
            return "Online";
        } else if (props.appCatalogTile.ApplicationStatus === "Publishing") {
            return "Currently Publishing";
        } else if (props.appCatalogTile.ApplicationStatus === "Offline") {
            return "Offline";
        } else if (props.appCatalogTile.ApplicationStatus === "Purging") {
            return "App is being Deleted";
        } else {
            return "";
        }
    }

    return (
        <DocumentCard
            aria-label={`Application Tile for ${props.appCatalogTile.Name}`}
            styles={cardStyles}
        >
            <div onClick={() => cardClick()}>
                <DocumentCardImage height={150} imageFit={ImageFit.centerContain} imageSrc={logoImageUrl} />
                <DocumentCardDetails>
                    <DocumentCardTitle title={props.appCatalogTile.Name} shouldTruncate={true} styles={cardTitleStyles} />
                </DocumentCardDetails>
                <Stack.Item style={{ display: "flex", justifyContent: "center", paddingTop: "15px", paddingBottom: "20px" }}>
                    <Text variant="small" nowrap>
                        {props.appCatalogTile != null ? props.appCatalogTile.Description : ""}
                    </Text>
                </Stack.Item>
            </div>
            <Stack horizontal={true} horizontalAlign="space-between" verticalAlign="center" tokens={themedSmallStackTokens}>
                <Stack.Item>
                    <FontIcon aria-label="StatusCircleInner" iconName="StatusCircleInner" className={getClassName()} />
                    <Text>{getStatusText()}</Text>
                </Stack.Item>
                <Stack.Item style={{ paddingRight: "5px " }}>
                    <ActionButton iconProps={props.appCatalogTile.IsFavorite ? favoriteStarFilledIcon : favoriteStarIcon} onClick={isFavoriteClick} />
                </Stack.Item>
            </Stack>
        </DocumentCard>
    );
}