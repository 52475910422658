import { Pivot, PivotItem } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { Tab } from "../../../model/manifest/Tab";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { applicationManifestState } from "../../../recoil/atoms";
import { FormPage } from "./FormPage";
import styles from "./FormTab.module.css";
import { ISubFormField } from "../../../interfaces/ISubFormField";

export interface SubFormTabProps {
    formId: string;
    applicationEntity: ApplicationEntity | undefined;
    disableControls: boolean;
    isDetailsView: boolean;
    editMode: boolean;
    shimmerControlsOnSubmit: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField;
}

export const SubFormTab: React.FC<SubFormTabProps> = (props: SubFormTabProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [tabsForDisplay, setTabsForDisplay] = useState<Tab[]>([]);

    useEffect(() => {
        var subForm = applicationManifest.SubForms.find(i => i.Id === props.formId);
        var tabs = [...subForm!.Tabs].sort((a, b) => a.SortOrder - b.SortOrder);
        setTabsForDisplay(tabs);
    }, [applicationManifest.SubForms, props.formId]);

    return (
        <>
            {tabsForDisplay.length > 0 &&
                <Pivot
                    aria-label="Tab region for form"
                    className={styles.tabRegion}
                    linkSize="large"
                    overflowBehavior="menu">
                    {tabsForDisplay.map((value, index) => {
                        return (
                            <PivotItem
                                key={value.Name + index}
                                headerText={value.Name}
                                alwaysRender={true}>
                                <FormPage
                                    formId={props.formId}
                                    tabId={value.Id!}
                                    applicationEntity={props.applicationEntity}
                                    disableControls={props.disableControls}
                                    isDetailsView={props.isDetailsView}
                                    editMode={props.editMode}
                                    shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                    entityId={props.entityId}
                                    subFormField={props.subFormField}>
                                </FormPage>
                            </PivotItem>
                        )
                    })}
                </Pivot>
            }
        </>
    );
}