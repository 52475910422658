import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { GuidField } from "./fields/GuidField";
import { IncrementalField } from "./fields/IncrementalField";
import { RandomField } from "./fields/RandomField";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface AutoGeneratedControlProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    isSearch: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const AutoGeneratedControl: React.FC<AutoGeneratedControlProps> = (props: AutoGeneratedControlProps) => {
    const isDisabled = (): boolean => {
        if (props.isSearch) return props.disabled;

        if (props.entityId == null) return true;

        if (props.subFormField != null) {
            if (props.subFormField.isNew) return true;
        }
        return props.disabled;
    }

    return (
        <>
            {props.control.FieldType === 29 &&
                <IncrementalField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={isDisabled()}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </IncrementalField>
            }
            {props.control.FieldType === 30 &&
                <GuidField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={isDisabled()}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </GuidField>
            }
            {props.control.FieldType === 31 &&
                <RandomField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={isDisabled()}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </RandomField>
            }
        </>
    );
}