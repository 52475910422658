import React from "react";
import { Shimmer, TextField, useTheme } from "@fluentui/react";
import { getDefaultFieldShimmer } from "../../../services/shimmerService";

export interface ImportDescriptionTextProps {
    descriptionValue: string;
    isLoading: boolean;
    onChangeDescriptionValue: (value: string) => void;
}

export const ImportDescriptionText: React.FC<ImportDescriptionTextProps> = (props: ImportDescriptionTextProps) => {
    const theme = useTheme();

    const onChangeDescriptionValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (!newValue || newValue.length <= 180) {
                props.onChangeDescriptionValue(newValue || "");
            }
        },
        [props],
    );


    return (
        <Shimmer
            customElementsGroup={getDefaultFieldShimmer(theme)}
            width={206}
            isDataLoaded={!props.isLoading}
            shimmerColors={{
                shimmer: theme.palette.themeTertiary,
                shimmerWave: theme.palette.themeSecondary,
                background: theme.semanticColors.bodyBackground,
            }}>
            <TextField
                label="Description"
                value={props.descriptionValue}
                onChange={onChangeDescriptionValue}
            />
        </Shimmer>
    );
}