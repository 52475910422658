import React, { useState } from "react";
import { DefaultButton, IBasePickerSuggestionsProps, IStackTokens, ITag, Label, Panel, PanelType, PrimaryButton, Stack, TagPicker, TextField } from "@fluentui/react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useId } from "@fluentui/react-hooks";
import { Group } from "../../../model/manifest/Group";
import axios, { AxiosRequestConfig } from "axios";
import { GroupInsertDto } from "../../../dtos/ApplicationSettings/GroupInsertDto";
import { ValidationError } from "../../../model/error/ValidationError";
import { dialogModalWrapperPropsState, groupSettingsState } from "../../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { useRecoilState } from "recoil";

export interface NewGroupPanelProps {
    appId: string;
    dismissPanel: () => void;
}

export const NewGroupPanel: React.FC<NewGroupPanelProps> = (props: NewGroupPanelProps) => {
    const { handleSubmit, control, formState: { errors, isDirty, isValid }, getValues, setError } = useForm({ defaultValues: defaultValues, mode: "onChange" });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [groups, setGroupsState]: [Group[], (groups: Group[]) => void] = useRecoilState(groupSettingsState);
    const tagPickerId = useId("tagPicker");

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack horizontal={true} horizontalAlign={"end"}>
                <PrimaryButton
                    styles={buttonStyles}
                    onClick={submitForm}
                    disabled={!isValid || !isDirty || isSubmitting}>
                    Create
                </PrimaryButton>
                <DefaultButton
                    onClick={props.dismissPanel}
                    disabled={isSubmitting}>
                    Cancel
                </DefaultButton>
            </Stack>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDirty, isSubmitting, isValid, props.dismissPanel],
    );

    const submitForm = async () => {
        setIsSubmitting(true);
        var data = getValues();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        var groupInsertDto: GroupInsertDto = {
            ApplicationId: props.appId,
            Description: data.Description,
            Inactive: false,
            Name: data.Name,
            Tags: data.Tags,
            Type: "Native"
        }
        const options: AxiosRequestConfig = {
            method: "POST",
            data: groupInsertDto,
            url: "/Group/Post",
            params: {
                applicationId: props.appId
            },
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                var group: Group = result.data;
                group.GroupUsers = [];
                var groupArray = [...groups];
                groupArray.push(group);
                setGroupsState(groupArray);
                setIsSubmitting(false);
                props.dismissPanel();
            }).catch((error) => {
                setIsSubmitting(false);
                if (error.response == null) {
                    console.log("Request canceled", "New Group Panel Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        //set errors manually
                        validationErrors.forEach((validationError: ValidationError) => {
                            setError(validationError.GroupIdentifier as any, {
                                type: "manual",
                                message: validationError.Message
                            })
                        });
                    } else {
                        var dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
    }

    const getTextFromItem = (item: ITag) => item.name;

    const pickerSuggestionsProps = (): IBasePickerSuggestionsProps => {
        var basePickerSuggestions: IBasePickerSuggestionsProps = {
            suggestionsHeaderText: "Suggested items"
        };
        return basePickerSuggestions;
    };

    const onResolveSuggestions = (filterText: string, tagList: ITag[]) => {
        var suggestedTags: ITag[] = []
        if (filterText === "") {
            var sortedList = suggestedTags.sort(function (a, b) {
                var textA = a.name;
                var textB = b.name;
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            // D
            return sortedList.map((i) => {
                return { ...i };
            });
        } else {
            const existingMatches = filterText
                ? suggestedTags
                    .filter(
                        (tag) =>
                            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
                    )
                    .filter((tag) => !listContainsTag(tag, tagList))
                : [];

            return existingMatches.some((a) => a.name === filterText)
                ? existingMatches
                : [
                    { key: filterText, name: filterText, isNewItem: true } as ITag,
                ].concat(existingMatches);
        }
    };

    const listContainsTag = (tag: ITag, tagList?: ITag[]) => {
        if (
            !tagList ||
            !tagList.length ||
            tagList.length === 0
        ) {
            return false;
        }
        return (
            tagList.filter((compareTag) => compareTag.key === tag.key).length > 0
        );
    };

    return (
        <Panel
            headerText="Create new group"
            isOpen={true}
            onDismiss={props.dismissPanel}
            type={PanelType.custom}
            customWidth="500px"
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <form onSubmit={handleSubmit(submitForm)}>
                <Stack horizontal={false} tokens={themedSmallStackTokens} style={{ "paddingTop": "25px" }}>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="Name"
                            rules={{
                                required: "Name is required.",
                                maxLength: { value: 100, message: "The max length of Name is 50 characters." },
                                validate: {
                                    unique: (value: string): string | undefined => {
                                        if (value == null || value.length === 0) {
                                            return undefined;
                                        } else {
                                            var foundName = groups.findIndex(i => i.Name === value);
                                            if (foundName >= 0) {
                                                return "The group name must be unique.";
                                            }
                                        }
                                        return undefined;
                                    },
                                },
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextField
                                    label="Name"
                                    required={true}
                                    placeholder="Group name"
                                    value={value}
                                    errorMessage={errors != null && errors["Name"]?.message != null ? errors["Name"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        onChange(newValue);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="Description"
                            rules={{
                                required: "Description is required.",
                                maxLength: { value: 2000, message: "The max length of Description is 2000 characters." }
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextField
                                    label="Description"
                                    required={true}
                                    multiline={true}
                                    rows={5}
                                    placeholder="Provides an overview for what the group represents"
                                    value={value}
                                    errorMessage={errors != null && errors["Description"]?.message != null ? errors["Description"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        onChange(newValue);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="Tags"
                            rules={{
                                validate: {
                                    maxTagLength: (value: string[]): string | undefined => {
                                        if (value == null || value.length === 0) {
                                            return "Tags are required.";
                                        } else {
                                            var invalidTag = value.find((t) => t.length > 64);
                                            if (invalidTag == null) {
                                                return undefined;
                                            }
                                            return `Max length of ${invalidTag} Tag is 64 characters`;
                                        }
                                    },
                                },
                            }}
                            render={({ field: { onChange, onBlur, value } }) => {
                                var selectedTags = value
                                    ? value.map((i: string) => {
                                        return { key: i, name: i };
                                    })
                                    : [];

                                return (
                                    <>
                                        <Label htmlFor={tagPickerId} className="CustomErrorLabel">Tags</Label>
                                        <div id={tagPickerId}>
                                            <TagPicker
                                                className={errors != null && errors["Tags"] != null ? "PickerFieldErrorBorder" : undefined}
                                                onResolveSuggestions={(filter: string, selectedItems?: ITag[] | undefined) => {
                                                    return onResolveSuggestions(filter, selectedItems !== undefined ? selectedItems : []);
                                                }}
                                                getTextFromItem={getTextFromItem}
                                                pickerSuggestionsProps={pickerSuggestionsProps()}
                                                //inputProps={inputProps}
                                                selectedItems={selectedTags}
                                                onChange={(items?: ITag[] | undefined) => {
                                                    if (items != null && items.length > 0) {
                                                        var itemArray = items.map((i: ITag) => {
                                                            return i.key;
                                                        })
                                                        onChange(itemArray);
                                                    } else {
                                                        onChange([]);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="CustomError">
                                            <ErrorMessage errors={errors} name={"tags"} />
                                        </div>
                                    </>
                                );
                            }}
                        />
                    </Stack.Item>
                </Stack>
            </form>
        </Panel>
    );
}

const defaultValues = {
    Name: "",
    Description: "",
    Tags: []
}

const buttonStyles = { root: { marginRight: 8 } };

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};