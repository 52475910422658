import { DetailsList, DetailsListLayoutMode, IColumn, Icon, mergeStyleSets, PrimaryButton, ProgressIndicator, SelectionMode, useTheme } from "@fluentui/react";
import React, { useEffect } from "react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { AttachmentModel } from "../../../../../model/reaccessData/AttachmentModel";
import moment from 'moment';
import { DocumentFileLink } from "../../DocumentFileLink";
import { DocumentUpload } from "../../../DocumentUpload";
import { useParams } from "react-router-dom";
import './DocumentListField.css';
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { removeButtonStyles } from "../../../../../services/styleService";
import axios from "axios";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";

export interface DocumentListFieldProps {
    control: ControlData;
    subFormField: ISubFormField | undefined;
    attachments: AttachmentModel[];
    hasError: boolean;
    removeAttachment: (fileId: string) => void;
    onSuccessfulUpload: (result: any, fileId: string, file: File) => void;
    onFailedUpload: (error: any, fileId: string) => void;
    startingHttpRequest: (fileId: string) => void;
}

export const DocumentListField: React.FC<DocumentListFieldProps> = (props: DocumentListFieldProps) => {
    const theme = useTheme();
    var { appId } = useParams();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        return function cleanup() {
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
    });

    const columns: IColumn[] = [
        {
            key: 'FileTypeDocumentListField',
            name: 'File Type',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'fileType',
            minWidth: 16,
            maxWidth: 16,
            //onColumnClick: this._onColumnClick,
            onRender: (item: AttachmentModel) => {
                return <>
                    {(item.FileSize != null && item.FileSize !== 0) &&
                        <Icon {...getFileTypeIconProps({ extension: item.FileExtension, size: 16, imageFileType: "svg" })} />
                    }
                </>
            }
        },
        {
            key: 'NameDocumentListField',
            name: 'Name',
            fieldName: 'name',
            minWidth: 75,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            // isSorted: true,
            // isSortedDescending: false,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            //onColumnClick: this._onColumnClick,
            data: 'string',
            onRender: (item: AttachmentModel) => {
                return <>
                    {(item.FileSize != null && item.FileSize !== 0) ?
                        (
                            <DocumentFileLink 
                                pageId={props.subFormField == null ? props.control.PageId : props.subFormField.ParentFormId}
                                subFormId={props.subFormField == null ? undefined : props.control.PageId}
                                attachmentModel={item}></DocumentFileLink>
                        ) : (
                            <ProgressIndicator description="" />
                        )
                    }
                </>
            },
            isPadded: true,
        },
        {
            key: 'DateModifiedTypeDocumentListField',
            name: 'Date Modified',
            fieldName: 'dateModifiedValue',
            minWidth: 75,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: false,
            //onColumnClick: this._onColumnClick,
            data: 'string',
            onRender: (item: AttachmentModel) => {
                return <span>
                    {item.UpdatedUtc != null &&
                        moment(item.UpdatedUtc).format("M/D/YYYY")
                    }
                </span>;
            },
            isPadded: true,
        },
        {
            key: 'ModifiedByDocumentListField',
            name: 'Modified By',
            fieldName: 'modifiedBy',
            minWidth: 75,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: false,
            data: 'string',
            //onColumnClick: this._onColumnClick,
            onRender: (item: AttachmentModel) => {
                return <span>
                    {item.UpdatedByName != null &&
                        item.UpdatedByName
                    }
                </span>;
            },
            isPadded: true,
        },
        {
            key: 'FileSizeDocumentListField',
            name: 'File Size',
            fieldName: 'fileSizeRaw',
            minWidth: 75,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
            //onColumnClick: this._onColumnClick,
            onRender: (item: AttachmentModel) => {
                return <span>
                    {(item.FileSize != null && item.FileSize > 0) &&
                        item.FileSize
                    }
                </span>;
            },
        },
        {
            key: 'EditDocumentListField',
            name: '',
            fieldName: 'edit',
            minWidth: 75,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: false,
            data: 'button',
            //onColumnClick: this._onColumnClick,
            onRender: (item: AttachmentModel) => {
                return <>
                    <span>
                        {(item.FileId != null && item.FileId !== "") &&
                            <DocumentUpload
                                applicationId={appId!}
                                pageId={props.subFormField == null ? props.control.PageId : props.subFormField.ParentFormId}
                                subFormId={props.subFormField == null ? undefined : props.control.PageId}
                                controlId={props.control.Id!}
                                description={undefined}
                                importData={undefined}
                                allowMultipleDocuments={false}
                                buttonLabel="Replace"
                                onSuccessfulUpload={(result: any, file: File) => {
                                    props.onSuccessfulUpload(result, item.FileId, file);
                                }}
                                onFailedUpload={(error: any) => {
                                    props.onFailedUpload(error, item.FileId);
                                }}
                                startingHttpRequest={(fileName: string) => {
                                    props.startingHttpRequest(item.FileId);
                                }}
                                source={source}>
                            </DocumentUpload>
                        }
                    </span>
                </>
            },
        },
        {
            key: 'DeleteDocumentListField',
            name: '',
            fieldName: 'delete',
            minWidth: 75,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: false,
            data: 'button',
            //onColumnClick: this._onColumnClick,
            onRender: (item: AttachmentModel) => {
                return <>
                    <span>
                        {(item.FileId != null && item.FileId !== "") &&
                            <PrimaryButton
                                styles={removeButtonStyles(theme)}
                                onClick={() => {
                                    props.removeAttachment(item.FileId);
                                }}>
                                Remove
                            </PrimaryButton>
                        }
                    </span>
                </>
            },
        }
    ];

    const getKey = (item: any, index?: number): string => {
        return item.key;
    }

    return (
        <>
            <DetailsList
                className={props.hasError ? "customListFieldErrorBorder" : ""}
                items={props.attachments}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={getKey}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
            />
        </>
    )
}