import React, { useEffect, useState } from "react";
import { Stack, Panel, PanelType, ActionButton, IIconProps, MessageBarType } from "@fluentui/react";
import axios, { AxiosRequestConfig } from 'axios';
import { FormTab } from "./FormTab";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { useRecoilValue } from "recoil";
import { applicationManifestState, decrementPanelCountState, halfScreenModeState, incrementPanelCountState, messageBarQueueState, resetEditState, searchResultState } from "../../../recoil/atoms";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { CustomFormFooter } from "./CustomFormFooter";
import { FormActions } from "./FormActions";
import { buildApplicationEntityForSave, buildDefaultApplicationEntity, buildDefaultValues, buildValues } from "../../../services/applicationEntityService";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { ValidationError } from "../../../model/error/ValidationError";
import { useRecoilState } from "recoil";
import { IEntityListResult } from "../../../interfaces/EntityListResult";
import { DialogModalWrapper } from "../DialogModalWrapper";
import getScreenSize from "../../../services/getScreenSize";
import "./PanelFormContainer.css";
import { FormName } from "./FormName";
import { useSetRecoilState } from "recoil";
import { IMessageBarQueue } from "../../../interfaces/IMessageBarQueue";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";

/**
 * Properties needed to build a form inside a panel.
 * If addEntity and editEntity callbacks are not provided the panel will be in readonly mode.
 */
export interface PanelFormContainerProps {
    formId: string;
    entityId: string | undefined;
    /** Callback function when a new entity has been created. */
    addEntity?: (applicationEntity: ApplicationEntity) => void;
    /** Callback function when an entity has been edited. */
    editEntity?: (applicationEntity: ApplicationEntity) => void;
    hideModal: () => void;
}

export const PanelFormContainer: React.FC<PanelFormContainerProps> = (props: PanelFormContainerProps) => {
    const [searchResult, setSearchResultState]: [IEntityListResult, (searchResult: IEntityListResult | undefined) => void] = useRecoilState(searchResultState);
    const [halfScreenMode, setHalfScreenModeState]: [boolean, (halfScreenMode: boolean) => void] = useRecoilState(halfScreenModeState);
    const [messages, setMessages]: [IMessageBarQueue[], (messages: IMessageBarQueue[]) => void] = useRecoilState(messageBarQueueState);
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const setIncrementPanelCount = useSetRecoilState(incrementPanelCountState);
    const setDecrementPanelCount = useSetRecoilState(decrementPanelCountState);
    const setResetEdit = useSetRecoilState(resetEditState);
    const [shimmerControlsOnSubmit, setShimmerControlsOnSubmit] = useState<boolean>(true);
    const [dialogModalWrapper, setDialogModalWrapper] = useState<IDialogModalWrapperProps>();
    const [applicationEntity, setApplicationEntity] = useState<ApplicationEntity | undefined>(undefined);
    const [saveFormState, setSaveFormState] = useState<boolean>();
    const [editMode, setEditMode] = useState<boolean>(props.entityId == null);
    var { appId } = useParams();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const screenSize: number = getScreenSize();
    const expandIcon: IIconProps = { iconName: "MiniExpandMirrored" };
    const splitIcon: IIconProps = { iconName: "Split" };

    const methods = useForm({
        mode: "onChange",
        defaultValues: props.addEntity != null ? buildDefaultValues(props.formId!, applicationManifest) : buildValues(props.formId!, applicationManifest, []),
    });

    useEffect(() => {
        setHalfScreenModeState(true);
        setIncrementPanelCount(true);
        return () => {
            setDecrementPanelCount(true);
        };
    }, [setDecrementPanelCount, setHalfScreenModeState, setIncrementPanelCount]);

    useEffect(() => {
        if (applicationEntity == null) {
          return;
        }
        var myFormValues = buildValues(props.formId, applicationManifest, applicationEntity.Fields);
        methods.reset(myFormValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [applicationEntity]);

    useEffect(() => {
        setShimmerControlsOnSubmit(false);
        var page = applicationManifest.Pages.find(i => i.Id === props.formId);
        if (page != null) {
            if (props.entityId) {
                const options: AxiosRequestConfig = {
                    method: "GET",
                    url: "/ApplicationEntityGet",
                    params: {
                        applicationId: appId,
                        formId: props.formId,
                        entityId: props.entityId
                    },
                    cancelToken: source.token
                };
                axios(options)
                    .then((result) => {
                        //This is setting App Entity locally compared to the Form Container since this is a view of another form
                        var applicationEntityResult: ApplicationEntity = result.data;
                        if (applicationEntityResult !== null) {
                            setApplicationEntity(applicationEntityResult);
                        }
                        setShimmerControlsOnSubmit(true);
                    }).catch((error) => {
                        if (error.response == null) {

                        }
                    });
            } else {
                var applicationEntity: ApplicationEntity = buildDefaultApplicationEntity(page!, "", applicationManifest.SubForms);
                setApplicationEntity(applicationEntity);
                setShimmerControlsOnSubmit(true);
            }
        }
        return function cleanup() {
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (saveFormState == null) {
            return;
        }
        var data = methods.getValues();
        var ae = buildApplicationEntityForSave(data, props.formId, applicationManifest, applicationEntity!);
        setShimmerControlsOnSubmit(false);

        const options: AxiosRequestConfig = {
            method: ae.EntityId == null ? "POST" : "PUT",
            data: ae,
            url: ae.EntityId == null ? "/ApplicationEntityPost" : "/ApplicationEntityPut",
            params: {
                applicationId: appId
            }
        };

        axios(options)
            .then((result: any) => {
                if (searchResult !== undefined) {
                    var sd: IEntityListResult = cloneDeep(searchResult);
                    sd.possibleChangesToResultSet = true;
                    setSearchResultState(sd);
                }
                var returnedApplicationEntity: ApplicationEntity = result.data;
                onEditModeChange(false);
                setApplicationEntity(returnedApplicationEntity);
                if (ae.EntityId == null && props.addEntity) {
                    var currentMessages = [...messages];
                    var messageId = uuidv4();
                    var message: IMessageBarQueue = {
                        id: messageId,
                        custumActionMessage: undefined,
                        messageBarType: MessageBarType.warning,
                        message: <>You have added a list value to the below record, but must still save for the change to take effect.</>,
                        actions: undefined,
                        expireMilliseconds: 12000,
                        expireStarted: false
                    }
                    currentMessages.push(message);
                    setMessages(currentMessages);

                    props.addEntity(returnedApplicationEntity);
                } else if (ae.EntityId != null && props.editEntity) {
                    var form = applicationManifest.Pages.find(i => i.Id === props.formId)!;
                    if (form.Controls.findIndex(i => i.IsGridChild) >= 0) {
                        setResetEdit(true);
                    } else {
                        props.editEntity(returnedApplicationEntity);
                    }
                }
                setShimmerControlsOnSubmit(true);
                setSaveFormState(undefined);
                props.hideModal();
            }).catch((error) => {
                setSaveFormState(undefined);
                setShimmerControlsOnSubmit(true);
                handleError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveFormState]);

    const handleError = (error: any): void => {
        if (error.response == null) {
            console.log("Request canceled", "Form Container Component cleaned up");
        } else {
            if (error.response.status === 401 || error.response.status === 409) {
                return;
            }
            var dialogProps: IDialogModalWrapperProps | undefined;
            if (error.response.status === 404) {
                //the entity was not found
                dialogProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: error.response.data,
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapper(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            } else if (error.response.status === 422) {
                if (error.response.data.error != null) {
                    //right now only one error this route
                    var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                    if (validationErrors.length === 1) {
                        dialogProps = {
                            isVisible: true,
                            title: validationErrors[0].Title,
                            subText: validationErrors[0].Message,
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapper(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                    } else {
                        //set errors on controls
                        validationErrors.forEach((validationError: ValidationError) => {
                            methods.setError(validationError.GroupIdentifier, {
                                type: "manual",
                                message: validationError.Message
                            })
                        });
                    }
                }
            } else {
                dialogProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapper(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            }
            setDialogModalWrapper(dialogProps);
        }
    }

    const onEditModeChange = (isEditMode: boolean): void => {
        setEditMode(isEditMode);
    }

    const customPanelWidth = (): string => {
        var subtractAmount = screenSize < 600 ? 0 : 50;
        if (halfScreenMode) {
            var halfScreen = (screenSize / 2) - subtractAmount;
            return halfScreen.toString() + "px";
        }

        var largeScreenSize = (screenSize * .75) - subtractAmount;
        return largeScreenSize.toString() + "px";
    }

    const onRenderHeaderContent = React.useCallback(
        () => (
            <>
                <ActionButton
                    iconProps={halfScreenMode ? expandIcon : splitIcon}
                    allowDisabledFocus
                    onClick={() => {
                        if (halfScreenMode) {
                            setHalfScreenModeState(false);
                        } else {
                            setHalfScreenModeState(true);
                        }
                    }}>
                    {halfScreenMode ? "Expand" : "Show Parent"}
                </ActionButton>
                <FormName
                    formId={props.formId}
                    entityId={props.entityId}
                    variantSize="xLarge"
                    applicationEntity={applicationEntity}>
                </FormName>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [applicationEntity, halfScreenMode, props.entityId, props.formId],
    );

    return (
        <Panel
            isOpen={true}
            onDismiss={props.hideModal}
            onDismissed={() => {
                setHalfScreenModeState(false);
            }}
            style={{ marginTop: "48px" }}
            styles={{
                commands: {
                    "@media (min-height: 480px)": {
                        position: "unset"
                    }
                }
            }}
            type={PanelType.custom}
            customWidth={customPanelWidth()}
            closeButtonAriaLabel="Close"
            onRenderHeader={onRenderHeaderContent}
        >
            <FormProvider {...methods} >
                <form>
                    <Stack>
                        <Stack.Item>
                            <FormTab
                                formId={props.formId}
                                applicationEntity={applicationEntity}
                                disableControls={false}
                                isDetailsView={false}
                                editMode={editMode}
                                shimmerControlsOnSubmit={shimmerControlsOnSubmit}
                                entityId={props.entityId}>
                            </FormTab>
                        </Stack.Item>
                        <CustomFormFooter></CustomFormFooter>
                        {(props.addEntity || props.editEntity) &&
                            <Stack.Item>
                                <FormActions
                                    applicationEntity={applicationEntity}
                                    formId={props.formId}
                                    isAddingEntity={props.entityId == null}
                                    editMode={editMode}
                                    onEditModeChange={onEditModeChange}
                                    displaySaveAddNew={false}
                                    displayBackToSearchButtonIfAvailable={false}
                                    shimmerControlsOnSubmit={shimmerControlsOnSubmit}
                                    setShimmerControlsOnSubmit={setShimmerControlsOnSubmit}
                                    hideModal={props.hideModal}
                                    onSaveClicked={() => {
                                        setSaveFormState(true);
                                    }}>
                                </FormActions>
                            </Stack.Item>
                        }
                    </Stack>
                </form>
            </FormProvider>
            {dialogModalWrapper != null &&
                <DialogModalWrapper dialogModalWrapperProps={dialogModalWrapper}></DialogModalWrapper>
            }
        </Panel>
    );
}
