import React from "react";
import { TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { ApplicationEntity } from "../../../../../model/reaccessData/ApplicationEntity";
import { useId } from "@fluentui/react-hooks";

export interface InlineFrameFieldProps {
    control: ControlData;
    applicationEntity: ApplicationEntity;
}

export const InlineFrameField: React.FC<InlineFrameFieldProps> = (props: InlineFrameFieldProps) => {
    const textBoxId = useId("iFrame");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textBoxId}`
    };

    const getHeight = (): string => {
        var cssUnit = props.control.InlineFrame.IsPixels ? "px" : "%";
        return props.control.InlineFrame.Height + cssUnit;
    }

    const getUrlSeperator = (completeSource: string): string => {
        if (completeSource.indexOf("?") >= 0) {
          return "&";
        } else {
          return "?";
        }
      }

    const getCompleteIframeSource = (): string => {
        var completeSource: string = props.control.InlineFrame.Source;
        if (props.control.InlineFrame.SendEntityId && props.applicationEntity.EntityId != null && props.applicationEntity.EntityId !== "") {
            completeSource += `${getUrlSeperator(completeSource)}EntityId=${props.applicationEntity.EntityId}`;
        }
        props.control.InlineFrame.ControlParameters.forEach((parameter, index) => {
            completeSource += `${getUrlSeperator(completeSource)}${parameter.Key}=`;
            if (parameter.UseControl) {
                var field = props.applicationEntity.Fields.find(i => i.Id === parameter.ControlId);
                completeSource += field != null ? field.TextValue : "";
            } else {
                completeSource += parameter.TextValue;
            }
        });
        return completeSource;
    }

    return (
        <>
            <TooltipHost
                content={props.control.ToolTipText}
                id={tooltipId}
                calloutProps={calloutProps}
            >
                <iframe
                    src={getCompleteIframeSource()}
                    title="A URL that was added inside of ReAccess App builder"
                    width="100%"
                    height={getHeight()}
                    allowFullScreen={props.control.InlineFrame.AllowFullScreen}></iframe>
            </TooltipHost>

        </>
    )
}