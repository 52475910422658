import React from "react";
import { Stack, IStackTokens } from "@fluentui/react";
import { ControlData } from "../../../model/manifest/ControlData";
import { FormRowItem } from "./FormRowItem";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { ISubFormField } from "../../../interfaces/ISubFormField";

export interface FormRowProps {
    controls: ControlData[];
    applicationEntity: ApplicationEntity | undefined;
    shouldValidate: boolean;
    disableControls: boolean;
    isDetailsView: boolean;
    editMode: boolean;
    shimmerControlsOnSubmit: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const FormRow: React.FC<FormRowProps> = (props: FormRowProps) => {
    return (
        <Stack horizontal wrap horizontalAlign="space-between" tokens={themedMediumStackTokens}>
            {
                props.controls.map((value, index) => {
                    return (
                        <Stack.Item
                            grow
                            disableShrink
                            key={"FormRowItem" + index}>
                            <FormRowItem
                                control={value}
                                applicationEntity={props.applicationEntity}
                                shouldValidate={props.shouldValidate}
                                isSearch={false}
                                disableControls={props.disableControls}
                                isDetailsView={props.isDetailsView}
                                editMode={props.editMode}
                                shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                entityId={props.entityId}
                                subFormField={props.subFormField}>
                            </FormRowItem>
                        </Stack.Item>
                    )
                })
            }
        </Stack>
    );
}
const themedMediumStackTokens: IStackTokens = {
    childrenGap: 'm',
    padding: 'm',
};