import { IColumn } from "@fluentui/react";
import { IKeyValuePair } from "../interfaces/IKeyValuePair";

export const shapeGridData = (columns: IColumn[], items: IKeyValuePair[]): IKeyValuePair[] => {
    var returnItems: IKeyValuePair[] = [];
    items.forEach(item => {
        var row: any = item;
        Object.entries(item).forEach((pair: any) => {
            //check to see if the key is in the column list of the object before comparing the value of a given pair
            var key = pair[0];
            var newValue: any = "";
            var column = columns.find(i => i.key === key);
            if (column != null) {
                var value = pair[1];
                if (column.data === "date" || column.data === "time" || column.data === "datetime") {
                    if (value != null && value !== "") {
                        var longDate = new Date(value);
                        if (value != null && value !== "" && longDate instanceof Date && !isNaN(longDate.getTime())) {
                            newValue = longDate;
                        } else {
                            newValue = value;
                        }
                    }
                } else if (column.data === "decimal") {
                    var decimalValue = parseFloat(value);
                    if (isNaN(decimalValue)) {
                        newValue = value;
                    } else {
                        newValue = decimalValue;
                    }
                } else if (column.data === "number") {
                    var intValue = parseInt(value);
                    if (isNaN(intValue)) {
                        newValue = value;
                    } else {
                        newValue = intValue;
                    }
                } else {
                    newValue = value;
                }
                row[key] = newValue;
            }
        });
        returnItems.push(row);
    });

    return returnItems;
}