import React, { useEffect, useState } from "react";
import {
    ActionButton,
    AnimationStyles,
    Callout,
    Checkbox,
    DetailsList,
    DetailsListLayoutMode,
    DirectionalHint,
    DocumentCard,
    getTheme,
    IColumn,
    Icon,
    IDocumentCardStyles,
    IIconProps,
    IImageProps,
    Image,
    IScrollablePaneStyles,
    IStackStyles,
    IStackTokens,
    mergeStyles,
    mergeStyleSets,
    MotionDurations,
    PrimaryButton,
    ScrollablePane,
    SelectionMode,
    Stack,
    Text,
    TextField
} from "@fluentui/react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { customerSummariesState, shimmerNavState } from "../../../recoil/atoms";
import axios, { AxiosRequestConfig } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IManifestSummaryDetailGridItem } from "../../../interfaces/manifestSummaries/IManifestSummaryDetailGridItem";
import { ManifestSummaryDetailDto } from "../../../dtos/ManifestSummary/ManifestSummaryDetailDto";
import { DeleteApplicationButton } from "./DeleteApplicationButton";
import ReAccessIconLogo from "../../../images/reaccess-symbol-blue-background.svg";
import { ManifestResourceDto } from "../../../dtos/Manifest/ManifestResourceDto";
import { CustomerSummaryDto } from "../../../dtos/User/CustomerSummaryDto";
import { hasTrialEnded } from "../../../services/customerService";

export const ReAccessApps: React.FC = () => {
    const customerSummaries: CustomerSummaryDto[] = useRecoilValue(customerSummariesState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const [manifestSummaryDetails, setManifestSummaryDetails] = useState<ManifestSummaryDetailDto[]>([]);
    const [manifestSummaryDetailGridItems, setManifestSummaryDetailGridItems] = useState<IManifestSummaryDetailGridItem[]>([]);
    const [calloutTarget, setCalloutTarget] = useState<string>();
    const [unsetTarget, setUnsetTarget] = useState<string>();
    const [inCallout, setInCallout] = useState<boolean>(false);
    const [manifestResource, setManifestResource] = useState<ManifestResourceDto>();
    var { customerId } = useParams();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const navigate = useNavigate();
    const theme = getTheme();
    const viewIcon: IIconProps = { iconName: 'View' };
    const settingsIcon: IIconProps = { iconName: 'Settings' };

    useEffect(() => {
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/ManifestSummary/GetAll",
            params: {
                customerId: customerId
            },
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                var manifestSummaryDetailDtos: ManifestSummaryDetailDto[] = result.data;
                var gridItems: IManifestSummaryDetailGridItem[] = [];
                manifestSummaryDetailDtos.forEach((item) => {
                    var gridItem: IManifestSummaryDetailGridItem = {
                        ApplicationId: item.ApplicationId,
                        CustomerId: item.CustomerId,
                        Description: item.Description,
                        Name: item.Name,
                        FavoriteCount: item.FavoriteCount,
                        UserCount: item.UserCount,
                        Selected: false,
                        Disabled: false
                    };
                    gridItems.push(gridItem);
                });
                setManifestSummaryDetailGridItems(gridItems);
                setManifestSummaryDetails(manifestSummaryDetailDtos);
            })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "Customer Portal Component cleaned up");
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (unsetTarget === calloutTarget && !inCallout) {
            setUnsetTarget(undefined);
            setCalloutTarget(undefined);
            setManifestResource(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unsetTarget, inCallout]);

    useEffect(() => {
        if (calloutTarget === undefined) {
            return;
        }
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/ManifestResource",
            params: {
                applicationId: getApplicationIdFromGridId()
            },
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {
                var manifestResourceDto: ManifestResourceDto = result.data;
                setManifestResource(manifestResourceDto);
            })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "Customer Portal Component cleaned up");
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calloutTarget]);

    const styles = mergeStyleSets({
        callout: {
            width: 350,
            padding: '10px 14px',
            background: theme.semanticColors.bodyBackground,
        },
        actions: {
            marginTop: 20,
        },
    });

    const viewAppClick = (applicationId: string) => {
        setShimmerNav(false);
        navigate(`/Application/${applicationId}/Home`, { replace: false });
    }

    const columns: IColumn[] = [
        {
            key: "Selected",
            name: "",
            fieldName: "selected",
            minWidth: 25,
            maxWidth: 25,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "boolean",
            onRender: (item: IManifestSummaryDetailGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Checkbox
                            checked={item.Selected}
                            disabled={item.Disabled}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                var details = [...manifestSummaryDetailGridItems];
                                var rowItem = details.find(i => i.ApplicationId === item.ApplicationId)!;
                                rowItem.Selected = isChecked ? true : false;
                                setManifestSummaryDetailGridItems(details);
                            }} />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 225,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IManifestSummaryDetailGridItem) => {
                var targetId = `IManifestSummaryDetailGridItem-${item.ApplicationId}`;
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <ActionButton
                            onClick={() => viewAppClick(item.ApplicationId)}
                            onMouseEnter={() => {
                                setCalloutTarget(undefined);
                                setManifestResource(undefined);
                                setTimeout(() => {
                                    setCalloutTarget(targetId);
                                }, 500);
                            }}
                            onMouseLeave={() => {
                                setTimeout(() => {
                                    setUnsetTarget(targetId);
                                }, 750);
                            }}
                            id={targetId}>
                            <Text variant={"mediumPlus"}>
                                {item.Name}
                            </Text>
                        </ActionButton>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Description",
            name: "Description",
            fieldName: "description",
            minWidth: 300,
            maxWidth: 400,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IManifestSummaryDetailGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {item.Description}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "UserCount",
            name: "User Count",
            fieldName: "userCount",
            minWidth: 125,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "number",
            onRender: (item: IManifestSummaryDetailGridItem) => {
                return <Stack horizontal={true} styles={columnStackStyles}>
                    <Stack.Item align="center" style={{ paddingTop: "5px", paddingRight: "5px" }}>
                        <Icon iconName="people" />
                    </Stack.Item>
                    <Stack.Item align="center" style={{ paddingRight: "15px" }}>
                        <Text variant={"mediumPlus"}>
                            {item.UserCount}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        }
    ];

    const manageApplicationClick = (applicationId: string) => {
        setShimmerNav(false);
        navigate(`/Application/${applicationId}/ApplicationSettings`, { replace: false });
    }

    const newApplicationClick = () => {
        navigate("/NewApplication/Templates", { replace: false });
    }

    const anySelected = (): boolean => {
        return manifestSummaryDetailGridItems.findIndex(i => i.Selected) === -1 ? false : true;
    };

    const getApplicationIdFromGridId = (): string => {
        return calloutTarget!.replace("IManifestSummaryDetailGridItem-", "");
    };

    const mySelected = (): ManifestSummaryDetailDto[] => {
        var selectedItems: ManifestSummaryDetailDto[] =
            manifestSummaryDetailGridItems.filter(i => i.Selected)
                .map((i: IManifestSummaryDetailGridItem) => ({
                    ApplicationId: i.ApplicationId,
                    CustomerId: i.CustomerId,
                    Description: i.Description,
                    Name: i.Name,
                    FavoriteCount: i.FavoriteCount,
                    UserCount: i.UserCount,
                }));
        return selectedItems;
    };

    const deleteSingle = (applicationId: string): ManifestSummaryDetailDto[] => {
        var selectedItems: ManifestSummaryDetailDto[] =
            manifestSummaryDetailGridItems.filter(i => i.ApplicationId === applicationId)
                .map((i: IManifestSummaryDetailGridItem) => ({
                    ApplicationId: i.ApplicationId,
                    CustomerId: i.CustomerId,
                    Description: i.Description,
                    Name: i.Name,
                    FavoriteCount: i.FavoriteCount,
                    UserCount: i.UserCount,
                }));
        return selectedItems;
    };

    const slideInAnimation = mergeStyles(AnimationStyles.slideUpIn20, MotionDurations.duration4, {});

    const classNames = mergeStyleSets({
        wrapper: {
            height: "35vh",
            width: "100%",
            position: "relative",
            maxHeight: "inherit",
        },
        pane: {
            maxWidth: "100%"
        }
    });

    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };

    const getCurrentCustomer = (): CustomerSummaryDto => {
        return customerSummaries.find(i => i.CustomerId === customerId)!;
    }

    return (
        <Stack tokens={themedMediumStackTokens}>
            <Stack.Item>
                <Text variant={"xxLarge"}>
                    My ReAccess Apps
                </Text>
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={themedSmallStackTokens}>
                    <DocumentCard styles={documentCardStyles}>
                        <Stack.Item>
                            <Stack horizontal={true} horizontalAlign="space-between" tokens={themedSmallStackTokens} wrap>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <Text variant={"mediumPlus"}>
                                            Total
                                        </Text>
                                        <div className="bolt-pill">
                                            <div className="bolt-pill-content">
                                                {manifestSummaryDetails != null ? manifestSummaryDetails.length : ""}
                                            </div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <Stack.Item>
                                            {anySelected()
                                                ?
                                                <DeleteApplicationButton
                                                    customerId={customerId!}
                                                    manifestSummaryDetailDtos={mySelected()}
                                                    buttonType="DefaultButton"
                                                    deleteSuccessful={() => {
                                                        var items = [...manifestSummaryDetailGridItems];
                                                        items = items.filter(i => !i.Selected);
                                                        setManifestSummaryDetailGridItems(items);
                                                    }} />
                                                :
                                                <PrimaryButton
                                                    text="New Application"
                                                    ariaDescription="Add new application button."
                                                    onClick={newApplicationClick}
                                                    allowDisabledFocus
                                                    disabled={hasTrialEnded(getCurrentCustomer().BillingStatus, getCurrentCustomer().TrialEndDate)} />
                                            }
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack tokens={themedSmallStackTokens}>
                            <Stack.Item>
                                <DetailsList
                                    items={manifestSummaryDetailGridItems != null ? manifestSummaryDetailGridItems : []}
                                    compact={true}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                />
                            </Stack.Item>
                        </Stack>
                    </DocumentCard>
                </Stack>
            </Stack.Item>
            {calloutTarget !== undefined && (
                <Callout
                    role="dialog"
                    className={styles.callout}
                    gapSpace={0}
                    onDismiss={() => (setCalloutTarget(undefined))}
                    target={`#${calloutTarget}`}
                    isBeakVisible={false}
                    directionalHint={DirectionalHint.rightTopEdge}
                    setInitialFocus
                    onMouseEnter={() => {
                        setInCallout(true);
                    }}
                    onMouseLeave={() => {
                        setTimeout(() => {
                            setInCallout(false);
                        }, 750);
                    }}
                >
                    <Stack horizontal={false}>
                        <Stack.Item>
                            <Stack horizontal={true} verticalAlign={"center"} tokens={themedSmallStackTokens}>
                                <Stack.Item>
                                    <Image {...imageProps} />
                                </Stack.Item>
                                <Stack.Item>
                                    <Text block variant="mediumPlus">
                                        {manifestSummaryDetails.find(i => i.ApplicationId === getApplicationIdFromGridId())!.Name}
                                    </Text>
                                </Stack.Item>
                                <Stack horizontal={false}>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack horizontal={true} verticalAlign={"center"} tokens={themedSmallStackTokens}>
                                <Stack.Item>
                                    <ActionButton
                                        iconProps={viewIcon}
                                        onClick={() => viewAppClick(getApplicationIdFromGridId())}>
                                        View
                                    </ActionButton>
                                </Stack.Item>
                                <Stack.Item>
                                    <ActionButton
                                        iconProps={settingsIcon}
                                        onClick={() => manageApplicationClick(getApplicationIdFromGridId())}>
                                        Manage
                                    </ActionButton>
                                </Stack.Item>
                                <Stack.Item>
                                    <DeleteApplicationButton
                                        customerId={customerId!}
                                        manifestSummaryDetailDtos={deleteSingle(getApplicationIdFromGridId())}
                                        buttonType="ActionButton"
                                        deleteSuccessful={() => {
                                            var items = [...manifestSummaryDetailGridItems];
                                            items = items.filter(i => i.ApplicationId !== getApplicationIdFromGridId());
                                            setManifestSummaryDetailGridItems(items);
                                        }} />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        {manifestResource !== undefined &&
                            <Stack.Item className={slideInAnimation}>
                                <div className={classNames.wrapper}>
                                    <ScrollablePane styles={scrollablePaneStyles}>
                                        <Stack horizontal={false} verticalAlign={"center"} tokens={themedSmallStackTokens}>
                                            <Stack.Item>
                                                <Text variant={"xLarge"}>
                                                    Global
                                                </Text>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField
                                                    label="Azure Environment"
                                                    borderless
                                                    value={manifestResource != null ? manifestResource.AzureEnvironment : ""} />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField
                                                    label="Customer Id"
                                                    borderless
                                                    value={manifestResource != null ? manifestResource.CustomerId : ""} />
                                            </Stack.Item>
                                            {manifestResource != null && manifestResource.TenantId != null && manifestResource.SubscriptionId != null &&
                                                <>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Tenant Id"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.TenantId : ""} />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Subscription Id"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.SubscriptionId : ""} />
                                                    </Stack.Item>
                                                </>
                                            }
                                            <Stack.Item>
                                                <Text variant={"xLarge"}>
                                                    Manifest
                                                </Text>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField
                                                    label="Id"
                                                    borderless
                                                    value={manifestResource != null ? manifestResource.ApplicationId : ""} />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField
                                                    label="Environment"
                                                    borderless
                                                    value={manifestResource != null ? manifestResource.ManifestEnvironment : ""} />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField
                                                    label="Version"
                                                    borderless
                                                    value={manifestResource != null ? manifestResource.ManifestVersion.toString() : ""} />
                                            </Stack.Item>
                                            {manifestResource.CosmosContainer != null &&
                                                <>
                                                    <Stack.Item>
                                                        <Text variant={"xLarge"}>
                                                            Cosmos
                                                        </Text>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Resource Group"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.CosmosResourceGroup : ""} />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Instance"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.CosmosInstance : ""} />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Database"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.CosmosDatabase : ""} />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Container"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.CosmosContainer : ""} />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Partition Key"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.CosmosPartitionKey : ""} />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Partition Value"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.CosmosPartitionValue : ""} />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Text variant={"xLarge"}>
                                                            Storage
                                                        </Text>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Account"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.StorageAccount : ""} />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <TextField
                                                            label="Resource Group"
                                                            borderless
                                                            value={manifestResource != null ? manifestResource.StorageAccountResourceGroup : ""} />
                                                    </Stack.Item>
                                                </>
                                            }
                                        </Stack>
                                    </ScrollablePane>
                                </div>
                            </Stack.Item>
                        }
                    </Stack>
                </Callout>
            )}
        </Stack>
    );
}

const columnStackStyles: IStackStyles = {
    root: {
        height: "100%"
    }
};

const themedMediumStackTokens: IStackTokens = {
    childrenGap: "m",
    padding: "m",
};

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};

const documentCardStyles: IDocumentCardStyles = {
    root: {
        boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
        borderRadius: "4px",
        maxWidth: "",
        minWidth: ""
    }
};

const imageProps: IImageProps = {
    src: ReAccessIconLogo,
    height: 32
};