import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { Shimmer, useTheme } from "@fluentui/react";
import { ApplicationEntity } from "../../../../model/reaccessData/ApplicationEntity";
import { getDefaultFieldShimmer } from "../../../../services/shimmerService";
import { InlineFrameField } from "./fields/InlineFrameField";

export interface WebControlProps {
    control: ControlData;
    applicationEntity: ApplicationEntity | undefined;
    shimmerControlsOnSubmit: boolean;
}

export const WebControl: React.FC<WebControlProps> = (props: WebControlProps) => {
    const theme = useTheme();

    return (
        <>
            {props.control.FieldType === 35 &&
                <>
                    <Shimmer
                        customElementsGroup={getDefaultFieldShimmer(theme)}
                        isDataLoaded={props.shimmerControlsOnSubmit === false ? false : props.applicationEntity != null}
                        shimmerColors={{
                            shimmer: theme.palette.themeTertiary,
                            shimmerWave: theme.palette.themeSecondary,
                            background: theme.semanticColors.bodyStandoutBackground,
                        }}>
                        <>
                            {props.applicationEntity != null &&
                                <InlineFrameField
                                    control={props.control}
                                    applicationEntity={props.applicationEntity}>
                                </InlineFrameField>
                            }
                        </>
                    </Shimmer>
                </>
            }
        </>
    );
}