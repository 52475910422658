import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import "./CommentContent.css";
import { CommentModel } from "../../../../model/reaccessData/CommentModel";
import { NewComment } from "./NewComment";
import { CommentContent } from "./CommentContent";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface CommentWrapperProps {
    control: ControlData;
    comment: CommentModel;
    comments: CommentModel[];
    index: number;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    addNewComment: (parentId: string | undefined) => void;
    cancelNewComment: (indexOfComment: number) => void;
    update: (index: number, value: unknown) => void;
    subFormField: ISubFormField | undefined;
}

export const CommentWrapper: React.FC<CommentWrapperProps> = (props: CommentWrapperProps) => {
    const showCommentContentArea = (): boolean => {
        return props.comment.Id == null ? false : true;
    }

    return (
        <>
            {showCommentContentArea()
                ?
                <CommentContent control={props.control}
                    comment={props.comment}
                    comments={props.comments}
                    index={props.index}
                    cascadingLevel={1}
                    shouldValidate={props.shouldValidate}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    entityId={props.entityId}
                    addNewComment={(parentId: string | undefined) => { props.addNewComment(parentId);}}
                    cancelNewComment={(indexOfComment: number) => { props.cancelNewComment(indexOfComment);}}
                    update={props.update}
                    subFormField={props.subFormField}
                />
                :
                <NewComment
                    control={props.control}
                    comment={props.comment}
                    comments={props.comments}
                    index={props.index}
                    shouldValidate={props.shouldValidate}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    entityId={props.entityId}
                    cancelNewComment={(indexOfComment: number) => { props.cancelNewComment(indexOfComment);}}
                    update={props.update}
                    subFormField={props.subFormField}
                />
            }
            <br />
        </>
    );
}