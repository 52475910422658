import React from "react";
import { DatePicker, TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import { ConnectForm } from "../../../form/ConnectForm";
import { useRecoilValue } from "recoil";
import { applicationManifestState, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { getRules } from "../../../../../services/ruleService";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { ReadOnlyTextBoxField } from "../../ReadOnlyTextBoxField";
import moment from 'moment';
import { useId } from "@fluentui/react-hooks";
import { buildFormPropertyName } from "../../../../../services/fieldService";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { cloneDeep } from "lodash";

export interface DatePickerFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const DatePickerField: React.FC<DatePickerFieldProps> = (props: DatePickerFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const datePickerId = useId("datePicker");
    const tooltipId = useId("tooltip");
    //For some reason 0 does not work for the DatePicker.  You must drop down the tool tip by
    //the size of the labe.  This will probably get resolved in a future version of Fluent UI.
    const calloutProps = {
        gapSpace: -30,
        target: `#${datePickerId}`
    };

    const getTextValue = (value: Date | string): string => {
        if (value !== "") {
            value = moment(value).format("M/D/YYYY");
        }
        return value;
    }

    return (
        <ConnectForm>
            {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                <Controller
                    control={methods.control}
                    rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                    name={buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField)}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <>
                            {!props.readOnly &&
                                <TooltipHost
                                    content={props.control.ToolTipText}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <DatePicker
                                        id={datePickerId}
                                        label={props.control.LabelText}
                                        allowTextInput={true}
                                        showGoToToday={true}
                                        value={value}
                                        disabled={props.disabled}
                                        isRequired={props.isRequired}
                                        onBlur={onBlur}
                                        onSelectDate={(date: Date | null | undefined) => {
                                            var myDate: any = date == null ? "" : date;
                                            //React Hook Form requires you call the array Update method when editing an array as well as the onChange method.
                                            if (props.subFormField != null) {
                                                var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                var updateableField = cloneDeep(myCurrentValue);
                                                updateableField[props.control.Id!] = myDate;
                                                props.subFormField.update(props.subFormField.Index, updateableField);
                                            }
                                            onChange(myDate);
                                        }} />
                                </TooltipHost>
                            }
                            {props.readOnly &&
                                <ReadOnlyTextBoxField
                                    control={props.control}
                                    value={getTextValue(value)}>
                                </ReadOnlyTextBoxField>
                            }
                        </>
                    )} />
            }
        </ConnectForm>
    )
}