import React from "react";
import { ControlData } from "../../../model/manifest/ControlData";
import { Stack } from "@fluentui/react";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { SearchRow } from "./SearchRow";

export interface SearchControlsProps {
    searchControlsToDisplay: Array<ControlData[]>;
    applicationEntitySearch: ApplicationEntity | undefined;
    shimmerControlsOnSubmit: boolean;
}

export const SearchControls: React.FC<SearchControlsProps> = (props: SearchControlsProps) => {

    return (
        <>
            <Stack>
                {props.searchControlsToDisplay.map((value, index) => {
                    return (
                        <Stack.Item key={"SearchItem" + index}>
                            <SearchRow
                                key={"SearchRow" + index}
                                controls={value}
                                applicationEntity={props.applicationEntitySearch}
                                shouldValidate={false}
                                disableControls={false}
                                isDetailsView={false}
                                shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                            </SearchRow>
                        </Stack.Item>
                    )
                })}
            </Stack>
        </>
    );
}