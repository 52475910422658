import React from "react";
import { TextField, TooltipHost, useTheme } from "@fluentui/react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Controller } from "react-hook-form";
import { ConnectForm } from "../../../form/ConnectForm";
import { useRecoilValue } from "recoil";
import { applicationManifestState, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { getRules } from "../../../../../services/ruleService";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { useId } from "@fluentui/react-hooks";
import { getPrefix, getSuffix } from "../../../../../helpers/TextBoxHelpers";
import { ErrorMessage } from "@hookform/error-message";
import { buildFormPropertyName } from "../../../../../services/fieldService";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";

export interface CommentThreadFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    index: number;
    update: (index: number, value: unknown) => void;
    subFormField: ISubFormField | undefined;
}

export const CommentThreadField: React.FC<CommentThreadFieldProps> = (props: CommentThreadFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const propertyName = buildFormPropertyName(props.control.Id!, false, props.index, props.subFormField);
    const theme = useTheme();
    const textBoxId = useId("textBox");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textBoxId}`
    };

    return (
        <ConnectForm>
            {(methods: any) =>
                <Controller
                    control={methods.control}
                    rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                    name={`${propertyName}.Text`}
                    render={({ field: { onChange, value } }) => (
                        <>
                            {!props.readOnly &&
                                <TooltipHost
                                    content={props.control.ToolTipText}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <TextField
                                        id={textBoxId}
                                        multiline={true}
                                        rows={5}
                                        prefix={getPrefix(props.control)}
                                        suffix={getSuffix(props.control)}
                                        value={value}
                                        disabled={props.disabled}
                                        readOnly={props.readOnly}
                                        required={props.isRequired}
                                        aria-describedby={tooltipId}
                                        theme={theme}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            onChange(newValue);
                                        }} />
                                    <div className="CustomError">
                                        {/* This error is at the array index which means it is caused my validation on the client. */}
                                        <ErrorMessage errors={methods.formState.errors} name={propertyName} />
                                    </div>
                                </TooltipHost>
                            }
                        </>
                    )} />
            }
        </ConnectForm>
    )
}