import React, { useEffect, useState } from "react";
import { ActionButton, DetailsList, DetailsListLayoutMode, DocumentCard, IColumn, IDocumentCardStyles, IIconProps, IStackStyles, IStackTokens, PrimaryButton, SelectionMode, Stack, Text } from "@fluentui/react";
import { useSetRecoilState } from "recoil";
import { customerSummariesState, navHeaderState } from "../../../recoil/atoms";
import { useRecoilState } from "recoil";
import axios from "axios";
import { CustomerSummaryDto } from "../../../dtos/User/CustomerSummaryDto";
import moment from "moment";
import { ICustomerSummaryGridItem } from "../../../interfaces/owners/ICustomerSummaryGridItem";
import { cloneDeep } from "lodash";
import { useNavigate } from "react-router-dom";
import { NewCustomerDialog } from "./NewCustomerDialog";

export const OwnerPortal: React.FC = () => {
    const setNavHeader = useSetRecoilState(navHeaderState);
    const [customerSummaries, setCustomerSummaries]: [CustomerSummaryDto[], (customerSummaries: CustomerSummaryDto[]) => void] = useRecoilState(customerSummariesState);
    const [, setCustomerSummaryGridItems] = useState<ICustomerSummaryGridItem[]>([]);
    const [customerDialogHidden, setCustomerDialogHidden] = useState<boolean>(true);
    const favoriteStarIcon: IIconProps = { iconName: "FavoriteStar" };
    const favoriteStarFilledIcon: IIconProps = { iconName: "FavoriteStarFill" };
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const navigate = useNavigate();

    useEffect(() => {
        setNavHeader("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var cs = [...customerSummaries];
        var customerSummaryGridItems: ICustomerSummaryGridItem[] = [];
        cs.forEach((item: CustomerSummaryDto) => {
            var customerSummaryGridItem: ICustomerSummaryGridItem = {
                BillingStatus: item.BillingStatus,
                CustomerEmail: item.CustomerEmail,
                CustomerId: item.CustomerId,
                CustomerName: item.CustomerName,
                CreatedDate: item.CreatedDate,
                CustomerType: item.CustomerType,
                IsFavorite: item.IsFavorite,
                TrialEndDate: item.TrialEndDate,
            }
            customerSummaryGridItems.push(customerSummaryGridItem);
        });
        setCustomerSummaryGridItems(customerSummaryGridItems);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerSummaries]);

    const isFavoriteClick = (customerSummaryGridItem: ICustomerSummaryGridItem) => {
        var updateCustomerSummaryDto = {
            CustomerId: customerSummaryGridItem.CustomerId,
            CustomerName: customerSummaryGridItem.CustomerName,
            isFavorite: !customerSummaryGridItem.IsFavorite
        }
        axios.put("/CustomerSummary", updateCustomerSummaryDto, { cancelToken: source.token });

        var customerSummaryDtos = cloneDeep(customerSummaries);
        var cs = customerSummaryDtos.find(i => i.CustomerId === customerSummaryGridItem.CustomerId)!;
        cs.IsFavorite = !cs.IsFavorite;
        setCustomerSummaries(customerSummaryDtos);
    }

    const viewCustomerSubscriptionClick = (customerSummaryGridItem: ICustomerSummaryGridItem) => {
        navigate(`/OwnerPortal/Customer/${customerSummaryGridItem.CustomerId}`, { replace: false });
    }

    const columns: IColumn[] = [
        {
            key: "View",
            name: "",
            fieldName: "view",
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "button",
            onRender: (item: ICustomerSummaryGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <PrimaryButton
                            text="View"
                            ariaDescription="View customer button."
                            onClick={() => viewCustomerSubscriptionClick(item)}
                            allowDisabledFocus />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 225,
            maxWidth: 525,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: ICustomerSummaryGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {item.CustomerName}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "IsFavorite",
            name: "Is Favorite",
            fieldName: "isFavorite",
            minWidth: 100,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: true,
            data: "bool",
            onRender: (item: ICustomerSummaryGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item style={{ width: "50px" }}>
                        <ActionButton
                            iconProps={item.IsFavorite ? favoriteStarFilledIcon : favoriteStarIcon}
                            onClick={() => isFavoriteClick(item)} />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "CustomerType",
            name: "Customer Type",
            fieldName: "customerType",
            minWidth: 125,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: ICustomerSummaryGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {item.CustomerType}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Billing",
            name: "Billing Status",
            fieldName: "billing",
            minWidth: 225,
            maxWidth: 275,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: ICustomerSummaryGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {item.BillingStatus}{item.BillingStatus === "Trial" ? ` - Ends ${moment(item.TrialEndDate).format("M/D/YYYY")}` : ""}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "CreatedDate",
            name: "Created Date",
            fieldName: "CreatedDate",
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: false,
            data: "datetime",
            onRender: (item: ICustomerSummaryGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {moment(item.CreatedDate).format("M/D/YYYY")}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        }
    ];

    return (
        <Stack tokens={themedMediumStackTokens}>
            <Stack.Item>
                <Text variant={"xxLarge"}>
                    My Accounts
                </Text>
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={themedSmallStackTokens}>
                    <DocumentCard styles={documentCardStyles}>
                        <Stack.Item>
                            <Stack horizontal={true} horizontalAlign="space-between" tokens={themedSmallStackTokens} wrap>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <Text variant={"mediumPlus"}>
                                            Total
                                        </Text>
                                        <div className="bolt-pill">
                                            <div className="bolt-pill-content">
                                                {customerSummaries != null ? customerSummaries.length : ""}
                                            </div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <Stack.Item>
                                            <PrimaryButton
                                                text="New Customer"
                                                ariaDescription="Add new customer button."
                                                onClick={() => { setCustomerDialogHidden(false) }}
                                                allowDisabledFocus />
                                        </Stack.Item>

                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack tokens={themedSmallStackTokens}>
                            <Stack.Item>
                                <DetailsList
                                    items={customerSummaries != null ? customerSummaries : []}
                                    compact={true}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                />
                            </Stack.Item>
                        </Stack>
                    </DocumentCard>
                </Stack>
            </Stack.Item>
            {!customerDialogHidden &&
                <NewCustomerDialog
                    close={() => setCustomerDialogHidden(true)}
                />
            }
        </Stack>
    );
}

const themedMediumStackTokens: IStackTokens = {
    childrenGap: "m",
    padding: "m",
};

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};

const documentCardStyles: IDocumentCardStyles = {
    root: {
        boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
        borderRadius: "4px",
        maxWidth: "",
        minWidth: ""
    }
};

const columnStackStyles: IStackStyles = {
    root: {
        height: "100%"
    }
};