import React, { useState } from "react";
import { ChoiceGroup, DefaultButton, Dialog, DialogFooter, DialogType, IChoiceGroupOption, IStackTokens, PrimaryButton, Stack, Text } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

export interface CustomerUserChangeDialogProps {
    update: (selectedKey: string) => void;
    cancel: () => void;
}

export const CustomerUserChangeDialog: React.FC<CustomerUserChangeDialogProps> = (props: CustomerUserChangeDialogProps) => {
    const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);
    const labelId: string = useId("dialogLabel");
    const subTextId: string = useId("subTextLabel");

    const onChange = React.useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
        setSelectedKey(option!.key);
    }, []);

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: { main: { minWidth: "450px !important", maxWidth: "500px !important" } }
        }),
        [labelId, subTextId],
    );

    return (
        <Dialog
            hidden={false}
            onDismiss={props.cancel}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}>
            <Stack tokens={themedMediumStackTokens}>
                <Stack.Item>
                    <Text variant={"mediumPlus"}>
                        You are about to remove a global admin/builder's role at the customer level.  You have a few options while doing this:
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <ChoiceGroup selectedKey={selectedKey} options={options} onChange={onChange} label="Pick one" />
                </Stack.Item>
            </Stack>
            <DialogFooter>
                <DefaultButton
                    onClick={props.cancel}
                    text="Cancel" />
                <PrimaryButton
                    text="OK"
                    ariaDescription="Send message for new customer."
                    disabled={selectedKey == null}
                    onClick={() => { props.update(selectedKey!) }}
                    allowDisabledFocus />
            </DialogFooter>
        </Dialog>

    )
};


const dialogContentProps = {
    type: DialogType.normal,
    title: "Update User"
};

const themedMediumStackTokens: IStackTokens = {
    childrenGap: 'm',
    padding: 'm',
};

const options: IChoiceGroupOption[] = [
    { key: "Remove", text: "Revoke all access" },
    { key: "Leave", text: "User remains an administrator for all current applications" },
    { key: "Switch", text: "User level access for all current applications, then select View to adjust" }
];