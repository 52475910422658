import React, { useEffect, useState } from "react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { useRecoilValue, } from "recoil";
import { applicationManifestState, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { ApplicationEntity } from "../../../../../model/reaccessData/ApplicationEntity";
import { EntityListResult } from "../../../gridLayout/EntityListResult";
import { IApplicationEntityResult, IEntityListResult } from "../../../../../interfaces/EntityListResult";
import { Grid } from "../../../../../model/manifest/Grid";
import { Controller, FieldValues, UseFormReturn, useWatch } from "react-hook-form";
import { Dropdown, IDropdownOption, SelectionMode } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useParams } from "react-router";
import { GridGetDto } from "../../../../../dtos/DataSource/GridGetDto";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { cloneDeep } from "lodash";
import { buildFormPropertyName } from "../../../../../services/fieldService";
import { getRules } from "../../../../../services/ruleService";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { ErrorMessage } from "@hookform/error-message";

export interface StandardGridFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    methods: UseFormReturn<FieldValues, any, undefined>;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const StandardGridField: React.FC<StandardGridFieldProps> = (props: StandardGridFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const [entityListResult, setEntityListResult] = useState<IEntityListResult | undefined>();
    const [deleteEntityId, setDeleteEntityId] = useState<string>();
    const [myPreviousFormValues, setMyPreviousFormValues] = useState<string[] | undefined>();
    const [editApplicationEntityResult, setEditApplicationEntityResult] = useState<IApplicationEntityResult>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    var { appId } = useParams();
    const dropdownId = useId("dropdown");
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const propertyName = buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField);
    //since this field watches it's values we do not need to also watch resetFieldSpecificState
    const myFormValues: string[] | undefined = useWatch({ name: propertyName });

    useEffect(() => {
        if (myFormValues && myPreviousFormValues && 
            myFormValues.length === myPreviousFormValues.length && 
            myFormValues.every((value, index) => value === myPreviousFormValues[index])) {
            return;
        }

        if (myFormValues == null || myFormValues.length < 1) {
            var entityList: IEntityListResult = {
                ApplicationEntityResults: [],
                RecordCount: 0,
                possibleChangesToResultSet: false
            }
            setEntityListResult(entityList);
            return;
        }
        setMyPreviousFormValues(myFormValues);
        var gridCall = buildGridCall(myFormValues);
        gridCall
            .then((result) => {
                var entityList: IEntityListResult = result.data;
                setEntityListResult(entityList);
                setIsLoading(false);
            }).catch((error) => {
                //TODO: what if the fail calls
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myFormValues, myPreviousFormValues]);

    useEffect(() => {
        if (deleteEntityId == null) {
            return;
        }
        deleteEntity(deleteEntityId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteEntityId]);

    useEffect(() => {
        if (editApplicationEntityResult == null) {
            return;
        }
        editEntity(editApplicationEntityResult);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editApplicationEntityResult]);

    const getOptions = (methods: any): IDropdownOption[] => {
        var dropdownOptions: IDropdownOption[] = [];
        var formValues = methods.getValues();
        if (formValues == null || formValues[propertyName] == null) {
            return dropdownOptions;
        }
        dropdownOptions = formValues[propertyName].map((i: any) => ({ key: i, text: i }));
        return dropdownOptions;
    }

    const deleteEntity = (entityId: string): void => {
        const entityList: IEntityListResult = { ...entityListResult! };
        entityList!.RecordCount--;
        entityList!.ApplicationEntityResults = entityList!.ApplicationEntityResults.filter((i: { EntityId: string; }) => i.EntityId !== entityId);
        setEntityListResult(entityList);
        setDeleteEntityId(undefined);
    }

    const editEntity = (applicationEntityResult: IApplicationEntityResult): void => {
        const entityList: IEntityListResult = { ...entityListResult! };
        var indexOfApplicationEntityResult = entityList.ApplicationEntityResults.findIndex(i => i.EntityId === applicationEntityResult.EntityId);
        entityList.ApplicationEntityResults.splice(indexOfApplicationEntityResult, 1, applicationEntityResult);
        setEntityListResult(entityList);
        setEditApplicationEntityResult(undefined);
    }

    const deleteFormValue = (entityId: string, methods: any): void => {
        if (props.subFormField == null) {
            var formValues = cloneDeep(methods.getValues());
            const newValues = formValues[propertyName].filter((i: string) => i !== entityId);
            methods.setValue(propertyName, newValues, {
                shouldValidate: true,
                shouldDirty: true,
            });
        } else {
            var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
            var updateableField = cloneDeep(myCurrentValue);
            const newValues = updateableField[props.control.Id!].filter((i: string) => i !== entityId);
            updateableField[props.control.Id!] = newValues;
            props.subFormField.update(props.subFormField.Index, updateableField);
        }

        setDeleteEntityId(entityId);
    }

    const addFormValue = (applicationEntity: ApplicationEntity, methods: any): void => {
        var newValues: string[] = [];
        if (props.subFormField == null) {
            var formValues = methods.getValues();
            newValues = formValues[propertyName].concat([applicationEntity.EntityId]);
            methods.setValue(propertyName, newValues, {
                shouldValidate: true,
                shouldDirty: true,
            });
        } else {
            var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
            var updateableField = cloneDeep(myCurrentValue);
            newValues = updateableField[props.control.Id!].concat([applicationEntity.EntityId]);
            updateableField[props.control.Id!] = newValues;
            props.subFormField.update(props.subFormField.Index, updateableField);
        }

        var gridCall = buildGridCall(newValues);
        gridCall
            .then((result) => {
                var entityList: IEntityListResult = result.data;
                setEntityListResult(entityList);
                setIsLoading(false);
            }).catch((error) => {
                //TODO: what if the call fails
                setIsLoading(false);
            });
    }

    const addFormValues = (applicationEntityResultsSelected: IApplicationEntityResult[], methods: any): void => {
        var newValue = applicationEntityResultsSelected.map(i => i.EntityId);
        var newValues: string[] = [];
        if (props.subFormField == null) {
            var formValues = cloneDeep(methods.getValues());
            newValues = formValues[propertyName].concat(newValue);
            methods.setValue(propertyName, newValues, {
                shouldValidate: true,
                shouldDirty: true,
            });
        } else {
            var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
            var updateableField = cloneDeep(myCurrentValue);
            newValues = updateableField[props.control.Id!].concat(newValue);
            updateableField[props.control.Id!] = newValues;
            props.subFormField.update(props.subFormField.Index, updateableField);
        }

        var gridCall = buildGridCall(newValues);
        gridCall
            .then((result) => {
                var entityList: IEntityListResult = result.data;
                setEntityListResult(entityList);
                setIsLoading(false);
            }).catch((error) => {
                //TODO: what if the call fails
                setIsLoading(false);
            });
    }

    const editFormValue = (): void => {
        var formValues = cloneDeep(props.methods.getValues());
        const entityIds = formValues[propertyName];
        var gridCall = buildGridCall(entityIds);
        gridCall
            .then((result) => {
                var entityList: IEntityListResult = result.data;
                setEntityListResult(entityList);
                setIsLoading(false);
            }).catch((error) => {
                //TODO: what if the call fails
                setIsLoading(false);
            });
    }

    const buildGridCall = (entityIds: string[]): Promise<AxiosResponse<any>> => {
        setIsLoading(true);
        var gridGetDto: GridGetDto = {
            GridId: props.control.GridId!,
            EntityIds: entityIds
        }
        const options: AxiosRequestConfig = {
            method: "POST",
            data: gridGetDto,
            url: "/GetGridItems",
            params: {
                applicationId: appId
            },
            cancelToken: source.token
        };
        return axios(options);
    }

    const getGrid = (): Grid => {
        var grid = applicationManifest.Grids.find(i => i.Id === props.control.GridId)!;
        return grid;
    }

    const getGridColumns = (): ControlData[] => {
        var grid = applicationManifest.Grids.find(i => i.Id === props.control.GridId)!;
        var page = applicationManifest.Pages.find(i => i.Id === grid.PageId)!;
        var controls: ControlData[] = [];
        grid.GridColumns.forEach(gc => {
            var control = page.Controls.find(i => i.Id === gc.ControlId)!;
            controls.push(control);
        });
        return controls;
    }

    return (
        <>
            <EntityListResult
                grid={getGrid()}
                gridName={props.control.LabelText}
                entityListResult={entityListResult}
                columnControls={getGridColumns()}
                isLoading={isLoading}
                displayViewButton={true}
                displayRemoveButton={props.control.IsGridChild ? false : !props.readOnly}
                displayAddNewExistingButton={props.control.IsGridChild ? false : true}
                disableAddNewExistingButton={props.readOnly || props.disabled}
                hideGridAddExistingButton={props.control.HideGridAddExistingButton}
                hideGridAddNewButton={props.control.HideGridAddNewButton}
                navigateOnEditClick={false}
                removeButtonText="Remove"
                selectionMode={SelectionMode.none}
                warningMessage={undefined}
                removeEntity={(entityId: string) => {
                    deleteFormValue(entityId, props.methods);
                }}
                addEntity={(applicationEntity: ApplicationEntity) => {
                    addFormValue(applicationEntity, props.methods);
                }}
                editEntity={editFormValue}
                onAddSelectedClicked={(applicationEntityResultsSelected: IApplicationEntityResult[]) => {
                    addFormValues(applicationEntityResultsSelected, props.methods);
                }}
            />
            <Controller
                control={props.methods.control}
                rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                name={propertyName}
                render={({ field: { onBlur, value }, fieldState: { error } }) => (
                    <>
                        <Dropdown
                            id={dropdownId}
                            multiSelect={true}
                            selectedKeys={value}
                            hidden={true}
                            required={props.isRequired}
                            options={getOptions(props.methods)}
                            onBlur={onBlur}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                            }}
                        />
                        <div className="CustomError">
                            <ErrorMessage errors={error} name={propertyName} />
                        </div>
                    </>
                )} />
        </>
    );
}
