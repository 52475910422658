import { atom } from "recoil";
import { BusinessRule } from "../model/manifest/BusinessRule";
import { IEntityListResult } from "../interfaces/EntityListResult";
import { IDialogModalWrapperProps } from "../interfaces/DialogModal";
import { ILoadingModalProps } from "../interfaces/ILoadingModal";
import { IMessageBarQueue } from "../interfaces/IMessageBarQueue";
import { ApplicationManifest } from "../model/manifest/ApplicationManifest";
import { CustumActionMessage } from "../enmus/CustumActionMessages";
import { Group } from "../model/manifest/Group";
import { IBreadcrumbItem } from "@fluentui/react";
import { ApplicationEntity } from "../model/reaccessData/ApplicationEntity";
import { CustomerSummaryDto } from "../dtos/User/CustomerSummaryDto";
import { AppCatalogCustomer } from "../dtos/AppCatalogCustomer";
import { CustomerDto } from "../dtos/Customer/CustomerDto";
import { atomFamily } from "recoil";
import { selectorFamily } from "recoil";
import { DefaultValue } from "recoil";
import { AuthenticationProvider } from "../enmus/AuthenticationProvider";

export const applicationManifestState: ApplicationManifest = atom({
  key: "applicationManifestState",
  default: null,
});

export const showNavPageMobileState: boolean = atom({
  key: "showNavPageMobileState",
  default: false,
});

export const navPageSelectedState: string = atom({
  key: "navPageSelectedState",
  default: "",
});

export const navHeaderState: string = atom({
  key: "navHeaderState",
  default: "",
});

export const shimmerNavState: boolean = atom({
  key: "shimmerNavState",
  default: false,
});

export const globalBusinessRulesState: BusinessRule[] = atom({
  key: "globalBusinessRulesState",
  default: [],
});

export const formSearchDataState: Array<any> = atom({
  key: "formSearchDataState",
  default: undefined,
});

export const searchResultState: Array<IEntityListResult> = atom({
  key: "searchResultState",
  default: undefined,
});

export const searchTextFilterState: string = atom({
  key: "searchTextFilterState",
  default: "",
});

export const messageBarQueueState: IMessageBarQueue[] = atom({
  key: "messageBarQueueState",
  default: [],
});

export const custumActionMessageState: CustumActionMessage = atom({
  key: "custumActionMessageState",
  default: [],
});

export const dialogModalWrapperPropsState: IDialogModalWrapperProps = atom({
  key: "dialogModalWrapperPropsState",
  default: undefined,
});

export const loadingModalState: ILoadingModalProps = atom({
  key: "loadingModalState",
  default: undefined,
});

export const isDarkModeThemeState: boolean = atom({
  key: "isDarkModeThemeState",
  default: localStorage.getItem("theme") === "dark" ? true : false,
});

export const halfScreenModeState: boolean = atom({
  key: "halfScreenModeState",
  default: false,
});

export const groupSettingsState: Group[] = atom({
  key: "groupSettingsState",
  default: undefined,
});

export const breadcrumbsState: IBreadcrumbItem[] = atom({
  key: "breadcrumbsState",
  default: [],
});

export const incrementPanelCountState: boolean = atom({
  key: "incrementPanelCountState",
  default: false,
});

export const decrementPanelCountState: boolean = atom({
  key: "decrementPanelCountState",
  default: false,
});

export const customerSummariesState: CustomerSummaryDto[] = atom({
  key: "customerSummariesState",
  default: [],
});

export const customerDtoState: CustomerDto[] = atom({
  key: "customerDtoState",
  default: [],
});

export const appCatalogCustomersState: AppCatalogCustomer[] = atom({
  key: "appCatalogCustomersState",
  default: [],
});

export const signInButtonDisabledState: boolean = atom({
  key: "signInButtonDisabledState",
  default: false,
});

export const resetSearchPageState: boolean = atom({
  key: "resetSearchPageState",
  default: false,
});

export const resetSaveAddNewState: boolean = atom({
  key: "resetSaveAddNewState",
  default: false,
});

export const resetEditState: boolean = atom({
  key: "resetEditState",
  default: false,
});

//Atom Family
const dataSourceItems = atomFamily({
  key: "dataSourceItems",
  default: null,
});

const controlIds = atom({
  key: "controlIds",
  default: [],
});

export const dataSourceItemSelector = selectorFamily({
  key: "dataSourceItemSelector",
  get:
    (dataSourceId: any) =>
    ({ get }: any) => {
      return get(dataSourceItems(dataSourceId));
    },
  set:
    (dataSourceId: any) =>
    ({ set, reset }: any, newDataSourceItems: any) => {
      // the 'set' method will delete the atom from the atomFamily.
      if (newDataSourceItems instanceof DefaultValue) {
        // reset method deletes the atom from atomFamily. Then update ids list.
        reset(dataSourceItems(dataSourceId));
        set(controlIds, (prevValue: any[]) => prevValue.filter((id: any) => id !== dataSourceId));
      } else {
        // creates the atom and update the ids list
        set(dataSourceItems(dataSourceId), newDataSourceItems);
        set(controlIds, (prev: any) => [...prev, dataSourceId]);
      }
    },
});

//Atom Family
const documentProgresses = atomFamily({
  key: "documentProgresses",
  default: undefined,
});

const documentControlIds = atom({
  key: "documentControlIds",
  default: [],
});

export const documentProgressSelector = selectorFamily({
  key: "documentProgressSelector",
  get:
    (documentControlId: any) =>
    ({ get }: any) => {
      return get(documentProgresses(documentControlId));
    },
  set:
    (documentControlId: any) =>
    ({ set, reset }: any, newDocumentProgressesItems: any) => {
      // the 'set' method will delete the atom from the atomFamily.
      if (newDocumentProgressesItems instanceof DefaultValue) {
        // reset method deletes the atom from atomFamily. Then update ids list.
        reset(documentProgresses(documentControlId));
        set(documentControlIds, (prevValue: any) => prevValue.filter((id: any) => id !== documentControlId));
      } else {
        // creates the atom and update the ids list
        set(documentProgresses(documentControlId), newDocumentProgressesItems);
        set(documentControlIds, (prev: any) => [...prev, documentControlId]);
      }
    },
});

/** Acts as an emitter for field components to know they need to reset state.
 */
export const resetFieldSpecificState: boolean = atom({
  key: "resetFieldSpecificState",
  default: false,
});

// For Electron Integration Testing
export const availableApplicationsState = atom({
  key: "availableApplicationsState",
  default: [],
});

export const selectedApplicationState = atom({
  key: "selectedApplicationState",
  default: undefined,
});

export const appEntityUpdateSignalR: ApplicationEntity = atom({
  key: "appEntityUpdateSignalR",
  default: undefined,
});

export const pendingSignalRMessages: string[] = atom({
  key: "pendingSignalRMessages",
  default: [],
});

export const authenticationProviderState: string = atom({
  key: "authenticationProviderState",
  default: AuthenticationProvider.Microsoft,
});
