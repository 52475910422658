import React, { useEffect } from "react";
import { IStackStyles, Stack, useTheme } from "@fluentui/react";
import { SideNavbar } from "./SideNavbar";
import useComponentVisible from "../../services/useComponentVisible";
import { showNavPageMobileState } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";

export const SideNavbarMobile: React.FC = () => {
    const setShowNavPageMobile = useSetRecoilState(showNavPageMobileState);
    const theme = useTheme();
    const { ref, isComponentVisible } = useComponentVisible(true);

    useEffect(() => {
        if (isComponentVisible === false) {
            setShowNavPageMobile(false);
        }
    }, [isComponentVisible, setShowNavPageMobile]);

    const sideNavBarMobileStackStyles: IStackStyles = {
        root: {
            whiteSpace: "nowrap",
            display: "flex",
            position: "absolute",
            height: "100%",
            color: theme.semanticColors.bodyText,
            background: theme.semanticColors.bodyFrameDivider,
            borderColor: theme.semanticColors.bodyFrameDivider,
            borderRight: `1px solid ${theme.semanticColors.menuDivider}`,
            borderBottom: `1px solid ${theme.semanticColors.menuDivider}`,
            zIndex: 2
        }
    };

    return (
        <div ref={ref}>
            {isComponentVisible &&
                <Stack grow>
                    <Stack.Item styles={sideNavBarMobileStackStyles}>
                        <SideNavbar></SideNavbar>
                    </Stack.Item>
                </Stack>
            }
        </div>
    );
}