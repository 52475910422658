import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { IStackTokens, Stack, Text } from "@fluentui/react";
import { groupSettingsState } from "../../../recoil/atoms";
import { useParams } from "react-router-dom";
import { Group } from "../../../model/manifest/Group";

export interface GroupHeaderProps {
    groupHeader: string;
}

export const GroupHeader: React.FC<GroupHeaderProps> = (props: GroupHeaderProps) => {
    const groups: Group[] = useRecoilValue(groupSettingsState);
    const [group, setGroup] = useState<Group>();
    var { groupId } = useParams();

    useEffect(() => {
        var group = groups.find(i => i.GroupId === groupId);
        if (group != null) {
            setGroup(group);
        }
    }, [groupId, groups]);

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };

    return (
        <Stack tokens={themedMediumStackTokens}>
            <Stack.Item>
                <Text variant={"xxLarge"}>
                    {group != null ? group.Name : ""} {props.groupHeader}
                </Text>
            </Stack.Item>
        </Stack>
    );
}