import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { resetSearchPageState } from "../../../recoil/atoms";
import { SearchPage } from "./SearchPage";

export const SearchPageIndex: React.FC = () => {
    const [resetSearchPage, setResetSearchPage]: [boolean, (resetSearchPage: boolean) => void] = useRecoilState(resetSearchPageState);

    useEffect(() => {
        if (!resetSearchPage) {return}

        setResetSearchPage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSearchPage]);

    return (
        <>
            {!resetSearchPage &&
                <SearchPage></SearchPage>
            }
        </>
    );
}