import React, { useEffect, useState } from "react";
import { IStackItemStyles, IStackTokens, Link, Stack, Toggle } from "@fluentui/react";
import { useSetRecoilState } from "recoil";
import { navHeaderState } from "../../../recoil/atoms";
import { useNavigate } from "react-router-dom";

export interface TourToggleAreaProps {
    toggleLabel: string;
    tourId: string;
    navigateUrl: string;
}

const getHasTaken = (storageLocation: string): boolean => {
    var hasTaken = localStorage.getItem(storageLocation);
    if (hasTaken?.toLowerCase() === "true") {
        return true;
    }
    return false;
}

export const TourToggleArea: React.FC<TourToggleAreaProps> = (props: TourToggleAreaProps) => {
    const setNavHeader = useSetRecoilState(navHeaderState);
    const [showLink, setShowLink] = useState<boolean>(!getHasTaken(props.tourId));
    const navigate = useNavigate();

    useEffect(() => {
        setNavHeader("Tour Guides");
    }, [setNavHeader]);

    const linkStackItemStyles: IStackItemStyles = {
        root: {
            paddingLeft: "20px",
            paddingTop: "29px"
        }
    };

    const themedSmallStackTokens: IStackTokens = {
        childrenGap: 's1',
        padding: 's1',
    };

    return (
        <>
            <Stack horizontal={true} tokens={themedSmallStackTokens}>
                <Stack.Item>
                    <Toggle
                        label={props.toggleLabel}
                        onText="Viewed"
                        offText="Not Viewed"
                        defaultChecked={getHasTaken(props.tourId)}
                        onChange={() => {
                            if (!getHasTaken(props.tourId)) {
                                localStorage.setItem(props.tourId, "true");
                            } else {
                                localStorage.setItem(props.tourId, "false");
                                setShowLink(true);
                            }
                        }} />
                </Stack.Item>
                {showLink &&
                    <Stack.Item>
                        <Link
                            styles={linkStackItemStyles}
                            underline
                            onClick={() => {
                                navigate(props.navigateUrl);
                            }}>
                            View
                        </Link>
                    </Stack.Item>
                }
            </Stack>
        </>
    );
}