import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";

export const uploadDocument = (
    applicationId: string, 
    pageId: string, 
    subFormId: string | undefined,
    controlId: string | undefined, 
    description: string | undefined, 
    importData: boolean | undefined, 
    file: File, 
    setDocumentProgresses: (documentProgresses: number | undefined) => void,
    source: CancelTokenSource) => {
    
    return new Promise((resolve, reject) => {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const config: AxiosRequestConfig<FormData> | undefined = { 
            headers: { 'Content-Type': 'multipart/form-data' }, 
            params: {},
            cancelToken: source.token
        };
        if (controlId !== undefined) {
            config.params = { applicationId: applicationId };
            config.onUploadProgress = function({progress}) {
                if (progress != null) {
                    var roundedProgress = Math.round((progress * 100));
                    console.log(roundedProgress);
                    setDocumentProgresses(roundedProgress);
                }
            }
            axios.post(`/Document/${pageId}/${controlId}/${subFormId != null ? subFormId : ''}`, formData, config)
                .then((result) => {
                    setDocumentProgresses(undefined);
                    resolve(result);
                }).catch((error) => {
                    setDocumentProgresses(undefined);
                    reject(error);
                });
        } else {
            if (importData) {
                axios.post(`/DataImport?ApplicationId=${applicationId}&PageId=${pageId}&Description=${description}&timeZoneName=${timezone}`, formData, config)
                .then((result) => {
                    resolve(result);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                axios.post(`/DataImportValidation?ApplicationId=${applicationId}&PageId=${pageId}&timeZoneName=${timezone}`, formData, config)
                .then((result) => {
                    resolve(result);
                }).catch((error) => {
                    reject(error);
                });
            }
        }
    });
}

function base64ToArrayBuffer(base64: string) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export const getDocument = (
    applicationId: string, 
    entityId: string, 
    fileId: string, 
    version: string, 
    fileName: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        axios.get(`/Document/${entityId}/${fileId}/${version}/${fileName}`, { params: { applicationId: applicationId } })
            .then((result) => {
                //document was not found or user could not be found
                if ((result.status === 200 && result.data == null) || result.status === 204) {
                    resolve("");
                }
                //document found
                var imageUrl = createImageUrlFromArray(result.data.Document, result.data.MimeType);
                resolve(imageUrl);
            }).catch((error) => {
                reject(error);
            });
    });
}

export const createImageUrlFromArray = (document: any, mimeType: string) => {
  const arrayBuffer = base64ToArrayBuffer(document);
  var byteArray = new Uint8Array(arrayBuffer);
  var blob = new Blob([byteArray], { type: mimeType });
  return URL.createObjectURL(blob);
};

export const downloadFileFromImageUrl = (
  imageUrl: string,
  fileName: string
) => {
  //create temporary link and set attributes
  const link = document.createElement("a");
  link.href = imageUrl;
  link.setAttribute("download", fileName);
  // Start download
  link.click();

  // Clean up and remove the link
  link.remove();
};
