export const copyAndSort = <T>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean,
  dataType?: string
): T[] => {
  const key = columnKey as keyof T;
  const copy = [...items];
  if (
    dataType?.toLowerCase() === "date" ||
    dataType?.toLowerCase() === "datetime" ||
    dataType?.toLowerCase() === "number" ||
    dataType?.toLowerCase() === "decimal"
  ) {
    const sortedCopy = copy.sort((a: T, b: T) => {
      if (isSortedDescending) {
        return a[key] < b[key] ? 1 : a[key] === b[key] ? 0 : -1;
      } else {
        return a[key] > b[key] ? 1 : a[key] === b[key] ? 0 : -1;
      }
    });
    return sortedCopy;
  } else {
    const sortedCopy = copy.sort((a: any, b: any) => {
      if (isSortedDescending) {
        return a[key]
          ? a[key].localeCompare(b[key], { sensitivity: "base" })
          : -1;
      } else {
        return b[key]
          ? b[key].localeCompare(a[key], { sensitivity: "base" })
          : -1;
      }
    });
    return sortedCopy;
  }
};
