import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { applicationManifestState, dialogModalWrapperPropsState, navHeaderState, shimmerNavState } from "../../../recoil/atoms";
import { useParams, Outlet } from 'react-router-dom';
import axios, { AxiosRequestConfig } from 'axios';
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { ValidationError } from "../../../model/error/ValidationError";

export const HomeIndex: React.FC = () => {
    const [applicationManifest, setApplicationManifest] = useRecoilState(applicationManifestState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    var { appId } = useParams();

    useEffect(() => {
        if (appId == null) return;

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (applicationManifest == null || applicationManifest.Id !== appId) {
            const options: AxiosRequestConfig = {
                method: "GET",
                url: "/ReAccessApplicationManifest",
                params: {
                    applicationId: appId
                },
                cancelToken: source.token
            };

            axios(options)
                .then((result) => {
                    var appManifest: ApplicationManifest = result.data;
                    setShimmerNav(true);
                    setApplicationManifest(appManifest);
                    setNavHeader(result.data.ApplicationName);
                })
                .catch((error) => {
                    if (error.response == null) {
                        console.log("Request canceled", "Home Index Component cleaned up");
                    } else {
                        if (error.response.status === 401 || error.response.status === 409) {
                            return;
                        }
                        var dialogProps: IDialogModalWrapperProps | undefined;
                        if (error.response.status === 422 && error.response.data.error != null) {
                            var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                            dialogProps = {
                                isVisible: true,
                                title: validationErrors[0].Title,
                                subText: validationErrors[0].Message,
                                isBlocking: true,
                                primaryButtonText: "OK",
                                secondaryButtonText: undefined,
                                onDismiss: () => {
                                    setDialogModalWrapperPropsState(undefined);
                                },
                                onSecondaryButtonDismiss: () => { }
                            }
                        } else {
                            dialogProps = {
                                isVisible: true,
                                title: "Uh oh",
                                subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                                isBlocking: true,
                                primaryButtonText: "OK",
                                secondaryButtonText: undefined,
                                onDismiss: () => {
                                    setDialogModalWrapperPropsState(undefined);
                                },
                                onSecondaryButtonDismiss: () => { }
                            }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                });
        }
        return function cleanup() {
            source.cancel();
        }
    }, [appId, applicationManifest, setApplicationManifest, setDialogModalWrapperPropsState, setNavHeader, setShimmerNav]);

    return (
        <>
            {applicationManifest != null &&
                <Outlet />
            }
        </>
    );
}