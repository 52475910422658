import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { Landing } from "../unauthenticated/Landing";
import { createTheme, ITheme, ThemeProvider } from "@fluentui/react";
import { lightTheme } from "../../themes";

export const UnAuthenticated: React.FC = () => {
    var theme: ITheme = createTheme(lightTheme);

    return (
        <ThemeProvider as={Fragment} theme={theme}>
            <Routes>
                <Route path="/*" element={<Landing />} />
            </Routes>
        </ThemeProvider>
    );
}