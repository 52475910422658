import React, { useState } from "react";
import { ActionButton, DocumentCard, FontSizes, FontWeights, IDocumentCardStyles, IIconProps, IStackItemStyles, IStackStyles, Stack, Text, useTheme } from "@fluentui/react";
import { CustomerSummaryDto } from "../../dtos/User/CustomerSummaryDto";
import axios from "axios";
import { applicationManifestState } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { hasTrialEnded } from "../../services/customerService";

export interface ICustomerSubscriptionProps {
    customerSummary: CustomerSummaryDto;
    close: () => void;
}

export const CustomerSubscription: React.FC<ICustomerSubscriptionProps> = (props: ICustomerSubscriptionProps) => {
    const [isFavorite, setIsFavorite] = useState<boolean>(props.customerSummary.IsFavorite);
    const setApplicationManifest = useSetRecoilState(applicationManifestState);
    const navigate = useNavigate();
    const favoriteStarIcon: IIconProps = { iconName: "FavoriteStar" };
    const favoriteStarFilledIcon: IIconProps = { iconName: "FavoriteStarFill" };
    const theme = useTheme();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const documentCardStyles: IDocumentCardStyles = {
        root: {
            width: "400px",
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            maxWidth: "",
            minWidth: "",
            backgroundColor: theme.semanticColors.bodyFrameDivider,
            color: theme.semanticColors.bodyText
        }
    };

    const isFavoriteClick = () => {
        var updateCustomerSummaryDto = {
            CustomerId: props.customerSummary.CustomerId,
            CustomerName: props.customerSummary.CustomerName,
            isFavorite: !isFavorite
        }
        axios.put("/CustomerSummary", updateCustomerSummaryDto, { cancelToken: source.token });

        setIsFavorite(!isFavorite);
    }

    const customerClick = () => {
        setApplicationManifest(null);
        props.close();
        navigate(`/OwnerPortal/Customer/${props.customerSummary.CustomerId}`, { replace: false });
    }

    return (
        <Stack styles={customerCardStyles}>
            <DocumentCard styles={documentCardStyles}>
                <Stack horizontal={true} styles={customerTextStyles}>
                    <Stack.Item style={{ width: "350px" }}>
                        <ActionButton style={{ textAlign: "start" }} onClick={customerClick}>
                            <Text styles={boldSubTextStyles}>
                                {props.customerSummary.CustomerName}
                            </Text>
                        </ActionButton>
                    </Stack.Item>
                    <Stack.Item align="center" style={{ width: "50px" }}>
                        <ActionButton iconProps={isFavorite ? favoriteStarFilledIcon : favoriteStarIcon} onClick={isFavoriteClick} />
                    </Stack.Item>
                </Stack>
                {hasTrialEnded(props.customerSummary.BillingStatus, props.customerSummary.TrialEndDate) &&
                    <Stack styles={trialTextStyles}>
                        <Stack.Item>
                            <Text>
                                Trial Ended
                            </Text>
                        </Stack.Item>
                    </Stack>
                }
            </DocumentCard>
        </Stack>
    );
}

const boldSubTextStyles: IStackItemStyles = {
    root: {
        fontSize: FontSizes.size18,
        fontWeight: FontWeights.bold
    }
};

const customerCardStyles: IStackStyles = {
    root: {
        padding: "0 24px 20px",
    }
}

const customerTextStyles: IStackStyles = {
    root: {
        padding: "10px 20px 20px",
    }
}

const trialTextStyles: IStackStyles = {
    root: {
        paddingLeft: "30px",
        paddingBottom: "10px"
    }
}