import React, { useState } from "react";
import { DefaultButton, Dropdown, IDropdownOption, IStackTokens, Panel, PanelType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { Controller, useForm } from "react-hook-form";
import axios, { AxiosRequestConfig } from "axios";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { ValidationError } from "../../../model/error/ValidationError";
import { ICustomerUserGridItem } from "../../../interfaces/users/ICustomerUserGridItem";
import { getCustomerUserRoleOptionsWithSelectAnOption } from "../../../services/roleService";
import { RegexConstants } from "../../../constants/RegexConstants";
import { useParams } from "react-router-dom";

export interface NewCustomerUserPanelProps {
    customerUsers: ICustomerUserGridItem[];
    addCustomerUser: (customerUser: ICustomerUserGridItem) => void;
    dismissPanel: () => void;
}

export const NewCustomerUserPanel: React.FC<NewCustomerUserPanelProps> = (props: NewCustomerUserPanelProps) => {
    const { handleSubmit, control, formState: { errors, isDirty, isValid }, getValues, setError } = useForm({ defaultValues: defaultValues, mode: "onChange" });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var { customerId } = useParams();

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack horizontal={true} horizontalAlign={"end"}>
                <PrimaryButton
                    styles={buttonStyles}
                    onClick={submitForm}
                    disabled={!isValid || !isDirty || isSubmitting}>
                    Add
                </PrimaryButton>
                <DefaultButton
                    onClick={props.dismissPanel}
                    disabled={isSubmitting}>
                    Cancel
                </DefaultButton>
            </Stack>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDirty, isSubmitting, isValid, props.dismissPanel],
    );

    const submitForm = async () => {
        setIsSubmitting(true);
        var data = getValues();
        var customerUserUpsertDto = {
            CustomerId: customerId,
            CustomerUserRoleType: data.customerUserRole,
            EmailAddress: data.emailAddress
        }
        const options: AxiosRequestConfig = {
            method: "POST",
            params: { customerId: customerId },
            data: customerUserUpsertDto,
            url: "/CustomerUser/Post",
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                var customerUser: ICustomerUserGridItem = {
                    Avatar: "",
                    CompanyName: "",
                    CustomerUserRoleType: data.customerUserRole,
                    Email: data.emailAddress,
                    FullName: "",
                    ImageInitials: "",
                    JobTitle: "",
                    PreviousRole: data.customerUserRole,
                    Disabled: false
                }
                props.addCustomerUser(customerUser);
                setIsSubmitting(false);
            }).catch((error) => {
                setIsSubmitting(false);
                if (error.response == null) {
                    console.log("Request canceled", "New Group User Panel Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        //set errors manually
                        validationErrors.forEach((validationError: ValidationError) => {
                            setError(validationError.GroupIdentifier as any, {
                                type: "manual",
                                message: validationError.Message
                            })
                        });
                    } else {
                        var dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
    }

    return (
        <Panel
            headerText="Add user to customer group"
            isOpen={true}
            onDismiss={props.dismissPanel}
            type={PanelType.custom}
            customWidth="500px"
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <form onSubmit={handleSubmit(submitForm)}>
                <Stack horizontal={false} tokens={themedSmallStackTokens} style={{ "paddingTop": "25px" }}>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="emailAddress"
                            rules={{
                                maxLength: { value: 100, message: "The max length of email is 100 characters." },
                                validate: {
                                    unique: (value: string): string | undefined => {
                                        if (value == null || value === "") {
                                            return "Email Address is required";
                                        }
                                        if (value.search(RegexConstants.EmailRegex) === -1) {
                                            return "Email Address is invalid";
                                        }
                                        if (props.customerUsers.findIndex(i => i.Email === value) >= 0) {
                                            return "Email Address is already active in your customer group";
                                        }
                                        return undefined;
                                    },
                                },
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextField
                                    label="Email Address"
                                    value={value}
                                    errorMessage={errors != null && errors["emailAddress"]?.message != null ? errors["emailAddress"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        onChange(newValue);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="customerUserRole"
                            rules={{
                                required: "Role is required"
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Dropdown
                                    label={"Customer User Role"}
                                    selectedKey={value}
                                    options={getCustomerUserRoleOptionsWithSelectAnOption()}
                                    errorMessage={errors != null && errors["customerUserRole"]?.message != null ? errors["customerUserRole"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                        onChange(option?.key);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                </Stack>
            </form>
        </Panel>
    );
}

const defaultValues = {
    emailAddress: "",
    customerUserRole: ""
}

const buttonStyles = { root: { marginRight: 8 } };

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};