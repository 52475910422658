import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { decrementPanelCountState, globalBusinessRulesState, halfScreenModeState, incrementPanelCountState, showNavPageMobileState } from "../../recoil/atoms";
import { BusinessRule } from "../../model/manifest/BusinessRule";
import { IStackItemStyles, IStackStyles, Stack, useTheme } from "@fluentui/react";
import { SideNavbar } from "./SideNavbar";
import getScreenSize from "../../services/getScreenSize";
import { SideNavbarMobile } from "./SideNavbarMobile";
import { MainAreaMessages } from "./MainAreaMessages";

export const Main: React.FC = () => {
    const showNavPageMobile: boolean = useRecoilValue(showNavPageMobileState);
    const [, setGlobalBusinessRules]: [BusinessRule[], (globalBusinessRules: BusinessRule[]) => void] = useRecoilState(globalBusinessRulesState);
    const [halfScreenMode, setHalfScreenModeState]: [boolean, (halfScreenMode: boolean) => void] = useRecoilState(halfScreenModeState);
    const [incrementPanelCount, setIncrementPanelCount]: [boolean, (incrementPanelCount: boolean) => void] = useRecoilState(incrementPanelCountState);
    const [decrementPanelCount, setDecrementPanelCount]: [boolean, (decrementPanelCount: boolean) => void] = useRecoilState(decrementPanelCountState);
    const [panelCount, setPanelCountState] = useState<number>(0);
    const theme = useTheme();
    const screenSize: number = getScreenSize();

    useEffect(() => {
        axios.get("/BusinessRule")
            .then((result) => {
                var businessRules: BusinessRule[] = result.data;
                setGlobalBusinessRules(businessRules);
            })
            .catch((error) => {

            });
    }, [setGlobalBusinessRules]);

    useEffect(() => {
        if (!incrementPanelCount) {
            return;
        }
        setIncrementPanelCount(false);
        setPanelCountState(panelCount + 1);
    }, [incrementPanelCount, panelCount, setIncrementPanelCount]);

    useEffect(() => {
        if (!decrementPanelCount) {
            return;
        }
        setDecrementPanelCount(false);
        setPanelCountState(panelCount - 1);
    }, [decrementPanelCount, panelCount, setDecrementPanelCount]);

    useEffect(() => {
        if (panelCount !== 0) {
            return;
        }
        setHalfScreenModeState(false);
    }, [panelCount, setHalfScreenModeState]);


    const sideNavBarStackStyles: IStackStyles = {
        root: {
            whiteSpace: "nowrap",
            display: "flex",
            color: theme.semanticColors.bodyText,
            background: theme.semanticColors.bodyFrameDivider,
            borderColor: theme.semanticColors.bodyFrameDivider,
            borderRight: `1px solid ${theme.semanticColors.menuDivider}`,
            zIndex: 2
        }
    };

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            justifyContent: "center",
            overflowY: "auto",
            zIndex: 1,
            width: halfScreenMode ? "50%" : "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    const bodyMobileStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            justifyContent: "center",
            overflowY: "auto",
            zIndex: 1,
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    return (
        <>
            {screenSize >= 600
                ?
                <Stack horizontal grow={2} styles={mainStackItemStyles}>
                    <Stack.Item styles={sideNavBarStackStyles}>
                        <SideNavbar></SideNavbar>
                    </Stack.Item>
                    <Stack.Item styles={bodyStackItemStyles}>
                        <Stack horizontal={false} style={{ width: "100%" }}>
                            <MainAreaMessages></MainAreaMessages>
                            <Stack.Item styles={bodyMobileStackItemStyles}>
                                <Outlet></Outlet>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
                :
                <>
                    <Stack horizontal={true} grow styles={mainStackItemStyles}>
                        {showNavPageMobile &&
                            <SideNavbarMobile></SideNavbarMobile>
                        }
                        <Stack.Item styles={bodyStackItemStyles}>
                            <Stack horizontal={false} style={{ width: "100%" }}>
                                <MainAreaMessages></MainAreaMessages>
                                <Stack.Item styles={bodyMobileStackItemStyles}>
                                    <Outlet></Outlet>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </>
            }
        </>
    );
}

const mainStackItemStyles: IStackItemStyles = {
    root: {
        overflow: "hidden",
        position: "relative"
    }
};
