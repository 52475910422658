import React, { useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import axios, { AxiosRequestConfig } from "axios";
import { Group } from "../../../model/manifest/Group";
import { applicationManifestState, dialogModalWrapperPropsState, groupSettingsState } from "../../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { useRecoilState, useSetRecoilState } from "recoil";

export const ApplicationSettingsIndex: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [groups, setGroupsState]: [Group[], (groups: Group[] | undefined) => void] = useRecoilState(groupSettingsState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const navigate = useNavigate();

    useEffect(() => {
        if (!applicationManifest.IsAdmin) {
            var dialogProps = {
                isVisible: true,
                title: "Permissions",
                subText: "You don't belong here.  If you need more permissions ask your application administrator.",
                isBlocking: true,
                primaryButtonText: "OK",
                secondaryButtonText: undefined,
                onDismiss: () => {
                    setDialogModalWrapperPropsState(undefined);
                    navigate(`/Application/${applicationManifest.Id}/Home`, { replace: true });
                },
                onSecondaryButtonDismiss: () => { }
            }
            setDialogModalWrapperPropsState(dialogProps);
            return;
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/Group/GetAll",
            params: {
                applicationId: applicationManifest.Id
            },
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {
                var groupArray: Group[] = result.data;
                groupArray.forEach(group => {
                    group.GroupUsers.sort((a, b) => a.EmailAddress.localeCompare(b.EmailAddress));
                });
                setGroupsState(groupArray);
            })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "Application Settings Component cleaned up");
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {groups &&
                <Outlet></Outlet>
            }
        </>
    );
}