import { ApplicationManifest } from "../model/manifest/ApplicationManifest";
import { Form } from "../model/manifest/Form";
import { PageData } from "../model/manifest/PageData";
import { ApplicationEntity } from "../model/reaccessData/ApplicationEntity";
import { formatCurrency } from "./currencyService";
import { getBooleanTextValueFromString } from "./fieldService";
import { checkIfValidUuid } from "./stringManipulationService";

export const getPage = (applicationManifest: ApplicationManifest, formId: string): PageData => {
    return applicationManifest.Pages!.find(i => i.Id === formId)!;
}

export const getFormName = (applicationManifest: ApplicationManifest, formId: string): string => {
    var form = applicationManifest.Pages.find(i => i.Id === formId) as Form;
    if (form != null) {
        return form.Name;
    }
    form = applicationManifest.SubForms.find(i => i.Id === formId) as Form;
    if (form != null) {
        return form.Name;
    }
    return "";
}

export const getForm = (applicationManifest: ApplicationManifest, formId: string): Form => {
    var pageData = applicationManifest.Pages.find(i => i.Id === formId);
    if (pageData != null) {
        return pageData as Form;
    }
    var subForm = applicationManifest.SubForms.find(i => i.Id === formId);
    return subForm as Form;
}

export const getFormRecordIdentifier = (applicationManifest: ApplicationManifest, formId: string, applicationEntity: ApplicationEntity): string => {
    var form = applicationManifest.Pages.find(i => i.Id === formId)!;
    if (form == null) {
        return ""; //this is a subform and not needed here
    }

    var stringBuilder = "";
    form.RecordDisplayIdentifier?.forEach((identifier) => {
        var field = applicationEntity.Fields.find(i => i.Id === identifier);
        if (field != null) {
            if (field.TextValue != null && field.TextValue !== "") {
                var control = form.Controls.find(i => i.Id === field!.Id);
                if (control == null) { return; }

                if (control.ControlType === 1) {
                    if (control.FieldType === 1 ||
                        control.FieldType === 3 ||
                        control.FieldType === 14 ||
                        control.FieldType === 18 ||
                        control.FieldType === 20 ||
                        control.FieldType === 23) {
                        stringBuilder += field.TextValue;
                    }
                    if (control.FieldType === 22) {
                        stringBuilder += "$" + formatCurrency(null, field.TextValue);
                    }
                }
                // Leaving this in for when we implement data sources
                // if (control.ControlType === 2) {
                //     var dataSource = applicationManifest.DataSources.find(i => i.Name === control!.DataSourceName)
                //     if (dataSource?.PageId == null || dataSource?.PageId === "") {
                //         var item = dataSource?.Items.find(i => i.Value === field!.TextValue);
                //         if (item != null) {
                //             stringBuilder += item.Text;
                //         }
                //     } else {

                //     }
                // }
                if (control.ControlType === 3) {
                    stringBuilder += getBooleanTextValueFromString(field.TextValue);
                }
                if (control.ControlType === 4) {
                    stringBuilder += field.TextValue;
                }
                if (control.ControlType === 5) {
                    if (control.FieldType === 24 || control.FieldType === 25 || control.FieldType === 32) {
                        stringBuilder += field.TextValue;
                    }
                }
            }
        } else {
            //This check is in place for when a field was not saved to the database.  Maybe there was a field added later or removed so this
            //record does not know about it.  Instead of displaying the control ID Guid we will just strip it out since 99.9999% of the time
            //nobody would hard code a GUID to be viewed tied to a record.
            if (!checkIfValidUuid(identifier))
                stringBuilder += identifier;
        }
    });

    return stringBuilder !== "" ? " - " + stringBuilder : "";
}

export const refreshManifest = (
    refreshManifestDependencies: any,
    navigate: any,
    appId: string): void => {
    refreshManifestDependencies.setDialogModalWrapperProps(undefined);
    refreshManifestDependencies.setFormSearchDataState(undefined);
    refreshManifestDependencies.setSearchResultState(undefined);
    refreshManifestDependencies.setShimmerNav(false);
    refreshManifestDependencies.setApplicationManifest(null);
    setTimeout(() => {
        if (refreshManifestDependencies.navPageSelected != null && refreshManifestDependencies.navPageSelected !== "") {
            navigate(`/Application/${appId}/Form/${refreshManifestDependencies.navPageSelected}/Search`, { replace: true });
        } else {
            navigate(`/Application/${appId}/Home`, { replace: true });
            navigate(0);
        }
    }, 150);
}