import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { DocumentCard, IDocumentCardStyles, IStackTokens, Stack } from "@fluentui/react";
import { groupSettingsState } from "../../../recoil/atoms";
import { useParams } from "react-router-dom";
import { Group } from "../../../model/manifest/Group";
import { GroupSettingsForm } from "./GroupSettingsForm";

export const GroupSettings: React.FC = () => {
    const groups: Group[] = useRecoilValue(groupSettingsState);
    const [group, setGroup] = useState<Group>();
    var { groupId } = useParams();

    useEffect(() => {
        var group = groups.find(i => i.GroupId === groupId);
        if (group != null) {
            setGroup(group);
        }
    }, [groupId, groups]);

    const documentCardStyles: IDocumentCardStyles = {
        root: {
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            height: "100%",
            maxWidth: "",
            minWidth: "50%"
        }
    };

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };

    return (
        <Stack tokens={themedMediumStackTokens}>
            <Stack.Item>
                <Stack horizontal={true} horizontalAlign="space-between" wrap>
                    <DocumentCard styles={documentCardStyles}>
                        <Stack tokens={themedMediumStackTokens}>
                            <Stack.Item>
                                {group != null &&
                                    <GroupSettingsForm
                                        group={group}>
                                    </GroupSettingsForm>
                                }
                            </Stack.Item>
                        </Stack>
                    </DocumentCard>
                    <Stack.Item>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}