import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { dialogModalWrapperPropsState, navHeaderState, shimmerNavState } from "../../recoil/atoms";
import { DefaultButton, IImageProps, IStackItemStyles, IStackTokens, Image, PrimaryButton, Separator, Stack, TextField, Text, useTheme } from "@fluentui/react";
import getScreenSize from "../../services/getScreenSize";
import axios from "axios";
import { IDialogModalWrapperProps } from "../../interfaces/DialogModal";

export const TrialEnded: React.FC = () => {
    const [comments, setComments] = useState<string>("");
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const screenSize: number = getScreenSize();
    const theme = useTheme();

    useEffect(() => {
        setNavHeader("Trial Ended");
        setShimmerNav(true);
    }, [setNavHeader, setShimmerNav]);

    const getImageSize = (): any => {
        if (screenSize >= 900) {
            return "500px";
        } else if (screenSize > 600) {
            return "350px";
        }

        return "250px";
    }

    const getMaxWidth = (): any => {
        if (screenSize >= 900) {
            return "50%";
        } else if (screenSize > 600) {
            return "";
        }
        return "";
    }

    const getMaxPaddingTop = (): any => {
        if (screenSize >= 900) {
            return "150px";
        } else if (screenSize > 600) {
            return "100px";
        }
        return "25px";
    }

    const imageProps: IImageProps = {
        src: './support-team.png',
        width: getImageSize(),
    };

    const itemStyles: IStackItemStyles = {
        root: {
            display: 'flex',
            justifyContent: 'center',
            maxWidth: getMaxWidth()
        }
    };

    const imageItemStyles: IStackItemStyles = {
        root: {
            display: screenSize >= 650 ? "flex" : "",
            justifyContent: 'center'
        }
    };

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    const mainStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: getMaxPaddingTop(),
    };

    const commentsStackTokens: IStackTokens = {
        childrenGap: 5
    };

    const commentItemStyles: IStackItemStyles = {
        root: {
            paddingTop: "20px"
        }
    };

    const onChangeCommentsFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (!newValue || newValue.length <= 5000) {
                setComments(newValue || "");
            }
        },
        [],
    );

    const submitTrialEndedRequest = (): void => {
        //both buttons on the screen flow thru this method.  If there are comments then the user does not want
        //to continue use.  If there are not comments the user click the Contact Me button.  The server will
        //determine the correct route and send the appropriate response
        var trialEndedDto = {
            comments: comments
        }
        axios.post("/TrialEnded", trialEndedDto)
            .then((result) => {
                var dialogProps: IDialogModalWrapperProps;
                if (comments == null || comments === "") {
                    dialogProps = {
                        isVisible: true,
                        title: "Awesome decision!",
                        subText: "Someone from our team will reach out to you shortly.",
                        isBlocking: false,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                } else {
                    dialogProps = {
                        isVisible: true,
                        title: "Sorry to hear",
                        subText: "Thanks for trying us out though!  Hope to see you again soon!",
                        isBlocking: false,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                }
                setDialogModalWrapperPropsState(dialogProps);
                setComments("");
            }).catch((error) => {
                var dialogProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                    isBlocking: false,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapperPropsState(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
                setDialogModalWrapperPropsState(dialogProps);
            });
    }

    return (
        <>
            <Stack horizontal={true} horizontalAlign="space-evenly" tokens={mainStackTokens} styles={bodyStackItemStyles} wrap>
                <Stack.Item styles={itemStyles}>
                    <Stack horizontal={false}>
                        <Stack horizontal={true} verticalAlign="end" wrap>
                            <Stack.Item grow={3}>
                                <Text variant={"mega"}>
                                    ReAccess
                                </Text>
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <PrimaryButton
                                    text="Contact Me"
                                    ariaDescription="Contact me button."
                                    onClick={submitTrialEndedRequest} />
                            </Stack.Item>
                        </Stack>
                        <Stack.Item>
                            <Separator></Separator>
                        </Stack.Item>
                        <Stack.Item>
                            <Text>
                                Thank you for trying out ReAccess.  We hope it was everything you wanted it to be.  If not, please know we are continually working to make the best no-code platform fully integrated with Azure.  If you wish to continue using ReAccess, please contact sales by clicking the "Contact Me" button above.  If not, please drop us a line in the comments box on what we could do better to serve you.
                            </Text>
                        </Stack.Item>
                        <Stack.Item styles={commentItemStyles}>
                            <Stack horizontal={false} tokens={commentsStackTokens}>
                                <Stack.Item>
                                    <TextField
                                        multiline
                                        rows={6}
                                        value={comments}
                                        onChange={onChangeCommentsFieldValue}
                                        label="Comments" />
                                </Stack.Item>
                                <Stack.Item>
                                    <DefaultButton
                                        text="Submit"
                                        ariaDescription="Submit comments button."
                                        onClick={submitTrialEndedRequest}
                                        disabled={comments === ""} />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item styles={imageItemStyles}>
                    <Image {...imageProps} />
                </Stack.Item>
            </Stack>
        </>
    );
}