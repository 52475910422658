import React from "react";
import { useRecoilValue } from "recoil";
import { applicationManifestState } from "../../../recoil/atoms";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { ControlData } from "../../../model/manifest/ControlData";
import { FormRow } from "./FormRow";
import { Stack } from "@fluentui/react";
import { isAuditField } from "../../../services/fieldService";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { Form } from "../../../model/manifest/Form";
import { getForm } from "../../../services/manifestService";
import { ISubFormField } from "../../../interfaces/ISubFormField";

export interface FormPageProps {
    formId: string;
    tabId: string | undefined;
    applicationEntity: ApplicationEntity | undefined;
    disableControls: boolean;
    isDetailsView: boolean;
    editMode: boolean;
    shimmerControlsOnSubmit: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const FormPage: React.FC<FormPageProps> = (props: FormPageProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);

    const tabControlsToDisplay = (): Array<ControlData[]> => {
        var form: Form = getForm(applicationManifest, props.formId);
        if (form != null) {
            var controls: ControlData[] = [...form.Controls];
            if (props.tabId != null && props.tabId !== "") {
                controls = form.Controls.filter(i => i.TabId === props.tabId);
            }
            controls = controls.filter(i => i.Row != null && i.Column != null && !isAuditField(i));
            controls.sort(function (a, b) {
                if (a.Row != null && b.Row != null && a.Column != null && b.Column != null) {
                    return a.Row - b.Row || a.Column - b.Column;
                } else {
                    return 0;
                }
            });
            var controlsToSet: Array<ControlData[]> = [];
            controls.forEach((c) => {
                if (c.Row != null) {
                    if (controlsToSet[c.Row - 1] == null) {
                        controlsToSet[c.Row - 1] = [];
                    }
                    controlsToSet[c.Row - 1].push(c);
                }
            });
            return controlsToSet;
        }
        return [];
    }

    return (
        <>
            {(tabControlsToDisplay()).length > 0 &&
                <>
                    <Stack grow={tabControlsToDisplay().length}>
                        <>
                            {tabControlsToDisplay().map((value, index) => {
                                return (
                                    <Stack.Item key={"FormRow" + index}>
                                        <FormRow
                                            controls={value}
                                            applicationEntity={props.applicationEntity}
                                            shouldValidate={true}
                                            disableControls={props.disableControls}
                                            isDetailsView={props.isDetailsView}
                                            editMode={props.editMode}
                                            shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                            entityId={props.entityId}
                                            subFormField={props.subFormField}>
                                        </FormRow>
                                    </Stack.Item>
                                )
                            })}
                        </>
                    </Stack>
                </>
            }
        </>
    );
}