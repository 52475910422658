import { useEffect, useState } from "react";
import axios from "axios";
import { MeCallout } from "./MeCallout";
import { IPersonaSharedProps, Persona, PersonaSize, Shimmer, ShimmerElementType, ShimmerElementsGroup, getInitials, useTheme } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { CustomerSummaryDto } from "../../dtos/User/CustomerSummaryDto";
import { customerSummariesState } from "../../recoil/atoms";
import { useRecoilState } from "recoil";
import { Buffer } from "buffer/";

export const Me: React.FC = () => {
  const [myPersona, setMyPersona] = useState<IPersonaSharedProps>();
  const [companyName, setCompanyName] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [photoGraphCallComplete, setPhotoGraphCallComplete] = useState<boolean>(false);
  const [infoGraphCallComplete, setInfoGraphCallComplete] = useState<boolean>(false);
  const [showMeCallout, setShowMeCallout] = useState<boolean>(false);
  const [customerSummaries, setCustomerSummaries]: [CustomerSummaryDto[], (customerSummaries: CustomerSummaryDto[]) => void] =
    useRecoilState(customerSummariesState);
  const { instance } = useMsal();
  const accountInfo = instance.getActiveAccount();
  const theme = useTheme();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    var p: IPersonaSharedProps = {
      imageUrl: accountInfo != null && accountInfo.name != null ? "" : "./default_user_image.jpg",
      imageInitials: accountInfo != null && accountInfo.name != null ? getInitials(accountInfo.name, false) : "",
      imageShouldFadeIn: true,
      onClick: () => {
        setShowMeCallout(true);
      },
    };

    axios
      .get(`${window.ReAccessWeb.process.env.MICROSOFT_GRAPH_API_URL}/v1.0/me/photos/96x96/$value`, {
        responseType: "arraybuffer",
        cancelToken: source.token,
      })
      .then((result) => {
        var prefix = "data:" + result.headers["content-type"] + ";base64,";
        var avatar = Buffer.from(result.data, "binary").toString("base64");
        p.imageUrl = prefix + avatar;
        setMyPersona(p);
        setPhotoGraphCallComplete(true);
      })
      .catch((error) => {
        setMyPersona(p);
        setPhotoGraphCallComplete(true);
      });

    axios
      .get(`${window.ReAccessWeb.process.env.MICROSOFT_GRAPH_API_URL}/v1.0/me?$select=companyName,jobTitle`, { cancelToken: source.token })
      .then((result) => {
        setCompanyName(result.data.companyName);
        setJobTitle(result.data.jobTitle);
        setInfoGraphCallComplete(true);
      })
      .catch((error) => {
        setInfoGraphCallComplete(true);
      });

    axios
      .get("/CustomerSummary", { cancelToken: source.token })
      .then((result) => {
        var customerSummaryDtos: CustomerSummaryDto[] = result.data;
        setCustomerSummaries(customerSummaryDtos);
      })
      .catch((error) => {
        //if fails should we handle by showing nothing in the component below?
      });

    return function cleanup() {
      source.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!photoGraphCallComplete || !infoGraphCallComplete) {
      return;
    }
    var reAccessUserDto = {
      avatar: myPersona?.imageUrl,
      companyName: companyName,
      jobTitle: jobTitle,
    };
    axios
      .put("/ReAccessUser", reAccessUserDto, {
        cancelToken: source.token,
      })
      .catch((error) => {
        //if fails should we handle by showing nothing in the component below?
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoGraphCallComplete, infoGraphCallComplete]);

  const getCustomElements = (): JSX.Element => {
    return (
      <ShimmerElementsGroup
        shimmerElements={[
          { type: ShimmerElementType.circle, height: 32 },
          { type: ShimmerElementType.gap, height: 46, width: 16 },
        ]}
      />
    );
  };

  return (
    <Shimmer
      customElementsGroup={getCustomElements()}
      width={48}
      isDataLoaded={myPersona !== undefined}
      shimmerColors={{
        shimmer: theme.palette.themeTertiary,
        shimmerWave: theme.palette.themeSecondary,
        background: theme.semanticColors.bodyBackground,
      }}
    >
      {myPersona != null && (
        <>
          <Persona
            {...myPersona}
            id={"myPersona"}
            size={PersonaSize.size32}
            imageAlt={accountInfo != null && accountInfo.name ? accountInfo.name : "Picture of logged in user"}
          />
          {showMeCallout && myPersona && (
            <MeCallout
              myPersona={myPersona}
              companyName={companyName}
              jobTitle={jobTitle}
              customerSummaries={customerSummaries}
              close={() => {
                setShowMeCallout(false);
              }}
            ></MeCallout>
          )}
        </>
      )}
    </Shimmer>
  );
};
