import React, { } from "react";
import { Stack, ScrollablePane, mergeStyleSets, IStackItemStyles, IScrollablePaneStyles, IStackStyles, Text, IStackTokens } from "@fluentui/react";
import { AppCard } from "./AppCard";
import { AppCatalogCustomer } from "../../../dtos/AppCatalogCustomer";
import { AppCatalogTile } from "../../../dtos/AppCatalogTile";

export interface ICustomerManifestsProps {
    customer: AppCatalogCustomer;
}

export const CustomerManifests: React.FC<ICustomerManifestsProps> = (props: ICustomerManifestsProps) => {
    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };
    const stackStyle: IStackStyles = {
        root: {
            display: "flex",
            width: "100%"
        },
    };
    const classNames = mergeStyleSets({
        wrapper: {
            height: "340px",
            width: "100%",
            position: "relative",
            maxHeight: "inherit",
        },
        pane: {
            maxWidth: "100%"
        }
    });
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };

    const createContentArea = (item: AppCatalogTile) => (
        <Stack.Item key={item.Id} styles={cardStackItemStyles}>
            <AppCard
                appCatalogTile={item}>
            </AppCard>
        </Stack.Item>
    );
    const contentAreas = props.customer.AppCatalogTiles.map(createContentArea);

    return (
        <Stack styles={stackStyle} horizontal={false} tokens={themedMediumStackTokens}>
            <Stack.Item>
                <Text variant="xLarge">
                    {props.customer.Name}
                </Text>
            </Stack.Item>
            <Stack.Item>
                <div className={classNames.wrapper}>
                    <ScrollablePane styles={scrollablePaneStyles}>
                        <Stack styles={stackStyle} horizontal={true}>
                            {contentAreas}
                        </Stack>
                    </ScrollablePane>
                </div>
            </Stack.Item>
        </Stack>
    );
}

const cardStackItemStyles: IStackItemStyles = {
    root: {
        display: "flex",
        paddingRight: "20px"
    },
};