import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { dialogModalWrapperPropsState, navHeaderState, shimmerNavState } from "../../recoil/atoms";
import { IImageProps, ITextFieldStyles, IStackItemStyles, IStackTokens, ITextFieldStyleProps, Image, PrimaryButton, Stack, TextField, useTheme, Checkbox, Link, IStackStyles, Text, ITextStyles } from "@fluentui/react";
import getScreenSize from "../../services/getScreenSize";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { ValidationError } from "../../model/error/ValidationError";
import { IDialogModalWrapperProps } from "../../interfaces/DialogModal";
import axios from "axios";
import { TrialRequest } from "../../model/trial/TrialRequest";
import { MainBackgroundLeft } from "../unauthenticated/MainBackgroundLeft";

export const Register: React.FC = () => {
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const { instance } = useMsal();
    const accountInfo = instance.getActiveAccount();
    const screenSize: number = getScreenSize();
    const theme = useTheme();
    const navigate = useNavigate();
    const defaultValues = {
        email: accountInfo!.username,
        firstName: "",
        lastName: "",
        companyName: "",
        appCode: "",
        legal: false,
        privacy: false
    }
    const { handleSubmit, control, formState: { errors, isDirty, isValid, isSubmitting }, setError } = useForm({ defaultValues: defaultValues, mode: "onChange" });

    useEffect(() => {
        setNavHeader("Register");
        setShimmerNav(true);
    }, [setNavHeader, setShimmerNav]);

    const logoProps: IImageProps = {
        src: './1x/Logo + by intact_1 3.png',
        width: "100%"
    };

    const rightStackItemStyles: IStackStyles = {
        root: {
            display: "flex",
            justifyContent: 'center',
            width: screenSize >= 768 ? "40%" : "100%",
            backgroundColor: "#F4F6F8"
        }
    };

    const powredByItemStyles: ITextStyles = {
        root: {
            color: "#AAB8C7",
            paddingBottom: "30px"
        }
    };

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };

    function getStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles> {
        return {
            fieldGroup: [
                { width: 300 },
                { display: "flex" }
            ]
        };
    }

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    const renderLabelWithLinkToLegalStatement = (): any => {
        return (
            <span>
                I have read and accept the{' '}
                <Link href="https://www.myreaccess.com/legal" target="_blank" underline>
                    Legal Statement
                </Link>
                {' '}located on the My ReAccess website.
            </span>
        );
    }

    const renderLabelWithLinkToPrivacyStatement = (): any => {
        return (
            <span>
                I have read and accept the{' '}
                <Link href="https://www.myreaccess.com/privacy-statement" target="_blank" underline>
                    Privacy Statement
                </Link>
                {' '}located on the My ReAccess website.
            </span>
        );
    }

    const submitForm = async (data: any) => {
        var trialRequest: TrialRequest = {
            Email: accountInfo!.username,
            FirstName: data.firstName,
            LastName: data.lastName,
            CompanyName: data.companyName,
            AppCode: data.appCode
        }
        await axios.post("/Register", trialRequest)
            .then(async (result: any) => {
                navigate(`/Welcome`, { replace: true });
            }).catch((error) => {
                var dialogProps: IDialogModalWrapperProps | undefined;
                if (error.response.status === 422) {
                    var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                    if (validationErrors.length === 1 && validationErrors[0].Title !== "Trial Entity Error") {
                        dialogProps = {
                            isVisible: true,
                            title: validationErrors[0].Title,
                            subText: validationErrors[0].Message,
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    } else {
                        //set errors on controls
                        validationErrors.forEach((validationError: ValidationError) => {
                            setError(validationError.GroupIdentifier as any, {
                                type: "manual",
                                message: validationError.Message
                            })
                        });
                    }
                } else {
                    dialogProps = {
                        isVisible: true,
                        title: "Uh oh",
                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                        isBlocking: false,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
    }

    return (
        <>
            <Stack horizontal={true} styles={bodyStackItemStyles}>
                <MainBackgroundLeft></MainBackgroundLeft>
                <Stack.Item styles={rightStackItemStyles}>
                    <Stack horizontal={false} verticalAlign="space-between">
                        <Stack.Item>
                            <Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <Stack horizontalAlign={"center"} tokens={themedMediumStackTokens}>
                                    <Stack.Item>
                                        <Image {...logoProps} />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Controller
                                            control={control}
                                            name="email"
                                            rules={{
                                                required: "Email is required",
                                                maxLength: { value: 100, message: "The max length of Email is 100" }
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <TextField
                                                    label="Email"
                                                    required={true}
                                                    styles={getStyles}
                                                    value={value}
                                                    disabled={accountInfo != null && accountInfo.username != null}
                                                    errorMessage={errors != null && errors["email"]?.message != null ? errors["email"].message : undefined}
                                                    onBlur={onBlur}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        onChange(newValue);
                                                    }} />
                                            )}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Controller
                                            control={control}
                                            name="firstName"
                                            rules={{
                                                required: "First Name is required",
                                                maxLength: { value: 100, message: "The max length of First Name is 100" }
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <TextField
                                                    label="First Name"
                                                    required={true}
                                                    styles={getStyles}
                                                    value={value}
                                                    errorMessage={errors != null && errors["firstName"]?.message != null ? errors["firstName"].message : undefined}
                                                    onBlur={onBlur}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        onChange(newValue);
                                                    }} />
                                            )}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Controller
                                            control={control}
                                            name="lastName"
                                            rules={{
                                                required: "Last Name is required",
                                                maxLength: { value: 100, message: "The max length of Last Name is 100" }
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <TextField
                                                    label="Last Name"
                                                    required={true}
                                                    styles={getStyles}
                                                    value={value}
                                                    errorMessage={errors != null && errors["lastName"]?.message != null ? errors["lastName"].message : undefined}
                                                    onBlur={onBlur}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        onChange(newValue);
                                                    }} />
                                            )}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Controller
                                            control={control}
                                            name="companyName"
                                            rules={{
                                                maxLength: { value: 150, message: "The max length of Company/Organization Name is 150" }
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <TextField
                                                    label="Company/Organization Name"
                                                    styles={getStyles}
                                                    value={value}
                                                    errorMessage={errors != null && errors["companyName"]?.message != null ? errors["companyName"].message : undefined}
                                                    onBlur={onBlur}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        onChange(newValue);
                                                    }} />
                                            )}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Controller
                                            control={control}
                                            name="appCode"
                                            rules={{
                                                maxLength: { value: 10, message: "The max length of App Code is 10" }
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <TextField
                                                    label="App Code"
                                                    styles={getStyles}
                                                    value={value}
                                                    errorMessage={errors != null && errors["appCode"]?.message != null ? errors["appCode"].message : undefined}
                                                    onBlur={onBlur}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        onChange(newValue);
                                                    }} />
                                            )}
                                        />
                                    </Stack.Item>
                                    <Stack.Item style={{ maxWidth: "300px" }}>
                                        <Controller
                                            control={control}
                                            name="legal"
                                            rules={{
                                                required: "Legal statement is required",
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <Checkbox
                                                    required={true}
                                                    onRenderLabel={renderLabelWithLinkToLegalStatement}
                                                    checked={value}
                                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                        onChange(checked);
                                                    }} />
                                            )}
                                        />
                                    </Stack.Item>
                                    <Stack.Item style={{ maxWidth: "300px" }}>
                                        <Controller
                                            control={control}
                                            name="privacy"
                                            rules={{
                                                required: "Privacy statement is required",
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <Checkbox
                                                    required={true}
                                                    onRenderLabel={renderLabelWithLinkToPrivacyStatement}
                                                    checked={value}
                                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                        onChange(checked);
                                                    }} />
                                            )}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <PrimaryButton
                                            text="Submit"
                                            ariaDescription="Submit register button."
                                            type="submit"
                                            style={{ width: "250px" }}
                                            disabled={!isValid || !isDirty || isSubmitting} />
                                    </Stack.Item>
                                </Stack>
                            </form>
                        </Stack.Item>
                        <Stack.Item style={{ marginBottom: "30px" }}>
                            <Stack horizontal={false}>
                                <Stack.Item align="center">
                                    <Text variant={screenSize < 1200 ? "mediumPlus" : "large"} styles={powredByItemStyles}>
                                        Powered by Intact
                                    </Text>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack >
        </>
    );
}