import React, { useEffect, useState } from "react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Dropdown, DropdownMenuItemType, IDropdownOption, TooltipHost } from "@fluentui/react";
import { ConnectForm } from "../../../form/ConnectForm";
import { Controller, FieldValues, UseFormReturn, useFormContext, useWatch } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { applicationManifestState, dataSourceItemSelector, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { getRules } from "../../../../../services/ruleService";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { useId } from "@fluentui/react-hooks";
import axios from "axios";
import { DataSourceItem } from "../../../../../model/manifest/DataSourceItem";
import { useParams } from "react-router-dom";
import { removeDashFromGuid } from "../../../../../services/stringManipulationService";
import { DataSourceItemCascade } from "../../../../../model/manifest/DataSourceItemCascade";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { buildFormPropertyName, readOnlyTextForDataSource } from "../../../../../services/fieldService";
import { getForm } from "../../../../../services/manifestService";
import { Form } from "../../../../../model/manifest/Form";
import { cloneDeep, isEqual } from "lodash";
import { ReadOnlyDropdownField } from "../../ReadOnlyDropdownField";

export interface DropdownChildFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    isSearch: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const DropdownChildField: React.FC<DropdownChildFieldProps> = (props: DropdownChildFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([]);
    const [dataSourceItems, setDataSourceItems]: [DataSourceItem[], (dataSourceItems: DataSourceItem[]) => void] = useRecoilState(dataSourceItemSelector(props.control.Id));
    const cascadeParentValue: string | undefined = useWatch({ name: buildFormPropertyName(props.control.CascadeParentControlId, false, undefined, props.subFormField) });
    const parentDataSourceItems: DataSourceItem[] = useRecoilValue(dataSourceItemSelector(props.control.CascadeParentControlId));
    const [previousCascadeValue, setPreviousCascadeValue] = useState<string | string[] | undefined>();
    const { getValues, setValue, formState: { isDirty } } = useFormContext();
    var { appId } = useParams();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const dropdownId = useId("dropdownChild");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${dropdownId}`
    };

    useEffect(() => {
        return function cleanup() {
            source.cancel();
            setDataSourceItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var options: IDropdownOption[] = [];
        if (parentDataSourceItems == null || parentDataSourceItems.length === 0) {
            options.unshift({ key: "", text: "Select an option" });
            setDropdownOptions(options);
            return;
        }
        if (cascadeParentValue == null || cascadeParentValue === "" || cascadeParentValue.length === 0) {
            return;
        }
        var form: Form = getForm(applicationManifest, props.control.PageId);
        var parentControl = form.Controls.find(c => c.Id === props.control.CascadeParentControlId)!;

        axios.get(`/DataSource/GetCascade?ApplicationId=${appId}&PageId=${props.control.PageId}&ChildControlId=${props.control.Id}&ParentSelectedEntityId=${cascadeParentValue}`,
            { cancelToken: source.token })
            .then((result) => {
                var dataSourceItems: DataSourceItemCascade[] = result.data;
                if (parentControl.FieldType === 39) {
                    dataSourceItems.forEach((item) => {
                        var parentControlId = removeDashFromGuid(item.ParentControlId);
                        if (options.findIndex(i => i.key === `${parentControlId}-header`) === -1) {
                            var parentDataSourceItem = parentDataSourceItems.find(i => i.Value === parentControlId)!;
                            options.push({ key: `${parentControlId}-divider`, text: '-', itemType: DropdownMenuItemType.Divider },);
                            options.push({ key: `${parentControlId}-header`, text: parentDataSourceItem.Text, itemType: DropdownMenuItemType.Header },);
                        }
                        options.push({ key: item.Value, text: item.Text });
                    });
                } else {
                    options = dataSourceItems.map(i => ({ key: i.Value, text: i.Text }));
                }
                options.unshift({ key: "", text: "Select an option" });
                setDropdownOptions(options);
                setDataSourceItems(dataSourceItems);
            })
            .catch((error) => {
                options.unshift({ key: "", text: "Select an option" });
                setDropdownOptions(options);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentDataSourceItems]);

    useEffect(() => {
        setPreviousCascadeValue(cascadeParentValue);
        var options: IDropdownOption[] = [];
        if (cascadeParentValue == null || cascadeParentValue === "") {
            if (isDirty) {
                setValue(buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField), "", {
                    shouldValidate: true,
                    shouldDirty: true,
                });
            }

            options.unshift({ key: "", text: "Select an option" });
            setDropdownOptions(options);
            return;
        }

        if (isEqual(cascadeParentValue, previousCascadeValue)) {
            return;
        }

        var form: Form = getForm(applicationManifest, props.control.PageId);
        var parentControl = form.Controls.find(ii => ii.Id === props.control.CascadeParentControlId)!;
        setDropdownOptions([{ key: "", text: "Loading..." }]);
        
        axios.get(`/DataSource/GetCascade?ApplicationId=${appId}&PageId=${props.control.PageId}&ChildControlId=${props.control.Id}&ParentSelectedEntityId=${cascadeParentValue}`,
            { cancelToken: source.token })
            .then((result) => {
                var dataSourceItems: DataSourceItemCascade[] = result.data;
                if (parentControl.FieldType === 39) {
                    dataSourceItems.forEach((item) => {
                        var parentControlId = removeDashFromGuid(item.ParentControlId);
                        if (options.findIndex(i => i.key === `${parentControlId}-header`) === -1) {
                            var parentDataSourceItem = parentDataSourceItems.find(i => i.Value === parentControlId)!;
                            options.push({ key: `${parentControlId}-divider`, text: '-', itemType: DropdownMenuItemType.Divider },);
                            options.push({ key: `${parentControlId}-header`, text: parentDataSourceItem.Text, itemType: DropdownMenuItemType.Header },);
                        }
                        options.push({ key: item.Value, text: item.Text });
                    });
                } else {
                    options = dataSourceItems.map(i => ({ key: i.Value, text: i.Text }));
                }
                options.unshift({ key: "", text: "Select an option" });
                setDropdownOptions(options);
                setDataSourceItems(dataSourceItems);

                var myValue: string = getValues(buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField));
                if (myValue != null && myValue !== "") {
                    if (dataSourceItems.findIndex(i => i.Value === myValue) === -1) {
                        //this could be refactored to use the update method inside props.subFormField
                        setValue(buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField), "", {
                            shouldValidate: true,
                            shouldDirty: true,
                        });
                    }
                }
            })
            .catch((error) => {
                options.unshift({ key: "", text: "Select an option" });
                setDropdownOptions(options);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cascadeParentValue]);

    return (
        <ConnectForm>
            {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                <>
                    <Controller
                        control={methods.control}
                        rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                        name={buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField)}
                        render={({ field: { onChange, value } }) => (
                            <>
                                {!props.readOnly &&
                                    <TooltipHost
                                        content={props.control.ToolTipText}
                                        id={tooltipId}
                                        calloutProps={calloutProps}
                                    >
                                        <Dropdown
                                            id={dropdownId}
                                            label={props.control.LabelText}
                                            selectedKey={value}
                                            disabled={props.disabled}
                                            required={props.isRequired}
                                            options={dropdownOptions}
                                            errorMessage={
                                                methods.formState.errors != null &&
                                                    methods.formState.errors[props.control.Id!] != null &&
                                                    methods.formState.errors[props.control.Id!]!.message != null ?
                                                    methods.formState.errors[props.control.Id!]!.message?.toString() :
                                                    undefined}
                                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                                //React Hook Form requires you call the array Update method when editing an array as well as the onChange method.
                                                if (props.subFormField != null) {
                                                    var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                    var updateableField = cloneDeep(myCurrentValue);
                                                    updateableField[props.control.Id!] = option?.key;
                                                    props.subFormField.update(props.subFormField.Index, updateableField);
                                                }
                                                onChange(option?.key);
                                            }} />
                                    </TooltipHost>
                                }
                                {props.readOnly &&
                                     <ReadOnlyDropdownField
                                        control={props.control}
                                        textValue={readOnlyTextForDataSource(value, dataSourceItems)}
                                        value={value}
                                 />
                                }
                            </>
                        )} />
                </>
            }
        </ConnectForm>
    );
}