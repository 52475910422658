import React from "react";
import { DefaultButton, Shimmer, ShimmerElementsGroup, ShimmerElementType, useTheme } from "@fluentui/react";
import { ConnectForm } from "./ConnectForm";
import { useNavigate, useParams } from "react-router-dom";

export interface BackToSearchButtonProps {
    shimmerControlsOnSubmit: boolean;
    setShimmerControlsOnSubmit: (shimmerControlsOnSubmit: boolean) => void;
}

export const BackToSearchButton: React.FC<BackToSearchButtonProps> = (props: BackToSearchButtonProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    var { appId, formId } = useParams();

    const getButtonShimmer = (): JSX.Element => {
        return (
            <div>
                <ShimmerElementsGroup
                    shimmerElements={[
                        { type: ShimmerElementType.line, height: 32 }
                    ]}
                />
            </div>
        );
    };

    return (
        <>
            <ConnectForm>
                {(methods: any) => {
                    return <Shimmer
                        customElementsGroup={getButtonShimmer()}
                        width={160}
                        isDataLoaded={props.shimmerControlsOnSubmit === false ? false : true}
                        shimmerColors={{
                            shimmer: theme.palette.themeTertiary,
                            shimmerWave: theme.palette.themeSecondary,
                            background: theme.semanticColors.bodyBackground,
                        }}>
                        <DefaultButton
                            text="Back To Search"
                            iconProps={{ iconName: "NavigateBack" }}
                            onClick={() => {
                                props.setShimmerControlsOnSubmit(false);
                                navigate(`/Application/${appId}/Form/${formId}/Search`, { replace: true });
                            }}
                        />
                    </Shimmer>
                }}
            </ConnectForm>
        </>
    );
}