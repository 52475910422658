import React, { useEffect, useState } from "react";
import {
    DetailsList,
    DetailsListLayoutMode,
    DocumentCard,
    Dropdown,
    getInitials,
    IColumn,
    IDocumentCardStyles,
    IDropdownOption,
    IIconProps,
    IStackStyles,
    IStackTokens,
    PrimaryButton,
    SearchBox,
    SelectionMode,
    Stack,
    Text
} from "@fluentui/react";
import axios, { AxiosRequestConfig } from "axios";
import { useParams } from "react-router-dom";
import { CustomerUserSearchDto } from "../../../dtos/User/CustomerUserSearchDto";
import { ICustomerUserGridItem } from "../../../interfaces/users/ICustomerUserGridItem";
import { UserPersonaGridItem } from "../gridLayout/UserPersonaGridItem";
import { getCustomerUserRoleOptions } from "../../../services/roleService";
import { NewCustomerUserPanel } from "./NewCustomerUserPanel";
import { ViewCustomerUserPanel } from "./ViewCustomerUserPanel";
import { Pagination } from "@fluentui/react-experiments";
import { CustomerUserChangeDialog } from "./CustomerUserChangeDialog";

export const CustomerUsers: React.FC = () => {
    const [customerUserGridItems, setCustomerUserGridItems] = useState<ICustomerUserGridItem[]>([]);
    const [customerUserGridItemsToDisplay, setCustomerUserGridItemsToDisplay] = useState<ICustomerUserGridItem[]>([]);
    const [filteredCustomerUserGridItems, setFilteredCustomerUserGridItems] = useState<ICustomerUserGridItem[]>([]);
    const [isAddCustomerUserOpen, setAddCustomerUserOpen] = useState(false);
    const [customerUserToView, setCustomerUserToView] = useState<ICustomerUserGridItem | undefined>();
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const [searchTextFilter, setSearchTextFilter] = useState<string>("");
    const [customerUserToUpdate, setCustomerUsertoUpdate] = useState<ICustomerUserGridItem | undefined>();
    var { customerId } = useParams();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const itemsPerPage = 10;
    const filterIcon: IIconProps = { iconName: "Filter" };

    useEffect(() => {
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/CustomerUser/GetAll",
            params: {
                customerId: customerId
            },
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                var customerUserSearchDtos: CustomerUserSearchDto[] = result.data;
                var customerUserGridItems: ICustomerUserGridItem[] = [];
                customerUserSearchDtos.forEach(user => {
                    var customerUserGridItem: ICustomerUserGridItem = {
                        Avatar: user.Avatar == null && user.FullName == null ? "./default_user_image.jpg" : user.Avatar,
                        CompanyName: user.CompanyName,
                        CustomerUserRoleType: user.CustomerUserRoleType,
                        Email: user.Email,
                        FullName: user.FullName,
                        ImageInitials: user.FullName != null
                            ? getInitials(user.FullName, false)
                            : "",
                        JobTitle: user.JobTitle,
                        PreviousRole: user.CustomerUserRoleType,
                        Disabled: false
                    }
                    customerUserGridItems.push(customerUserGridItem);
                });
                customerUserGridItems = sortCustomerUsers(customerUserGridItems);
                setCustomerUserGridItems(customerUserGridItems);
            })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "Customer Users Component cleaned up");
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //text search on items.
        var matchingItems: any[] = [];
        var items = [...customerUserGridItems];
        for (var item of items) {
            let textMatch = false;
            if (searchTextFilter === "" ||
                (item.FullName != null && item.FullName.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1) ||
                (item.CustomerUserRoleType != null && item.CustomerUserRoleType.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1)) {
                textMatch = true;
            }
            if (textMatch) {
                matchingItems.push(item);
            }
        }
        setFilteredCustomerUserGridItems(matchingItems);

        //calculate which items to display
        var skipToIndex = selectedPageIndex * itemsPerPage;
        var takeToIndex = skipToIndex + itemsPerPage;
        setCustomerUserGridItemsToDisplay(matchingItems.slice(skipToIndex, takeToIndex));

        //check to see if page count is less than current selection.  if so adjust it down accordingly
        var pageCount = Math.ceil(matchingItems.length / itemsPerPage);
        var pageCountIndex = pageCount - 1;
        if (pageCountIndex < 0) {
            setSelectedPageIndex(0);
        } else if (selectedPageIndex + 1 > pageCountIndex) {
            setSelectedPageIndex(pageCountIndex);
        }
    }, [customerUserGridItems, selectedPageIndex, searchTextFilter]);

    const sortCustomerUsers = (customerUsersArray: ICustomerUserGridItem[]): ICustomerUserGridItem[] => {
        return customerUsersArray.sort((a, b) => a.Email.localeCompare(b.Email));
    }

    const addedUser = (customerUser: ICustomerUserGridItem): void => {
        var customerUsersArray = [...customerUserGridItems];
        customerUsersArray.push(customerUser);
        customerUsersArray = sortCustomerUsers(customerUsersArray);
        setCustomerUserGridItems(customerUsersArray);
        setAddCustomerUserOpen(false);
    };

    const deleteCustomerUser = (): void => {
        var customerUsersArray = [...customerUserGridItems];
        customerUsersArray = customerUsersArray.filter(i => i.Email !== customerUserToView!.Email);
        customerUsersArray = sortCustomerUsers(customerUsersArray);
        setCustomerUserGridItems(customerUsersArray);
        setCustomerUserToView(undefined);
    };

    const updateCustomer = (rowItem: ICustomerUserGridItem, selectedKey: string | undefined): void => {
        const options: AxiosRequestConfig = {
            method: "put",
            url: "/CustomerUser/Put",
            params: { customerId: customerId },
            data: {
                customerId: customerId,
                emailAddress: rowItem.Email,
                customerUserRoleType: rowItem.CustomerUserRoleType,
                selectedKey: selectedKey
            },
            cancelToken: source.token
        };
        axios(options)
            .then(() => {
                var permissions = [...customerUserGridItems];
                rowItem = permissions.find(i => i.Email === rowItem.Email)!;
                rowItem.PreviousRole = "";
                rowItem.Disabled = false;
                setCustomerUserGridItems(permissions);
            })
            .catch((error) => {
                if (error.response != null) {
                    var permissions = [...customerUserGridItems];
                    rowItem = permissions.find(i => i.Email === rowItem.Email)!;
                    rowItem.CustomerUserRoleType = rowItem.PreviousRole;
                    rowItem.Disabled = false;
                    setCustomerUserGridItems(permissions);
                }
            });
    };

    const columns: IColumn[] = [
        {
            key: "View",
            name: "",
            fieldName: "view",
            minWidth: 75,
            maxWidth: 75,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "boolean",
            onRender: (item: ICustomerUserGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <PrimaryButton
                            text="View"
                            onClick={() => { setCustomerUserToView(item); }}
                            allowDisabledFocus
                        />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 225,
            maxWidth: 525,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: ICustomerUserGridItem) => {
                return <UserPersonaGridItem
                    avatar={item.Avatar}
                    fullName={item.FullName}
                    emailAddress={item.Email}
                />
            },
            isPadded: true,
        },
        {
            key: "Role",
            name: "Role",
            fieldName: "role",
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: ICustomerUserGridItem) => {
                var isDisabled = item.Disabled || item.CustomerUserRoleType === "Owner";

                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Dropdown
                            selectedKey={item.CustomerUserRoleType}
                            disabled={isDisabled}
                            options={getCustomerUserRoleOptions()}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                var permissions = [...customerUserGridItems];
                                var rowItem = permissions.find(i => i.Email === item.Email)!;
                                rowItem.PreviousRole = rowItem.CustomerUserRoleType;
                                rowItem.CustomerUserRoleType = option!.key.toString();
                                rowItem.Disabled = true;
                                setCustomerUserGridItems(permissions);

                                if (rowItem.CustomerUserRoleType === "User") {
                                    setCustomerUsertoUpdate(rowItem);
                                } else {
                                    updateCustomer(rowItem, undefined);
                                }
                            }} />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "CompanyName",
            name: "Company Name",
            fieldName: "companyName",
            minWidth: 125,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "number",
            onRender: (item: ICustomerUserGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {item.CompanyName}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        }
    ];

    const getPageCount = (): number => {
        return Math.ceil(customerUserGridItems.length / itemsPerPage);
    }

    const pageChange = (index: number): void => {
        setSelectedPageIndex(index);
    }
    
    const updateCustomerUserFromSelection = (selectedKey :string): void => {
        updateCustomer(customerUserToUpdate!, selectedKey);
        setCustomerUsertoUpdate(undefined);
    };

    const cancelUpdate = (): void => {
        var customerUsersArray = [...customerUserGridItems];
        var customerUser = customerUsersArray.find(i => i.Email === customerUserToUpdate!.Email)!;
        customerUser.CustomerUserRoleType = customerUser.PreviousRole;
        customerUser.Disabled = false;
        setCustomerUserGridItems(customerUsersArray);
        setCustomerUsertoUpdate(undefined);
    };

    return (
        <Stack tokens={themedMediumStackTokens}>
            <Stack.Item>
                <Text variant={"xxLarge"}>
                    Customer Users
                </Text>
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={themedSmallStackTokens}>
                    <DocumentCard styles={documentCardStyles}>
                        <Stack.Item>
                            <Stack horizontal={true} horizontalAlign="space-between" tokens={themedSmallStackTokens} wrap>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <Text variant={"mediumPlus"}>
                                            Total
                                        </Text>
                                        <div className="bolt-pill">
                                            <div className="bolt-pill-content">
                                                {customerUserGridItems != null ? customerUserGridItems.length : ""}
                                            </div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <Stack.Item>
                                            <SearchBox
                                                placeholder="Filter"
                                                iconProps={filterIcon}
                                                value={searchTextFilter}
                                                onChange={(_, newValue) => {
                                                    setSearchTextFilter(newValue || "");
                                                }}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <PrimaryButton
                                                text="Add User to Account"
                                                ariaDescription="Add new user to account button."
                                                onClick={() => setAddCustomerUserOpen(true)}
                                                allowDisabledFocus />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack tokens={themedSmallStackTokens}>
                            <Stack.Item>
                                <DetailsList
                                    items={customerUserGridItemsToDisplay != null ? customerUserGridItemsToDisplay : []}
                                    compact={true}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Pagination
                                    selectedPageIndex={selectedPageIndex}
                                    pageCount={getPageCount()}
                                    itemsPerPage={itemsPerPage}
                                    totalItemCount={filteredCustomerUserGridItems.length}
                                    format={"buttons"}
                                    previousPageAriaLabel={"previous page"}
                                    nextPageAriaLabel={"next page"}
                                    firstPageAriaLabel={"first page"}
                                    lastPageAriaLabel={"last page"}
                                    pageAriaLabel={"page"}
                                    selectedAriaLabel={"selected"}
                                    onPageChange={pageChange} />
                            </Stack.Item>
                        </Stack>
                    </DocumentCard>
                </Stack>
            </Stack.Item>
            {isAddCustomerUserOpen &&
                <NewCustomerUserPanel
                    customerUsers={customerUserGridItems}
                    addCustomerUser={addedUser}
                    dismissPanel={() => { setAddCustomerUserOpen(false) }}>
                </NewCustomerUserPanel>
            }
            {customerUserToView != null &&
                <ViewCustomerUserPanel
                    customerUser={customerUserToView}
                    deleteCustomerUser={deleteCustomerUser}
                    dismissPanel={() => { setCustomerUserToView(undefined) }}>
                </ViewCustomerUserPanel>
            }
            {customerUserToUpdate != null &&
                <CustomerUserChangeDialog
                    update={updateCustomerUserFromSelection}
                    cancel={cancelUpdate}
                />
            }
        </Stack>
    );
}

const columnStackStyles: IStackStyles = {
    root: {
        height: "100%"
    }
};

const themedMediumStackTokens: IStackTokens = {
    childrenGap: "m",
    padding: "m",
};

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};

const documentCardStyles: IDocumentCardStyles = {
    root: {
        boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
        borderRadius: "4px",
        maxWidth: "",
        minWidth: ""
    }
};