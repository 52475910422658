import { ControlData } from "../model/manifest/ControlData";
import { IDropdownOption, ITag } from "@fluentui/react";
import { DataSource } from "../model/manifest/DataSource";
import { removeDashFromGuid } from "./stringManipulationService";
import { DataSourceItem } from "../model/manifest/DataSourceItem";
import { ISubFormField } from "../interfaces/ISubFormField";
import { StringConstants } from "../constants/StringConstants";

/**
 * Determines if a control is an audit field.
 * @param controlData 
 * @returns A boolean indicating if the control is a dropdown field.
 * @example isAuditField({FieldType: 18}) returns true
 */
export const isAuditField = (controlData: ControlData): boolean => {
    if (controlData.FieldType === 18 ||
        controlData.FieldType === 19 ||
        controlData.FieldType === 20 ||
        controlData.FieldType === 21)
        return true;

    return false;
}

/**
 * Determins if a control is and audit date time field.
 * @param controlData 
 * @returns A boolean indicating if the control is an audit date time field.
 * @example isAuditDateTimeField({FieldType: 19}) returns true
 */
export const isAuditDateTimeField = (controlData: ControlData): boolean => {
    if (controlData.FieldType === 19 ||
        controlData.FieldType === 21)
        return true;

    return false;
}

/**
 * Creates the formatted dropdown options for the Dropdown and Type Ahead Field.
 * If the form is in search mode and their is a default search value not included in the list this funciton will add it to the set of selectable options.
 * @param dataSourceItems The list of data source items.
 * @param dataSource The data source.
 * @param defaultSearchTextValue The default search text value.
 * @param entityId The entity id.
 * @param isSearch If the form is in search mode.
 * @param addSelectAnOption If the form should add a "Select an option" option.
 * @returns The formatted dropdown options.
 * @example getFormattedOptions([{Value: "1", Text: "One"}, {Value: "2", Text: "Two"}], {PageId: "1"}, "Three", "4", true, true) returns [{key: "1", text: "One"}, {key: "2", text: "Two"}, {key: "Three", text: "Three"}]
 */
export const getFormattedOptions = (
    dataSourceItems: DataSourceItem[] | undefined,
    dataSource: DataSource,
    defaultSearchTextValue: string | undefined,
    entityId: string | undefined,
    isSearch: boolean,
    addSelectAnOption: boolean): IDropdownOption[] => {
    if (dataSourceItems == null) {
        return ([{ key: "", text: "Loading..." }]);
    }
    var dropdownOptions: IDropdownOption[] = [];
    if (dataSource != null) {
        if (dataSource.PageId != null && dataSource.PageId !== "") {
            //remove itself from the list of options (by entityId) if form data source
            if (entityId != null && entityId !== "") {
                var textEntityGuid = removeDashFromGuid(entityId);
                dropdownOptions = dataSourceItems.filter(i => i.Value !== textEntityGuid).map(i => ({ key: i.Value, text: i.Text }));
            } else {
                dropdownOptions = dataSourceItems.map(i => ({ key: i.Value, text: i.Text }));
            }
        } else {
            dropdownOptions = dataSourceItems.map(i => ({ key: i.Value, text: i.Text }));
        }
    } else {
        //if no data source, just use the items - this happens with child fields that use grids instead of data sources.
        dropdownOptions = dataSourceItems.map(i => ({ key: i.Value, text: i.Text }));
    }

    if (addSelectAnOption) {
        dropdownOptions.unshift({ key: "", text: "Select an option" });
    }

    //return options if no further processing required.
    if (!isSearch || defaultSearchTextValue == null || defaultSearchTextValue === "") { return dropdownOptions; }

    //check to see if item is already in list
    var foundOption = dropdownOptions.find(i => i.key === defaultSearchTextValue);
    if (foundOption != null) { return dropdownOptions; }

    //add extra default search option
    var option: IDropdownOption = {
        key: defaultSearchTextValue,
        text: defaultSearchTextValue
    }
    dropdownOptions.push(option);

    return dropdownOptions;
}

/**
 * Creates the formatted dropdown options for the Type and Search Field.
 * @param dataSource The data source.
 * @param entityId The entity id.
 * @param dataSourceItems The list of data source items.
 * @returns The formatted dropdown options.
 * @example getFormattedOptionsForTypeAhead([{Value: "1", Text: "One"}, {Value: "2", Text: "Two"}], {PageId: "1"}) returns [{key: "1", text: "One"}, {key: "2", text: "Two"}]
 */
export const getFormattedTags = (
    dataSource: DataSource,
    entityId: string | undefined,
    dataSourceItems: DataSourceItem[]): ITag[] => {
    var tags: ITag[] = [];
    if (dataSource != null) {
        if (dataSource.PageId != null && dataSource.PageId !== "" && dataSourceItems != null) {
            //remove itself from the list of options (by entityId) if form data source
            if (entityId != null && entityId !== "") {
                var textEntityGuid = removeDashFromGuid(entityId);
                tags = dataSourceItems.filter(i => i.Value !== textEntityGuid).map(i => ({ key: i.Value, name: i.Text }));
            } else {
                tags = dataSourceItems.map(i => ({ key: i.Value, name: i.Text }));
            }
        } else {
            tags = dataSourceItems.map(i => ({ key: i.Value, name: i.Text }));
        }
    }
    return tags;
}

/*
 * Returns the text value for a data source item.
 * @param value The value of the data source item.
 * @param dataSourceItems The list of data source items.
 * @returns The text value of the data source item.
 * @example readOnlyTextForDataSource("1", [{Value: "1", Text: "One"}, {Value: "2", Text: "Two"}]) returns "One"
 */
export const readOnlyTextForDataSource = (
    value: string | undefined,
    dataSourceItems: DataSourceItem[] | undefined): string => {
    if (dataSourceItems == null) {
        return "Loading...";
    }
    return dataSourceItems?.find(i => i.Value === value)?.Text ?? "";
}

/*
 * Returns the text value for a multi select data source item.
 * @param values The values of the data source items.
 * @param dataSourceItems The list of data source items.
 * @returns The text value of the data source items.
 * @example readOnlyTextForMultiSelectDataSource(["1", "2"], [{Value: "1", Text: "One"}, {Value: "2", Text: "Two"}]) returns "One, Two"
 */
export const readOnlyTextForMultiSelectDataSource = (
    values: string[] | undefined,
    dataSourceItems: DataSourceItem[] | undefined): string => {
    if (dataSourceItems == null) {
        return "Loading...";
    }

    if (values == null || values.length === 0) return "";

    var options: string[] = [];
    values.forEach(value => {
        var option = dataSourceItems?.find(i => i.Value === value)?.Text;
        if (option != null) {
            options.push(option);
        }
    })

    return options.join(", ");
}

/**
 * Returns Yes or No based on the text value of a boolean.
 * @param booleanTextValue The value of the boolean in text format.
 * @returns Yes or No
 * @example getBooleanTextValueFromString("true") returns "Yes"
 */
export const getBooleanTextValueFromString = (booleanTextValue: string | null): string => {
    if (booleanTextValue != null && booleanTextValue.toLowerCase() === "true") {
        return "Yes";
    }
    return "No";
}

/**
 * Returns Yes or No based on the value of a boolean.
 * @param booleanValue The value of the boolean.
 * @returns Yes or No
 * @example getBooleanTextValue(true) returns "Yes"
 */
export const getBooleanTextValue = (booleanValue: boolean | null): string => {
    if (booleanValue != null && booleanValue.toString().toLowerCase() === "true") {
        return "Yes";
    }
    return "No";
}

export const buildFormPropertyName = (
    controlId: string,
    isTime: boolean,
    commentIndex: number | undefined,
    subFormField: ISubFormField | undefined): string => {
    var propertyName: string = controlId;
    if (commentIndex != null) {
        propertyName = `${propertyName}.${commentIndex}`;
    }
    if (isTime) {
        propertyName = `${StringConstants.TimePickerId}${propertyName}`;
    }
    if (subFormField != null) {
        propertyName = `${subFormField.ParentControlId}.${subFormField.Index}.${propertyName}`;
    }
    return propertyName;
}