import React from "react";
import { ITextProps, IStackTokens, Stack, Text } from "@fluentui/react";
import { Headers } from "./Headers";
import { Definitions } from "./Definitions";

export const HeadersAndDefinitions: React.FC = () => {

    const themedLargeStackTokens: IStackTokens = {
        childrenGap: 'l1',
        padding: 'l1',
    };

    return (
        <>
            <Stack horizontal={false} tokens={themedLargeStackTokens}>
                <Stack.Item>
                    <Text variant={"large" as ITextProps['variant']}>
                        Headers and Definitions
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant={"medium" as ITextProps['variant']}>
                        Download header/definitions and upload data for the entity.
                    </Text>
                </Stack.Item>
                <Stack horizontal={true} wrap>
                    <Stack.Item>
                        <Headers></Headers>
                    </Stack.Item>
                    <Stack.Item>
                        <Definitions></Definitions>
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
}