import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { availableApplicationsState, selectedApplicationState } from "../../recoil/atoms";
import { IAppUpdatedResponse } from "../../model/IElectronApi";
import { writeConfigRequest } from "secure-electron-store";

export const ElectronTest: React.FC = () => {
    const setAvailableApplications = useSetRecoilState(availableApplicationsState);
    const [selectedApplication, setSelectedApplication] = useRecoilState(selectedApplicationState);

    useEffect(() => {
        const apps = [
            'COVID Tracker',
            'College Football Stats',
            'Funny Jokes'
        ];

        setAvailableApplications(apps);

        // If this global exists then we are running in electron
        if (window.electronApi) {
            const currentValueInStore = window.electronApi.store.initial()["selectedApp"];
            if (currentValueInStore !== undefined) {
                setSelectedApplication(currentValueInStore);
            }

            window.electronApi.menu.updateApplicationsInMenu(apps);

            window.electronApi.menu.onAppUpdated((response: IAppUpdatedResponse) => {
                setSelectedApplication(response.selectedApp);
                // Feed back to Electron to show how to update Electron store based on events in rederer process
                window.electronApi.store.send(writeConfigRequest, "selectedApp", response.selectedApp);
            });
        }

    }, [setAvailableApplications, setSelectedApplication]);

    if (selectedApplication !== undefined) {
        return null;//(<h2 style={{ backgroundColor: 'white', color: 'black' }}>{selectedApplication}</h2>);
    }
    return null;
}