import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { navHeaderState, shimmerNavState } from "../../recoil/atoms";
import { IImageProps, IStackItemStyles, IStackTokens, Image, PrimaryButton, Stack, useTheme, IStackStyles, Text, ITextStyles } from "@fluentui/react";
import getScreenSize from "../../services/getScreenSize";
import { useNavigate } from "react-router-dom";
import { MainBackgroundLeft } from "../unauthenticated/MainBackgroundLeft";

export const NoAccess: React.FC = () => {
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const screenSize: number = getScreenSize();
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        setNavHeader("Register");
        setShimmerNav(true);
    }, [setNavHeader, setShimmerNav]);

    const logoProps: IImageProps = {
        src: './1x/Logo + by intact_1 3.png',
        width: "100%"
    };

    const rightStackItemStyles: IStackStyles = {
        root: {
            display: "flex",
            justifyContent: 'center',
            width: screenSize >= 768 ? "40%" : "100%",
            backgroundColor: "#F4F6F8"
        }
    };

    const powredByItemStyles: ITextStyles = {
        root: {
            color: "#AAB8C7",
            paddingBottom: "30px"
        }
    };

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };


    return (
        <>
            <Stack horizontal={true} styles={bodyStackItemStyles}>
                <MainBackgroundLeft></MainBackgroundLeft>
                <Stack.Item styles={rightStackItemStyles}>
                    <Stack horizontal={false} verticalAlign="space-between">
                        <Stack.Item>
                            <Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack horizontalAlign={"center"} tokens={themedMediumStackTokens}>
                                <Stack.Item>
                                    <Image {...logoProps} />
                                </Stack.Item>
                                <Stack.Item style={{ maxWidth: "400px" }}>
                                    <Text>
                                        It appears that you were invited by a customer however, you have no access.
                                    </Text>
                                    <br />
                                    <br />
                                    <Text>
                                        Reach out to your administrator for access to an application or click Register below to begin your own journey!
                                    </Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <PrimaryButton
                                        text="Register"
                                        ariaDescription="Navigate to the register page."
                                        onClick={() => { 
                                            navigate(`/Register`, { replace: true });
                                         }}
                                        style={{ width: "200px" }} />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item style={{ marginBottom: "30px" }}>
                            <Stack horizontal={false}>
                                <Stack.Item align="center">
                                    <Text variant={screenSize < 1200 ? "mediumPlus" : "large"} styles={powredByItemStyles}>
                                        Powered by Intact
                                    </Text>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack >
        </>
    );
}