import { createContext } from "react";
import { AppInsightsApp } from "./AppInsightsApp";
import { SentryApp } from "./SentryApp";
import { authenticationProviderState } from "./recoil/atoms";
import * as Sentry from "@sentry/react";
import { EventMessage, EventType, AuthenticationResult, PublicClientApplication } from "@azure/msal-browser";
import { appInsights } from "./services/ApplicationInsightsService";
import { useRecoilValue } from "recoil";
import { AuthenticationProvider } from "./enmus/AuthenticationProvider";
import { msalConfigGoogle, msalConfigMicrosoft } from "./factories/AuthConfig";
import { App } from "./App";

// Create a context for each scenario
var msalInstanceGoogle = new PublicClientApplication(msalConfigGoogle);
var msalInstanceMicrosoft = new PublicClientApplication(msalConfigMicrosoft);

const eventCallback = (msalInstance: PublicClientApplication, event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);

    if (window.ReAccessWeb.process.env.SENTRY_DSN != null && window.ReAccessWeb.process.env.SENTRY_DSN !== "") {
      Sentry.setUser({
        id: account!.localAccountId,
        email: account!.username,
        username: account!.name,
      });
    } else {
      appInsights.setAuthenticatedUserContext(account!.localAccountId, account!.username, true);
    }
  }
  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    if (window.ReAccessWeb.process.env.SENTRY_DSN != null && window.ReAccessWeb.process.env.SENTRY_DSN !== "") {
      Sentry.setUser(null);
    } else {
      appInsights.clearAuthenticatedUserContext();
    }
  }

  if (event.eventType === EventType.INITIALIZE_END) {
    if (
      window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY != null &&
      window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY !== ""
    ) {
      let user = appInsights.context.user;
      var activeAccount = msalInstance.getActiveAccount()!;
      if (user.accountId == null && activeAccount != null && user.accountId !== activeAccount.username) {
        appInsights.setAuthenticatedUserContext(activeAccount.localAccountId, activeAccount.username, true);
      }
    }
  }
};

// hook up event callbacks for each msal instance
msalInstanceGoogle.addEventCallback((event) => eventCallback(msalInstanceGoogle, event));
msalInstanceMicrosoft.addEventCallback((event) => eventCallback(msalInstanceMicrosoft, event));

// Start by setting context to the Microsoft instance - but recoil authenticationProviderState will cause
// this to be updated via the value prop in MsalContext.Provider
export const MsalContext = createContext(msalInstanceMicrosoft);

export const LoggingWrapper: React.FC = () => {
  const authenticationProvider = useRecoilValue(authenticationProviderState);

  const msalInstance = authenticationProvider === AuthenticationProvider.Google ? msalInstanceGoogle : msalInstanceMicrosoft;

  if (window.ReAccessWeb.process.env.SENTRY_DSN != null && window.ReAccessWeb.process.env.SENTRY_DSN !== "") {
    return (
      <MsalContext.Provider value={msalInstance}>
        <SentryApp />
      </MsalContext.Provider>
    );
  } else if (
    window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY != null &&
    window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY !== "") {
    return (
      <MsalContext.Provider value={msalInstance}>
        <AppInsightsApp />
      </MsalContext.Provider>
    );
  } else {
    return (
      <MsalContext.Provider value={msalInstance}>
        <App msalInstance={msalInstance} />
      </MsalContext.Provider>
    );
  }
};
