import React from "react";
import { ITextProps, Text } from "@fluentui/react";
import { applicationManifestState } from "../../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { getFormName, getFormRecordIdentifier } from "../../../services/manifestService";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";

export interface FormNameProps {
    formId: string;
    entityId: string | undefined;
    variantSize: string;
    applicationEntity: ApplicationEntity | undefined;
}

export const FormName: React.FC<FormNameProps> = (props: FormNameProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);

    return (
        <div>
            <Text variant={props.variantSize as ITextProps['variant']}>
                {getFormName(applicationManifest, props.formId)}
            </Text>
            {(props.entityId != null && props.applicationEntity != null) &&
                <Text variant={props.variantSize === "xxLarge" ? "large" : "mediumPlus" as ITextProps['variant']}>
                    {getFormRecordIdentifier(applicationManifest, props.formId, props.applicationEntity)}
                </Text>
            }
        </div>
    );
}