import { IButtonStyles, Theme } from "@fluentui/react";

export const removeButtonStyles = (theme: Theme): IButtonStyles => {
    var buttonStyle: IButtonStyles = {
        root: {
            backgroundColor: theme.palette.red,
            color: theme.palette.white
        },
        rootHovered: {
            backgroundColor: theme.palette.redDark,
            color: theme.palette.white
        }
    };

    return buttonStyle;
}

export const removeButtonAlternateStyles = (theme: Theme): IButtonStyles => {
    var buttonStyle: IButtonStyles = {
        root: {
            color: theme.palette.red,
            borderColor: theme.palette.red
        },
        rootHovered: {
            color: theme.palette.redDark,
            borderColor: theme.palette.redDark
        }
    };

    return buttonStyle;
}

export const getControlWidth = (screenSize: number, additionalSpace: number): any => {
    if (screenSize >= 900) {
        return 500 + additionalSpace + "px";
    } else if (screenSize > 600) {
        return 400 + additionalSpace + "px";
    }
    return 300 + additionalSpace + "px";
}