import React, { useEffect } from "react";
import { Stack, IStackItemStyles, IStackStyles } from "@fluentui/react";
import { Outlet, useNavigate } from "react-router-dom";
import { customerDtoState, dialogModalWrapperPropsState, navPageSelectedState, shimmerNavState } from "../../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { StringConstants } from "../../../constants/StringConstants";
import axios from "axios";
import { AppCatalogCustomer } from "../../../dtos/AppCatalogCustomer";
import { useRecoilState } from "recoil";
import { CustomerDto } from "../../../dtos/Customer/CustomerDto";

export const NewAppIndex: React.FC = () => {
    const setNavPageSelected = useSetRecoilState(navPageSelectedState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [customerDtos, setCustomerDtos]: [CustomerDto[], (appCatalogCustomers: CustomerDto[]) => void] = useRecoilState(customerDtoState);
    const navigate = useNavigate();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        axios.get("/Customer", {
            cancelToken: source.token,
        })
            .then((result) => {
                var customers: AppCatalogCustomer[] = result.data;
                if (customers.length === 0) {
                    var dialogProps = {
                        isVisible: true,
                        title: "Account Access",
                        subText: "To create a new application you must have your own account or be a global administrator/builder on someone else's account.",
                        isBlocking: true,
                        primaryButtonText: "OK",
                        secondaryButtonText: "Create Trial Account",
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                            navigate("/AppCatalog");
                        },
                        onSecondaryButtonDismiss: () => { 
                            setDialogModalWrapperPropsState(undefined);
                            navigate("/Register", { replace: false });
                        }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                    return;
                }
                customers.sort((a, b) => a.Name.localeCompare(b.Name));
                setCustomerDtos(customers);
                setNavPageSelected(StringConstants.NewApplicationMenuItem);
                setShimmerNav(true);
            })
            .catch((error) => {
                setShimmerNav(true);
                if (error.response == null) {
                    console.log("Request canceled", "New App Index Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps = {
                        isVisible: true,
                        title: "Uh oh",
                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                        isBlocking: true,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
        return function cleanup() {
            source.cancel();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stackStyle: IStackStyles = {
        root: {
            display: "flex",
            width: "95%",
            paddingTop: "25px",
            transition: "0.3s",
        },
    };

    const stackItemStyles: IStackItemStyles = {
        root: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: "center",
            paddingTop: 10,
            transition: "0.3s"
        },
    };

    return (
        <Stack styles={stackStyle}>
            <Stack.Item styles={stackItemStyles}>
                {(customerDtos != null && customerDtos.length > 0) &&
                    <Outlet />
                }
            </Stack.Item>
        </Stack>
    );
}