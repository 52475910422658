import React, { useEffect, useState } from "react";
import { navPageSelectedState, navHeaderState, shimmerNavState, dialogModalWrapperPropsState, loadingModalState, applicationManifestState, customerDtoState } from "../../../recoil/atoms";
import { Stack, IStackItemStyles, TextField, DefaultButton, Dropdown, IDropdownOption, IStackTokens, ITextFieldStyleProps, ITextFieldStyles, IDropdownStyles, DocumentCard, useTheme, IDocumentCardStyles } from "@fluentui/react";
import { StringConstants } from "../../../constants/StringConstants";
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import getScreenSize from "../../../services/getScreenSize";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { ILoadingModalProps } from "../../../interfaces/ILoadingModal";
import Tour from "reactour";
import { ValidationError } from "../../../model/error/ValidationError";
import { getControlWidth } from "../../../services/styleService";
import { CustomerDto } from "../../../dtos/Customer/CustomerDto";

const defaultValues = {
    ApplicationName: "",
    Description: "",
    HomeIconName: "Home",
    HomePageContent: "",
    CustomerId: undefined
}

export const CreateBasicApplication: React.FC = () => {
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavPageSelected = useSetRecoilState(navPageSelectedState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const setLoadingModalState = useSetRecoilState(loadingModalState);
    const setApplicationManifest = useSetRecoilState(applicationManifestState);
    const { handleSubmit, control, formState: { errors, isDirty, isSubmitting, isValid }, setError } = useForm({ defaultValues: defaultValues, mode: "onChange" });
    const customerDtos: CustomerDto[] = useRecoilValue(customerDtoState);
    const [icons, setIcons] = useState<Array<IDropdownOption>>([]);
    const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
    const screenSize: number = getScreenSize();
    const navigate = useNavigate();
    const theme = useTheme();
    const accentColor = theme.semanticColors.primaryButtonBackground;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        setNavPageSelected(StringConstants.NewApplicationMenuItem);
        setNavHeader("New Application");
        setShimmerNav(true);
        axios.get("/Icon", {
            cancelToken: source.token,
        })
            .then((result) => {
                var icons: any = result.data;
                var formattedIcons: IDropdownOption[] = icons.map((i: { Value: number; DisplayName: string; Description: string; }) => ({ key: i.DisplayName, text: i.DisplayName, data: { icon: i.Description } }));
                setIcons(formattedIcons);

                var hasTakenTour = localStorage.getItem("tour_create_basic_app_1");
                if (hasTakenTour?.toLowerCase() !== "true") {
                    if (customerDtos.length > 1) {
                        steps.splice(5, 0, {
                            selector: '[data-tut="ClientId"]',
                            content: () => (
                                <div>
                                    You must select a Customer to add the application to if you have more than one.
                                    <br />< br />
                                </div>
                            )
                        })
                    }
                    setIsTourOpen(true);
                    localStorage.setItem("tour_create_basic_app_1", "true");
                }
            })
            .catch((error) => {
                setShimmerNav(true);
                if (error.response == null) {
                    console.log("Request canceled", "Create Basic Application Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps = {
                        isVisible: true,
                        title: "Uh oh",
                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                        isBlocking: true,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });

        return function cleanup() {
            source.cancel();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mainStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: "40px",
    };

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    function getStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles> {
        return {
            fieldGroup: [
                { width: getControlWidth(screenSize, 0) },
                { display: "flex" }
            ]
        };
    }

    const getDropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: getControlWidth(screenSize, 0) },
    };

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };

    const documentCardStyles: IDocumentCardStyles = {
        root: {
            width: getControlWidth(screenSize, 75),
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            maxWidth: "",
            minWidth: ""
        }
    };

    const submitForm = (data: any, e: any) => {
        var loadingModalProps: ILoadingModalProps = {
            isVisible: true,
            title: "Creating Application",
            progressTitle: "Hold tight, building the next big thing!",
            progressSubText: "",
            isBlocking: true,
        };
        setLoadingModalState(loadingModalProps);

        if (customerDtos!.length === 1) {
            data.CustomerId = customerDtos![0].Id;
        }

        axios.post("/BlankApplicationTemplate", data, {
            cancelToken: source.token,
        })
            .then((result: any) => {
                setApplicationManifest(null);
                setLoadingModalState(undefined);
                navigate(`/Application/${result.data.ApplicationId}/Home`, { replace: true });
            }).catch((error) => {
                setLoadingModalState(undefined);
                if (error.response == null) {
                    console.log("Request canceled", "Create Basic Application Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        //set errors on controls
                        validationErrors.forEach((validationError: ValidationError) => {
                            setError((validationError.GroupIdentifier) as any, {
                                type: "manual",
                                message: validationError.Message
                            })
                        });
                    } else {
                        var dialogProps: IDialogModalWrapperProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
    }

    const getCustomerOptions = (): IDropdownOption[] => {
        return customerDtos.map(i => ({ key: i.Id, text: i.Name }));
    }

    return (
        <>
            <Stack tokens={mainStackTokens} styles={bodyStackItemStyles}>
                <Stack horizontalAlign={"center"}>
                    <DocumentCard styles={documentCardStyles}>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Stack horizontalAlign={"center"} tokens={themedMediumStackTokens}>
                                <Stack.Item>
                                    <div data-tut="ApplicationName">
                                        <Controller
                                            control={control}
                                            name="ApplicationName"
                                            rules={{
                                                required: "Name is required",
                                                maxLength: { value: 128, message: "The max length of Name is 128 characters" }
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <TextField
                                                    label="Name"
                                                    value={value}
                                                    styles={getStyles}
                                                    errorMessage={errors != null && errors["ApplicationName"]?.message != null ? errors["ApplicationName"].message : undefined}
                                                    onBlur={onBlur}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        onChange(newValue);
                                                    }} />
                                            )}
                                        />
                                    </div>
                                </Stack.Item>
                                <Stack.Item>
                                    <div data-tut="Description">
                                        <Controller
                                            control={control}
                                            name="Description"
                                            rules={{
                                                required: "Description is required",
                                                maxLength: { value: 2048, message: "The max length of Description is 2048 characters" }
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <TextField
                                                    label="Description"
                                                    multiline={true}
                                                    rows={5}
                                                    value={value}
                                                    styles={getStyles}
                                                    errorMessage={errors != null && errors["Description"]?.message != null ? errors["Description"].message : undefined}
                                                    onBlur={onBlur}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        onChange(newValue);
                                                    }} />
                                            )}
                                        />
                                    </div>
                                </Stack.Item>
                                <Stack.Item>
                                    <div data-tut="HomeIconName">
                                        <Controller
                                            control={control}
                                            name="HomeIconName"
                                            rules={{
                                                required: "Home Page Icon is required"
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <Dropdown
                                                    label={"Home Page Icon"}
                                                    selectedKey={value}
                                                    options={icons}
                                                    styles={getDropdownStyles}
                                                    errorMessage={errors != null && errors["HomeIconName"]?.message != null ? errors["HomeIconName"].message : undefined}
                                                    onBlur={onBlur}
                                                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                                        onChange(option?.key);
                                                    }} />
                                            )}
                                        />
                                    </div>
                                </Stack.Item>
                                <Stack.Item>
                                    <div data-tut="HomePageContent">
                                        <Controller
                                            control={control}
                                            name="HomePageContent"
                                            rules={{
                                                maxLength: { value: 2048, message: "The max length of Home Page Content is 2048 characters" }
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <TextField
                                                    label="Home Page Content"
                                                    multiline={true}
                                                    rows={5}
                                                    value={value}
                                                    styles={getStyles}
                                                    errorMessage={errors != null && errors["HomePageContent"]?.message != null ? errors["HomePageContent"].message : undefined}
                                                    onBlur={onBlur}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        onChange(newValue);
                                                    }} />
                                            )}
                                        />
                                    </div>
                                </Stack.Item>
                                {(customerDtos.length > 1) &&
                                    <Stack.Item>
                                        <div data-tut="ClientId">
                                            <Controller
                                                control={control}
                                                name="CustomerId"
                                                rules={{
                                                    required: "Customer is required"
                                                }}
                                                render={({ field: { onChange, onBlur, value } }) => (
                                                    <Dropdown
                                                        label="Customer"
                                                        selectedKey={value}
                                                        options={getCustomerOptions()}
                                                        placeholder="Select"
                                                        styles={getDropdownStyles}
                                                        errorMessage={errors != null && errors["CustomerId"]?.message != null ? errors["CustomerId"].message : undefined}
                                                        onBlur={onBlur}
                                                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                                            onChange(option?.key);
                                                        }} />
                                                )}
                                            />
                                        </div>
                                    </Stack.Item>
                                }
                            </Stack>
                            <Stack horizontalAlign={"end"} tokens={themedMediumStackTokens}>
                                <Stack.Item>
                                    <DefaultButton
                                        data-tut="SaveNewApplication"
                                        key="save"
                                        uniqueId="save"
                                        text="Save"
                                        iconProps={{ iconName: "Save" }}
                                        primary
                                        type="submit"
                                        disabled={
                                            !isValid ||
                                            !isDirty ||
                                            isSubmitting ||
                                            customerDtos.length === 0}
                                    />
                                </Stack.Item>
                            </Stack>
                        </form>
                    </DocumentCard>
                </Stack>
            </Stack>
            <Tour
                onRequestClose={() => setIsTourOpen(false)}
                steps={steps}
                isOpen={isTourOpen}
                maskClassName="Tour"
                rounded={5}
                accentColor={accentColor}
                disableFocusLock={true}
                disableInteraction={false}
            />
        </>
    );
}

const steps = [
    {
        content: "Great choice!  Let's enter some basic information to create your application.You will then be able to go into our App Builder and expand from there!",
    },
    {
        selector: '[data-tut="ApplicationName"]',
        content: () => (
            <div>
                To get started, please enter the name of your application.
                <br />< br />
            </div>
        )
    },
    {
        selector: '[data-tut="Description"]',
        content: () => (
            <div>
                The description provides an overview of what the application represents.
                <br />< br />
            </div>
        )
    },
    {
        selector: '[data-tut="HomeIconName"]',
        content: () => (
            <div>
                The icon is what will show up in the navigation menu.
                <br />< br />
                We have defaulted it to a Home, but you can adjust this to whatever you like.
            </div>
        )
    },
    {
        selector: '[data-tut="HomePageContent"]',
        content: () => (
            <div>
                Home Page Content allows you to add custom content to your home page.
                <br />< br />
            </div>
        )
    },
    {
        selector: '[data-tut="SaveNewApplication"]',
        content: () => (
            <div>
                That's it, now let's create your application by clicking save!
                <br />< br />
                <span aria-label="party popper emoji" role="img" style={{ fontSize: "30px" }}>
                    🎉
                </span>
            </div>
        )
    }
];