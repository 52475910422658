import React from "react";
import { DatePicker, Stack, TooltipHost, TextField, IStackStyles, IStackItemStyles } from "@fluentui/react";
import { ControlData } from "../../../model/manifest/ControlData";
import { Controller } from "react-hook-form";
import { ConnectForm } from "../form/ConnectForm";
import { StringConstants } from "../../../constants/StringConstants";
import { useId } from "@fluentui/react-hooks";

export interface FromToDateTimePickerProps {
    control: ControlData;
    isFromDate: boolean;
    shimmerControlsOnSubmit: boolean;
}

export const FromToDateTimePicker: React.FC<FromToDateTimePickerProps> = (props: FromToDateTimePickerProps) => {
    const datePickerId = useId("datePicker");
    const dateTooltipId = useId("tooltip");
    //For some reason 0 does not work for the DatePicker.  You must drop down the tool tip by
    //the size of the label.  This will probably get resolved in a future version of Fluent UI.
    const calloutProps = {
        gapSpace: -30,
        target: `#${datePickerId}`
    };
    const timePickerId = useId("timePicker");
    const timeTooltipId = useId("tooltip");
    const timeCalloutProps = {
        gapSpace: 0,
        target: `#${timePickerId}`
    };

    const setTimePortion = (date: Date | null | undefined, methods: any): any => {
        if (date == null) return;

        var timeControlName = getTimeName();

        var timePortion = methods.getValues(timeControlName);
        if (timePortion == null || timePortion === "") {
            methods.setValue(timeControlName, props.isFromDate ? "00:00" : "23:59");
        }
    }

    const getTimeName = (): string => {
        return props.isFromDate ?
            `${StringConstants.FromTimeSearchParameter}${props.control.Id}` :
            `${StringConstants.ToTimeSearchParameter}${props.control.Id}`;
    }

    return (
        <>
            <Stack styles={mainSideNavStyle}>
                <Stack.Item grow={3}>
                    <ConnectForm>
                        {(methods: any) =>
                            <>
                                <Controller
                                    control={methods.control}
                                    name={props.isFromDate ?
                                        `${StringConstants.FromDateTimeSearchParameter}${props.control.Id}` :
                                        `${StringConstants.ToDateTimeSearchParameter}${props.control.Id}`}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <TooltipHost
                                                content={props.control.ToolTipText}
                                                id={dateTooltipId}
                                                calloutProps={calloutProps}
                                            >
                                                <DatePicker
                                                    id={datePickerId}
                                                    label={props.isFromDate ? `From ${props.control.LabelText}` : `To ${props.control.LabelText}`}
                                                    allowTextInput={true}
                                                    showGoToToday={true}
                                                    value={value}
                                                    onSelectDate={(date: Date | null | undefined) => {
                                                        date == null ? onChange("") : onChange(date);
                                                        setTimePortion(date, methods);
                                                    }} />
                                            </TooltipHost>
                                        </>
                                    )} />
                            </>
                        }
                    </ConnectForm>
                </Stack.Item>
                <Stack.Item grow={2} styles={timeStackItemStyles}>
                    <ConnectForm>
                        {(methods: any) =>
                            <>
                                <Controller
                                    control={methods.control}
                                    name={getTimeName()}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <TooltipHost
                                                content={props.control.ToolTipText}
                                                id={timeTooltipId}
                                                calloutProps={timeCalloutProps}
                                            >
                                                <TextField
                                                    id={timePickerId}
                                                    type="time"
                                                    label={props.isFromDate ? `From ${props.control.LabelText}` : `To ${props.control.LabelText}`}
                                                    value={value}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        onChange(newValue);
                                                    }} />
                                            </TooltipHost>
                                        </>
                                    )} />
                            </>
                        }
                    </ConnectForm>
                </Stack.Item>
            </Stack>
        </>
    )
}

const mainSideNavStyle: IStackStyles = {
    root: {
        display: "flex",
        flexDirection: "row"
    },
};
const timeStackItemStyles: IStackItemStyles = {
    root: {
        marginLeft: "16px"
    }
}