import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { DropdownField } from "./fields/DropdownField";
import { TypeAheadField } from "./fields/TypeAheadField";
import { TypeAndSearchField } from "./fields/TypeAndSearchField";
import { TypeAndSearchOnServerField } from "./fields/TypeAndSearchOnServerField";
import { DropdownChildField } from "./fields/DropdownChildField";
import { MultiSelectField } from "./fields/MultiSelectField";
import { MultiSelectChildField } from "./fields/MultiSelectChildField";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface ListControlProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    isSearch: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const ListControl: React.FC<ListControlProps> = (props: ListControlProps) => {

    return (
        <>
            {(props.control.FieldType === 16 || props.control.FieldType === 33) &&
                <>
                    {!props.control.IsCascadeChild
                        ?
                        <DropdownField
                            control={props.control}
                            shouldValidate={props.shouldValidate}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            isRequired={props.isRequired}
                            isSearch={props.isSearch}
                            entityId={props.entityId}
                            subFormField={props.subFormField}>
                        </DropdownField>
                        :
                        <DropdownChildField
                            control={props.control}
                            shouldValidate={props.shouldValidate}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            isRequired={props.isRequired}
                            isSearch={props.isSearch}
                            entityId={props.entityId}
                            subFormField={props.subFormField}>
                        </DropdownChildField>
                    }
                </>
            }
            {props.control.FieldType === 34 &&
                <TypeAheadField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    isSearch={props.isSearch}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </TypeAheadField>
            }
            {props.control.FieldType === 36 &&
                <>
                    {props.control.TypeAndSearchOnServer
                        ?
                        <TypeAndSearchOnServerField
                            control={props.control}
                            shouldValidate={props.shouldValidate}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            isRequired={props.isRequired}
                            isDropDown={true}
                            isSearch={props.isSearch}
                            entityId={props.entityId}
                            subFormField={props.subFormField}>
                        </TypeAndSearchOnServerField>
                        :
                        <TypeAndSearchField
                            control={props.control}
                            shouldValidate={props.shouldValidate}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            isRequired={props.isRequired}
                            isSearch={props.isSearch}
                            entityId={props.entityId}
                            subFormField={props.subFormField}>
                        </TypeAndSearchField>
                    }
                </>
            }
            {props.control.FieldType === 39 &&
                <>
                    {!props.control.IsCascadeChild
                        ?
                        <MultiSelectField
                            control={props.control}
                            shouldValidate={props.shouldValidate}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            isRequired={props.isRequired}
                            isSearch={props.isSearch}
                            entityId={props.entityId}
                            subFormField={props.subFormField}>
                        </MultiSelectField>
                        :
                        <MultiSelectChildField control={props.control}
                            shouldValidate={props.shouldValidate}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            isRequired={props.isRequired}
                            isSearch={props.isSearch}
                            entityId={props.entityId}
                            subFormField={props.subFormField}>
                        </MultiSelectChildField>
                    }
                </>
            }
        </>
    );
}