import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { breadcrumbsState, navPageSelectedState, shimmerNavState } from "../../../recoil/atoms";
import { Outlet } from "react-router-dom";
import { IBreadcrumbItem } from "@fluentui/react";

export const CustomerPortalIndex: React.FC = () => {
    const setNavPageSelected = useSetRecoilState(navPageSelectedState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setBreadcrumbs = useSetRecoilState(breadcrumbsState);
    
    useEffect(() => {
        setNavPageSelected(undefined);
        setShimmerNav(true);

        const itemsWithHref: IBreadcrumbItem[] = [
            { text: "My Portal", key: "ownerBreadcrumb", href: `/OwnerPortal` },
            { text: "Account", key: "customerBreadcrumb", href: "", isCurrentItem: true },
        ];
        setBreadcrumbs(itemsWithHref);

        return function cleanup() {
            setBreadcrumbs([]);
        }
    }, [setBreadcrumbs, setNavPageSelected, setShimmerNav]);
    
    return (
        <Outlet></Outlet>
    );
}