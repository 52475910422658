import React, { useEffect } from "react";
import { ITextProps, IStackItemStyles, IStackTokens, Stack, Text } from "@fluentui/react";
import { useSetRecoilState } from "recoil";
import { navHeaderState } from "../../../recoil/atoms";
import getScreenSize from "../../../services/getScreenSize";

export const HelpVideos: React.FC = () => {
    const setNavHeader = useSetRecoilState(navHeaderState);
    const screenSize: number = getScreenSize();

    useEffect(() => {
        setNavHeader("Videos");
    }, [setNavHeader]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            paddingTop: "10px",
            paddingBottom: "15px"
        }
    };

    const themedLargeStackTokens: IStackTokens = {
        childrenGap: 'l1',
        padding: 'l1',
    };

    const getVideoWidth = (): number => {
        if (screenSize >= 900) {
            return 720;
        } else if (screenSize > 600) {
            return 500;
        }
        return 300;
    }

    const getVideoHeight = (): number => {
        if (screenSize >= 900) {
            return 400;
        } else if (screenSize > 600) {
            return 350;
        }
        return 200;
    }

    return (
        <>
            <Stack horizontal={false}>
                <Stack.Item styles={stackItemStyles}>
                    <Text variant={"xLarge" as ITextProps['variant']}>
                        Training Videos
                    </Text>
                </Stack.Item>
                <Stack horizontal={false} tokens={themedLargeStackTokens}>
                    <Stack.Item>
                        <Text variant={"large" as ITextProps['variant']}>
                            ReAccess Live
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant={"medium" as ITextProps['variant']}>
                            ReAccess NoCode Live Events provides topic specific training items and Q&A for attendees.
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <iframe
                            src="https://www.youtube.com/embed/?listType=playlist&list=PL_hiEHioxatdmk9QHwBEJL2sbxsKvmM9p"
                            title="ReAccess Quick Training Videos"
                            width={getVideoWidth()}
                            height={getVideoHeight()}
                            allowFullScreen />
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant={"large" as ITextProps['variant']}>
                            App Builder
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant={"medium" as ITextProps['variant']}>
                            These training videos are an overview of ReAccess and the LAD/D process
                    </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <iframe
                            src="https://www.youtube.com/embed/?listType=playlist&list=PL_hiEHioxatcVp1370dLUTWbDufT-DMjN"
                            title="ReAccess Training Videos"
                            width={getVideoWidth()}
                            height={getVideoHeight()}
                            allowFullScreen />
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
}