import * as Sentry from "@sentry/react";
import { App } from "./App";
import { useContext } from "react";
import { MsalContext } from "./LoggingWrapper";

export const SentryApp: React.FC = () => {
  const msalInstance = useContext(MsalContext);
  return (
    <>
      {window.ReAccessWeb.process.env.SENTRY_DSN != null && window.ReAccessWeb.process.env.SENTRY_DSN !== "" && <App msalInstance={msalInstance} />}
    </>
  );
};

if (window.ReAccessWeb.process.env.SENTRY_DSN != null && window.ReAccessWeb.process.env.SENTRY_DSN !== "") {
  Sentry.withProfiler(SentryApp);
}
