import React from "react";
import { TextField, TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../model/manifest/ControlData";
import { Controller } from "react-hook-form";
import { ConnectForm } from "../form/ConnectForm";
import { StringConstants } from "../../../constants/StringConstants";
import { formatCurrency } from "../../../services/currencyService";
import { useId } from "@fluentui/react-hooks";

export interface FromToCurrencyPickerProps {
    control: ControlData;
    isFromCurrency: boolean;
    shimmerControlsOnSubmit: boolean;
}

export const FromToCurrencyPicker: React.FC<FromToCurrencyPickerProps> = (props: FromToCurrencyPickerProps) => {
    const textBoxId = useId("textBox");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textBoxId}`
    };

    return (
        <ConnectForm>
            {(methods: any) =>
                <Controller
                    control={methods.control}
                    name={props.isFromCurrency ?
                        `${StringConstants.FromCurrencySearchParameter}${props.control.Id!}` :
                        `${StringConstants.ToCurrencySearchParameter}${props.control.Id!}`}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <>
                            <TooltipHost
                                content={props.control.ToolTipText}
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <TextField
                                    id={textBoxId}
                                    label={props.isFromCurrency ? `From ${props.control.LabelText}` : `To ${props.control.LabelText}`}
                                    prefix="$"
                                    value={value}
                                    errorMessage={
                                        methods.formState.errors != null &&
                                            methods.formState.errors[props.control.Id!] != null &&
                                            methods.formState.errors[props.control.Id!].message != null ?
                                            methods.formState.errors[props.control.Id!].message :
                                            undefined}
                                    onChange={(event: any, newValue?: string) => {
                                        newValue = formatCurrency(event, newValue);
                                        onChange(newValue);
                                    }} />
                            </TooltipHost>
                        </>
                    )} />
            }
        </ConnectForm>
    )
}