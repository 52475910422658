import React, { } from "react";
import { ProgressIndicator } from "@fluentui/react";
import { useRecoilValue } from "recoil";
import { documentProgressSelector } from "../../../../../../recoil/atoms";

export interface ControlProgressIndicatorProps {
    controlId: string;
    label: string;
}

export const ControlProgressIndicator: React.FC<ControlProgressIndicatorProps> = (props: ControlProgressIndicatorProps) => {
    const documentProgress: number | undefined = useRecoilValue(documentProgressSelector(props.controlId));

    return (
        <>
            {documentProgress != null &&
                <ProgressIndicator label={props.label} description={`${(documentProgress)}% complete`} percentComplete={documentProgress * .01} />
            }
        </>
    );
};
