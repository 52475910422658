import React, { useEffect, useState } from "react";
import { DefaultButton, DetailsList, DetailsListLayoutMode, DocumentCard, Dropdown, IColumn, IDocumentCardStyles, IDropdownOption, IStackStyles, IStackTokens, PrimaryButton, SelectionMode, Stack, Text, useTheme } from "@fluentui/react";
import { IGroupDataFilterGridItem } from "../../../interfaces/users/IGroupDataFilterGridItem";
import { GroupDataFilterPanel } from "./GroupDataFilterPanel";
import { removeButtonStyles } from "../../../services/styleService";
import { applicationManifestState, dialogModalWrapperPropsState } from "../../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import axios, { AxiosRequestConfig } from "axios";
import { GroupDataFilterDto } from "../../../dtos/Manifest/GroupDataFilterDto";
import { useParams } from "react-router-dom";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { useSetRecoilState } from "recoil";
import { StringConstants } from "../../../constants/StringConstants";

export const GroupDataFilter: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [groupDataFilterGridItems, setGroupDataFilterGridItems] = useState<IGroupDataFilterGridItem[]>([]);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [isGroupDataFilterGridOpen, setIsGroupDataFilterGridOpen] = useState(false);
    const [editingGroupDataFilterGridItem, setEditingGroupDataFilterGridItem] = useState<IGroupDataFilterGridItem>();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const theme = useTheme();
    var { groupId } = useParams();

    useEffect(() => {
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/GroupDataFilter/GetAll",
            params: {
                groupId: groupId,
                applicationId: applicationManifest.Id
            },
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {
                var groupDataFilterDtos: GroupDataFilterDto[] = result.data;
                var groupDataFilterGridItems: IGroupDataFilterGridItem[] = [];
                groupDataFilterDtos.forEach((item) => {
                    var groupDataFilterGridItem: IGroupDataFilterGridItem = {
                        GroupId: item.GroupId,
                        PageId: item.PageId,
                        FieldId: item.ControlId,
                        FilterType: item.FilterType,
                        Text: item.Text,
                        DataSourceItemDtos: item.DataSourceItemDtos,
                        Disabled: false,
                        Selected: false
                    };
                    groupDataFilterGridItems.push(groupDataFilterGridItem);
                })
                setGroupDataFilterGridItems(groupDataFilterGridItems);
            })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "User Permissions Component cleaned up");
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createdGroupDataFilter = (groupDataFilterDto: GroupDataFilterDto): void => {
        var groupDataFilterGridItemArray = [...groupDataFilterGridItems];
        var groupDataFilterGridItem: IGroupDataFilterGridItem = {
            GroupId: groupDataFilterDto.GroupId,
            PageId: groupDataFilterDto.PageId,
            FieldId: groupDataFilterDto.ControlId,
            FilterType: groupDataFilterDto.FilterType,
            Text: groupDataFilterDto.Text,
            DataSourceItemDtos: groupDataFilterDto.DataSourceItemDtos,
            Disabled: false,
            Selected: false
        };
        groupDataFilterGridItemArray.push(groupDataFilterGridItem);
        setGroupDataFilterGridItems(groupDataFilterGridItemArray);
        setIsGroupDataFilterGridOpen(false);
    };

    const editGroupDataFilter = (groupDataFilterDto: GroupDataFilterDto): void => {
        var groupDataFilterGridItemArray = [...groupDataFilterGridItems];
        var item = groupDataFilterGridItemArray.find(i => i.PageId === groupDataFilterDto.PageId &&
            i.FieldId === groupDataFilterDto.ControlId)!;
        item.FilterType = groupDataFilterDto.FilterType;
        item.Text = groupDataFilterDto.Text;
        item.DataSourceItemDtos = groupDataFilterDto.DataSourceItemDtos;
        setGroupDataFilterGridItems(groupDataFilterGridItemArray);
        setEditingGroupDataFilterGridItem(undefined);
        setIsGroupDataFilterGridOpen(false);
    };

    const documentCardStyles: IDocumentCardStyles = {
        root: {
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            height: "100%",
            maxWidth: "",
            minWidth: "50%"
        }
    };

    const columns: IColumn[] = [
        {
            key: "Selected",
            name: "",
            fieldName: "selected",
            minWidth: 225,
            maxWidth: 225,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "buttons",
            onRender: (item: IGroupDataFilterGridItem) => {
                return <Stack horizontal={true} verticalAlign="center" styles={columnStackStyles} tokens={themedSmallStackTokens}>
                    <Stack.Item>
                        <DefaultButton
                            text="Edit"
                            disabled={item.Disabled}
                            onClick={() => {
                                setEditingGroupDataFilterGridItem(item);
                                setIsGroupDataFilterGridOpen(true);
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton
                            text="Delete"
                            styles={removeButtonStyles(theme)}
                            disabled={item.Disabled}
                            onClick={() => {
                                var dialogProps: IDialogModalWrapperProps = {
                                    isVisible: true,
                                    title: "Delete",
                                    subText: "Are you sure you want to delete this group data filter?",
                                    isBlocking: true,
                                    primaryButtonText: "OK",
                                    secondaryButtonText: "Cancel",
                                    onDismiss: () => {
                                        setDialogModalWrapperPropsState(undefined);
                                        var groupDataFilterGridItemArray = [...groupDataFilterGridItems];
                                        var groupDataFilterGrid = groupDataFilterGridItemArray.find(i => i.PageId === item.PageId &&
                                                                                                         i.FieldId === item.FieldId)!;
                                        groupDataFilterGrid.Disabled = true;
                                        setGroupDataFilterGridItems(groupDataFilterGridItemArray);
                                        const options: AxiosRequestConfig = {
                                            method: "delete",
                                            url: "/GroupDataFilter/Delete",
                                            params: {
                                                applicationId: applicationManifest.Id,
                                                groupId: item.GroupId,
                                                pageId: item.PageId,
                                                controlId: item.FieldId
                                            },
                                            cancelToken: source.token
                                        };

                                        axios(options)
                                            .then((result) => {
                                                var groupDataFilterGridItemArray = [...groupDataFilterGridItems];
                                                var indexOfGroupDataFilter = groupDataFilterGridItemArray.indexOf(item);
                                                groupDataFilterGridItemArray.splice(indexOfGroupDataFilter, 1);
                                                setGroupDataFilterGridItems(groupDataFilterGridItemArray);
                                            })
                                            .catch((error) => {
                                                var groupDataFilterGridItemArray = [...groupDataFilterGridItems];
                                                var groupDataFilterGrid = groupDataFilterGridItemArray.find(i => i.PageId === item.PageId &&
                                                                                                                 i.FieldId === item.FieldId)!;
                                                groupDataFilterGrid.Disabled = false;
                                                setGroupDataFilterGridItems(groupDataFilterGridItemArray);

                                                if (error.response == null) {
                                                    console.log("Request canceled", "Group Data Filter Component cleaned up");
                                                } else {
                                                    if (error.response.status === 401 || error.response.status === 409) {
                                                        return;
                                                    }
                                                    var dialogProps = {
                                                        isVisible: true,
                                                        title: "Uh oh",
                                                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                                                        isBlocking: true,
                                                        primaryButtonText: "OK",
                                                        secondaryButtonText: undefined,
                                                        onDismiss: () => {
                                                            setDialogModalWrapperPropsState(undefined);
                                                        },
                                                        onSecondaryButtonDismiss: () => { }
                                                    }
                                                    setDialogModalWrapperPropsState(dialogProps);
                                                }
                                            });
                                    },
                                    onSecondaryButtonDismiss: () => {
                                        setDialogModalWrapperPropsState(undefined);
                                    }
                                }
                                setDialogModalWrapperPropsState(dialogProps);
                            }}
                        />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Page",
            name: "Page",
            fieldName: "pageId",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IGroupDataFilterGridItem) => {
                var page = applicationManifest.Pages.find(i => i.Id === item.PageId)!;
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {page.Name}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Field",
            name: "Field",
            fieldName: "fieldId",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IGroupDataFilterGridItem) => {
                var page = applicationManifest.Pages.find(i => i.Id === item.PageId)!;
                var control = page.Controls.find(i => i.Id === item.FieldId)!;
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {control.LabelText}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "FilterType",
            name: "FilterType",
            fieldName: "filterType",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IGroupDataFilterGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Text variant={"mediumPlus"}>
                            {item.FilterType}
                        </Text>
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Values",
            name: "Values",
            fieldName: "values",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: false,
            data: "array | string",
            onRender: (item: IGroupDataFilterGridItem) => {
                if (item.FilterType === StringConstants.ExtactValues) {
                    var options: IDropdownOption[] = item.DataSourceItemDtos.map(i => ({ key: i.Value, text: i.Text, disabled: true }));
                    return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                        <Stack.Item>
                            <Dropdown
                                selectedKeys={item.DataSourceItemDtos.map(i => i.Value)}
                                disabled={false}
                                multiSelect={true}
                                options={options}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {

                                }} />
                        </Stack.Item>
                    </Stack>
                } else {
                    return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                        <Stack.Item>
                            <Text variant={"mediumPlus"}>
                                {item.Text}
                            </Text>
                        </Stack.Item>
                    </Stack>
                }
            },
            isPadded: true,
        },
    ];

    return (
        <Stack tokens={themedSmallStackTokens} horizontal={false}>
            <Stack.Item>
                <DocumentCard styles={documentCardStyles}>
                    <Stack tokens={themedSmallStackTokens}>
                        <Stack.Item>
                            <Stack horizontal={true} horizontalAlign="space-between" tokens={themedSmallStackTokens} wrap>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <Text variant={"mediumPlus"}>
                                            Total
                                        </Text>
                                        <div className="bolt-pill">
                                            <div className="bolt-pill-content">
                                                {groupDataFilterGridItems != null ? groupDataFilterGridItems.length : ""}
                                            </div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <PrimaryButton
                                            text="Add"
                                            ariaDescription="New group data filter button."
                                            onClick={() => { setIsGroupDataFilterGridOpen(true) }}
                                            allowDisabledFocus />
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <DetailsList
                                items={groupDataFilterGridItems != null ? groupDataFilterGridItems : []}
                                compact={true}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                            />
                        </Stack.Item>
                    </Stack>
                </DocumentCard>
            </Stack.Item>
            {isGroupDataFilterGridOpen &&
                <GroupDataFilterPanel
                    groupDataFilters={groupDataFilterGridItems}
                    groupDataFilter={editingGroupDataFilterGridItem}
                    createdGroupDataFilter={createdGroupDataFilter}
                    editGroupDataFilter={editGroupDataFilter}
                    dismissPanel={() => {
                        setEditingGroupDataFilterGridItem(undefined);
                        setIsGroupDataFilterGridOpen(false);
                    }}>
                </GroupDataFilterPanel>
            }
        </Stack>
    );
}

const columnStackStyles: IStackStyles = {
    root: {
        height: "100%"
    }
};

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};