import React, { useState } from "react";
import { Dialog, DialogFooter, PrimaryButton, DialogType, DefaultButton } from "@fluentui/react";
import { useParams } from "react-router";
import { useId } from "@fluentui/react-hooks";
import axios, { AxiosRequestConfig } from "axios";
import { ICustomerUserGridItem } from "../../../interfaces/users/ICustomerUserGridItem";
import { useSetRecoilState } from "recoil";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";

export interface RemoveUserFromCustomerModalProps {
    customerUser: ICustomerUserGridItem;
    isHidden: boolean;
    deleteCustomerUser: () => void;
    cancel: () => void;
}

export const RemoveUserFromCustomerModal: React.FC<RemoveUserFromCustomerModalProps> = (props: RemoveUserFromCustomerModalProps) => {
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    var { customerId } = useParams();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const labelId: string = useId("dialogLabel");
    const subTextId: string = useId("subTextLabel");
    const dialogContentProps = {
        type: DialogType.normal,
        title: "Approve Removal",
        subText: "Are you sure you want to remove this user from your customer group?"
    };
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
        }),
        [labelId, subTextId],
    );

    const deleteCustomer = (): void => {
        setIsSubmitting(true);
        var customerUserDeleteDto = {
            CustomerId: customerId,
            EmailAddress: props.customerUser.Email
        }
        const options: AxiosRequestConfig = {
            method: "DELETE",
            data: customerUserDeleteDto,
            params: { customerId: customerId },
            url: "/CustomerUser/Delete",
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                props.deleteCustomerUser();
            }).catch((error) => {
                setIsSubmitting(false);
                if (error.response == null) {
                    console.log("Request canceled", "Remove User From Customer Modal Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps = {
                        isVisible: true,
                        title: "Uh oh",
                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                        isBlocking: true,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
    };

    return (
        <>
            <Dialog
                hidden={props.isHidden}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                onDismiss={props.cancel}>
                <DialogFooter>
                    <DefaultButton onClick={props.cancel} text={"Cancel"} disabled={isSubmitting} />
                    <PrimaryButton onClick={deleteCustomer} text="OK" disabled={isSubmitting} />
                </DialogFooter>
            </Dialog>
        </>
    );
}