import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { customerSummariesState, dialogModalWrapperPropsState, navHeaderState, shimmerNavState } from "../../recoil/atoms";
import { Stack, Image, IStackTokens, IStackItemStyles, useTheme, IImageProps, TextField, ITextFieldStyleProps, ITextFieldStyles, Checkbox, PrimaryButton, Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import axios from "axios";
import { IDialogModalWrapperProps } from "../../interfaces/DialogModal";
import { useMsal } from "@azure/msal-react";
import getScreenSize from "../../services/getScreenSize";
import { Controller, useForm } from "react-hook-form";
import { CustomerSummaryDto } from "../../dtos/User/CustomerSummaryDto";
import { useRecoilState } from "recoil";

export const Purge: React.FC = () => {
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [customerSummaries, setCustomerSummaries]: [CustomerSummaryDto[], (customerSummaries: CustomerSummaryDto[]) => void] = useRecoilState(customerSummariesState);
    const { instance } = useMsal();
    const theme = useTheme();
    const accountInfo = instance.getActiveAccount();
    const screenSize: number = getScreenSize();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const defaultValues = {
        email: accountInfo!.username,
        customerId: "",
        experimental: false,
        purgeAll: false
    }
    const { handleSubmit, control, formState: { errors, isDirty, isValid, isSubmitting } } = useForm({ defaultValues: defaultValues, mode: "onChange" });

    useEffect(() => {
        setNavHeader("Purge Account");
        setShimmerNav(true);

        axios.get("/CustomerSummary", { cancelToken: source.token })
            .then((result) => {
                var customerSummaryDtos: CustomerSummaryDto[] = result.data;
                setCustomerSummaries(customerSummaryDtos);
            }).catch((error) => {
                var dialogProps: IDialogModalWrapperProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: "Something went wrong.  Please try your request again again.",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapperPropsState(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                };
                setDialogModalWrapperPropsState(dialogProps);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMaxPaddingTop = (screenSize: number): any => {
        if (screenSize >= 900) {
            return "75px";
        } else if (screenSize > 600) {
            return "60px";
        }
        return "25px";
    }

    const mainStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: getMaxPaddingTop(screenSize),
    };

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    const imageProps: IImageProps = {
        src: './3x/Logo by Intact.png',
        width: screenSize >= 750 ? 550 : screenSize - 200
    };

    function getStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles> {
        return {
            fieldGroup: [
                { width: 300 },
                { display: "flex" }
            ]
        };
    }

    const getDropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300 },
    };

    const purgeAccountRequest = (data: any): void => {
        axios.post(`/PurgeCustomer?customerId=${data.customerId}`)
            .then((result) => {
                var dialogProps: IDialogModalWrapperProps = {
                    isVisible: true,
                    title: "Purge Account",
                    subText: "Most of your account data has been purged.  Please allow 5 minutes for the remaining updates to the system.",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: async () => {
                        await instance.logoutRedirect({
                            account: accountInfo,
                            postLogoutRedirectUri: "/"
                        });
                        setDialogModalWrapperPropsState(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }

                setDialogModalWrapperPropsState(dialogProps);
            }).catch((error) => {
                var dialogProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                    isBlocking: false,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapperPropsState(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
                setDialogModalWrapperPropsState(dialogProps);
            });
    }

    const getCustomerOptions = (): IDropdownOption[] => {
        return customerSummaries.map(i => ({ key: i.CustomerId, text: i.CustomerName }));
    }

    return (
        <>
            <Stack tokens={mainStackTokens} styles={bodyStackItemStyles}>
                <Stack horizontalAlign={"center"}>
                    <Stack.Item>
                        <Image {...imageProps} />
                    </Stack.Item>
                    <Stack.Item>
                        {" "}
                    </Stack.Item>
                    <Stack.Item>
                        <form onSubmit={handleSubmit(purgeAccountRequest)}>
                            <Stack horizontal={true} horizontalAlign={"center"} tokens={themedMediumStackTokens} wrap>
                                <Stack.Item>
                                    <Controller
                                        control={control}
                                        name="email"
                                        rules={{
                                            required: "Email is required"
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <TextField
                                                label="Email"
                                                required={true}
                                                styles={getStyles}
                                                value={value}
                                                disabled={true}
                                                onBlur={onBlur}
                                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                    onChange(newValue);
                                                }} />
                                        )}
                                    />
                                </Stack.Item>
                                {screenSize > 600 &&
                                    <Stack.Item>
                                        {" "}
                                    </Stack.Item>
                                }
                            </Stack>
                            <Stack horizontal={true} horizontalAlign={"center"} tokens={themedMediumStackTokens} wrap>
                                <Stack.Item>
                                    <Controller
                                        control={control}
                                        name="customerId"
                                        rules={{
                                            required: "Customer is required"
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Dropdown
                                                label="Customer"
                                                selectedKey={value}
                                                options={getCustomerOptions()}
                                                placeholder="Select"
                                                styles={getDropdownStyles}
                                                errorMessage={errors != null && errors["customerId"]?.message != null ? errors["customerId"].message : undefined}
                                                onBlur={onBlur}
                                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                                    onChange(option?.key);
                                                }} />
                                        )}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal={true} horizontalAlign={"start"} tokens={themedMediumStackTokens} wrap>
                                <Stack.Item>
                                    <Controller
                                        control={control}
                                        name="experimental"
                                        rules={{
                                            required: "Expieremental acknowledgement is required",
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Checkbox
                                                required={true}
                                                label="I understand this is an expieremental feature."
                                                checked={value}
                                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                    onChange(checked);
                                                }} />
                                        )}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal={true} horizontalAlign={"start"} tokens={themedMediumStackTokens} wrap>
                                <Stack.Item>
                                    <Controller
                                        control={control}
                                        name="purgeAll"
                                        rules={{
                                            required: "Purge of data acknowledgement is required",
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Checkbox
                                                required={true}
                                                label="I understand this will purge all my data releated my account."
                                                checked={value}
                                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                    onChange(checked);
                                                }} />
                                        )}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal={true} horizontalAlign={"end"} tokens={themedMediumStackTokens} wrap>
                                <Stack.Item>
                                    <PrimaryButton
                                        text="Submit"
                                        ariaDescription="Delete account button."
                                        type="submit"
                                        disabled={!isValid || !isDirty || isSubmitting} />
                                </Stack.Item>
                            </Stack>
                        </form>
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
}