import React, { useState } from "react";
import { DefaultButton, IBasePickerSuggestionsProps, IPersonaProps, IStackTokens, Label, NormalPeoplePicker, Panel, PanelType, PrimaryButton, Stack, getInitials, useTheme } from "@fluentui/react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useId } from "@fluentui/react-hooks";
import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { ValidationError } from "../../../model/error/ValidationError";
import { IGroupMemberGridItem } from "../../../interfaces/users/IGroupMemberGridItem";
import { ManifestUserPermissionDto } from "../../../dtos/Manifest/ManifestUserPermissionDto";

export interface NewGroupMemberPanelProps {
    appId: string;
    groupId: string;
    groupMembers: IGroupMemberGridItem[];
    addedUser: (groupMemeber: IGroupMemberGridItem) => void;
    dismissPanel: () => void;
}

export const NewGroupMemberPanel: React.FC<NewGroupMemberPanelProps> = (props: NewGroupMemberPanelProps) => {
    const { handleSubmit, control, formState: { errors, isDirty, isValid }, getValues, setError } = useForm({ defaultValues: defaultValues, mode: "onChange" });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>();
    const [textFilterLength, setTextFilterLength] = useState<number>(0);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const theme = useTheme();
    const tagPickerId = useId("tagPicker");
    const picker = React.useRef(null);
    const CancelToken = axios.CancelToken;

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack horizontal={true} horizontalAlign={"end"}>
                <PrimaryButton
                    styles={buttonStyles}
                    onClick={submitForm}
                    disabled={!isValid || !isDirty || isSubmitting}>
                    Add
                </PrimaryButton>
                <DefaultButton
                    onClick={props.dismissPanel}
                    disabled={isSubmitting}>
                    Cancel
                </DefaultButton>
            </Stack>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDirty, isSubmitting, isValid, props.dismissPanel],
    );

    const submitForm = async () => {
        setIsSubmitting(true);
        var data = getValues();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        var persona: IPersonaProps = data.userPersona![0];
        var groupUserUpsertDto = {
            Email: persona!.secondaryText!,
            GroupId: props.groupId
        }
        const options: AxiosRequestConfig = {
            method: "POST",
            data: groupUserUpsertDto,
            url: "/GroupUser",
            params: {
                applicationId: props.appId
            },
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                var groupMemeber: IGroupMemberGridItem = {
                    Avatar: persona.imageUrl ? persona.imageUrl : "",
                    EmailAddress: persona!.secondaryText!,
                    FullName: persona.text ? persona.text : "",
                    GroupId: props.groupId,
                    Disabled: false,
                    Selected: false
                }
                props.addedUser(groupMemeber);
                setIsSubmitting(false);
            }).catch((error) => {
                setIsSubmitting(false);
                if (error.response == null) {
                    console.log("Request canceled", "New Group User Panel Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        //set errors manually
                        validationErrors.forEach((validationError: ValidationError) => {
                            var identifier = validationError.GroupIdentifier as any === "EmailAddress" ?  "userPersona" :
                                validationError.GroupIdentifier as any
                            setError(identifier, {
                                type: "manual",
                                message: validationError.Message
                            })
                        });
                    }else {
                        var dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
    }

    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested People',
        noResultsFoundText: textFilterLength >= 3 ? "No results found" : "Minimum of three characters needed",
        loadingText: 'Loading',
        showRemoveButtons: false
    };

    const onResolveSuggestions = async (filterText: string, tagList: IPersonaProps[]): Promise<IPersonaProps[]> => {
        if (cancelTokenSource != null) {
            cancelTokenSource.cancel();
        }
        setTextFilterLength(filterText == null ? 0 : filterText.length);
        if (filterText == null || filterText.length < 3) {
            return [];
        }
        const source = CancelToken.source();
        setCancelTokenSource(source);
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/ManifestUserPermission/GetBySearchValue",
            params: {
                applicationId: props.appId,
                searchValue: filterText
            },
            cancelToken: source.token
        };

        return axios(options)
            .then((result) => {
                setCancelTokenSource(undefined);
                var manifestUserPermissionDtos: ManifestUserPermissionDto[] = result.data;
                var personaProps: IPersonaProps[] = [];
                manifestUserPermissionDtos.forEach(user => {
                    if (props.groupMembers.findIndex(i => i.EmailAddress === user.EmailAddress) > -1) {
                        return;
                    }
                    var personaProp: IPersonaProps = {
                        imageUrl: user.Avatar == null && user.FullName == null ? "./default_user_image.jpg" : user.Avatar,
                        imageInitials: user.FullName != null
                            ? getInitials(user.FullName, false)
                            : "",
                        imageShouldFadeIn: false,
                        text: user.FullName != null ? user.FullName : undefined,
                        secondaryText: user.EmailAddress,
                        showSecondaryText: true,
                        theme: theme
                    }
                    personaProps.push(personaProp);
                });
                return personaProps;
            })
            .catch((error) => {
                return [];
            });
    };

    return (
        <Panel
            headerText="Add user to group"
            isOpen={true}
            onDismiss={props.dismissPanel}
            type={PanelType.custom}
            customWidth="500px"
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <form onSubmit={handleSubmit(submitForm)}>
                <Stack horizontal={false} tokens={themedSmallStackTokens} style={{ "paddingTop": "25px" }}>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="userPersona"
                            rules={{
                                validate: {
                                    unique: (value: IPersonaProps[] | undefined): string | undefined => {
                                        if (value == null || value.length === 0) {
                                            return "A user is required";
                                        } else {
                                            var foundEmail = props.groupMembers.findIndex(i => i.EmailAddress === value[0].secondaryText);
                                            if (foundEmail >= 0) {
                                                return "The user is already in this group.";
                                            }
                                        }
                                        return undefined;
                                    },
                                },
                            }}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <>
                                        <Label htmlFor={tagPickerId} className="CustomErrorLabel">Find User of Application</Label>
                                        <div id={tagPickerId}>
                                            <NormalPeoplePicker
                                                onResolveSuggestions={(filter: string, selectedItems?: IPersonaProps[] | undefined) => {
                                                    return onResolveSuggestions(filter, selectedItems !== undefined ? selectedItems : []);
                                                }}
                                                getTextFromItem={getTextFromItem}
                                                pickerSuggestionsProps={suggestionProps}
                                                key={'normal'}
                                                selectionAriaLabel="Selected person"
                                                inputProps={{ "aria-label": "People Picker" }}
                                                componentRef={picker}
                                                selectedItems={value}
                                                styles={{}}
                                                itemLimit={1}
                                                onChange={(items?: IPersonaProps[] | undefined) => {
                                                    if (items != null && items.length > 0) {
                                                        onChange(items);
                                                    } else {
                                                        onChange([]);
                                                    }
                                                }}
                                                resolveDelay={300}
                                            />
                                        </div>
                                        <div className="CustomError">
                                            <ErrorMessage errors={errors} name={"userPersona"} />
                                        </div>
                                    </>
                                );
                            }}
                        />
                    </Stack.Item>
                </Stack>
            </form>
        </Panel>
    );
}

const defaultValues = {
    userPersona: undefined
}

const buttonStyles = { root: { marginRight: 8 } };

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};