import React, { useState, useEffect } from "react";
import { INavLinkGroup, INavLink, INavStyles, Nav } from "@fluentui/react";
import { StringConstants } from "../../constants/StringConstants";
import { useNavigate } from "react-router";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { navPageSelectedState, applicationManifestState, navHeaderState, searchResultState, searchTextFilterState, showNavPageMobileState, formSearchDataState } from "../../recoil/atoms";
import { ApplicationManifest } from "../../model/manifest/ApplicationManifest";
import getScreenSize from "../../services/getScreenSize";
import { hideMobileNav } from "../../services/sideNavService";

export interface INavSettings {
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
}

export const NavSettings: React.FC<INavSettings> = (props: INavSettings) => {
    const [navPageSelected, setNavPageSelected] = useRecoilState(navPageSelectedState);
    const [applicationManifest, setApplicationManifest]: [ApplicationManifest, (applicationManifest: ApplicationManifest | null) => void] = useRecoilState(applicationManifestState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const setFormSearchDataState = useSetRecoilState(formSearchDataState);
    const setSearchResult = useSetRecoilState(searchResultState);
    const setSearchTextFilter = useSetRecoilState(searchTextFilterState);
    const setShowNavPageMobile = useSetRecoilState(showNavPageMobileState);
    const [navLinkGroups, setNavLinkGroups] = useState<INavLinkGroup[]>([]);
    const navigate = useNavigate();
    const screenSize: number = getScreenSize();

    useEffect(() => {
        const getUrl = (): string => {
            if (applicationManifest == null) {
                return "";
            }
            var page = applicationManifest.Pages.find(i => i.Id === navPageSelected);
            if (page == null) {
                return `${window.ReAccessWeb.process.env.APP_BUILDER_URL}Application/${applicationManifest.Id}`;
            } else {
                return `${window.ReAccessWeb.process.env.APP_BUILDER_URL}Application/${applicationManifest.Id}/FormBuilder/${page.Id}`;
            }
        }

        var navLinks: INavLink[] = [];
        var appCatalog: INavLink = {
            name: "App Catalog",
            url: "",
            icon: "ThumbnailView",
            key: StringConstants.AppCatalogMenuItem,
            onClick: () => {
                hideMobileNav(screenSize, setShowNavPageMobile);
                setApplicationManifest(null);
                setFormSearchDataState(undefined);
                setSearchResult(undefined);
                setSearchTextFilter("");
                setNavPageSelected(StringConstants.AppCatalogMenuItem);
                setNavHeader("App Catalog");
                navigate("AppCatalog");
            }
        }
        navLinks.push(appCatalog);
        if (applicationManifest != null && applicationManifest.IsBuilderOrAdmin) {
            var appBuilder: INavLink = {
                name: "App Builder",
                url: getUrl(),
                icon: "BuildDefinition",
                key: "App Builder",
                target: "_blank",
                onClick: () => {
                    hideMobileNav(screenSize, setShowNavPageMobile);
                }
            }
            navLinks.push(appBuilder);
        }
        if (applicationManifest != null && applicationManifest.IsAdmin) {
            var appSettings: INavLink = {
                name: "App Settings",
                url: "",
                icon: "Settings",
                key: StringConstants.AppSettingsMenuItem,
                onClick: () => {
                    hideMobileNav(screenSize, setShowNavPageMobile);
                    setNavPageSelected(StringConstants.AppSettingsMenuItem);
                    navigate(`Application/${applicationManifest.Id}/ApplicationSettings`);
                }
            }
            navLinks.push(appSettings);
        }
        if (props.expanded) {
            var showLess: INavLink = {
                name: "Show Less",
                url: "",
                icon: "DoubleChevronLeft",
                key: "Show Less",
                onClick: () => {
                    hideMobileNav(screenSize, setShowNavPageMobile);
                    localStorage.setItem(StringConstants.SideNavExpanded, "false");
                    props.setExpanded(false);
                }
            }
            navLinks.push(showLess);
        } else {
            var showMore: INavLink = {
                name: "Show More",
                url: "",
                icon: "DoubleChevronRight",
                key: "Show More",
                onClick: () => {
                    localStorage.setItem(StringConstants.SideNavExpanded, "true");
                    props.setExpanded(true);
                }
            }
            navLinks.push(showMore);
        }

        var navLinkGroup: INavLinkGroup = {
            links: navLinks
        };
        var navLinkGroups: INavLinkGroup[] = [];
        navLinkGroups.push(navLinkGroup);
        setNavLinkGroups(navLinkGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationManifest, navPageSelected, navigate, props, screenSize, setApplicationManifest, setNavHeader, setNavPageSelected, setSearchResult, setSearchTextFilter, setShowNavPageMobile]);

    return (
        <Nav
            selectedKey={navPageSelected}
            ariaLabel="Settings Area"
            styles={navStyles}
            groups={navLinkGroups}
        />
    );
}

const navStyles: Partial<INavStyles> = {
    root: {
        overflowY: "auto",
    },
};