export const formatCurrency = (event: any, value: string | undefined): string => {
    // don't validate empty input
    if (value == null || value === "") { return ""; }

    var isNegative = value.indexOf("-") === 0;
    if (isNegative) {
        value = value.substring(1);
    } else {
        isNegative = value.indexOf("(") === 0 && value.indexOf(")") === value.length - 1;
        if (isNegative) {
            value = value.substring(1);
            value = value.substring(value.length - 1, 1);
        }
    }

    if (event != null) {
        // original length
        var original_len = value.length;
        // initial caret position
        var caret_pos = event.target.selectionStart;

        const element = event.target;
        window.requestAnimationFrame(() => {
            if (value != null) {
                var updated_len = value.length;
                caret_pos = updated_len - original_len + caret_pos;
                element.selectionStart = caret_pos;
                element.selectionEnd = caret_pos;
            }
        })
    }

    // check for decimal
    if (value.indexOf(".") >= 0) {
        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = value.indexOf(".");

        // split number by decimal point
        var left_side = value.substring(0, decimal_pos);
        var right_side = value.substring(decimal_pos);

        // add commas to left side of number
        left_side = formatNumber(left_side);

        // validate right side
        right_side = formatNumber(right_side);

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        value = left_side + "." + right_side;
    } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        value = formatNumber(value);
    }

    return isNegative ? "-" + value : value;
}

function formatNumber(n: string): string {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}