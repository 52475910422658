import React from "react";
import { DefaultButton } from "@fluentui/react";
import { useRecoilValue } from "recoil";
import { applicationManifestState } from "../../../recoil/atoms";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { PageData } from "../../../model/manifest/PageData";
import { getPage } from "../../../services/manifestService";

export const NewEntityButton: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    var { appId, formId } = useParams();
    const pageSelected: PageData = getPage(applicationManifest, formId!);
    const navigate = useNavigate();

    return (
        <>
            {(applicationManifest.IsAdmin || pageSelected!.Insert) &&
                <DefaultButton
                    text="New"
                    iconProps={{ iconName: "Add" }}
                    onClick={() => {
                        navigate(`/Application/${appId}/Form/${formId}/NewRecord`, { replace: false });
                    }}
                />
            }
        </>
    );
}