import { DetailsList, DetailsListLayoutMode, IColumn, Icon, mergeStyleSets, SelectionMode } from "@fluentui/react";
import React, { } from "react";
import { ControlData } from "../../../model/manifest/ControlData";
import { AttachmentModel } from "../../../model/reaccessData/AttachmentModel";
import moment from 'moment';
import { DocumentFileLink } from "./DocumentFileLink";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { ISubFormField } from "../../../interfaces/ISubFormField";

export interface ReadOnlyDocumentListFieldProps {
    control: ControlData;
    subFormField: ISubFormField | undefined;
    attachments: AttachmentModel[];
}

export const ReadOnlyDocumentListField: React.FC<ReadOnlyDocumentListFieldProps> = (props: ReadOnlyDocumentListFieldProps) => {

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
    });

    const columns: IColumn[] = [
        {
            key: 'FileTypeReadOnlyDocumentListField',
            name: 'File Type',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'fileType',
            minWidth: 16,
            maxWidth: 16,
            //onColumnClick: this._onColumnClick,
            onRender: (item: AttachmentModel) => {
                return <Icon {...getFileTypeIconProps({ extension: item.FileExtension, size: 16, imageFileType: "svg" })} />
            },
        },
        {
            key: 'NameReadOnlyDocumentListField',
            name: 'Name',
            fieldName: 'name',
            minWidth: 75,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            // isSorted: true,
            // isSortedDescending: false,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            //onColumnClick: this._onColumnClick,
            data: 'string',
            onRender: (item: AttachmentModel) => {
                return <DocumentFileLink
                    pageId={props.subFormField == null ? props.control.PageId : props.subFormField.ParentFormId}
                    subFormId={props.subFormField == null ? undefined : props.control.PageId}
                    attachmentModel={item}>
                </DocumentFileLink>
            },
            isPadded: true,
        },
        {
            key: 'DateModifiedTypeReadOnlyDocumentListField',
            name: 'Date Modified',
            fieldName: 'dateModifiedValue',
            minWidth: 75,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: false,
            //onColumnClick: this._onColumnClick,
            data: 'string',
            onRender: (item: AttachmentModel) => {
                return <span>{moment(item.UpdatedUtc).format("M/D/YYYY")}</span>;
            },
            isPadded: true,
        },
        {
            key: 'ModifiedByReadOnlyDocumentListField',
            name: 'Modified By',
            fieldName: 'modifiedBy',
            minWidth: 75,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: false,
            data: 'string',
            //onColumnClick: this._onColumnClick,
            onRender: (item: AttachmentModel) => {
                return <span>{item.UpdatedByName}</span>;
            },
            isPadded: true,
        },
        {
            key: 'FileSizeReadOnlyDocumentListField',
            name: 'File Size',
            fieldName: 'fileSizeRaw',
            minWidth: 75,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
            //onColumnClick: this._onColumnClick,
            onRender: (item: AttachmentModel) => {
                return <span>{item.FileSize}</span>;
            },
        },
    ];

    const getKey = (item: any, index?: number): string => {
        return item.key;
    }

    return (
        <>
            <DetailsList
                items={props.attachments}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={getKey}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
            />
        </>
    )
}