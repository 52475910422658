import React from "react";
import { DefaultButton } from "@fluentui/react";
import { applicationManifestState, searchTextFilterState } from "../../../recoil/atoms";
import { ConnectForm } from "../form/ConnectForm";
import { useSetRecoilState } from "recoil";
import { cloneDeep } from "lodash";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { StringConstants } from "../../../constants/StringConstants";

export interface ClearSearchButtonProps {
    shimmerControlsOnSubmit: boolean;
    formId: string;
}

/**
 * Clears the search page by removing search results and setting the search form to its original state.
 */
export const ClearSearchButton: React.FC<ClearSearchButtonProps> = (props: ClearSearchButtonProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const setSearchTextFilter = useSetRecoilState(searchTextFilterState);

    return (
        <>
            <ConnectForm>
                {(methods: any) =>
                    <DefaultButton
                        text="Clear"
                        iconProps={{ iconName: "Clear" }}
                        disabled={props.shimmerControlsOnSubmit === false ? true : false}
                        onClick={() => {
                            setSearchTextFilter("");
                            var form = applicationManifest.Pages.find((f) => f.Id === props.formId)!;
                            var myValues = cloneDeep(methods.getValues());
                            for (let key in myValues) {
                                if (myValues.hasOwnProperty(key)) {
                                    //some of the keys have prefix's.  Need to scrub them first to be able to look them up on the form.
                                    let controlId = key.startsWith(StringConstants.FromDateSearchParameter) ? key.replace(StringConstants.FromDateSearchParameter, "") :
                                        key.startsWith(StringConstants.ToDateSearchParameter) ? key.replace(StringConstants.ToDateSearchParameter, "") :
                                        key.startsWith(StringConstants.FromDateTimeSearchParameter) ? key.replace(StringConstants.FromDateTimeSearchParameter, "") :
                                        key.startsWith(StringConstants.ToDateTimeSearchParameter) ? key.replace(StringConstants.ToDateTimeSearchParameter, "") :
                                        key.startsWith(StringConstants.FromTimeSearchParameter) ? key.replace(StringConstants.FromTimeSearchParameter, "") :
                                        key.startsWith(StringConstants.ToTimeSearchParameter) ? key.replace(StringConstants.ToTimeSearchParameter, "") :
                                        key.startsWith(StringConstants.FromCurrencySearchParameter) ? key.replace(StringConstants.FromCurrencySearchParameter, "") :
                                        key.startsWith(StringConstants.ToCurrencySearchParameter) ? key.replace(StringConstants.ToCurrencySearchParameter, "") :
                                        key;

                                    let control = form.Controls.find((c) => c.Id === controlId)!;
                                    if (control.ControlType === 2 && control.FieldType === 39) {
                                        myValues[key] = [];
                                    } else {
                                        
                                        myValues[key] = "";
                                    }

                                }
                            }
                            methods.reset(myValues);
                        }}
                    />}
            </ConnectForm>
        </>
    );
}