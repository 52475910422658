import React from "react";
import './TextBlockField.css';
import { ControlData } from "../../../../../model/manifest/ControlData";
import { TextField, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { getPrefix, getSuffix } from "../../../../../helpers/TextBoxHelpers";

export interface TextBlockFieldProps {
    control: ControlData;
}

export const TextBlockField: React.FC<TextBlockFieldProps> = (props: TextBlockFieldProps) => {
    const textBoxId = useId("textBox");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textBoxId}`
    };

    return (
        <>
            <TooltipHost
                content={props.control.ToolTipText}
                id={tooltipId}
                calloutProps={calloutProps}
            >
                <TextField
                    id={textBoxId}
                    className="textBlockField"
                    borderless
                    multiline
                    autoAdjustHeight
                    readOnly={true}
                    resizable={false}
                    prefix={getPrefix(props.control)}
                    suffix={getSuffix(props.control)}
                    value={props.control.TextValue} />
            </TooltipHost>
        </>
    )
}
