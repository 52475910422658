import { ApplicationManifest } from "../model/manifest/ApplicationManifest";
import { ControlData } from "../model/manifest/ControlData";
import { RegexConstants } from "../constants/RegexConstants";
import { BusinessRule } from "../model/manifest/BusinessRule";

export const getRules = (applicationManifest: ApplicationManifest, control: ControlData, globalBusinessRules: Array<BusinessRule>, shouldValidate: boolean, isAdding: boolean): any => {
    var r: any = {};
    if (shouldValidate) {
        var customRegex: RegExp | undefined;
        if (control.BusinessRuleName != null && control.BusinessRuleName !== "") {
            var customRule = applicationManifest.BusinessRules.find(i => i.Name === control.BusinessRuleName);
            if (customRule == null) {
                customRule = globalBusinessRules.find(i => i.Name === control.BusinessRuleName);
            }
            if (customRule != null && customRule.RuleType === 1) {
                customRegex = new RegExp(customRule.Expression);
            }
        }
        r = {
            required: isRequired(control, isAdding) ? `${control.LabelText} is required` : null,
            validate: {
                controlRegex: (value: string) => {
                    if (control.ControlType === 3) {
                        try {
                            return customRegex != null &&
                                value != null &&
                                value !== "" &&
                                value.toString().toLowerCase().search(customRegex) === -1 ?
                                customRule?.ErrorMessage :
                                null;
                        } catch {
                            return null;
                        }
                    } else {
                        try {
                            return customRegex != null &&
                                value != null &&
                                value !== "" &&
                                value.search(customRegex) === -1 ?
                                customRule?.ErrorMessage :
                                null;
                        } catch {
                            return null;
                        }
                    }
                },
                email: (value: string) =>
                    control.FieldType === 3 &&
                        value != null &&
                        value !== "" &&
                        value.search(RegexConstants.EmailRegex) === -1 ?
                        `${control.LabelText} is invalid` :
                        null,
                url: (value: string) =>
                    control.FieldType === 23 &&
                        value != null &&
                        value !== "" &&
                        value.search(RegexConstants.UrlRegex) === -1 ?
                        `${control.LabelText} is invalid` :
                        null,
                requiredList: (value: string[]) =>
                    control.FieldType === 39 &&
                        control.IsRequired &&
                        (value == null ||
                            value.length === 0) ?
                        `${control.LabelText} is required` :
                        null,
            }
        };
    }
    return r;
}

const isRequired = (control: ControlData, isAdding: boolean): boolean => {
    //added control type 3 (boolean) here since even though it might be required it will always have a value (true = checked or false = unchecked)
    if (control.ControlType === 3 ||
        (isAdding && control.ControlType === 4 && (control.FieldType === 29 || control.FieldType === 30 || control.FieldType === 31))) {
        return false;
    }
    return control.IsRequired;
}