export const base64ToArrayBuffer = (base64: string): Uint8Array => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

export const getMimeTypeByFileName = (fileName: string): string => {
    const fileExtension = getFileExtension(fileName);
    if (fileExtension === "") return "";
    switch (fileExtension) {
        case 'jpe':
        case 'jpg':
        case 'jpeg':
        case 'jfif':
        case 'pjpeg':
        case 'pjp':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        case 'tif':
        case 'tiff':
            return 'image/tiff';
        case 'bmp':
            return 'image/bmp';
        case 'ico':
        case 'cur':
            return 'image/x-icon';
    }
    return '';
}

function getFileExtension(fileName: string): string {
    if (!fileName) return '';
    const fileNameSplit = fileName.split('.');
    if (fileNameSplit.length <= 1) return '';
    return fileNameSplit[fileNameSplit.length - 1];
}