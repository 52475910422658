import { RegexConstants } from "../constants/RegexConstants";

export const convertStringToGuid = (stringToConvert: string | undefined): string | undefined => {
    if (stringToConvert == null || stringToConvert.length !== 32) { return ""; }

    var parts = [];
    parts.push(stringToConvert.slice(0, 8));
    parts.push(stringToConvert.slice(8, 12));
    parts.push(stringToConvert.slice(12, 16));
    parts.push(stringToConvert.slice(16, 20));
    parts.push(stringToConvert.slice(20, 32));
    return parts.join('-');
}

export const removeDashFromGuid = (stringToConvert: string): string => {
    return stringToConvert.split("-").join("");
}

export const checkIfValidUuid = (strToTest: string): boolean => {
    return strToTest.search(RegexConstants.UuidRegex) === -1 ? false : true;
  }