import { IStackTokens, SelectionMode, Stack } from "@fluentui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { IEntityListResult } from "../../../interfaces/EntityListResult";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { ControlData } from "../../../model/manifest/ControlData";
import { applicationManifestState, dialogModalWrapperPropsState, formSearchDataState, resetSearchPageState, searchResultState } from "../../../recoil/atoms";
import { buildDefaultSearchValues, buildParamsAndSearchCall, buildSearchApplicationEntity } from "../../../services/applicationEntityService";
import { createImageUrlFromArray, downloadFileFromImageUrl } from "../../../services/documentService";
import { ClearSearchButton } from "./ClearSearchButton";
import { ExportButton } from "./ExportButton";
import { ImportButton } from "./ImportButton";
import { NewEntityButton } from "./NewEntityButton";
import { ResetSearchButton } from "./ResetSearchButton";
import { SearchButton } from "./SearchButton";
import { SearchControls } from "./SearchControls";
import { SearchHeader } from "./SearchHeader";
import { EntityListResult } from "../gridLayout/EntityListResult";
import { ValidationError } from "../../../model/error/ValidationError";
import { FormName } from "../form/FormName";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import useRefreshManifestDependencies from "../../../services/useRefreshManifestDependencies";
import { refreshManifest } from "../../../services/manifestService";
import { useRecoilValue } from "recoil";

export const SearchPage: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [searchResult, setSearchResultState]: [IEntityListResult, (searchResult: IEntityListResult | undefined) => void] = useRecoilState(searchResultState);
    const [formSearchData, setFormSearchDataState]: [any, (formSearchData: any | undefined) => void] = useRecoilState(formSearchDataState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const setResetSearchPage = useSetRecoilState(resetSearchPageState);
    const [applicationEntity, setApplicationEntity] = useState<ApplicationEntity>();
    const [shimmerControlsOnSubmit, setShimmerControlsOnSubmit] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    var { appId, formId } = useParams();
    const methods = useForm({
        mode: "onChange",
        defaultValues: buildDefaultSearchValues(formId!, applicationManifest),
    });
    const refreshManifestDependencies = useRefreshManifestDependencies();
    const stackTokens: IStackTokens = { childrenGap: 20 };
    const navigate = useNavigate();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if (searchResult != null && formSearchData != null) {
            methods.reset(formSearchData);
            return;
        }

        var page = applicationManifest.Pages.find(i => i.Id === formId);
        if (page == null) return; //TODO: Reload or message?

        var controls = page.Controls.filter(i => i.SearchRow != null && i.SearchColumn != null && i.SearchRow > 0 && i.SearchColumn > 0 && i.View);
        controls.sort(function (a, b) {
            if (a.SearchRow != null && b.SearchRow != null && a.SearchColumn != null && b.SearchColumn != null) {
                return a.SearchRow - b.SearchRow || a.SearchColumn - b.SearchColumn;
            } else {
                return 0;
            }
        });

        var searchControlsApplicationEntity = buildSearchApplicationEntity(page);

        if (page.ExecuteSearchOnLoad) {
            var searchData: any = {};
            searchControlsApplicationEntity.Fields.forEach((field) => {
                searchData[field.Id] = field.TextValue;
            });
            var searchOnloadCall = buildParamsAndSearchCall(searchData, appId!, formId!, applicationManifest.Version, source, true, true);
            setShimmerControlsOnSubmit(false);
            setIsLoading(true);
            searchOnloadCall
                .then((result) => {
                    handleSuccess(result);
                    setShimmerControlsOnSubmit(true);
                    setIsLoading(false);
                    setApplicationEntity(searchControlsApplicationEntity);
                }).catch((error) => {
                    setShimmerControlsOnSubmit(true);
                    setIsLoading(false);
                    handleError(error);
                });
        } else {
            setShimmerControlsOnSubmit(true);
        }

        return function cleanup() {
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handlePopstate = () => {
            // Trigger the effect when browser back button is clicked
            setSearchResultState(undefined);
            setFormSearchDataState(undefined);
            setResetSearchPage(true);
        };

        // Add event listener for popstate
        window.addEventListener('popstate', handlePopstate);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (applicationEntity == null) {
            return;
        }
        //build default search values when application entity is set
        var myFormValues = buildDefaultSearchValues(formId!, applicationManifest);
        methods.reset(myFormValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationEntity]);

    const handleSuccess = (searchResult: any): void => {
        if (searchResult.data.RecordCount > 0) {
            var sr: IEntityListResult = searchResult.data;
            setSearchResultState(sr);
        } else if (searchResult.data.RecordCount === 0) {
            var dialogProps: IDialogModalWrapperProps = {
                isVisible: true,
                title: "Search Result",
                subText: "No records found",
                isBlocking: false,
                primaryButtonText: "OK",
                secondaryButtonText: undefined,
                onDismiss: () => {
                    setDialogModalWrapperPropsState(undefined);
                },
                onSecondaryButtonDismiss: () => { }
            }
            setDialogModalWrapperPropsState(dialogProps);
            setSearchResultState(undefined);
        }
    }

    const handleError = (error: any): void => {
        setShimmerControlsOnSubmit(true);
        if (error.response == null) {
            console.log("Request canceled", "Form Container Component cleaned up");
            return;
        } else {
            if (error.response.status === 401 || error.response.status === 409) {
                return;
            }
            var dialogProps: IDialogModalWrapperProps | undefined;
            if (error.response.status === 408) {
                dialogProps = {
                    isVisible: true,
                    title: "Request timeout",
                    subText: "Please try your request again",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        refreshManifest(
                            refreshManifestDependencies,
                            navigate,
                            appId!
                        );
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            } else if (error.response.status === 422 && error.response.data.error != null) {
                var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                dialogProps = {
                    isVisible: true,
                    title: validationErrors[0].Title,
                    subText: validationErrors[0].Message,
                    isBlocking: false,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapperPropsState(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            } else {
                dialogProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapperPropsState(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            }
            setDialogModalWrapperPropsState(dialogProps);
        }
    }

    const submitForm = (isSearchAction: boolean) => {
        var data = methods.getValues();
        //Store off search data to re-load it on a back click
        setFormSearchDataState(data);
        setShimmerControlsOnSubmit(false);

        var searchCall = buildParamsAndSearchCall(data, appId!, formId!, applicationManifest.Version, source, false, isSearchAction);
        setIsLoading(true);
        searchCall
            .then((result) => {
                if (result.data != null) {
                    if (isSearchAction) {
                        handleSuccess(result);
                    }
                    else {
                        var imageUrl = createImageUrlFromArray(result.data.ExportResult, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                        var pageData = applicationManifest.Pages.find(i => i.Id === formId);
                        downloadFileFromImageUrl(imageUrl, pageData!.Name + ".xlsx")
                    }
                }
                setIsLoading(false);
                setShimmerControlsOnSubmit(true);
            }).catch((error) => {
                handleError(error);
                setIsLoading(false);
            });
    };

    const getSearchControls = (): ControlData[][] => {
        var page = applicationManifest.Pages.find(i => i.Id === formId)!;
        var controls = page.Controls.filter(i => i.SearchRow != null && i.SearchColumn != null && i.SearchRow > 0 && i.SearchColumn > 0 && i.View);
        controls.sort(function (a, b) {
            if (a.SearchRow != null && b.SearchRow != null && a.SearchColumn != null && b.SearchColumn != null) {
                return a.SearchRow - b.SearchRow || a.SearchColumn - b.SearchColumn;
            } else {
                return 0;
            }
        });

        var controlsToSet: Array<ControlData[]> = [];
        controls.forEach((c) => {
            if (controlsToSet[c.SearchRow! - 1] == null) {
                controlsToSet[c.SearchRow! - 1] = [];
            }
            controlsToSet[c.SearchRow! - 1].push(c);
        });
        controlsToSet = controlsToSet.filter(i => i != null);

        return controlsToSet;
    }

    const getSearchColumns = (): ControlData[] => {
        var page = applicationManifest.Pages.find(i => i.Id === formId)!;
        var searchResultControls = page.Controls.filter(i => i.SearchResultOrder != null && i.SearchResultOrder > 0);
        searchResultControls = searchResultControls!.sort((a, b) => a.SearchResultOrder! - b.SearchResultOrder!);
        return searchResultControls;
    }

    const setSearchWarningMessage = (): string | undefined => {
        if (searchResult == null || searchResult.ApplicationEntityResults.length === searchResult.RecordCount) {
            return undefined;
        }
        return "The search results exceeded 1000 records. Please refine your search or export the data."
    }

    return (
        <>
            <FormProvider {...methods}>
                <form>
                    <Stack tokens={numericalSpacingStackTokens}>
                        <Stack.Item>
                            <FormName
                                formId={formId!}
                                entityId={undefined}
                                variantSize="xxLarge"
                                applicationEntity={undefined}>
                            </FormName>
                        </Stack.Item>
                        <Stack.Item>
                            <SearchHeader></SearchHeader>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack horizontal wrap horizontalAlign="end" tokens={stackTokens}>
                                <Stack.Item>
                                    <NewEntityButton></NewEntityButton>
                                </Stack.Item>
                                <Stack.Item>
                                    <ImportButton></ImportButton>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            {(getSearchControls().length > 0) &&
                                <SearchControls
                                    searchControlsToDisplay={getSearchControls()}
                                    applicationEntitySearch={applicationEntity}
                                    shimmerControlsOnSubmit={shimmerControlsOnSubmit}>
                                </SearchControls>
                            }
                        </Stack.Item>
                        <Stack.Item>
                            <Stack horizontal wrap horizontalAlign="end" tokens={stackTokens}>
                                <Stack.Item>
                                    <ClearSearchButton
                                        shimmerControlsOnSubmit={shimmerControlsOnSubmit}
                                        formId={formId!}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <ResetSearchButton
                                        shimmerControlsOnSubmit={shimmerControlsOnSubmit} />
                                </Stack.Item>
                                <Stack.Item>
                                    <SearchButton
                                        shimmerControlsOnSubmit={shimmerControlsOnSubmit}
                                        onSearchClicked={() => {
                                            submitForm(true);
                                        }}>
                                    </SearchButton>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <EntityListResult
                                grid={undefined}
                                gridName="Search Results"
                                entityListResult={searchResult}
                                columnControls={getSearchColumns()}
                                isLoading={isLoading}
                                displayViewButton={true}
                                displayRemoveButton={false}
                                displayAddNewExistingButton={false}
                                disableAddNewExistingButton={false}
                                hideGridAddExistingButton={true}
                                hideGridAddNewButton={true}
                                navigateOnEditClick={true}
                                removeButtonText="Delete"
                                selectionMode={SelectionMode.none}
                                warningMessage={setSearchWarningMessage()}
                                removeEntity={undefined}
                                addEntity={undefined}
                                editEntity={undefined}
                            />
                        </Stack.Item>
                        {searchResult != null &&
                            <Stack.Item>
                                <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
                                    <Stack.Item>
                                        <ExportButton
                                            shimmerControlsOnSubmit={shimmerControlsOnSubmit}
                                            onSearchClicked={() => {
                                                submitForm(false);
                                            }}>
                                        </ExportButton>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        }
                    </Stack>
                </form>
            </FormProvider>
        </>
    );
}

const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};
