
import axios, { AxiosPromise, AxiosRequestConfig, CancelTokenSource } from 'axios';
import moment from 'moment';
import { StringConstants } from "../constants/StringConstants";
import { ApplicationEntitySearchDto } from '../dtos/ApplicationEntity/ApplicationEntitySearchDto';
import { ApplicationManifest } from "../model/manifest/ApplicationManifest";
import { PageData } from "../model/manifest/PageData";
import { ApplicationEntity } from "../model/reaccessData/ApplicationEntity";
import { ApplicationEntityUpsertDto } from "../model/reaccessData/ApplicationEntityUpsertDto";
import { AttachmentModel } from "../model/reaccessData/AttachmentModel";
import { AttachmentModelUpsertDto } from "../model/reaccessData/AttachmentModelUpsertDto";
import { Field } from "../model/reaccessData/Field";
import { FieldUpsertDto } from "../model/reaccessData/FieldUpsertDto";
import { isAuditDateTimeField, isAuditField } from "./fieldService";
import { ControlData } from '../model/manifest/ControlData';
import { ControlEntitySearchDto } from '../dtos/ApplicationEntity/ControlEntitySearchDto';
import { CommentModelUpsertDto } from '../model/reaccessData/CommentModelUpsertDto';
import { CommentModel } from '../model/reaccessData/CommentModel';
import { SubFormModelUpsertDto } from '../model/reaccessData/SubFormModelUpsertDto';
import { Form } from '../model/manifest/Form';
import { SubForm } from '../model/manifest/SubForm';
import { SubFormModel } from '../model/reaccessData/SubFormModel';
import { formatCurrency } from './currencyService';
import { getForm } from './manifestService';
import { getDefaultDateValueForSearchControl, getDefaultTimeValueForSearchControl } from './fromToDateService';


export const buildSearchApplicationEntity = (page: PageData): ApplicationEntity => {
    var controls = page.Controls.filter(i => i.SearchRow != null && i.SearchRow > 0 && i.SearchColumn != null && i.SearchColumn > 0);
    var fields: Field[] = [];
    controls.forEach((control) => {
        var field: Field = {
            Id: control.Id!,
            TextValue: GetDefaulSearchTextValue(control),
            TextList: GetDefaultSearchListValue(control),
            UpdatedById: null,
            UpdatedByName: null,
            UpdatedUtc: null,
            Attachments: [],
            Comments: [],
            SubForms: []
        }
        fields.push(field);
    });
    var applicationEntity: ApplicationEntity = {
        EntityId: "",
        ApplicationId: page.ApplicationId,
        FormId: page.Id!,
        Fields: fields
    };

    return applicationEntity;
}

/**
 * Builds the default search values for the form based on the manifest.  This is used for the Search screen.
 * @param formId 
 * @param applicationManifest 
 * @returns 
 */
export const buildDefaultSearchValues = (formId: string, applicationManifest: ApplicationManifest): any => {
    var form = getForm(applicationManifest, formId);
    var defaultValues: any = {};
    var controls = form.Controls.filter((i) => i.SearchRow != null && i.SearchRow > 0 && i.SearchColumn != null && i.SearchColumn > 0);

    controls.forEach(control => {
        var field = getDefaultSearchField(control);
        if (control.FieldType === 5) {
            defaultValues[StringConstants.FromDateSearchParameter + field!.Id!] = getDefaultDateValueForSearchControl(true, control);
            defaultValues[StringConstants.ToDateSearchParameter + field!.Id!] = getDefaultDateValueForSearchControl(false, control);
        } else if (control.FieldType === 19 || control.FieldType === 21) {
            defaultValues[StringConstants.FromDateTimeSearchParameter + field!.Id!] = getDefaultDateValueForSearchControl(true, control);
            defaultValues[StringConstants.ToDateTimeSearchParameter + field!.Id!] = getDefaultDateValueForSearchControl(false, control);
            defaultValues[StringConstants.FromTimeSearchParameter + field!.Id!] = getDefaultTimeValueForSearchControl(true, control);
            defaultValues[StringConstants.ToTimeSearchParameter + field!.Id!] = getDefaultTimeValueForSearchControl(false, control);
        } else if (control.FieldType === 22) {
            defaultValues[StringConstants.FromCurrencySearchParameter + field!.Id!] = formatCurrency(null, control.DefaultSearchTextValue != null ? control.DefaultSearchTextValue : "");
            defaultValues[StringConstants.ToCurrencySearchParameter + field!.Id!] = formatCurrency(null, control.DefaultSearchSupportingTextValue != null ? control.DefaultSearchSupportingTextValue : "");
        } else if (control.ControlType === 10) {
            defaultValues[control.Id!] = [];
            defaultValues[control.Id!].push(buildDefaultValues(control.SubFormId!, applicationManifest));
        } else {
            defaultValues[control.Id!] = getValueByControlType(field, control, true);
        }
    });
    return defaultValues;
}

export const getDefaultSearchField = (control: ControlData): Field => {
    var field: Field = {
        Id: control.Id!,
        TextValue: GetDefaulSearchTextValue(control),
        TextList: GetDefaultSearchListValue(control),
        UpdatedById: null,
        UpdatedByName: null,
        UpdatedUtc: null,
        Attachments: [],
        Comments: [],
        SubForms: []
    }
    return field;
}

const GetDefaulSearchTextValue = (control: ControlData): string => {
    if (control.ControlType === 3) {
        return control.DefaultSearchBooleanValue != null ? control.DefaultSearchBooleanValue.toString() : "false";
    } else {
        if (control.FieldType === 5 || control.FieldType === 7) {
            if (control.DefaultSearchTextValue != null && control.DefaultSearchTextValue !== "") {
                return moment(control.DefaultSearchTextValue).toISOString();
            }
            return "";
        }
        if (control.DefaultSearchTextValue != null && control.DefaultSearchTextValue !== "") {
            return control.DefaultSearchTextValue;
        } else if (control.DefaultSearchSupportingTextValue != null && control.DefaultSearchSupportingTextValue !== "") {
            return control.DefaultSearchSupportingTextValue;
        }
        return "";
    }
}

const GetDefaultSearchListValue = (control: ControlData): string[] => {
    if (control.ControlType === 2 && control.FieldType === 39) {
        return control.DefaultSearchListValues != null ? control.DefaultSearchListValues : [];
    }
    return [];
}

export const buildDefaultApplicationEntity = (form: Form, entityId: string | undefined, subForms: SubForm[]): ApplicationEntity => {
    var controls = form!.Controls.filter(i => i.Row != null && i.Column != null && !isAuditDateTimeField(i))
    var fields: Field[] = [];
    controls.forEach((control) => {
        fields.push(getDefaultField(control, entityId, subForms));
    });
    var applicationEntity: ApplicationEntity = {
        EntityId: entityId,
        ApplicationId: form.ApplicationId,
        FormId: form.Id!,
        Fields: fields
    };

    return applicationEntity;
}

export const getDefaultField = (control: ControlData, entityId: string | undefined, subForms: SubForm[]): Field => {
    var field: Field = {
        Id: control.Id!,
        TextValue: GetDefaultTextValue(control),
        TextList: GetDefaultListValue(control),
        UpdatedById: null,
        UpdatedByName: null,
        UpdatedUtc: null,
        Attachments: [],
        Comments: [],
        SubForms: GetDefaultSubFormValue(control, entityId, subForms)
    }
    return field;
}

const GetDefaultTextValue = (control: ControlData): string => {
    if (control.ControlType === 3) {
        return control.DefaultBooleanValue != null ? control.DefaultBooleanValue.toString() : "false";
    } else {
        if (control.DefaultTextValue != null && control.DefaultTextValue !== "") {
            return control.DefaultTextValue;
        }
        return "";
    }
}

const GetDefaultListValue = (control: ControlData): string[] => {
    if (control.ControlType === 2 && control.FieldType === 39) {
        return control.DefaultListValues != null ? control.DefaultListValues : [];
    }
    return [];
}

export const GetDefaultSubFormValue = (control: ControlData, entityId: string | undefined, subForms: SubForm[]): SubFormModel[] => {
    if (control.ControlType === 10) {
        return [
            {
                Id: undefined,
                Fields: buildDefaultApplicationEntity(subForms.find(i => i.Id === control.SubFormId!)!, entityId, subForms).Fields
            }
        ]
    }
    return [];
}

/**
 * Builds the default values for the form based on the manifest.  This is used for the Add New button.
 * @param formId The form id to get the default values for.
 * @param applicationManifest The manifest to get the form from.
 * @returns The default values for the form.
 */
export const buildDefaultValues = (formId: string, applicationManifest: ApplicationManifest): any => {
    var form = getForm(applicationManifest, formId);
    var defaultValues: any = {};
    var controls = form.Controls.filter((i) => !isAuditField(i));

    controls.forEach(control => {
        var field = getDefaultField(control, "", applicationManifest.SubForms);
        if (control.ControlType !== 10) {
            defaultValues[control.Id!] = getValueByControlType(field, control, false);
        } else {
            defaultValues[control.Id!] = [];
            defaultValues[control.Id!].push(buildDefaultValues(control.SubFormId!, applicationManifest));
        }

        if (control.FieldType === 7) {
            defaultValues[StringConstants.TimePickerId + field!.Id!] = field! != null && field!.TextValue != null && field!.TextValue !== "" ? moment(field!.TextValue).format("HH:mm") : "";
        }
    });
    return defaultValues;
}

/**
* Builds the default values for the form based on the manifest.  This is used for the Add New button.
* @param formId The form id to get the default values for.
* @param applicationManifest The manifest to get the form from.
* @param applicationEntity The entity to get the default values from.
* @returns The default values for the form.
*/
export const buildValues = (formId: string, applicationManifest: ApplicationManifest, fields: Field[]): any => {
    var form = getForm(applicationManifest, formId);
    var defaultValues: any = {};
    var controls = form.Controls.filter((i) => !isAuditField(i));

    controls.forEach(control => {
        var field = fields.find(i => i.Id === control.Id);

        //if the field was not saved in the form data previously, get the default value
        if (field == null) {
            field = getDefaultField(control, "", applicationManifest.SubForms);
        }

        if (control.ControlType !== 10) {
            defaultValues[control.Id!] = getValueByControlType(field, control, false);

            if (control.FieldType === 7) {
                defaultValues[StringConstants.TimePickerId + field!.Id!] = field! != null && field!.TextValue != null && field!.TextValue !== "" ? moment(field!.TextValue).format("HH:mm") : "";
            }
        } else {
            defaultValues[control.Id!] = [];
            field?.SubForms.forEach((subForm) => {
                defaultValues[control.Id!].push(buildValues(control.SubFormId!, applicationManifest, subForm.Fields));
            });
        }
    });
    return defaultValues;
}

/**
* Gets the correct value from the field based on the control type.  
* You can only go down one level in the Forms so there is no need to check for subforms here.
* @param field The field to get the value from.
* @param control The control to get the value for.
* @returns The value for the control.
*/
export const getValueByControlType = (field: Field | undefined, control: ControlData, isSearch: boolean): any => {
    if (control.ControlType === 1) {
        if (control.FieldType === 5 ||
            control.FieldType === 7) {
            return field != null && field.TextValue != null && field.TextValue !== "" ? moment(field.TextValue).toDate() : "";
        }
        if (control.FieldType === 22) {
            return formatCurrency(null, field?.TextValue != null ? field.TextValue : "");
        }
        return field?.TextValue != null ? field.TextValue : "";
    }
    if (control.ControlType === 2) {
        if (control.FieldType === 39) {
            return field != null && field.TextList != null ? field.TextList : [];
        }
        return field?.TextValue != null ? field.TextValue : "";
    }
    if (control.ControlType === 3) {
        return field?.TextValue?.toLowerCase() === "true" ? true : false;
    }
    if (control.ControlType === 4) {
        return field?.TextValue != null ? field.TextValue : "";
    }
    if (control.ControlType === 5) {
        if (isSearch) {
            return field?.TextValue != null ? field.TextValue : "";
        } else {
            return field?.Attachments != null ? field.Attachments : [];
        }
    }
    if (control.ControlType === 7) {
        return field?.TextList != null ? field.TextList : [];
    }
    if (control.ControlType === 9) {
        if (field?.Comments != null && field.Comments.length > 0) {
            //adjust text for deleted comments
            field.Comments.forEach((comment) => {
                if (comment.IsDeleted) {
                    comment.Text = "** This comment was deleted by the author **"
                }
            });
            return field.Comments.sort((a, b) => a.InsertedUtc! > b.InsertedUtc! ? 1 : -1);
        }
        var comments: CommentModel[] = [
            {
                Id: undefined,
                ParentId: undefined,
                Text: "",
                IsDeleted: false,
                InsertedByEmail: "",
                InsertedByName: "",
                InsertedByImage: "",
                InsertedUtc: undefined
            }
        ];
        return comments;
    }
}

const buildFormFieldsForSave = (data: any, form: Form, applicationManifest: ApplicationManifest, applicationEntityFields: Field[]): FieldUpsertDto[] => {
    var fields: FieldUpsertDto[] = [];
    form.Controls.forEach((control) => {
        var field = data[control.Id!];
        if (field != null) {
            var textValue: string = "";
            var textList: string[] = [];
            var attachmentModels: AttachmentModelUpsertDto[] = [];
            var commentsModels: CommentModelUpsertDto[] = [];
            var subFormModels: SubFormModelUpsertDto[] = [];
            if (control!.ControlType === 1) {
                if (control!.FieldType === 5) {
                    textValue = moment(field).toISOString();
                } else if (control!.FieldType === 7) {
                    var dateTime: moment.Moment
                    var dateValue: any = field;
                    if (dateValue != null && dateValue !== "") {
                        var datePortion = moment(dateValue).format("MM/DD/YYYY");
                        var timePortion = data[`${StringConstants.TimePickerId}${control.Id!}`];
                        if (timePortion != null && timePortion !== "") {
                            dateTime = moment(datePortion + " " + timePortion);
                        } else {
                            dateTime = moment(datePortion);
                        }
                        textValue = dateTime.toISOString();
                    }
                } else if (control!.FieldType === 22) {
                    // remove commas from currency field value
                    textValue = field != null && field !== "" ? parseFloat(field.replace(/,/g, '')).toFixed(2).toString() : "";
                } else {
                    textValue = field;
                }
            } else if (control!.ControlType === 2) {
                if (control.FieldType === 39) {
                    textList = field != null && field !== "" ? field : [];
                } else {
                    textValue = field;
                }
            } else if (control!.ControlType === 3) {
                textValue = field.toString();
            } else if (control!.ControlType === 4) {
                textValue = field;
            } else if (control!.ControlType === 5) {
                field.forEach((attachment: AttachmentModel) => {
                    if (textValue === "") {
                        textValue = `${attachment.FileName}${attachment.FileExtension}`;
                    } else {
                        textValue += `, ${attachment.FileName}${attachment.FileExtension}`;
                    }
                });
                attachmentModels = field;
            } else if (control!.ControlType === 7) {
                textList = field;
            } else if (control!.ControlType === 9) {
                var currentField = applicationEntityFields.find(i => i.Id === control.Id)!;
                field.forEach((comment: CommentModel) => {
                    if (comment.Text == null || comment.Text === "") {
                        return;
                    }
                    if (comment.Id != null) {
                        var foundComment = currentField.Comments.find(i => i.Id === comment.Id)!;
                        if (foundComment.Text !== comment.Text || foundComment.IsDeleted !== comment.IsDeleted) {
                            commentsModels.push(comment as CommentModelUpsertDto);
                        }
                    } else {
                        commentsModels.push(comment as CommentModelUpsertDto);
                    }
                });
            } else if (control!.ControlType === 10) {
                field.forEach((subFormField: any, index: number) => {
                    var mySubForm: SubFormModel | undefined = applicationEntityFields.find(i => i.Id === control.Id)?.SubForms[index];
                    var subFormModel: SubFormModelUpsertDto = {
                        Id: mySubForm != null ? mySubForm.Id : undefined,
                        Fields: buildFormFieldsForSave(
                            subFormField,
                            applicationManifest.SubForms.find(i => i.Id === control.SubFormId)!,
                            applicationManifest,
                            mySubForm?.Fields ?? [])
                    };
                    subFormModels.push(subFormModel);
                });
            }

            var f: FieldUpsertDto = {
                Id: control.Id!,
                TextValue: textValue,
                TextList: textList,
                Attachments: attachmentModels,
                Comments: commentsModels,
                SubForms: subFormModels
            }
            fields.push(f);
        }
    })
    return fields;
}

export const buildApplicationEntityForSave = (
    data: any,
    formId: string,
    applicationManifest: ApplicationManifest,
    applicationEntity: ApplicationEntity): ApplicationEntityUpsertDto => {

    var fields: FieldUpsertDto[] = buildFormFieldsForSave(
        data,
        applicationManifest.Pages.find(i => i.Id === formId)!,
        applicationManifest,
        applicationEntity.Fields);

    var applicationEntityUpsertDto: ApplicationEntityUpsertDto = {
        EntityId: applicationEntity.EntityId !== "" ? applicationEntity.EntityId : undefined,
        ApplicationId: applicationEntity.ApplicationId,
        FormId: applicationEntity.FormId,
        Fields: fields,
        ManifestVersion: applicationManifest.Version,
        ApplicationEntityVersion: applicationEntity.Version
    };

    return applicationEntityUpsertDto;
}

export const buildParamsAndSearchCall = (
    data: any,
    appId: string,
    formId: string,
    version: number,
    source: CancelTokenSource,
    searchOnLoad: boolean,
    isSearchAction: boolean): AxiosPromise => {
    var controlEntitySearchDtos: ControlEntitySearchDto[] = [];

    var searchData: [string, string][] = Object.entries(data);
    for (const [controlId, value] of searchData) {
        if (controlId.startsWith(StringConstants.FromTimeSearchParameter) ||
            controlId.startsWith(StringConstants.ToTimeSearchParameter)) {
            //We are handling the time portion of these control with one date time below
            continue;
        }
        if (controlId.startsWith(StringConstants.FromDateSearchParameter)) {
            var fromDateControlId = controlId.replace(StringConstants.FromDateSearchParameter, "");
            var fromStringValue = value != null && value !== "" ? moment(value).toISOString() : "";
            var controlEntitySearchDto: ControlEntitySearchDto = {
                ControlId: fromDateControlId,
                Value: fromStringValue,
                Values: [],
                SupportingControlId: undefined,
                SupportingValue: undefined
            }
            controlEntitySearchDtos.push(controlEntitySearchDto);
        } else if (controlId.startsWith(StringConstants.ToDateSearchParameter)) {
            var toDateControlId = controlId.replace(StringConstants.ToDateSearchParameter, "");
            var toStringValue = value != null && value !== "" ? moment(value).add(1, "day").add(-1, "millisecond").toISOString() : "";
            controlEntitySearchDtos[controlEntitySearchDtos.length - 1].SupportingControlId = toDateControlId;
            controlEntitySearchDtos[controlEntitySearchDtos.length - 1].SupportingValue = toStringValue;
        } else if (controlId.startsWith(StringConstants.FromDateTimeSearchParameter)) {
            var fromDateTimeControlId = controlId.replace(StringConstants.FromDateTimeSearchParameter, "");
            var textValue: string = "";
            if (value != null && value !== "") {
                var datePortion = moment(value).format("MM/DD/YYYY");
                var timeValue = data[`${StringConstants.FromTimeSearchParameter}${fromDateTimeControlId}`];
                var dateTime: moment.Moment;
                if (timeValue != null && timeValue !== "") {
                    dateTime = moment(datePortion + " " + timeValue);
                } else {
                    dateTime = moment(datePortion);
                }
                textValue = dateTime.toISOString();
            }
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var controlEntitySearchDto: ControlEntitySearchDto = {
                ControlId: fromDateTimeControlId,
                Value: textValue,
                Values: [],
                SupportingControlId: undefined,
                SupportingValue: undefined
            }
            controlEntitySearchDtos.push(controlEntitySearchDto);
        } else if (controlId.startsWith(StringConstants.ToDateTimeSearchParameter)) {
            var toDateTimeControlId = controlId.replace(StringConstants.ToDateTimeSearchParameter, "");
            var toTextValue: string = "";
            if (value != null && value !== "") {
                var toDatePortion = moment(value).format("MM/DD/YYYY");
                var toTimeValue = data[`${StringConstants.ToTimeSearchParameter}${toDateTimeControlId}`];
                var toDateTime: moment.Moment;
                if (toTimeValue != null && toTimeValue !== "") {
                    toDateTime = moment(toDatePortion + " " + toTimeValue);
                } else {
                    toDateTime = moment(toDatePortion);
                }
                toTextValue = toDateTime.toISOString();
            }
            controlEntitySearchDtos[controlEntitySearchDtos.length - 1].SupportingControlId = toDateTimeControlId;
            controlEntitySearchDtos[controlEntitySearchDtos.length - 1].SupportingValue = toTextValue;
        } else if (controlId.startsWith(StringConstants.FromCurrencySearchParameter)) {
            var fromCurrencyControlId = controlId.replace(StringConstants.FromCurrencySearchParameter, "");
            var currencyValue = value != null && value !== "" ? parseFloat(value.replace(',', '')).toString() : "";
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var controlEntitySearchDto: ControlEntitySearchDto = {
                ControlId: fromCurrencyControlId,
                Value: currencyValue,
                Values: [],
                SupportingControlId: undefined,
                SupportingValue: undefined
            }
            controlEntitySearchDtos.push(controlEntitySearchDto);
        } else if (controlId.startsWith(StringConstants.ToCurrencySearchParameter)) {
            var toCurrencyControlId = controlId.replace(StringConstants.ToCurrencySearchParameter, "");
            var currencySupportingValue = value != null && value !== "" ? parseFloat(value.replace(',', '')).toString() : "";
            controlEntitySearchDtos[controlEntitySearchDtos.length - 1].SupportingControlId = toCurrencyControlId;
            controlEntitySearchDtos[controlEntitySearchDtos.length - 1].SupportingValue = currencySupportingValue;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var controlEntitySearchDto: ControlEntitySearchDto = {
                ControlId: controlId,
                Value: Array.isArray(value) ? "" : value.toString(),
                Values: Array.isArray(value) ? value : [],
                SupportingControlId: undefined,
                SupportingValue: undefined
            }
            controlEntitySearchDtos.push(controlEntitySearchDto);
        }
    }

    var submitAction = isSearchAction ? "/ApplicationEntitySearch" : "/SearchResultsExport";

    var applicationEntitySearchDto: ApplicationEntitySearchDto = {
        ApplicationId: appId,
        PageId: formId,
        ManifestVersion: version,
        ControlEntitySearchDtos: controlEntitySearchDtos,
        SearchOnLoad: searchOnLoad
    }

    const options: AxiosRequestConfig = {
        method: "POST",
        data: applicationEntitySearchDto,
        url: submitAction,
        params: {
            applicationId: appId
        },
        cancelToken: source.token
    };
    return axios(options);
}