import React from "react";
import { DefaultButton } from "@fluentui/react";
import { resetFieldSpecificState } from "../../../recoil/atoms";
import { ConnectForm } from "./ConnectForm";
import { useSetRecoilState } from "recoil";

export interface ResetFormButtonProps {
    shimmerControlsOnSubmit: boolean;
}


export const ResetFormButton: React.FC<ResetFormButtonProps> = (props: ResetFormButtonProps) => {
    const setResetFieldSpecificState = useSetRecoilState(resetFieldSpecificState);

    return (
        <ConnectForm>
            {(methods: any) =>
                <DefaultButton
                    text="Reset"
                    iconProps={{ iconName: "Refresh" }}
                    disabled={props.shimmerControlsOnSubmit === false ? true : false}
                    onClick={() => {
                        methods.reset();
                        setResetFieldSpecificState(true);
                        setTimeout(() => {
                            setResetFieldSpecificState(false);
                        }, 50);
                    }}
                />
            }
        </ConnectForm>
    );
}