import React, { } from "react";
import { Modal, ProgressIndicator, getTheme, mergeStyleSets, FontWeights } from "@fluentui/react";
import { ILoadingModalProps } from "../../interfaces/ILoadingModal";

export interface LoadingModalProps{
    loadingModalProps: ILoadingModalProps
}

export const LoadingModal: React.FC<LoadingModalProps> = (props: LoadingModalProps) => {
    const modalStyles = { main: { maxWidth: 450 } };
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
        body: {
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            overflowY: "hidden",
            selectors: {
                p: { margin: "14px 0" }
            },
        },
    });

    return (
        <Modal
            titleAriaId={"Loading Modal"}
            isOpen={props.loadingModalProps.isVisible}
            isBlocking={true}
            containerClassName={contentStyles.container}
            dragOptions={undefined}
            styles={modalStyles}
        >
            <div className={contentStyles.header}>
                <span>{props.loadingModalProps.title}</span>
            </div>
            <div className={contentStyles.body}>
                <p>
                    <ProgressIndicator label={props.loadingModalProps.progressTitle} description={props.loadingModalProps.progressSubText} />
                </p>
            </div>
        </Modal>
    );
}