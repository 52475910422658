import React from "react";
import { INavLinkGroup, INavLink, INavStyles, Nav, Shimmer, ShimmerElementsGroup, ShimmerElementType, useTheme } from "@fluentui/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ApplicationManifest } from '../../model/manifest/ApplicationManifest';
import { applicationManifestState, shimmerNavState, searchResultState, searchTextFilterState, navPageSelectedState, showNavPageMobileState, resetSearchPageState, formSearchDataState } from "../../recoil/atoms";
import { useLocation, useNavigate } from "react-router";
import { StringConstants } from "../../constants/StringConstants";
import getScreenSize from "../../services/getScreenSize";

export interface NavPagesProps {
    expanded: boolean;
}

export const NavPages: React.FC<NavPagesProps> = (props: NavPagesProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const shimmerNav = useRecoilValue(shimmerNavState);
    const navPageSelected = useRecoilValue(navPageSelectedState);
    const setSearchResult = useSetRecoilState(searchResultState);
    const setSearchTextFilter = useSetRecoilState(searchTextFilterState);
    const setShowNavPageMobile = useSetRecoilState(showNavPageMobileState);
    const setResetSearchPage = useSetRecoilState(resetSearchPageState);
    const setFormSearchDataState = useSetRecoilState(formSearchDataState);
    const theme = useTheme();
    const navigate = useNavigate();
    const screenSize: number = getScreenSize();
    const location = useLocation();

    const getCustomElements = (): JSX.Element => {
        var rows = [];
        for (var i = 0; i < 8; i++) {
            if (props.expanded) {
                rows.push(
                    <ShimmerElementsGroup
                        key={"ShimmerGroup" + i}
                        backgroundColor={theme.semanticColors.bodyFrameDivider}
                        shimmerElements={[
                            { type: ShimmerElementType.gap, height: 42, width: "15px" },
                            { type: ShimmerElementType.circle, height: 15 },
                            { type: ShimmerElementType.gap, height: 42, width: "15px" },
                            { type: ShimmerElementType.line, height: 20, width: "90px" },
                            { type: ShimmerElementType.gap, height: 42, width: "15px" }
                        ]}
                    />
                )
            } else {
                rows.push(
                    <ShimmerElementsGroup
                        key={"ShimmerGroup" + i}
                        backgroundColor={theme.semanticColors.bodyFrameDivider}
                        shimmerElements={[
                            { type: ShimmerElementType.gap, height: 42, width: "15px" },
                            { type: ShimmerElementType.circle, height: 15 },
                            { type: ShimmerElementType.gap, height: 42, width: "20px" }
                        ]}
                    />
                )
            }
        }
        return (
            <div>
                {rows}
            </div>
        );
    };

    const getNavLinkGroups = (): INavLinkGroup[] | null => {
        var navLinkGroups: INavLinkGroup[] = [];

        if (applicationManifest == null) return navLinkGroups;

        var navLinks: INavLink[] = [];
        var navLink: INavLink = {
            name: applicationManifest.ApplicationName,
            url: "",
            icon: applicationManifest.HomeIconName != null && applicationManifest.HomeIconName !== "" ? applicationManifest.HomeIconName : "Home",
            key: applicationManifest.Id
        }
        navLinks.push(navLink);
        var pages = applicationManifest.Pages.filter(i => !i.HideFormFromNavigation).slice();
        pages.sort(function (a, b) {
            return a.SortOrder - b.SortOrder;
        });
        pages.forEach(page => {
            navLink = {
                name: page.Name,
                url: "",
                icon: page.IconName != null && page.IconName !== "" ? page.IconName : "PreviewLink",
                key: page.Id,
            }
            navLinks.push(navLink);
        });
        if (applicationManifest.ReportsUrl != null && applicationManifest.ReportsUrl !== "") {
            navLink = {
                name: "Reports",
                url: applicationManifest.ReportsUrl,
                icon: "ReportDocument",
                key: StringConstants.ReportsMenuItem,
                target: "_blank"
            }
            navLinks.push(navLink);
        }
        var navLinkGroup: INavLinkGroup = {
            links: navLinks
        };
        navLinkGroups.push(navLinkGroup);
        return navLinkGroups
    }

    function navLinkClicked(v?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        if (screenSize < 600) {
            setShowNavPageMobile(false);
        }

        if (item == null || item.key === StringConstants.ReportsMenuItem) { return; }

        if (navPageSelected === item?.key && 
            !location.pathname.includes("/Entity/") &&
            !location.pathname.includes("/Import") &&
            !location.pathname.includes("/NewRecord")) { return; }

        setSearchResult(undefined);
        setFormSearchDataState(undefined);
        setSearchTextFilter("");
        setTimeout(() => {
            if (applicationManifest.Id === item?.key) {
                navigate(`/Application/${applicationManifest.Id}/Home`);
            } else {
                navigate(`/Application/${applicationManifest.Id}/Form/${item?.key}/Search`);
                setResetSearchPage(true);
            }
        }, 100);
    }

    return (
        <Shimmer
            customElementsGroup={getCustomElements()}
            width={props.expanded ? 150 : 48}
            isDataLoaded={shimmerNav}
            shimmerColors={{
                shimmer: theme.palette.themeTertiary,
                shimmerWave: theme.palette.themeSecondary,
                background: theme.semanticColors.bodyFrameDivider,
            }}>
            <Nav
                selectedKey={navPageSelected}
                ariaLabel="Form Navigation"
                styles={navStyles}
                groups={getNavLinkGroups()}
                onLinkClick={navLinkClicked}
            />
        </Shimmer>
    );
}

const navStyles: Partial<INavStyles> = {
    root: {
        overflowY: 'auto',
    },
};
