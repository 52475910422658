import { ControlData } from "../model/manifest/ControlData";
import moment from "moment";

export const getDefaultDateValueForSearchControl = (isFromDate: boolean, control: ControlData): Date | string => {
    if (control != null) {
        if (isFromDate) {
            if (control.DefaultSearchTextValue != null && control.DefaultSearchTextValue !== "") {
                return moment(control.DefaultSearchTextValue).toDate();
            }
        } else {
            if (control.DefaultSearchSupportingTextValue != null && control.DefaultSearchSupportingTextValue !== "") {
                return moment(control.DefaultSearchSupportingTextValue).toDate();
            }
        }
    }
    return "";
}

export const getDefaultTimeValueForSearchControl = (isFromDate: boolean, control: ControlData): string => {
    var defaultDateTime = getDefaultDateValueForSearchControl(isFromDate, control);
    if (defaultDateTime != null && defaultDateTime !== "") {
        return moment(defaultDateTime).format("HH:mm");
    }
    return "";
}