import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { ToggleField } from "./fields/ToggleField";
import { CheckboxField } from "./fields/CheckboxField";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface BooleanControlProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const BooleanControl: React.FC<BooleanControlProps> = (props: BooleanControlProps) => {

    return (
        <>
            {props.control.FieldType === 15 &&
                <CheckboxField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </CheckboxField>
            }
            {props.control.FieldType === 26 &&
                <ToggleField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </ToggleField>
            }
        </>
    );
}