import React from "react";
import { DefaultButton, IButtonStyles, IIconProps } from "@fluentui/react";

interface IProps {
  signInCallback: () => void;
  signInButtonDisabled: boolean;
}

export const GoogleSignInButton: React.FC<IProps> = (props: IProps) => {
  // // Using default so user is prompted for all permissions right away
  // //  without this the user was never prompted for consent for multi-tenant
  // //  API permissions if the API was in a different tenant compared to the
  // //  tenant of the logged in user.
  // var loginRequest: RedirectRequest = {
  //   scopes: [".default"],
  // };

  //console.log(JSON.stringify(loginRequest));

  var googleIcon: IIconProps = { iconName: "google-logo-svg" };

  const signInButtonStyles: IButtonStyles = {
    root: {
      width: "275px",
      height: "50px",
    },
  };

  return (
    <>
      <DefaultButton
        text="Sign in with Google"
        ariaDescription="Sign In with Google button."
        onClick={() => props.signInCallback()}
        allowDisabledFocus
        iconProps={googleIcon}
        styles={signInButtonStyles}
        disabled={props.signInButtonDisabled}
      />
    </>
  );
};
