import React, { useEffect, useState } from "react";
import {
    ShimmeredDetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    IColumn,
    Stack,
    SearchBox,
    IIconProps,
    IStackTokens,
    Text,
    ITextProps,
    useTheme,
    PrimaryButton,
    MessageBar,
    MessageBarType
} from "@fluentui/react";
import { Pagination } from "@fluentui/react-experiments/lib/Pagination";
import { ItemsPerPage } from "./ItemsPerPageDropdown";
import { IKeyValuePair } from "../../../interfaces/IKeyValuePair";
import { copyAndSort } from "../../../helpers/SearchResultsHelper";
import moment from "moment";
import { NewAddExistingButton } from "./NewAddExistingButton";
import { Grid } from "../../../model/manifest/Grid";

export interface DetailsListWrapperProps {
    columns: IColumn[] | undefined;
    items: IKeyValuePair[] | undefined;
    keysToIgnoreInSearch: string[];
    grid: Grid | undefined;
    gridName: string;
    displayAddNewExistingButton: boolean;
    disableAddNewExistingButton: boolean;
    hideGridAddExistingButton: boolean;
    hideGridAddNewButton: boolean;
    selectionMode: SelectionMode;
    isLoading: boolean;
    /* This is a unique key that the grid will need to evalute on */
    rowId: string;
    warningMessage: string | undefined;
    onItemInvoked?: (item?: any, index?: number, ev?: Event) => void;
    onAddNewClicked?: (addNew: boolean) => void;
    onAddSelectedClicked?: () => void;
}

export const DetailsListWrapper: React.FC<DetailsListWrapperProps> = (props: DetailsListWrapperProps) => {
    const [searchItemsPerPage, setSearchItemsPerPage] = useState<number>(props.grid?.ItemsPerPage ?? 5);
    const [gridItems, setGridItems] = useState<IKeyValuePair[]>();
    const [columns, setColumns] = useState<IColumn[]>();
    const [gridItemsToDisplay, setGridItemsToDisplay] = useState<any[]>();
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const [searchTextFilter, setSearchTextFilter] = useState<string>("");
    const theme = useTheme();
    const stringColumns = JSON.stringify(props.columns);
    const filterIcon: IIconProps = { iconName: "Filter" };

    useEffect(() => {
        if (props.columns == null) {
            setColumns(props.columns);
            return;
        }

        if (columns != null) {
            const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
                if (columns == null || gridItems == null) { return; }
                const cols = columns;
                const items = gridItems;

                const newColumns: IColumn[] = cols.slice();
                const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
                newColumns.forEach((newCol: IColumn) => {
                    if (newCol === currColumn) {
                        currColumn.isSortedDescending = !currColumn.isSortedDescending;
                        currColumn.isSorted = true;
                    } else {
                        newCol.isSorted = false;
                        newCol.isSortedDescending = true;
                    }
                });
                const newItems = copyAndSort(items, currColumn.key!, currColumn.isSortedDescending, currColumn.data);
                setGridItems(newItems);
                setColumns(newColumns);
            };

            if (columns.length < props.columns!.length) {
                const cols = columns;
                cols.unshift(props.columns![0]);
                setColumns(cols);
            } else if (columns.length > props.columns!.length) {
                const cols = columns;
                cols.splice(0, 1);
                setColumns(cols);
            } else {
                columns.forEach(col => {
                    if (col.key === "SelectedEntity" || col.key === "commandButtons") {
                        return;
                    }
                    col.onColumnClick = onColumnClick;
                });
                setColumns(columns);
            }
        } else {
            setColumns(props.columns);
        }
    }, [columns, gridItems, props.columns]);

    useEffect(() => {
        if (props.items == null || props.isLoading) {
            setGridItems(undefined);
            return;
        }
        if (props.items != null && gridItems == null) {
            setGridItems(props.items);
            return;
        }

        var gridItemLength = gridItems!.length - 1;
        var propItemLength = props.items!.length - 1;
        if (gridItems!.length === props.items.length) {
            //an item was edited
            var editedItems = [...gridItems!];
            var edited = false;
            for (gridItemLength; gridItemLength >= 0; gridItemLength--) {
                // eslint-disable-next-line no-loop-func
                var foundItem = props.items.find(i => i[props.rowId] === editedItems[gridItemLength][props.rowId])!;
                if (JSON.stringify(editedItems[gridItemLength]) === JSON.stringify(foundItem)) {
                    continue;
                }
                editedItems[gridItemLength] = foundItem;
                edited = true;
            }
            if (edited) {
                setGridItems(editedItems);
            }
        } else if (gridItems!.length > props.items.length) {
            //an item was removed
            var reducedItems = [...gridItems!];
            for (gridItemLength; gridItemLength >= 0; gridItemLength--) {
                // eslint-disable-next-line no-loop-func
                var foundIndex = props.items.findIndex(i => i[props.rowId] === reducedItems[gridItemLength][props.rowId]);
                if (foundIndex >= 0) {
                    continue;
                }
                reducedItems.splice(gridItemLength, 1);
            }
            setGridItems(reducedItems);
        } else if (gridItems!.length < props.items.length) {
            //an item was added
            var increasedItems = [...props.items];
            for (propItemLength; propItemLength >= 0; propItemLength--) {
                // eslint-disable-next-line no-loop-func
                var foundGridIndex = gridItems!.findIndex(i => i[props.rowId] === increasedItems[propItemLength][props.rowId]);
                if (foundGridIndex >= 0) {
                    continue;
                }
                increasedItems.splice(propItemLength, 0, increasedItems[propItemLength][props.rowId]);
            }
            setGridItems(increasedItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridItems, props.items, props.isLoading]);

    useEffect(() => {
        if (columns == null || gridItems == null) {
            setGridItemsToDisplay([]);
            setFilteredItems([]);
            return;
        }
        //text search on items.
        var matchingItems: any[] = [];
        var items = [...gridItems];
        for (var item of items) {
            let textMatch = false;
            Object.entries(item).forEach((pair: any) => {
                //check to see if the key is in the column list of the object before comparing the value of a given pair
                var key = pair[0];
                var col = columns.find(i => i.key === key);
                if (col != null && props.keysToIgnoreInSearch.findIndex(i => i === key) === -1) {
                    var value = pair[1];
                    if (value != null) {
                        if (col.data === "boolean") {
                            value = value.toString().toLowerCase() === "true" ? "Yes" : "No";
                        } else if (col.data === "date") {
                            value = moment(value).format("M/DD/YYYY");
                        } else if (col.data === "time") {
                            value = moment(value).format("h:mm A");
                        } else if (col.data === "datetime") {
                            value = moment(value).format("M/DD/YYYY h:mm A");
                        }
                    }
                    if (searchTextFilter === "" || (value != null && value.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1)) {
                        textMatch = true;
                    }
                }
            });
            if (textMatch) {
                matchingItems.push(item);
            }
        }
        setFilteredItems(matchingItems);

        //calculate which items to display
        var skipToIndex = selectedPageIndex * searchItemsPerPage;
        var takeToIndex = skipToIndex + searchItemsPerPage;
        setGridItemsToDisplay(matchingItems.slice(skipToIndex, takeToIndex));

        //check to see if page count is less than current selection.  if so adjust it down accordingly
        var pageCount = Math.ceil(matchingItems.length / searchItemsPerPage);
        var pageCountIndex = pageCount - 1;
        if (pageCountIndex < 0) {
            setSelectedPageIndex(0);
        } else if (selectedPageIndex + 1 > pageCountIndex) {
            setSelectedPageIndex(pageCountIndex);
        }
    }, [stringColumns, columns, selectedPageIndex, searchItemsPerPage, gridItems, gridItems?.length, searchTextFilter, props.keysToIgnoreInSearch]);


    const pageChange = (index: number): void => {
        setSelectedPageIndex(index);
    }

    const getPageCount = (): number => {
        return Math.ceil(filteredItems.length / searchItemsPerPage);
    }

    const countOfSelectedItems = (): number => {
        if (gridItems == null) {
            return 0;
        }
        return gridItems.filter(i => i["SelectedEntity"]).length;
    }

    const getAddItemsButtonText = (): string => {
        var countOfItems = countOfSelectedItems();

        if (countOfItems === 0) {
            return "Add Items";
        } else if (countOfItems === 1) {
            return "Add 1 Item";
        }
        return `Add ${countOfItems} Items`;
    }

    const stackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 10,
    };

    return (
        <>
            <Stack horizontal={false} tokens={stackTokens}>
                <Stack.Item>
                    <Stack horizontal={true} wrap tokens={stackTokens}>
                        <Stack.Item grow={2}>
                            <Text variant={"xLarge" as ITextProps['variant']}>
                                {props.gridName}
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <ItemsPerPage
                                isLoading={props.isLoading}
                                searchItemsPerPage={searchItemsPerPage}
                                setSearchItemsPerPage={setSearchItemsPerPage}>
                            </ItemsPerPage>
                        </Stack.Item>
                        <Stack.Item grow={1}>
                            <SearchBox
                                placeholder="Filter"
                                iconProps={filterIcon}
                                value={searchTextFilter}
                                onChange={(_, newValue) => {
                                    setSearchTextFilter(newValue || "");
                                }}
                            />
                        </Stack.Item>
                        {(props.displayAddNewExistingButton && props.onAddNewClicked != null) &&
                            <Stack.Item>
                                <NewAddExistingButton
                                    hideGridAddExistingButton={props.hideGridAddExistingButton}
                                    hideGridAddNewButton={props.hideGridAddNewButton}
                                    disableAddNewExistingButton={props.disableAddNewExistingButton}
                                    onAddNewClicked={props.onAddNewClicked}
                                />
                            </Stack.Item>
                        }
                    </Stack>
                    {props.warningMessage !== undefined &&
                        <Stack horizontal={true} wrap tokens={stackTokens}>
                            <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={false}
                            >
                                {props.warningMessage}
                            </MessageBar>
                        </Stack>
                    }
                </Stack.Item>
                <Stack.Item>
                    <ShimmeredDetailsList
                        items={gridItemsToDisplay || []}
                        compact={props.grid?.Compact}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        enableShimmer={props.isLoading}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={props.grid == null || props.grid.IsHeaderVisible ? true : false}
                        theme={theme}
                        onItemInvoked={props.onItemInvoked}
                    />
                </Stack.Item>
                <Stack horizontal={true} horizontalAlign="space-between">
                    <Stack.Item>
                        {(props.selectionMode !== SelectionMode.none && props.onAddSelectedClicked)
                            ?
                            <PrimaryButton
                                text={getAddItemsButtonText()}
                                primary
                                disabled={countOfSelectedItems() === 0}
                                onClick={props.onAddSelectedClicked}
                            />
                            :
                            <></>
                        }
                    </Stack.Item>
                    <Stack.Item>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            pageCount={getPageCount()}
                            itemsPerPage={searchItemsPerPage}
                            totalItemCount={filteredItems.length}
                            format={"buttons"}
                            previousPageAriaLabel={"previous page"}
                            nextPageAriaLabel={"next page"}
                            firstPageAriaLabel={"first page"}
                            lastPageAriaLabel={"last page"}
                            pageAriaLabel={"page"}
                            selectedAriaLabel={"selected"}
                            onPageChange={pageChange} />
                    </Stack.Item>
                    <Stack.Item>
                        <></>
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
}