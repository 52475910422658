import React from "react";
import { DatePicker, TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../model/manifest/ControlData";
import { Controller } from "react-hook-form";
import { ConnectForm } from "../form/ConnectForm";
import { StringConstants } from "../../../constants/StringConstants";
import { useId } from "@fluentui/react-hooks";

export interface FromToDatePickerProps {
    control: ControlData;
    isFromDate: boolean;
    shimmerControlsOnSubmit: boolean;
}

export const FromToDatePicker: React.FC<FromToDatePickerProps> = (props: FromToDatePickerProps) => {
    const datePickerId = useId("datePicker");
    const tooltipId = useId("tooltip");
    //For some reason 0 does not work for the DatePicker.  You must drop down the tool tip by
    //the size of the label.  This will probably get resolved in a future version of Fluent UI.
    const calloutProps = {
        gapSpace: -30,
        target: `#${datePickerId}`
    };

    return (
        <ConnectForm>
            {(methods: any) =>
                <Controller
                    control={methods.control}
                    name={props.isFromDate ?
                        `${StringConstants.FromDateSearchParameter}${props.control.Id!}` :
                        `${StringConstants.ToDateSearchParameter}${props.control.Id!}`}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <>
                            <TooltipHost
                                content={props.control.ToolTipText}
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <DatePicker
                                    id={datePickerId}
                                    label={props.isFromDate ? `From ${props.control.LabelText}` : `To ${props.control.LabelText}`}
                                    allowTextInput={true}
                                    showGoToToday={true}
                                    value={value}
                                    onSelectDate={(date: Date | null | undefined) => {
                                        if (date == null) {
                                            onChange("");
                                            return;
                                        }
                                        onChange(date);
                                    }} />
                            </TooltipHost>
                        </>
                    )} />
            }
        </ConnectForm>
    )
}