import React, { useEffect } from "react";
import { ITextProps, IStackItemStyles, IStackTokens, Stack, Text } from "@fluentui/react";
import { useSetRecoilState } from "recoil";
import { navHeaderState } from "../../../recoil/atoms";
import { TourToggleArea } from "./TourToggleArea";

export const HelpTour: React.FC = () => {
    const setNavHeader = useSetRecoilState(navHeaderState);

    useEffect(() => {
        setNavHeader("Tour Guides");
    }, [setNavHeader]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            paddingTop: "10px",
            paddingBottom: "15px"
        }
    };

    const themedLargeStackTokens: IStackTokens = {
        childrenGap: 'l1',
        padding: 'l1',
    };

    return (
        <>
            <Stack horizontal={false}>
                <Stack.Item styles={stackItemStyles}>
                    <Text variant={"xLarge" as ITextProps['variant']}>
                        Training Tours
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant={"medium" as ITextProps['variant']}>
                        Sometimes we all need a little refresher course.
                        </Text>
                    <br />
                    <Text variant={"medium" as ITextProps['variant']}>
                        To re-take a tour, toggle the tour to "Not Viewed"
                        </Text>
                    <br />
                    <Text variant={"medium" as ITextProps['variant']}>
                        Likewise, to not view a particular tutorial, mark it as "Viewed"
                        </Text>
                </Stack.Item>
                <Stack horizontal={false} tokens={themedLargeStackTokens}>
                    <Stack.Item>
                        <Text variant={"large" as ITextProps['variant']}>
                            New Application
                        </Text>
                    </Stack.Item>
                    <TourToggleArea
                        toggleLabel="Templates"
                        tourId="tour_templates_1"
                        navigateUrl="/NewApplication/Templates">
                    </TourToggleArea>
                    <TourToggleArea
                        toggleLabel="Create Basic App"
                        tourId="tour_create_basic_app_1"
                        navigateUrl="/NewApplication/Create">
                    </TourToggleArea>
                </Stack>
            </Stack>
        </>
    );
}