import { DefaultButton, IColumn, PrimaryButton, useTheme, SelectionMode, Checkbox } from "@fluentui/react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { IKeyValuePair } from "../../../interfaces/IKeyValuePair";
import { IApplicationEntityResult, IEntityListResult } from "../../../interfaces/EntityListResult";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { ControlData } from "../../../model/manifest/ControlData";
import { Grid } from "../../../model/manifest/Grid";
import { GridColumn } from "../../../model/manifest/GridColumn";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { applicationManifestState } from "../../../recoil/atoms";
import { formatCurrency } from "../../../services/currencyService";
import { shapeGridData } from "../../../services/detailsListService";
import { getBooleanTextValueFromString } from "../../../services/fieldService";
import { removeButtonAlternateStyles } from "../../../services/styleService";
import { PanelFormContainer } from "../form/PanelFormContainer";
import { DetailsListWrapper } from "./DetailsListWrapper";
import { PanelMultiSelectSearchContainer } from "../search/PanelMultiSelectSearchContainer";
import { DialogModalWrapper } from "../DialogModalWrapper";

/**
 * Properties needed to build out a Details List (grid).
 * If addEntity and editEntity callbacks are not provided and a user navigates to a new entity from the list the from will be readonly.
 */
export interface EntityListResultProps {
    grid: Grid | undefined;
    gridName: string;
    entityListResult: IEntityListResult | undefined;
    columnControls: ControlData[];
    isLoading: boolean;
    displayViewButton: boolean;
    displayRemoveButton: boolean;
    displayAddNewExistingButton: boolean;
    disableAddNewExistingButton: boolean;
    hideGridAddExistingButton: boolean;
    hideGridAddNewButton: boolean;
    navigateOnEditClick: boolean;
    removeButtonText: string;
    selectionMode: SelectionMode;
    warningMessage: string | undefined;
    /** Callback function when the remove/delete button has been clicked. */
    removeEntity?: (entityId: string) => void;
    /** Callback function when a new entity has been created. */
    addEntity?: (applicationEntity: ApplicationEntity) => void;
    /** Callback function when an entity has been edited. */
    editEntity?: (applicationEntity: ApplicationEntity) => void;
    /** Callback function when items have been selected. */
    onAddSelectedClicked?: (applicationEntityResultsSelected: IApplicationEntityResult[]) => void;
}

export const EntityListResult: React.FC<EntityListResultProps> = (props: EntityListResultProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [dialogModalWrapper, setDialogModalWrapper] = useState<IDialogModalWrapperProps>();
    const [gridItems, setGridItems] = useState<IKeyValuePair[]>();
    const [columns, setColumns] = useState<IColumn[]>();
    const [entityIdForModal, setEntityIdForModal] = useState<string>();
    const [displayEntityPanel, setDisplayEntityPanel] = useState<boolean>();
    const [displayMultiSearchPanel, setDisplayMultiSearchPanel] = useState<boolean>();
    const [entitiesSelected, setEntitiesSelected] = useState<string[]>([]);
    const [entityToSelect, setEntityToSelect] = useState<string>();
    const [entityToDeselect, setEntityToDeselect] = useState<string>();
    const [selectAllEntities, setSelectAllEntities] = useState<boolean>(false);
    const [deselectAllEntities, setDeselectAllEntities] = useState<boolean>(false);
    const allSelected = useRef(false);
    const theme = useTheme();
    const stringEntityList = JSON.stringify(props.entityListResult);

    var { appId, formId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getDataType = (fieldType: number): string => {
            if (fieldType === 5) {
                return "date";
            } else if (fieldType === 6) {
                return "time";
            } else if (fieldType === 7 || fieldType === 19 || fieldType === 21) {
                return "datetime";
            } else if (fieldType === 22) {
                return "decimal";
            } else if (fieldType === 29) {
                return "number";
            } else if (fieldType === 26) {
                return "boolean";
            } else {
                return "string";
            }
        }

        const getButtonColumnWidth = (): number => {
            if (props.displayViewButton || props.displayRemoveButton) {
                return 180;
            }
            return 0;
        }

        //build columns
        var cols: IColumn[] = [];
        var col: IColumn;
        if (props.selectionMode === SelectionMode.multiple) {
            col = {
                key: "SelectedEntity",
                name: "",
                fieldName: "SelectedEntity",
                ariaLabel: "Select Entity",
                minWidth: 30,
                maxWidth: 30,
                isRowHeader: true,
                isResizable: true,
                isSorted: false,
                data: "boolean",
                onRenderHeader: () => {
                    console.log("Render Header All Selected = " + allSelected.current);
                    return <>
                        <Checkbox
                            checked={allSelected.current}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                if (isChecked) {
                                    console.log("Select All");
                                    setSelectAllEntities(true);
                                } else {
                                    console.log("Un Select All");
                                    setDeselectAllEntities(true);
                                }
                            }} />
                    </>;
                },
                onRender: (item: IKeyValuePair) => {
                    return <>
                        <Checkbox
                            checked={item["SelectedEntity"]}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                if (isChecked) {
                                    console.log("Selected item: " + item["EntityId"]);
                                    setEntityToSelect(item["EntityId"]);
                                } else {
                                    console.log("Un Selected item: " + item["EntityId"]);
                                    setEntityToDeselect(item["EntityId"]);
                                }
                            }} />
                    </>;
                },
                isPadded: true,
            };
            cols.push(col);
        }
        if (props.displayViewButton || props.displayRemoveButton) {
            col = {
                key: "commandButtons",
                name: "",
                fieldName: "commandButtons",
                ariaLabel: "Edit and Delete Buttons",
                minWidth: getButtonColumnWidth(),
                maxWidth: getButtonColumnWidth(),
                isRowHeader: true,
                isResizable: true,
                isSorted: false,
                data: "commandButtons",
                onRender: (item: IKeyValuePair) => {
                    return <>
                        {item["DisplayViewButton"] &&
                            <PrimaryButton
                                text="View"
                                onClick={() => {
                                   onNavigate(item);
                                }}
                                allowDisabledFocus
                            />
                        }
                        {(item["DisplayViewButton"] && item["DisplayRemoveButton"]) &&
                            <>
                                &nbsp;
                            </>
                        }
                        {item["DisplayRemoveButton"] &&
                            <DefaultButton
                                text={props.removeButtonText}
                                styles={removeButtonAlternateStyles(theme)}
                                onClick={() => {
                                    var dialogProps: IDialogModalWrapperProps = {
                                        isVisible: true,
                                        title: props.removeButtonText,
                                        subText: "Are you sure you want to delete this record?",
                                        isBlocking: true,
                                        primaryButtonText: "OK",
                                        secondaryButtonText: "Cancel",
                                        onDismiss: () => {
                                            setDialogModalWrapper(undefined);
                                            props.removeEntity!(item["EntityId"]);
                                        },
                                        onSecondaryButtonDismiss: () => {
                                            setDialogModalWrapper(undefined);
                                        }
                                    }
                                    setDialogModalWrapper(dialogProps);
                                }}
                                allowDisabledFocus
                            />
                        }
                    </>;
                },
                isPadded: true,
            };
            cols.push(col);
        }

        props.columnControls.forEach((control) => {
            var gridColumn: GridColumn | undefined;
            if (props.grid != null) {
                var gc = props.grid.GridColumns.find(i => i.ControlId === control.Id)!;
                gridColumn = gc;
            }
            var col: IColumn = {
                key: control!.Id!,
                name: control!.LabelText,
                fieldName: control!.DataDisplayName,
                ariaLabel: `${control!.LabelText} column`,
                minWidth: gridColumn != null ? gridColumn.MinWidth : 100,
                maxWidth: gridColumn != null ? gridColumn.MaxWidth : 250,
                isRowHeader: true,
                isResizable: gridColumn != null ? gridColumn.IsResizable : true,
                isCollapsible: gridColumn != null ? gridColumn.IsCollapsible : false,
                isPadded: gridColumn != null ? gridColumn.IsPadded : true,
                isSorted: false,
                sortAscendingAriaLabel: `${control!.LabelText} column sorted ascending`,
                sortDescendingAriaLabel: `${control!.LabelText} column sorted descending`,
                data: getDataType(control.FieldType),
                onRender: (item: IKeyValuePair) => {
                    var value: string = "";
                    Object.entries(item).forEach((pair: any) => {
                        //check to see if the key is in the column list of the object before comparing the value of a given pair
                        var key = pair[0];
                        if (control.Id === key) {
                            value = pair[1];
                            if (col.data === "date") {
                                if (value != null && value !== "") {
                                    var shortDate = new Date(value);
                                    if (moment.isDate(shortDate)) {
                                        value = moment(value).format("M/DD/YYYY");
                                    }
                                }
                            } else if (col.data === "time") {
                                if (value != null && value !== "") {
                                    var time = new Date(value);
                                    if (moment.isDate(time)) {
                                        value = moment(value).format("h:mm A");
                                    }
                                }
                            } else if (col.data === "datetime") {
                                if (value != null && value !== "") {
                                    var longDate = new Date(value);
                                    if (moment.isDate(longDate)) {
                                        value = moment(value).format("M/DD/YYYY h:mm A");
                                    }
                                }
                            } else if (col.data === "decimal") {
                                if (control.FieldType === 22 && value != null && value !== "") {
                                    value = `$${formatCurrency(null, value)}`;
                                }
                            } else if (control.ControlType === 3) {
                                value = getBooleanTextValueFromString(value);
                            }
                        }
                    });
                    return <span>{value}</span>;
                }
            };
            cols.push(col);
        });
        setColumns(cols);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationManifest.Pages, formId, props.displayViewButton, props.displayRemoveButton]);

    useEffect(() => {
        if (columns == null) {
            return;
        }
        if (props.entityListResult == null ||
            props.entityListResult.ApplicationEntityResults == null ||
            props.entityListResult.ApplicationEntityResults.length === 0) {
            setGridItems(undefined);
            return;
        } else {
            const cols = columns;
            //build grid items
            var keyValuePairs: IKeyValuePair[] = [];
            props.entityListResult.ApplicationEntityResults.forEach(applicationEntityResult => {
                var row: IKeyValuePair = {};
                //these keys will be ignored in the wrapper component when un-boxing columnn data
                row["EntityId"] = applicationEntityResult.EntityId;
                row["SelectedEntity"] = entitiesSelected.includes(applicationEntityResult.EntityId);
                row["DisplayViewButton"] = props.displayViewButton;
                row["DisplayRemoveButton"] = props.displayRemoveButton;
                applicationEntityResult.FieldResults.forEach(result => {
                    row[result.ControlId] = result.TextValue;
                });
                // some data might not have been there so add the key with an empty string
                cols.forEach(col => {
                    var element = row[col.key];
                    if (element == null) {
                        row[col.key] = "";
                    }
                });
                keyValuePairs.push(row);
            });

            var newList = shapeGridData(cols, keyValuePairs);
            setGridItems(newList);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, entitiesSelected, stringEntityList]);

    useEffect(() => {
        if (entityToSelect == null) {
            return;
        }
        const selected: string[] = [...entitiesSelected];
        selected.push(entityToSelect);
        allSelected.current = props.entityListResult?.ApplicationEntityResults.length === selected.length;
        setEntitiesSelected(selected);
        setEntityToSelect(undefined);
    }, [entitiesSelected, entityToSelect, props.entityListResult?.ApplicationEntityResults]);

    useEffect(() => {
        if (entityToDeselect == null) {
            return;
        }
        const selected: string[] = [...entitiesSelected];
        const newValues: string[] = selected.filter((i: string) => i !== entityToDeselect);
        allSelected.current = false;
        setEntitiesSelected(newValues);
        setEntityToDeselect(undefined);
    }, [entitiesSelected, entityToDeselect]);

    useEffect(() => {
        if (!selectAllEntities) {
            return;
        }
        const selected: string[] = props.entityListResult ? props.entityListResult.ApplicationEntityResults.map(i => i.EntityId) : [];
        allSelected.current = true;
        setEntitiesSelected(selected);
        setSelectAllEntities(false);
    }, [props.entityListResult, selectAllEntities]);

    useEffect(() => {
        if (!deselectAllEntities) {
            return;
        }
        allSelected.current = false;
        setEntitiesSelected([]);
        setDeselectAllEntities(false);
    }, [deselectAllEntities]);

    const onNavigate = (item: IKeyValuePair): void => {
        if (props.navigateOnEditClick) {
            navigate(`/Application/${appId}/Form/${formId}/Entity/${item["EntityId"]}`, { replace: false });
        } else {
            setEntityIdForModal(item["EntityId"]);
            setDisplayEntityPanel(true);
        }
    }

    const onAddNewClicked = (addNew: boolean): void => {
        if (addNew) {
            //show form panel (add single)
            setEntityIdForModal(undefined);
            setDisplayEntityPanel(true);
        } else {
            //show search panel (add multiple)
            setDisplayMultiSearchPanel(true);
        }
    }

    /**
     * Builds the application entity results from the selected entity ID's.
     * This is only called from within the details list wrapper when you have come from a multi-select Panel implementation.
    */
    const onAddSelectedClicked = (): void => {
        var applicationEntityResults: IApplicationEntityResult[] = [];
        entitiesSelected.forEach((entity) => {
            applicationEntityResults.push(props.entityListResult!.ApplicationEntityResults.find(i => i.EntityId === entity)!);
        });
        props.onAddSelectedClicked!(applicationEntityResults);
    }

    return (
        <>
            <DetailsListWrapper
                columns={columns}
                items={gridItems}
                keysToIgnoreInSearch={gridKeys}
                grid={props.grid}
                gridName={props.gridName}
                isLoading={props.isLoading}
                rowId="EntityId"
                displayAddNewExistingButton={props.displayAddNewExistingButton}
                disableAddNewExistingButton={props.disableAddNewExistingButton}
                hideGridAddExistingButton={props.hideGridAddExistingButton}
                hideGridAddNewButton={props.hideGridAddNewButton}
                selectionMode={props.selectionMode}
                warningMessage={props.warningMessage}
                onItemInvoked={onNavigate}
                onAddNewClicked={onAddNewClicked}
                onAddSelectedClicked={onAddSelectedClicked}>
            </DetailsListWrapper>
            {displayEntityPanel &&
                <PanelFormContainer
                    formId={props.columnControls[0].PageId}
                    entityId={entityIdForModal}
                    addEntity={props.addEntity}
                    editEntity={props.editEntity}
                    hideModal={() => {
                        setEntityIdForModal(undefined);
                        setDisplayEntityPanel(false);
                    }} />
            }
            {displayMultiSearchPanel &&
                <PanelMultiSelectSearchContainer
                    formId={props.columnControls[0].PageId}
                    currentEntityIds={props.entityListResult ? props.entityListResult?.ApplicationEntityResults.map(i => i.EntityId) : []}
                    onAddSelectedClicked={(applicationEntityResultsSelected: IApplicationEntityResult[]) => {
                        //this uses the props on add selected and not the private on the component because that
                        //function was called deeper in the chain by the details list wrapper
                        props.onAddSelectedClicked!(applicationEntityResultsSelected);
                        setDisplayMultiSearchPanel(false);
                    }}
                    hidePanel={() => {
                        setDisplayMultiSearchPanel(false);
                    }}
                />
            }
            {dialogModalWrapper != null &&
                <DialogModalWrapper dialogModalWrapperProps={dialogModalWrapper}></DialogModalWrapper>
            }
        </>
    );
}

const gridKeys: string[] = [
    "EntityId",
    "SelectedEntity",
    "DisplayViewButton",
    "DisplayRemoveButton"
];