import { IDropdownOption } from "@fluentui/react";

export const getUserRoleOptionsWithSelectAnOption = (): IDropdownOption[] => {
    return [
        {key: "", text: "Select an option"}, 
        {key: "Administrator", text: "Administrator"}, 
        {key: "Builder", text: "Builder"},
        {key: "User", text: "User"}
    ];
}

export const getUserRoleOptions = (): IDropdownOption[] => {
    return [
        {key: "Administrator", text: "Administrator"}, 
        {key: "Builder", text: "Builder"},
        {key: "User", text: "User"}
    ];
}

export const getCustomerUserRoleOptionsWithSelectAnOption = (): IDropdownOption[] => {
    return [
        {key: "", text: "Select an option"},
        {key: "User", text: "User"},
        {key: "Global Application Administrator", text: "Global Application Administrator"},
        {key: "Global Application Builder", text: "Global Application Builder"}
    ];
}

export const getCustomerUserRoleOptions = (): IDropdownOption[] => {
    return [
        {key: "Owner", text: "Owner", disabled: true}, 
        {key: "User", text: "User"},
        {key: "Global Application Administrator", text: "Global Application Administrator"},
        {key: "Global Application Builder", text: "Global Application Builder"}
    ];
}