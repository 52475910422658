import React from "react";
import { useSetRecoilState } from "recoil";
import { customerSummariesState, dialogModalWrapperPropsState } from "../../../recoil/atoms";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { CustomerSummaryDto } from "../../../dtos/User/CustomerSummaryDto";
import { useRecoilState } from "recoil";
import { IStackTokens, ITextFieldStyleProps, ITextFieldStyles, PrimaryButton, Stack, Text, TextField } from "@fluentui/react";
import axios from "axios";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { ValidationError } from "../../../model/error/ValidationError";
import { hasTrialEnded } from "../../../services/customerService";
import moment from "moment";

export const CustomerInformation: React.FC = () => {
    const [customerSummaries, setCustomerSummaries]: [CustomerSummaryDto[], (customerSummaries: CustomerSummaryDto[]) => void] = useRecoilState(customerSummariesState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    var { customerId } = useParams();
    const defaultValues = {
        customerName: customerSummaries.find(i => i.CustomerId === customerId)!.CustomerName
    }
    const { handleSubmit, control, formState: { errors, isDirty, isValid, isSubmitting } } = useForm({ defaultValues: defaultValues, mode: "onChange" });

    const submitForm = async (data: any) => {
        var updateCustomerSummaryDto = {
            CustomerId: customerId,
            CustomerName: data.customerName,
            isFavorite: customerSummaries.find(i => i.CustomerId === customerId)!.IsFavorite
        }
        await axios.put("/CustomerSummary", updateCustomerSummaryDto)
            .then((result: any) => {
                var summaries = [...customerSummaries];
                var customerSummary = summaries.find(i => i.CustomerId === customerId)!;
                customerSummary.CustomerName = updateCustomerSummaryDto.CustomerName;
                setCustomerSummaries(summaries);
            }).catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "Customer Information Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps: IDialogModalWrapperProps | undefined;
                    if (error.response.status === 422) {
                        //there can be multiple errors, but in this case we will just show one at a time which should only ever be related to the name
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        dialogProps = {
                            isVisible: true,
                            title: validationErrors[0].Title,
                            subText: validationErrors[0].Message,
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    } else {
                        dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: false,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
    }

    const getStatusText = (): string => {
        var billingStatus = getCurrentCustomer().BillingStatus;
        if (billingStatus !== "Trial") {
            return billingStatus;
        }

        return `${billingStatus} - End Date ${moment(getCurrentCustomer().TrialEndDate).format("MM/DD/YYYY")}`;
    }

    const getCurrentCustomer = (): CustomerSummaryDto => {
        return customerSummaries.find(i => i.CustomerId === customerId)!;
    }

    return (
        <Stack horizontal={true} horizontalAlign={"space-between"} wrap={true}>
            <Stack.Item>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Stack tokens={themedMediumStackTokens}>
                        <Stack.Item>
                            <Text variant={"xxLarge"}>
                                Account Details
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack tokens={themedSmallStackTokens}>
                                <Stack.Item>
                                    <Controller
                                        control={control}
                                        name="customerName"
                                        rules={{
                                            validate: {
                                                unique: (value: string): string | undefined => {
                                                    if (value == null || value.length === 0) {
                                                        return "Customer Name is required";
                                                    } else {
                                                        if (value.length > 100) {
                                                            return "The max length of Customer Name is 100";
                                                        }
                                                    }
                                                    return undefined;
                                                },
                                            },
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <TextField
                                                label="Customer Name"
                                                styles={getStyles}
                                                value={value}
                                                errorMessage={errors != null && errors["customerName"]?.message != null ? errors["customerName"].message : undefined}
                                                onBlur={onBlur}
                                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                    onChange(newValue);
                                                }} />
                                        )}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <PrimaryButton
                                        text="Save"
                                        ariaDescription="Save customer summary button."
                                        type="submit"
                                        disabled={!isValid || !isDirty || isSubmitting} />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </form>
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={themedMediumStackTokens}>
                    <Stack.Item>
                        <Text variant={"xxLarge"}>
                            Billing Details
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label="Customer Type"
                            borderless
                            styles={getStyles}
                            value={getCurrentCustomer().CustomerType}
                            readOnly={true} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label="Status"
                            borderless
                            styles={getStyles}
                            value={getStatusText()}
                            readOnly={true} />
                    </Stack.Item>
                    {hasTrialEnded(getCurrentCustomer().BillingStatus, getCurrentCustomer().TrialEndDate) &&
                        <Stack.Item>
                            <PrimaryButton
                                text="Activate Subscription"
                                ariaDescription="Activate Subscription button."
                                onClick={() => {
                                    var dialogProps = {
                                        isVisible: true,
                                        title: "Activate Subscription",
                                        subText: "By clicking OK below the system will let our staff know that you are interesetd in activating your subscription.  Someone will reach out to your shortly.",
                                        isBlocking: false,
                                        primaryButtonText: "OK",
                                        secondaryButtonText: "Cancel",
                                        onDismiss: () => {
                                            setDialogModalWrapperPropsState(undefined);
                                            var trialEndedDto = {
                                                customerId: customerId
                                            };
                                            axios.post("/TrialEnded", trialEndedDto)
                                                .then((result) => {
                                                    var dialogProps = {
                                                        isVisible: true,
                                                        title: "Subscription",
                                                        subText: "Someone from our team will reach out to you shortly.",
                                                        isBlocking: false,
                                                        primaryButtonText: "OK",
                                                        secondaryButtonText: undefined,
                                                        onDismiss: () => {
                                                            setDialogModalWrapperPropsState(undefined);
                                                        },
                                                        onSecondaryButtonDismiss: () => { }
                                                    }
                                                    setDialogModalWrapperPropsState(dialogProps);
                                                }).catch((error) => {
                                                    var dialogProps = {
                                                        isVisible: true,
                                                        title: "Uh oh",
                                                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                                                        isBlocking: false,
                                                        primaryButtonText: "OK",
                                                        secondaryButtonText: undefined,
                                                        onDismiss: () => {
                                                            setDialogModalWrapperPropsState(undefined);
                                                        },
                                                        onSecondaryButtonDismiss: () => { }
                                                    }
                                                    setDialogModalWrapperPropsState(dialogProps);
                                                });
                                        },
                                        onSecondaryButtonDismiss: () => {
                                            setDialogModalWrapperPropsState(undefined);
                                        }
                                    }
                                    setDialogModalWrapperPropsState(dialogProps);
                                }}
                                allowDisabledFocus />
                        </Stack.Item>
                    }
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

const themedMediumStackTokens: IStackTokens = {
    childrenGap: "m",
    padding: "m",
};

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};

function getStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles> {
    return {
        fieldGroup: [
            { width: 425 },
            { display: "flex" }
        ]
    };
}