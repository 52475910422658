import React from "react";
import { ITextProps, IStackTokens, Stack, Text } from "@fluentui/react";
import { DownloadHeadersDefinitionsButton } from "./DownloadHeadersDefinitionsButton";

export const Headers: React.FC = () => {

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: 'm',
        padding: 'm',
    };

    return (
        <>
            <Stack horizontal={false} tokens={themedMediumStackTokens}>
                <Stack.Item>
                    <Text variant={"medium" as ITextProps['variant']}>
                        A comma separated file that can be opened with Excel that contains all of the labels in the header row.
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <DownloadHeadersDefinitionsButton isTemplate={true} />
                </Stack.Item>
            </Stack>
        </>
    );
}