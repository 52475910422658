import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { DocumentCard, IDocumentCardStyles, IStackTokens, Stack, Text, TextField } from "@fluentui/react";
import { applicationManifestState, shimmerNavState } from "../../../recoil/atoms";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import axios, { AxiosRequestConfig } from "axios";
import { ManifestResourceDto } from "../../../dtos/Manifest/ManifestResourceDto";
import { DeleteApplicationButton } from "../customerPortal/DeleteApplicationButton";
import { ManifestSummaryDetailDto } from "../../../dtos/ManifestSummary/ManifestSummaryDetailDto";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { DeleteApplicationDataButton } from "../customerPortal/DeleteApplicationDataButton";

export const ResourceSettings: React.FC = () => {
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [manifestResource, setManifestResource] = useState<ManifestResourceDto>();
    const navigate = useNavigate();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/ManifestResource",
            params: {
                applicationId: applicationManifest.Id
            },
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {
                var manifestResourceDto: ManifestResourceDto = result.data;
                setManifestResource(manifestResourceDto);
            })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "Resource Settings Component cleaned up");
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const documentUpperCardStyles: IDocumentCardStyles = {
        root: {
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            height: "100%",
            maxWidth: "",
            minWidth: ""
        }
    };

    const documentLowerCardStyles: IDocumentCardStyles = {
        root: {
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            height: "100%",
            maxWidth: "",
            minWidth: ""
        }
    };

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "m",
    };

    const themedSmallStackTokens: IStackTokens = {
        childrenGap: "s1",
        padding: "s1",
    };

    const myManifestSummary = (): ManifestSummaryDetailDto[] => {
        var manifestSummaryDetailDtos: ManifestSummaryDetailDto[] = [
            {
                ApplicationId: applicationManifest.Id!,
                CustomerId: manifestResource!.CustomerId,
                Description: applicationManifest.Description,
                Name: applicationManifest.ApplicationName,
                FavoriteCount: 0,
                UserCount: 0,
            }
        ];
        return manifestSummaryDetailDtos;
    };

    const navigateFromApp = () => {
        setShimmerNav(false);
        navigate(`/AppCatalog`, { replace: true });
    };

    return (
        <Stack.Item>
            <Stack tokens={themedMediumStackTokens}>
                <Stack.Item>
                    <Stack horizontal={true} horizontalAlign="space-between">
                        <Stack.Item>
                            <Text variant={"xxLarge"}>
                                Resource Settings
                            </Text>
                        </Stack.Item>
                        {manifestResource != null &&
                            <Stack horizontal={true} horizontalAlign="end">
                                <Stack.Item style={{ "paddingRight": "10px" }}>
                                    <DeleteApplicationDataButton
                                        customerId={manifestResource.CustomerId}
                                        manifestSummaryDetailDtos={myManifestSummary()}
                                        buttonType="DefaultButton" />
                                </Stack.Item>
                                <Stack.Item style={{ "paddingRight": "10px" }}>
                                    <DeleteApplicationButton
                                        customerId={manifestResource.CustomerId}
                                        manifestSummaryDetailDtos={myManifestSummary()}
                                        buttonType="DefaultButton"
                                        deleteSuccessful={navigateFromApp} />
                                </Stack.Item>
                            </Stack>
                        }
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack tokens={themedSmallStackTokens} horizontal={true} wrap>
                        <Stack.Item grow={2}>
                            <DocumentCard styles={documentUpperCardStyles}>
                                <Stack tokens={themedSmallStackTokens}>
                                    <Stack.Item>
                                        <Text variant={"xLarge"}>
                                            Global
                                        </Text>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField
                                            label="Azure Environment"
                                            borderless
                                            value={manifestResource != null ? manifestResource.AzureEnvironment : ""} />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField
                                            label="Customer Id"
                                            borderless
                                            value={manifestResource != null ? manifestResource.CustomerId : ""} />
                                    </Stack.Item>
                                    {manifestResource != null && manifestResource.TenantId != null && manifestResource.SubscriptionId != null &&
                                        <>
                                            <Stack.Item>
                                                <TextField
                                                    label="Tenant Id"
                                                    borderless
                                                    value={manifestResource != null ? manifestResource.TenantId : ""} />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField
                                                    label="Subscription Id"
                                                    borderless
                                                    value={manifestResource != null ? manifestResource.SubscriptionId : ""} />
                                            </Stack.Item>
                                        </>
                                    }
                                </Stack>
                            </DocumentCard>
                        </Stack.Item>
                        <Stack.Item grow={2}>
                            <DocumentCard styles={documentUpperCardStyles}>
                                <Stack tokens={themedSmallStackTokens}>
                                    <Stack.Item>
                                        <Text variant={"xLarge"}>
                                            Manifest
                                        </Text>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField
                                            label="Id"
                                            borderless
                                            value={manifestResource != null ? manifestResource.ApplicationId : ""} />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField
                                            label="Environment"
                                            borderless
                                            value={manifestResource != null ? manifestResource.ManifestEnvironment : ""} />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField
                                            label="Version"
                                            borderless
                                            value={manifestResource != null ? manifestResource.ManifestVersion.toString() : ""} />
                                    </Stack.Item>
                                </Stack>
                            </DocumentCard>
                        </Stack.Item>
                    </Stack>
                    {manifestResource != null && manifestResource.CosmosContainer != null &&
                        <Stack tokens={themedSmallStackTokens} horizontal={true} wrap>
                            <Stack.Item grow={3}>
                                <DocumentCard styles={documentLowerCardStyles}>
                                    <Stack tokens={themedSmallStackTokens}>
                                        <Stack.Item>
                                            <Text variant={"xLarge"}>
                                                Cosmos
                                            </Text>
                                        </Stack.Item>
                                        <Stack horizontal={true} wrap>
                                            <Stack.Item grow={2}>
                                                <Stack.Item>
                                                    <TextField
                                                        label="Resource Group"
                                                        borderless
                                                        value={manifestResource != null ? manifestResource.CosmosResourceGroup : ""} />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <TextField
                                                        label="Instance"
                                                        borderless
                                                        value={manifestResource != null ? manifestResource.CosmosInstance : ""} />
                                                </Stack.Item>
                                            </Stack.Item>
                                            <Stack.Item grow={2}>
                                                <Stack.Item>
                                                    <TextField
                                                        label="Database"
                                                        borderless
                                                        value={manifestResource != null ? manifestResource.CosmosDatabase : ""} />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <TextField
                                                        label="Container"
                                                        borderless
                                                        value={manifestResource != null ? manifestResource.CosmosContainer : ""} />
                                                </Stack.Item>
                                            </Stack.Item>
                                            <Stack.Item grow={2}>
                                                <Stack.Item>
                                                    <TextField
                                                        label="Partition Key"
                                                        borderless
                                                        value={manifestResource != null ? manifestResource.CosmosPartitionKey : ""} />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <TextField
                                                        label="Partition Value"
                                                        borderless
                                                        value={manifestResource != null ? manifestResource.CosmosPartitionValue : ""} />
                                                </Stack.Item>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack>
                                </DocumentCard>
                            </Stack.Item>
                            <Stack.Item grow={2}>
                                <DocumentCard styles={documentLowerCardStyles}>
                                    <Stack tokens={themedSmallStackTokens}>
                                        <Stack.Item>
                                            <Text variant={"xLarge"}>
                                                Storage
                                            </Text>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TextField
                                                label="Account"
                                                borderless
                                                value={manifestResource != null ? manifestResource.StorageAccount : ""} />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TextField
                                                label="Resource Group"
                                                borderless
                                                value={manifestResource != null ? manifestResource.StorageAccountResourceGroup : ""} />
                                        </Stack.Item>
                                    </Stack>
                                </DocumentCard>
                            </Stack.Item>
                        </Stack>
                    }
                </Stack.Item>
            </Stack>
        </Stack.Item>
    );
}