import React from "react";
import { createTheme, ITheme, mergeStyleSets, Shimmer, ShimmerElementsGroup, ShimmerElementType, ThemeProvider, useTheme } from "@fluentui/react";

export const CustomImageShimmer: React.FC = () => {
    const theme = useTheme();

    // Custom theme for shimmer
    const customThemeForShimmer: ITheme = createTheme({
        palette: {
            // palette slot used in Shimmer for main background
            neutralLight: theme.palette.themeSecondary,
            // palette slot used in Shimmer for tip of the moving wave
            neutralLighter: theme.palette.themeTertiary,
            // palette slot used in Shimmer for all the space around the shimmering elements
            white: theme.palette.themeTertiary
        },
    });

    const getCustomElements = (backgroundColor?: string) => {
        return (
            <div style={{ display: "flex" }}>
                <ShimmerElementsGroup
                    backgroundColor={backgroundColor}
                    shimmerElements={[
                        { type: ShimmerElementType.circle, height: 125 },
                        { type: ShimmerElementType.gap, width: 16, height: 125 },
                    ]}
                />
            </div>
        );
    };

    const classNames = mergeStyleSets({
        themedBackgroundWrapper: {
            height: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: customThemeForShimmer.palette.white,
        }
    });

    return (
        <ThemeProvider theme={customThemeForShimmer}>
            <div className={classNames.themedBackgroundWrapper}>
                <Shimmer customElementsGroup={getCustomElements()} />
            </div>
        </ThemeProvider>
    )
}
