import React from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, IStackTokens, PrimaryButton, Stack, Text } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import axios, { AxiosRequestConfig } from "axios";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { useSetRecoilState } from "recoil";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";

export interface NewCustomerDialogProps {
    close: () => void;
}

export const NewCustomerDialog: React.FC<NewCustomerDialogProps> = (props: NewCustomerDialogProps) => {
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const labelId: string = useId("dialogLabel");
    const subTextId: string = useId("subTextLabel");
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: { main: { minWidth: "450px !important", maxWidth: "500px !important" } }
        }),
        [labelId, subTextId],
    );

    const emailStaff = (): void => {
        dismissDialog();

        const options: AxiosRequestConfig = {
            method: "post",
            url: "/NewCustomerRequest",
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {

            })
            .catch((error) => {
                if (error.response == null) {
                    console.log("Request canceled", "Delete Application Button Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    } else {
                        var dialogProps: IDialogModalWrapperProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we were unable to message our team about the new customer request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        };
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
    };

    const dismissDialog = (): void => {
        props.close();
    }

    return (
        <Dialog
            hidden={false}
            onDismiss={dismissDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}>
            <Stack tokens={themedMediumStackTokens}>
                <Stack.Item>
                    <Text variant={"mediumPlus"}>
                        By clicking OK below the system will let our staff know that you are interesetd in creating a new customer.  Someone will reach out to your shortly.
                    </Text>
                </Stack.Item>
            </Stack>
            <DialogFooter>
                <DefaultButton
                    onClick={dismissDialog}
                    text="Cancel" />
                <PrimaryButton
                    text="OK"
                    ariaDescription="Send message for new customer."
                    onClick={emailStaff}
                    allowDisabledFocus />
            </DialogFooter>
        </Dialog>
    );
}

const dialogContentProps = {
    type: DialogType.normal,
    title: "New Customer Request"
};

const themedMediumStackTokens: IStackTokens = {
    childrenGap: 'm',
    padding: 'm',
};