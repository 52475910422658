import React, { useState } from "react";
import { ActionButton, DefaultButton, FontSizes, FontWeights, IconButton, IIconProps, IStackItemStyles, IStackStyles, Stack, Text } from "@fluentui/react";
import { CustomerSummaryDto } from "../../dtos/User/CustomerSummaryDto";
import { CustomerSubscription } from "./CustomerSubscription";
import { applicationManifestState } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

export interface ICustomerSubscriptionsProps {
    customerSummaries: CustomerSummaryDto[] | undefined;
    close: () => void;
}

export const CustomerSubscriptions: React.FC<ICustomerSubscriptionsProps> = (props: ICustomerSubscriptionsProps) => {
    const [expanded, setExpanded] = useState<boolean>(props.customerSummaries != null && props.customerSummaries.length === 1 ? true : false);
    const setApplicationManifest = useSetRecoilState(applicationManifestState);
    const navigate = useNavigate();
    const expandReAccessCustomersIcon: IIconProps = { iconName: 'DoubleChevronDown' };
    const shrinkReAccessCustomersIcon: IIconProps = { iconName: 'DoubleChevronUp' };

    const ownerClick = () => {
        setApplicationManifest(null);
        props.close();
        navigate(`/OwnerPortal`, { replace: false });
    }

    return (
        <>
            {props.customerSummaries != null
                ?
                <>
                    {props.customerSummaries.length === 0
                        ?
                        <>
                            <Stack horizontal={true} styles={customerHeaderStyles} horizontalAlign="center">
                                <Stack.Item align="center">
                                    <DefaultButton
                                        text="Create ReAccess Trial Account"
                                        ariaDescription="Create Trial button."
                                        onClick={() => {
                                            navigate("/Register", { replace: false });
                                        }}
                                    />
                                </Stack.Item>
                            </Stack>
                        </>
                        :
                        <>
                            <Stack horizontal={true} styles={customerHeaderStyles}>
                                <Stack.Item align="center">
                                    <ActionButton onClick={ownerClick}>
                                        <Text styles={boldSubTextStyles}>
                                            My ReAccess {props.customerSummaries?.length > 1 ? "Accounts" : "Account"}
                                        </Text>
                                    </ActionButton>
                                </Stack.Item>
                                <Stack.Item styles={signOutItemStyles}>
                                    {expanded
                                        ?
                                        <IconButton iconProps={shrinkReAccessCustomersIcon} aria-label="Shrink ReAccess Subscriptions" onClick={() => setExpanded(false)} />
                                        :
                                        <IconButton iconProps={expandReAccessCustomersIcon} aria-label="Expand ReAccess Subscriptions" onClick={() => setExpanded(true)} />
                                    }
                                </Stack.Item>
                            </Stack>
                            {expanded &&
                                <>
                                    {props.customerSummaries.map(customerSummary => {
                                        return <CustomerSubscription
                                            key={"customerSubscription" + customerSummary.CustomerId}
                                            customerSummary={customerSummary}
                                            close={props.close} />
                                    })}
                                </>
                            }
                        </>
                    }
                </>
                :
                <Stack>
                    Loading Customer Accounts
                </Stack>
            }
        </>
    );
}

const customerHeaderStyles: IStackStyles = {
    root: {
        padding: '0 24px 20px',
        display: "flex"
    }
}

const boldSubTextStyles: IStackItemStyles = {
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold
    }
};

const signOutItemStyles: IStackItemStyles = {
    root: {
        display: "flex",
        marginLeft: "auto !important"
    }
};