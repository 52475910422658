import React from "react";
import { IPersonaSharedProps, Persona, PersonaSize, Stack, Text, getInitials } from "@fluentui/react";

export interface UserPersonaGridItemProps {
    avatar: string;
    fullName: string;
    emailAddress: string;
}

export const UserPersonaGridItem: React.FC<UserPersonaGridItemProps> = (props: UserPersonaGridItemProps) => {
    var persona: IPersonaSharedProps = {
        imageUrl: props.avatar == null && props.fullName == null ? "./default_user_image.jpg" : props.avatar,
        imageInitials: props.fullName != null
            ? getInitials(props.fullName, false)
            : "",
        imageShouldFadeIn: false
    };

    return <Stack horizontal={true}>
        <Stack.Item>
            <Persona
                {...persona}
                size={PersonaSize.size48}
                imageAlt={"Picture of user"}
            />
        </Stack.Item>
        <Stack horizontal={false} verticalAlign="center">
            <Stack.Item>
                <Text variant={"mediumPlus"} style={{ "fontWeight": "bold" }}>
                    {props.fullName}
                </Text>
            </Stack.Item>
            <Stack.Item>
                <Text variant={"mediumPlus"}>
                    {props.emailAddress}
                </Text>
            </Stack.Item>
        </Stack>
    </Stack>
}