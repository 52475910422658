import React, { Fragment, useEffect } from "react";
import { HomePage } from "../../components/main/HomePage";
import { AppCatalog } from "../main/AppCatalog";
import { Route, Routes } from "react-router-dom";
import { HomeIndex } from "../main/home/HomeIndex";
import { FormIndex } from "../main/form/FormIndex";
import { ImportPage } from "../main/import/ImportPage";
import { AddEntity } from "../main/form/AddEntity";
import { EditEntity } from "../main/form/EditEntity";
import { NewAppIndex } from "../main/newApp/NewAppIndex";
import { NewApp } from "../main/newApp/NewApp";
import { NotFound } from "../main/NotFound";
import { TrialEnded } from "../main/TrialEnded";
import { Main } from "./Main";
import { Register } from "../main/Register";
import { CreateBasicApplication } from "../main/newApp/CreateBasicApplication";
import { HelpPageIndex } from "../main/help/HelpPageIndex";
import { HelpTour } from "../main/help/HelpTour";
import { HelpVideos } from "../main/help/HelpVideos";
import { Navbar } from "./Navbar";
import { useRecoilValue } from "recoil";
import { createTheme, ITheme, ThemeProvider } from "@fluentui/react";
import { isDarkModeThemeState } from "../../recoil/atoms";
import { darkTheme, lightTheme } from "../../themes";
import { Welcome } from "../main/Welcome";
import { Purge } from "../main/Purge";
import { ApplicationSettings } from "../main/portal/ApplicationSettings";
import { GroupTabs } from "../main/portal/GroupTabs";
import { ApplicationSettingsIndex } from "../main/portal/ApplicationSettingsIndex";
import { setupSignalRConnection } from "../../services/signalRService";
import { useMsal } from "@azure/msal-react";
import { CustomerPortal } from "../main/customerPortal/CustomerPortal";
import { CustomerPortalIndex } from "../main/customerPortal/CustomerPortalIndex";
import { OwnerPortalIndex } from "../main/customerPortal/OwnerPortalIndex";
import { OwnerPortal } from "../main/customerPortal/OwnerPortal";
import ThrowError from "./../../ThrowError";
import { NoAccess } from "../main/NoAccess";
import { SearchPageIndex } from "../main/search/SearchPageIndex";

export const Authenticated: React.FC = () => {
  const { instance } = useMsal();
  const isDarkModeTheme: boolean = useRecoilValue(isDarkModeThemeState);
  var theme: ITheme = isDarkModeTheme ? createTheme(darkTheme) : createTheme(lightTheme);

  useEffect(() => {
    setupSignalRConnection(instance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider as={Fragment} theme={theme}>
      <Routes>
        <Route
          path="/TrialEnded"
          element={
            <>
              <Navbar
                color={theme.semanticColors.bodyText}
                bodyBackground={theme.semanticColors.bodyBackground}
                menuDividerColor={theme.semanticColors.menuDivider}
              ></Navbar>
              <TrialEnded />
            </>
          }
        />
        <Route path="/Register" element={<Register />} />
        <Route path="/NoAccess" element={<NoAccess />} />
        <Route path="/Welcome" element={<Welcome />} />
        <Route
          path="/Purge"
          element={
            <>
              <Navbar
                color={theme.semanticColors.bodyText}
                bodyBackground={theme.semanticColors.bodyBackground}
                menuDividerColor={theme.semanticColors.menuDivider}
              ></Navbar>
              <Purge />
            </>
          }
        />
        <Route
          path="/*"
          element={
            <>
              <Navbar
                color={theme.semanticColors.bodyText}
                bodyBackground={theme.semanticColors.bodyBackground}
                menuDividerColor={theme.semanticColors.menuDivider}
              ></Navbar>
              <NotFound />
            </>
          }
        />
        <Route
          path=""
          element={
            <>
              <Navbar
                color={theme.semanticColors.bodyText}
                bodyBackground={theme.semanticColors.bodyBackground}
                menuDividerColor={theme.semanticColors.menuDivider}
              ></Navbar>
              <Main />
            </>
          }
        >
          <Route path="/" element={<AppCatalog />} />
          <Route path="AppCatalog" element={<AppCatalog />} />
          <Route path="OwnerPortal" element={<OwnerPortalIndex />}>
            <Route path="" element={<OwnerPortal />} />
            <Route path="Customer/:customerId" element={<CustomerPortalIndex />}>
              <Route path="" element={<CustomerPortal />} />
            </Route>
          </Route>
          <Route path="Help" element={<HelpPageIndex />}>
            <Route path="Tour" element={<HelpTour />} />
            <Route path="Videos" element={<HelpVideos />} />
          </Route>
          <Route path="NewApplication" element={<NewAppIndex />}>
            <Route path="Templates" element={<NewApp />} />
            <Route path="Create" element={<CreateBasicApplication />} />
          </Route>
          <Route path="Application/:appId" element={<HomeIndex />}>
            <Route path="ApplicationSettings" element={<ApplicationSettingsIndex />}>
              <Route path="" element={<ApplicationSettings />} />
              <Route path="Group/:groupId" element={<GroupTabs />} />
            </Route>
            <Route path="Home" element={<HomePage />} />
            <Route path="Form/:formId" element={<FormIndex />}>
              <Route path="Search" element={<SearchPageIndex />} />
              <Route path="Import" element={<ImportPage />} />
              <Route path="NewRecord" element={<AddEntity />} />
              <Route path="Entity/:entityId" element={<EditEntity />} />
            </Route>
          </Route>
          <Route path="TestException" element={<ThrowError />}></Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
};
