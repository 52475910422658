import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { TextBoxField } from "./fields/TextBoxField";
import { DatePickerField } from "./fields/DatePickerField";
import { DateTimePickerField } from "./fields/DateTimePickerField";
import { CurrencyField } from "./fields/CurrencyField";
import { TextBlockField } from "./fields/TextBlockField";
import { TextTitleField } from "./fields/TextTitleField";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface TextControlProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const TextControl: React.FC<TextControlProps> = (props: TextControlProps) => {

    return (
        <>
            {(props.control.FieldType === 1 ||
                props.control.FieldType === 3 ||
                props.control.FieldType === 14 ||
                props.control.FieldType === 18 ||
                props.control.FieldType === 20 ||
                props.control.FieldType === 23) &&
                <TextBoxField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </TextBoxField>
            }
            {props.control.FieldType === 5 &&
                <DatePickerField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </DatePickerField>
            }
            {props.control.FieldType === 7 &&
                <DateTimePickerField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </DateTimePickerField>
            }
            {props.control.FieldType === 22 &&
                <CurrencyField
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    isRequired={props.isRequired}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </CurrencyField>
            }
            {props.control.FieldType === 27 &&
                <TextTitleField
                    control={props.control}>
                </TextTitleField>
            }
            {props.control.FieldType === 28 &&
                <>
                    <TextBlockField
                        control={props.control}>
                    </TextBlockField>
                </>
            }
        </>
    );
}