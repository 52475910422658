import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { applicationManifestState, custumActionMessageState, messageBarQueueState } from "../../recoil/atoms";
import { MessageBar, MessageBarButton, MessageBarType, Stack } from "@fluentui/react";
import { IMessageBarQueue } from "../../interfaces/IMessageBarQueue";
import { CustumActionMessage } from "../../enmus/CustumActionMessages";
import { refreshManifest } from "../../services/manifestService";
import useRefreshManifestDependencies from "../../services/useRefreshManifestDependencies";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../model/manifest/ApplicationManifest";
import axios, { AxiosRequestConfig } from "axios";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

/** Displays the first three messages in the Message Bar Queue.  
 * These messages are built outside the component and added to the messageBarQueueState.
*/
export const MainAreaMessages: React.FC = () => {
    const [messages, setMessages]: [IMessageBarQueue[], (messages: IMessageBarQueue[]) => void] = useRecoilState(messageBarQueueState);
    const [customActionMessage, setCustumActionMessage]: [CustumActionMessage | undefined, (customActionMessage: CustumActionMessage | undefined) => void] = useRecoilState(custumActionMessageState);
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [refreshMessageBarId, setRefreshMessageBarId] = useState<string>();
    const [removeMessageBarId, setRemoveMessageBarId] = useState<string>();
    const refreshManifestDependencies = useRefreshManifestDependencies();
    const navigate = useNavigate();
    var { appId } = useParams();

    //this effect will take any messages that have an expiration and start their timer
    useEffect(() => {
        var currentMessages = cloneDeep(messages);

        var expiratoryMessages = currentMessages.filter(i => i.expireMilliseconds != null && !i.expireStarted);
        if (expiratoryMessages == null || expiratoryMessages.length === 0) return;

        expiratoryMessages.forEach((item) => {
            item.expireStarted = true;
            setTimeout(() => {
                setRemoveMessageBarId(item.id);
            }, item.expireMilliseconds);
        });
       
        setMessages(currentMessages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    //this effect will remove an expired message from the queue
    useEffect(() => {
        if (removeMessageBarId == null) { return; }

        var currentMessages = [...messages];
        currentMessages = currentMessages.filter(i => i.id !== removeMessageBarId);
        setMessages(currentMessages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeMessageBarId]);

    //this effect will filter out any messages based on the ID.  Not all messages have ID, but this effect will not change anything with them.
    useEffect(() => {
        if (refreshMessageBarId == null) {
            return;
        }
        var currentMessages = [...messages];
        currentMessages = currentMessages.filter(i => i.id !== refreshMessageBarId);
        if (currentMessages.length !== messages.length) {
            setMessages(currentMessages);
        }
        setRefreshMessageBarId(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshMessageBarId]);

    //this effect will act on any new custom action messages that come in.  they will need to be built in this component for the actions to work properly.
    useEffect(() => {
        if (customActionMessage === undefined) {
            return;
        }
        setCustumActionMessage(undefined);
        //act on any messages that require extra handling
        var currentMessages = [...messages];
        var messageBarId = uuidv4();
        var message: IMessageBarQueue;
        if (customActionMessage === CustumActionMessage.ApplicationSubscription) {
            if (currentMessages.findIndex(i => i.custumActionMessage === CustumActionMessage.ApplicationSubscription) === -1) {
                message = {
                    id: messageBarId,
                    custumActionMessage: CustumActionMessage.ApplicationSubscription,
                    messageBarType: MessageBarType.blocked,
                    message: <>The application's subscription is out of date.  Please contact your administrator and have them contact us to update your billing.</>,
                    actions: undefined,
                    expireMilliseconds: undefined,
                    expireStarted: false
                }
                currentMessages.push(message);
                setMessages(currentMessages);
            }
        }
        if (customActionMessage === CustumActionMessage.DeploymentInProgress) {
            if (currentMessages.findIndex(i => i.custumActionMessage === CustumActionMessage.DeploymentInProgress) === -1) {
                message = {
                    id: messageBarId,
                    custumActionMessage: CustumActionMessage.DeploymentInProgress,
                    messageBarType: MessageBarType.blocked,
                    message: <>We are adjusting a few things.  Please wait a minute and try your request again.</>,
                    actions: undefined,
                    expireMilliseconds: undefined,
                    expireStarted: false
                }
                currentMessages.push(message);
                setMessages(currentMessages);
            }
        }
        if (customActionMessage === CustumActionMessage.UpdateApp) {
            if (currentMessages.findIndex(i => i.custumActionMessage === CustumActionMessage.UpdateApp) === -1) {
                message = {
                    id: messageBarId,
                    custumActionMessage: CustumActionMessage.UpdateApp,
                    messageBarType: MessageBarType.blocked,
                    message: <>Your ReAccess app is out of date.  Refresh the brower to get the latest.</>,
                    actions: undefined,
                    expireMilliseconds: undefined,
                    expireStarted: false
                }
                currentMessages.push(message);
                setMessages(currentMessages);
            }
        }
        if (customActionMessage === CustumActionMessage.ManifestIsPublishing) {
            //the only way to recieve this message is by being inside a manifest.
            //that does not mean you will have a manifest in memoryso we must look at the route param for the application id.
            message = {
                id: messageBarId,
                custumActionMessage: CustumActionMessage.ManifestIsPublishing,
                messageBarType: MessageBarType.blocked,
                message: <>{applicationManifest != null ? applicationManifest.ApplicationName : "The application "} is currently being published.  Please wait a few minutes and try to reload it.</>,
                actions: <div>
                    <MessageBarButton onClick={() => {
                        const CancelToken = axios.CancelToken;
                        const source = CancelToken.source();
                        const options: AxiosRequestConfig = {
                            method: "GET",
                            url: "/IsManifestPublishing",
                            params: {
                                applicationId: appId!
                            },
                            cancelToken: source.token
                        };

                        axios(options)
                            .then((result) => {
                                if (result.data === false) {
                                    setRefreshMessageBarId(messageBarId);
                                    refreshManifest(
                                        refreshManifestDependencies,
                                        navigate,
                                        appId!
                                    );
                                }
                            })
                            .catch((error) => {
                                if (error.response == null) {
                                    console.log("Request canceled", "Main Area Message Component cleaned up");
                                }
                            });
                    }}>Reload</MessageBarButton>
                </div>,
                expireMilliseconds: undefined,
                expireStarted: false
            }

            if (currentMessages.findIndex(i => i.custumActionMessage === CustumActionMessage.ManifestIsPublishing) === -1) {
                currentMessages.push(message);
                setMessages(currentMessages);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customActionMessage]);

    return (
        <>
            {messages.slice(0, 3).map((message, index) => {
                return (
                    <Stack.Item>
                        <MessageBar
                            messageBarType={message.messageBarType}
                            isMultiline={false}
                            onDismiss={() => {
                                var currentMessages = [...messages];
                                currentMessages.splice(index, 1);
                                setMessages(currentMessages);
                            }}
                            dismissButtonAriaLabel="Close"
                            actions={message.actions}
                        >
                            {message.message}
                        </MessageBar>
                    </Stack.Item>
                )
            })}
        </>
    );
}
