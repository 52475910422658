import React from "react";
import { IButtonStyles, IIconProps, PrimaryButton } from "@fluentui/react";

interface IProps {
  signInCallback: () => void;
  signInButtonDisabled: boolean;
}

export const SignIn: React.FC<IProps> = (props: IProps) => {
  const microsoftIcon: IIconProps = { iconName: "microsoft-logo-svg" };

  const signInButtonStyles: IButtonStyles = {
    root: {
      width: "275px",
      height: "50px",
    },
  };

  return (
    <>
      <PrimaryButton
        text="Sign in with Microsoft"
        ariaDescription="Sign In button."
        onClick={() => props.signInCallback()}
        allowDisabledFocus
        iconProps={microsoftIcon}
        styles={signInButtonStyles}
        disabled={props.signInButtonDisabled}
      />
    </>
  );
};
