import React, { useEffect, useState } from "react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Dropdown, DropdownMenuItemType, IDropdownOption, TooltipHost } from "@fluentui/react";
import { ConnectForm } from "../../../form/ConnectForm";
import { Controller, FieldValues, UseFormReturn, useFormContext, useWatch } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { applicationManifestState, dataSourceItemSelector, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { getRules } from "../../../../../services/ruleService";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { useId } from "@fluentui/react-hooks";
import axios from "axios";
import { DataSourceItem } from "../../../../../model/manifest/DataSourceItem";
import { useParams } from "react-router-dom";
import { removeDashFromGuid } from "../../../../../services/stringManipulationService";
import { DataSourceItemCascade } from "../../../../../model/manifest/DataSourceItemCascade";
import { ReadOnlyDropdownField } from "../../ReadOnlyDropdownField";
import { buildFormPropertyName, readOnlyTextForMultiSelectDataSource } from "../../../../../services/fieldService";
import { useRecoilState } from "recoil";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { cloneDeep, isEqual } from "lodash";

export interface MultiSelectChildFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    isSearch: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const MultiSelectChildField: React.FC<MultiSelectChildFieldProps> = (props: MultiSelectChildFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[] | undefined>();
    const cascadeParentValue: string | string[] | undefined = useWatch({ name: buildFormPropertyName(props.control.CascadeParentControlId, false, undefined, props.subFormField) });
    const parentDataSourceItems: DataSourceItem[] = useRecoilValue(dataSourceItemSelector(props.control.CascadeParentControlId));
    const [dataSourceItems, setDataSourceItems]: [DataSourceItemCascade[], (dataSourceItems: DataSourceItemCascade[]) => void] = useRecoilState(dataSourceItemSelector(props.control.Id));
    const [previousCascadeValue, setPreviousCascadeValue] = useState<string | string[] | undefined>();
    const { getValues, setValue, formState: { isDirty } } = useFormContext();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var { appId } = useParams();
    const dropdownId = useId("dropdownChild");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${dropdownId}`
    };

    useEffect(() => {
        return function cleanup() {
            source.cancel();
            setDataSourceItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (parentDataSourceItems == null || parentDataSourceItems.length === 0) {
            return;
        }
        if (cascadeParentValue == null || cascadeParentValue.length === 0) {
            return;
        }
        setDropdownOptions(undefined);
        var options: IDropdownOption[] = [];
        axios.get(`/DataSource/GetCascade?ApplicationId=${appId}&PageId=${props.control.PageId}&ChildControlId=${props.control.Id}&ParentSelectedEntityId=${cascadeParentValue}`,
            { cancelToken: source.token })
            .then((result) => {
                var myItems: DataSourceItemCascade[] = result.data;
                myItems.forEach((item) => {
                    var parentControlId = removeDashFromGuid(item.ParentControlId);
                    if (options.findIndex(i => i.key === `${parentControlId}-header`) === -1) {
                        var parentDataSourceItem = parentDataSourceItems.find(i => i.Value === parentControlId)!;
                        options.push({ key: `${parentControlId}-divider`, text: '-', itemType: DropdownMenuItemType.Divider },);
                        options.push({ key: `${parentControlId}-header`, text: parentDataSourceItem.Text, itemType: DropdownMenuItemType.Header },);
                    }
                    options.push({ key: item.Value, text: item.Text });
                });
                setDropdownOptions(options);
                setDataSourceItems(myItems);
            })
            .catch((error) => {
                setDropdownOptions(options);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentDataSourceItems]);

    useEffect(() => {
        setPreviousCascadeValue(cascadeParentValue);
        var options: IDropdownOption[] = [];
        if (cascadeParentValue == null || cascadeParentValue.length === 0) {
            //if there is nothing selected at the parent, set myself to nothing as well if the state is dirty
            if (isDirty) {
                setValue(buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField), [], {
                    shouldValidate: true,
                    shouldDirty: true,
                });
            }
            //no matter what if there is nothing selected at the parent then  set teh options to nothing
            options.unshift({ key: "", text: "Select an option" });
            setDropdownOptions(options);
            return;
        }

        if (isEqual(cascadeParentValue, previousCascadeValue)) {
            return;
        }

        setDropdownOptions([{ key: "", text: "Loading..." }]);

        axios.get(`/DataSource/GetCascade?ApplicationId=${appId}&PageId=${props.control.PageId}&ChildControlId=${props.control.Id}&ParentSelectedEntityId=${cascadeParentValue}`,
            { cancelToken: source.token })
            .then((result) => {
                var myItems: DataSourceItemCascade[] = result.data;
                myItems.forEach((item) => {
                    var parentControlId = removeDashFromGuid(item.ParentControlId);
                    if (options.findIndex(i => i.key === `${parentControlId}-header`) === -1) {
                        var parentDataSourceItem = parentDataSourceItems.find(i => i.Value === parentControlId)!;
                        options.push({ key: `${parentControlId}-divider`, text: '-', itemType: DropdownMenuItemType.Divider },);
                        options.push({ key: `${parentControlId}-header`, text: parentDataSourceItem.Text, itemType: DropdownMenuItemType.Header },);
                    }
                    options.push({ key: item.Value, text: item.Text });
                });
                setDropdownOptions(options);
                setDataSourceItems(myItems);

                var myValue: string[] = getValues(buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField));
                if (myValue != null && myValue.length > 0) {
                    var foundValues: string[] = [];
                    myValue.forEach(value => {
                        if (myItems.findIndex(i => i.Value === value) > -1) {
                            foundValues.push(value);
                        }
                    });
                    if (myValue.length !== foundValues.length) {
                        setValue(buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField), foundValues, {
                            shouldValidate: true,
                            shouldDirty: true,
                        });
                    }
                }
            })
            .catch((error) => {
                setDropdownOptions(options);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cascadeParentValue]);

    return (
        <>
            <ConnectForm>
                {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                    <>
                        <Controller
                            control={methods.control}
                            rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                            name={buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField)}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    {!props.readOnly &&
                                        <TooltipHost
                                            content={props.control.ToolTipText}
                                            id={tooltipId}
                                            calloutProps={calloutProps}
                                        >
                                            <Dropdown
                                                id={dropdownId}
                                                label={props.control.LabelText}
                                                multiSelect={true}
                                                selectedKeys={value}
                                                disabled={props.disabled}
                                                required={props.isRequired}
                                                options={dropdownOptions != null ? dropdownOptions : []}
                                                placeholder="Select options"
                                                errorMessage={
                                                    methods.formState.errors != null &&
                                                        methods.formState.errors[props.control.Id!] != null &&
                                                        methods.formState.errors[props.control.Id!]!.message != null ?
                                                        methods.formState.errors[props.control.Id!]!.message?.toString() :
                                                        undefined}
                                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                                    const currentOption: string = option!.key as string;
                                                    var myValue: string[];

                                                    if (option?.selected) {
                                                        if (value == null || value === "") {
                                                            value = [];
                                                        }
                                                        myValue = value.concat([currentOption]);
                                                    } else {
                                                        myValue = value.filter((i: string) => i !== currentOption);
                                                    }

                                                    if (props.subFormField != null) {
                                                        var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                        var updateableField = cloneDeep(myCurrentValue);
                                                        updateableField[props.control.Id!] = option?.key;
                                                        props.subFormField.update(props.subFormField.Index, updateableField);
                                                    }

                                                    onChange(myValue);
                                                }} />
                                        </TooltipHost>
                                    }
                                    {props.readOnly &&
                                        <ReadOnlyDropdownField
                                            control={props.control}
                                            textValue={readOnlyTextForMultiSelectDataSource(
                                                value,
                                                dataSourceItems
                                            )}
                                            value={value}
                                        />
                                    }
                                </>
                            )} />
                    </>
                }
            </ConnectForm>

        </>
    );
}