import React, { useEffect, useState } from "react";
import { IColumn, SelectionMode } from "@fluentui/react";
import { IDataImportHistory, IDataImportHistoryResult } from "../../../interfaces/DataImportHistoryResult";
import moment from "moment";
import { DetailsListWrapper } from "../gridLayout/DetailsListWrapper";

export interface ImportHistoryGridProps {
    dataImportHistoryResult: IDataImportHistoryResult;
    isLoading: boolean;
}

export const ImportHistoryGrid: React.FC<ImportHistoryGridProps> = (props: ImportHistoryGridProps) => {
    const [gridItems, setGridItems] = useState<IDataImportHistory[]>(props.dataImportHistoryResult.DataImportHistory);
    const [columns,] = useState<IColumn[]>([
        {
            key: 'ImportNumber',
            name: 'Import Number',
            ariaLabel: 'Column operations for Import Number, Press to sort on File type',
            fieldName: 'ImportNumber',
            minWidth: 125,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: false,
            data: 'number',
            onRender: (item: IDataImportHistory) => {
                return <span>{item.ImportNumber}</span>;
            },
        },
        {
            key: 'ImportStatus',
            name: 'Status',
            fieldName: 'ImportStatus',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: false,
            data: 'string',
            onRender: (item: IDataImportHistory) => {
                return <span>{item.Status}</span>;
            },
            isPadded: true,
        },
        {
            key: 'Description',
            name: 'Description',
            fieldName: 'Description',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: false,
            data: 'string',
            onRender: (item: IDataImportHistory) => {
                return <span>{item.Description}</span>;
            },
            isPadded: true,
        },
        {
            key: 'RecordCount',
            name: 'Record Count',
            fieldName: 'RecordCount',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: false,
            data: 'number',
            onRender: (item: IDataImportHistory) => {
                return <span>{item.RecordCount}</span>;
            },
            isPadded: true,
        },
        {
            key: 'SuccessCount',
            name: 'Success Count',
            fieldName: 'SuccessCount',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: false,
            data: 'number',
            onRender: (item: IDataImportHistory) => {
                return <span>{item.SuccessCount}</span>;
            },
            isPadded: true,
        },
        {
            key: 'FailedCount',
            name: 'Failed Count',
            fieldName: 'FailedCount',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: false,
            data: 'number',
            onRender: (item: IDataImportHistory) => {
                return <span>{item.FailedCount}</span>;
            },
            isPadded: true,
        },
        {
            key: 'IncompleteCount',
            name: 'Incomplete Count',
            fieldName: 'IncompleteCount',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: false,
            data: 'number',
            onRender: (item: IDataImportHistory) => {
                return <span>{item.IncompleteCount}</span>;
            },
            isPadded: true,
        },
        {
            key: 'ImportedBy',
            name: 'Imported By',
            fieldName: 'ImportedBy',
            minWidth: 125,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onRender: (item: IDataImportHistory) => {
                return <span>{item.ImportedBy}</span>;
            },
            isPadded: true,
        },
        {
            key: 'Date',
            name: 'Date',
            fieldName: 'Date',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: true,
            data: 'datetime',
            onRender: (item: IDataImportHistory) => {
                return <span>{moment(item.ImportedDate).format("M/D/YYYY h:mm A")}</span>;
            }
        }
    ]);

    useEffect(() => {
        setGridItems(props.dataImportHistoryResult.DataImportHistory);
    }, [props.dataImportHistoryResult.DataImportHistory]);

    return (
        <>
            <DetailsListWrapper
                columns={columns}
                items={gridItems}
                keysToIgnoreInSearch={[]}
                grid={undefined}
                gridName={"Import History"}
                isLoading={props.isLoading}
                rowId="Id"
                displayAddNewExistingButton={false}
                disableAddNewExistingButton={false}
                hideGridAddExistingButton={true}
                hideGridAddNewButton={true}
                selectionMode={SelectionMode.none}
                warningMessage={undefined}
            />
        </>
    );
}