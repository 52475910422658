import { ITheme, ShimmerElementsGroup, ShimmerElementType } from "@fluentui/react";

export const getDefaultFieldShimmer = (theme: ITheme): JSX.Element => {
    return (
        <div>
            {getLabelShimmer(theme)}
            <ShimmerElementsGroup
                backgroundColor={theme.semanticColors.bodyStandoutBackground}
                shimmerElements={[
                    { type: ShimmerElementType.line, height: 32, width: "100%" }
                ]}
            />
        </div>
    );
};

export const getLabelShimmer = (theme: ITheme): JSX.Element => {
    return (
        <div>
            <ShimmerElementsGroup
                backgroundColor={theme.semanticColors.bodyStandoutBackground}
                shimmerElements={[
                    { type: ShimmerElementType.gap, height: 5, width: "100%" }
                ]}
            />
            <ShimmerElementsGroup
                backgroundColor={theme.semanticColors.bodyStandoutBackground}
                shimmerElements={[
                    { type: ShimmerElementType.line, height: 20, width: "70px" },
                    { type: ShimmerElementType.gap, height: 20, width: "100%" }
                ]}
            />
            <ShimmerElementsGroup
                backgroundColor={theme.semanticColors.bodyStandoutBackground}
                shimmerElements={[
                    { type: ShimmerElementType.gap, height: 5, width: "100%" }
                ]}
            />
        </div>
    );
};

export const getButtonShimmer = (): JSX.Element => {
    return (
        <div>
            <ShimmerElementsGroup
                shimmerElements={[
                    { type: ShimmerElementType.line, height: 32 }
                ]}
            />
        </div>
    );
};

export const getSingleLineShimmer = (height: number): JSX.Element => {
    return (
        <div>
            <ShimmerElementsGroup
                shimmerElements={[
                    { type: ShimmerElementType.line, height: height }
                ]}
            />
        </div>
    );
};