import React, { useEffect, useState } from "react";
import axios from 'axios';
import { PowerBIEmbed } from "powerbi-client-react";
import { Embed, models, Report } from "powerbi-client";
import { PowerBiAccessTokenDto } from "../../dtos/PowerBi/PowerBiAccessTokenDto";
import moment from "moment";
import { PowerBi } from "../../model/manifest/PowerBi";
import { useParams } from "react-router-dom";
import { css } from '@emotion/css';
import { useId } from "@fluentui/react-hooks";

export interface PowerBiReportProps {
    powerBi: PowerBi;
}

export const PowerBiReport: React.FC<PowerBiReportProps> = (props: PowerBiReportProps) => {
    const [accessToken, setAccessToken] = useState<string>();
    const [report, setReport] = useState<Report>();
    const [refreshReport, setRefreshReport] = useState<boolean>(false);
    const [myStyle, setMyStyle] = useState<string>();
    var { appId } = useParams();
    const powerBiId = useId("powerBi");

    useEffect(() => {
        if (report == null) {
            return;
        }
        const app = document.getElementById(powerBiId)!;
        const myStyle = css`
            height: ${props.powerBi.PowerBiStyle.Height}vh;
            margin: 1% auto;
            width: ${props.powerBi.PowerBiStyle.Width}%;
        `;
        const mySubStyle = css`
            ${powerBiId}.${myStyle} iframe {
                border-style: ${props.powerBi.PowerBiStyle.ShowBorder ? "2px" : "none"};
            }
        `;
        app.classList.add(myStyle);
        app.classList.add(mySubStyle);
        setMyStyle(myStyle);

        GetPowerBiAccessToken();

        return function cleanup() {
            app.classList.remove(myStyle);
            app.classList.remove(mySubStyle);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report]);

    useEffect(() => {
        if (refreshReport == null || refreshReport === false) {
            return;
        }
        setRefreshReport(false);
        GetPowerBiAccessToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshReport]);

    const GetPowerBiAccessToken = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        var url = props.powerBi.IsRls ? "/GetPowerBiRowLevelSecurityAccessToken" : "/GetPowerBiAccessToken"
        axios({
            method: "get",
            url: url,
            params: {
                applicationId: appId,
                powerBiId: props.powerBi.Id
            },
            cancelToken: source.token,
        }).then((result) => {
            var powerBiAccessToken: PowerBiAccessTokenDto = result.data;
            if (powerBiAccessToken.StatusCode === 200) {
                var powerBiTimeInMilliseconds = moment(powerBiAccessToken.Expiration).add(-10, "m").valueOf();
                var currentTimeInMilliseconds = moment().valueOf();
                var waitTime = powerBiTimeInMilliseconds - currentTimeInMilliseconds;
                setAccessToken(powerBiAccessToken.Token);
                if (report != null) {
                    //if the report was already set and this is an automatic refresh we need to wait for the
                    //new access token to be set in state before calling report.refresh.
                    setTimeout(() => {
                        report.refresh();
                    }, 250);
                }
                //The amount of time to wait until automatically refreshing the report
                setTimeout(() => {
                    setRefreshReport(true);
                }, waitTime);
            } else {
                //TODO: handle errors status codes
                //400 bad request (could be a role issue)
            }
        }).catch((error) => {
            setAccessToken(undefined);
            if (error.response == null) {
                console.log("Request canceled", "Power BI Component cleaned up");
            } else {
                // handle error
            }
        });
    }

    return (
        <div id={powerBiId}>
            <PowerBIEmbed
                embedConfig={{
                    type: "report",
                    id: props.powerBi.ReportId,
                    embedUrl: props.powerBi.EmbedUrl,
                    accessToken: accessToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                expanded: props.powerBi.IsFilterExpanded,
                                visible: props.powerBi.IsFilterVisible
                            },
                            pageNavigation: {
                                visible: props.powerBi.IsPageNavigationVisible
                            }
                        },
                        background: models.BackgroundType.Transparent
                    },
                }}
                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) {
                            if (event == null || event.detail == null) {
                                return;
                            }
                            if (event.detail.errorCode === "403") {
                                //Access token has expired, resubmit with a new access token
                                //TODO: should I create a new call to clear cache or pass a variable in the original to reload cache?
                                //Maybe this just needs to be fixed on the server, but should we handle it still... just in case?
                                console.log("Access token has expired, resubmit with a new access token");
                            }
                        }]
                    ])
                }
                cssClassName={myStyle}
                getEmbeddedComponent={(embedObject: Embed) => {
                    console.log(`Embedded object of type "${embedObject.embedtype}" received`);
                    if (report == null) {
                        setReport(embedObject as Report);
                    }
                }}
            />
        </div>
    );
}