import React, { } from "react";
import { Stack, ScrollablePane, mergeStyleSets, IStackItemStyles, Shimmer, ShimmerElementsGroup, ShimmerElementType, IScrollablePaneStyles, useTheme } from "@fluentui/react";
import { ShimmerAppCard } from "./ShimmerAppCard";
import getScreenSize from "../../../services/getScreenSize";

export const ShimmerAppCatalogPage: React.FC = () => {
    const theme = useTheme();
    const screenSize: number = getScreenSize();

    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };

    const getCustomElements = (): JSX.Element => {
        return (
            <div>
                <ShimmerElementsGroup
                    shimmerElements={[
                        { type: ShimmerElementType.line, height: 35 }
                    ]}
                />
            </div>
        );
    };

    return (
        <>
            <Shimmer
                customElementsGroup={getCustomElements()}
                isDataLoaded={false}
                width={350}
                shimmerColors={{
                    shimmer: theme.palette.themeTertiary,
                    shimmerWave: theme.palette.themeSecondary,
                    background: theme.semanticColors.bodyBackground,
                }}>
            </Shimmer>
            <div className={classNames.wrapper}>
                <ScrollablePane styles={scrollablePaneStyles}>
                    <Stack horizontal={true}>
                        <Stack.Item styles={cardStackItemStyles}>
                            <ShimmerAppCard></ShimmerAppCard>
                        </Stack.Item>
                        {screenSize >= 768 &&
                            <Stack.Item styles={cardStackItemStyles}>
                                <ShimmerAppCard></ShimmerAppCard>
                            </Stack.Item>
                        }
                    </Stack>
                </ScrollablePane>
            </div>
            <Shimmer
                customElementsGroup={getCustomElements()}
                isDataLoaded={false}
                width={350}
                shimmerColors={{
                    shimmer: theme.palette.themeTertiary,
                    shimmerWave: theme.palette.themeSecondary,
                    background: theme.semanticColors.bodyBackground,
                }}>
            </Shimmer>
            <div className={classNames.wrapper}>
                <ScrollablePane styles={scrollablePaneStyles}>
                    <Stack horizontal={true}>
                        <Stack.Item styles={cardStackItemStyles}>
                            <ShimmerAppCard></ShimmerAppCard>
                        </Stack.Item>
                    </Stack>
                </ScrollablePane>
            </div>
        </>
    );
}

const cardStackItemStyles: IStackItemStyles = {
    root: {
        paddingRight: "20px"
    },
};

const classNames = mergeStyleSets({
    wrapper: {
        height: "30vh",
        width: "100%",
        position: "relative",
        maxHeight: "inherit",
    },
    pane: {
        maxWidth: "100%"
    }
});