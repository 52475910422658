import React, { useState } from "react";
import { DefaultButton, Dropdown, IBasePickerSuggestionsProps, IDropdownOption, IPersonaProps, IStackTokens, Label, NormalPeoplePicker, Panel, PanelType, PrimaryButton, Stack, TextField, Toggle, getInitials, useTheme } from "@fluentui/react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useId } from "@fluentui/react-hooks";
import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { CustomerUserSearchDto } from "../../../dtos/User/CustomerUserSearchDto";
import { getUserRoleOptionsWithSelectAnOption } from "../../../services/roleService";
import { RegexConstants } from "../../../constants/RegexConstants";
import { IManifestUserPermissionGridItem } from "../../../interfaces/users/IManifestUserPermissionGridItem";
import { ValidationError } from "../../../model/error/ValidationError";

export interface NewUserPanelProps {
    appId: string;
    manifestUserPermissions: IManifestUserPermissionGridItem[];
    createdUser: (emailAddress: string, role: string, avatar: string, fullName: string) => void;
    dismissPanel: () => void;
}

export const NewUserPanel: React.FC<NewUserPanelProps> = (props: NewUserPanelProps) => {
    const { handleSubmit, control, formState: { errors, isDirty, isValid }, getValues, setError, setValue } = useForm({ defaultValues: defaultValues, mode: "onChange" });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>();
    const [textFilterLength, setTextFilterLength] = useState<number>(0);
    const [byCustomer, setByCustomer] = useState<boolean>(true);
    const [sendEmail, setSendEmail] = useState<boolean>(true);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const theme = useTheme();
    const tagPickerId = useId("tagPicker");
    const picker = React.useRef(null);
    const CancelToken = axios.CancelToken;

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack horizontal={true} horizontalAlign={"end"}>
                <PrimaryButton
                    styles={buttonStyles}
                    onClick={submitForm}
                    disabled={!isValid || !isDirty || isSubmitting}>
                    Create
                </PrimaryButton>
                <DefaultButton
                    onClick={props.dismissPanel}
                    disabled={isSubmitting}>
                    Cancel
                </DefaultButton>
            </Stack>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDirty, isSubmitting, isValid, props.dismissPanel],
    );

    const submitForm = async () => {
        setIsSubmitting(true);
        var data = getValues();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        var persona: IPersonaProps | undefined = data.userPersona != null ? data.userPersona![0] : undefined;
        var manifestUserUpsertDto = {
            EmailAddress: byCustomer ? persona!.secondaryText! : data.customEmailAddress,
            Role: data.role,
            SendEmail: data.sendEmail
        }
        const options: AxiosRequestConfig = {
            method: "POST",
            data: manifestUserUpsertDto,
            url: "/ManifestUserPermission/Post",
            params: {
                applicationId: props.appId
            },
            cancelToken: source.token
        };
        axios(options)
            .then((result) => {
                props.createdUser(
                    manifestUserUpsertDto.EmailAddress,
                    manifestUserUpsertDto.Role,
                    persona != null && persona.imageUrl != null ? persona.imageUrl : "",
                    persona != null && persona.text != null ? persona.text : "");
                setIsSubmitting(false);
            }).catch((error) => {
                setIsSubmitting(false);
                if (error.response == null) {
                    console.log("Request canceled", "New Group Panel Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        //set errors manually
                        validationErrors.forEach((validationError: ValidationError) => {
                            var identifier = validationError.GroupIdentifier as any === "EmailAddress" ?
                                byCustomer ?
                                    "userPersona" :
                                    "customEmailAddress" :
                                validationError.GroupIdentifier as any
                            setError(identifier, {
                                type: "manual",
                                message: validationError.Message
                            })
                        });
                    } else {
                        var dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                        setDialogModalWrapperPropsState(dialogProps);
                    }
                }
            });
    }

    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested People',
        noResultsFoundText: textFilterLength >= 3 ? "No results found" : "Minimum of three characters needed",
        loadingText: 'Loading',
        showRemoveButtons: false
    };

    const onResolveSuggestions = async (filterText: string, tagList: IPersonaProps[]): Promise<IPersonaProps[]> => {
        if (cancelTokenSource != null) {
            cancelTokenSource.cancel();
        }
        setTextFilterLength(filterText == null ? 0 : filterText.length);
        if (filterText == null || filterText.length < 3) {
            return [];
        }
        const source = CancelToken.source();
        setCancelTokenSource(source);
        const options: AxiosRequestConfig = {
            method: "GET",
            url: "/CustomerUser/Get",
            params: {
                customerId: props.manifestUserPermissions[0].CustomerId,
                searchValue: filterText
            },
            cancelToken: source.token
        };

        return axios(options)
            .then((result) => {
                setCancelTokenSource(undefined);
                var customerUserSearchDtos: CustomerUserSearchDto[] = result.data;
                var personaProps: IPersonaProps[] = [];
                customerUserSearchDtos.forEach(user => {
                    var personaProp: IPersonaProps = {
                        imageUrl: user.Avatar == null && user.FullName == null ? "./default_user_image.jpg" : user.Avatar,
                        imageInitials: user.FullName != null
                            ? getInitials(user.FullName, false)
                            : "",
                        imageShouldFadeIn: false,
                        text: user.FullName,
                        secondaryText: user.Email,
                        showSecondaryText: true,
                        theme: theme
                    }
                    personaProps.push(personaProp);
                });
                return personaProps;
            })
            .catch((error) => {
                return [];
            });
    };

    return (
        <Panel
            headerText="Add user to application"
            isOpen={true}
            onDismiss={props.dismissPanel}
            type={PanelType.custom}
            customWidth="500px"
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <form onSubmit={handleSubmit(submitForm)}>
                <Stack horizontal={false} tokens={themedSmallStackTokens} style={{ "paddingTop": "25px" }}>
                    <Stack.Item>
                        <Toggle
                            label="Add By"
                            inlineLabel={false}
                            onText="Existing User"
                            offText="New User"
                            checked={byCustomer}
                            onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                setByCustomer(checked!);
                                setValue("userPersona", undefined);
                                setValue("customEmailAddress", "");
                            }} />
                    </Stack.Item>
                    <Stack.Item>
                        {byCustomer
                            ?
                            <Controller
                                control={control}
                                name="userPersona"
                                rules={{
                                    validate: {
                                        unique: (value: IPersonaProps[] | undefined): string | undefined => {
                                            if (value == null || value.length === 0) {
                                                return "A user is required";
                                            } else {
                                                var foundEmail = props.manifestUserPermissions.findIndex(i => i.EmailAddress === value[0].secondaryText);
                                                if (foundEmail >= 0) {
                                                    return "The user already has persmissions in the application.";
                                                }
                                            }
                                            return undefined;
                                        },
                                    },
                                }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <>
                                        <Label htmlFor={tagPickerId} className="CustomErrorLabel">Email</Label>
                                        <div id={tagPickerId}>
                                            <NormalPeoplePicker
                                                onResolveSuggestions={(filter: string, selectedItems?: IPersonaProps[] | undefined) => {
                                                    return onResolveSuggestions(filter, selectedItems !== undefined ? selectedItems : []);
                                                }}
                                                getTextFromItem={getTextFromItem}
                                                pickerSuggestionsProps={suggestionProps}
                                                key={'normal'}
                                                selectionAriaLabel="Selected person"
                                                inputProps={{ "aria-label": "People Picker" }}
                                                componentRef={picker}
                                                selectedItems={value}
                                                styles={{}}
                                                itemLimit={1}
                                                onChange={(items?: IPersonaProps[] | undefined) => {
                                                    if (items != null && items.length > 0) {
                                                        onChange(items);
                                                    } else {
                                                        onChange([]);
                                                    }
                                                }}
                                                resolveDelay={300}
                                            />
                                        </div>
                                        <div className="CustomError">
                                            <ErrorMessage errors={errors} name={"userPersona"} />
                                        </div>
                                    </>
                                )}
                            />
                            :
                            <Controller
                                control={control}
                                name="customEmailAddress"
                                rules={{
                                    maxLength: { value: 100, message: "The max length of email is 100 characters." },
                                    validate: {
                                        unique: (value: string): string | undefined => {
                                            if (value == null || value === "") {
                                                return "Email is required.";
                                            }
                                            if (value.search(RegexConstants.EmailRegex) === -1) {
                                                return "Email is invalid.";
                                            }
                                            return undefined;
                                        },
                                    },
                                }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        label="Email"
                                        required={true}
                                        value={value}
                                        errorMessage={errors != null && errors["customEmailAddress"]?.message != null ? errors["customEmailAddress"].message : undefined}
                                        onBlur={onBlur}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            onChange(newValue);
                                        }} />
                                )}
                            />
                        }

                    </Stack.Item>
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="role"
                            rules={{
                                required: "Role is required."
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Dropdown
                                    label={"Role"}
                                    selectedKey={value}
                                    required={true}
                                    options={getUserRoleOptionsWithSelectAnOption()}
                                    errorMessage={errors != null && errors["role"]?.message != null ? errors["role"].message : undefined}
                                    onBlur={onBlur}
                                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                        onChange(option?.key);
                                    }} />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Toggle
                            label="Send Invitation Email"
                            inlineLabel={false}
                            onText="Yes"
                            offText="No"
                            checked={sendEmail}
                            onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                setSendEmail(checked!);
                                setValue("sendEmail", checked!);
                            }} />
                    </Stack.Item>
                </Stack>
            </form>
        </Panel>
    );
}

const defaultValues = {
    userPersona: undefined,
    role: "",
    customEmailAddress: "",
    sendEmail: true
}

const buttonStyles = { root: { marginRight: 8 } };

const themedSmallStackTokens: IStackTokens = {
    childrenGap: "s1",
    padding: "s1",
};