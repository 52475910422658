import React from "react";
import { ITextProps, IStackTokens, Stack, Text } from "@fluentui/react";
import { DownloadHeadersDefinitionsButton } from "./DownloadHeadersDefinitionsButton";

export const Definitions: React.FC = () => {

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: 'm',
        padding: 'm',
    };

    return (
        <>
            <Stack horizontal={false} tokens={themedMediumStackTokens}>
                <Stack.Item>
                    <Text variant={"medium" as ITextProps['variant']}>
                        A text file that contains information about each of the fields in the header file.
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <DownloadHeadersDefinitionsButton isTemplate={false} />
                </Stack.Item>
            </Stack>
        </>
    );
}