import React from "react";
import { Label, Link, TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { useId } from "@fluentui/react-hooks";

export interface ReadOnlyLinkFieldProps {
    control: ControlData;
    value: string;
}

export const ReadOnlyLinkField: React.FC<ReadOnlyLinkFieldProps> = (props: ReadOnlyLinkFieldProps) => {
    const linkFieldId = useId("link");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${linkFieldId}`
    };

    const handleUrl = (): string => {
        let url = props.value;
        if (url != null && url !== "" && !url.startsWith("http://") && !url.startsWith("https://")) {
            url = "http://" + url;
        }
        return url;
    }

    return (
        <>
            <Label htmlFor={linkFieldId}>{props.control.LabelText}</Label>
            <TooltipHost
                content={props.control.ToolTipText}
                id={tooltipId}
                calloutProps={calloutProps}
            >
                {props.control.FieldType === 3 &&
                    <Link id={linkFieldId} href={`mailto:${props.value}`}>
                        {props.value}
                    </Link>
                }
                {props.control.FieldType === 23 &&
                    <Link id={linkFieldId} href={handleUrl()} target="_blank">
                        {props.value}
                    </Link>
                }
            </TooltipHost>
        </>
    )
}