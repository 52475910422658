import React, { useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { IBreadcrumbItem, IStackItemStyles, Pivot, PivotItem, Stack, useTheme } from "@fluentui/react";
import { applicationManifestState, breadcrumbsState, navHeaderState, shimmerNavState } from "../../../recoil/atoms";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { GroupSettings } from "./GroupSettings";
import { GroupMembers } from "./GroupMembers";
import { GroupDataFilter } from "./GroupDataFilter";
import { GroupHeader } from "./GroupHeader";
import { StringConstants } from "../../../constants/StringConstants";

export const GroupTabs: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const setBreadcrumbs = useSetRecoilState(breadcrumbsState);

    const theme = useTheme();

    useEffect(() => {
        setNavHeader(`${applicationManifest.ApplicationName} Settings`);
        setShimmerNav(true);

        const itemsWithHref: IBreadcrumbItem[] = [
            { text: "Application Settings", key: "appSettingsBreadcrumb", href: `/Application/${applicationManifest.Id}/ApplicationSettings` },
            { text: "Group", key: "groupBreadcrumb", href: "", isCurrentItem: true },
        ];
        setBreadcrumbs(itemsWithHref);

        return function cleanup() {
            setBreadcrumbs([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    return (
        <>
            <Stack styles={bodyStackItemStyles}>
                <Pivot
                    aria-label="Tab region for Group User Settings"
                    linkSize="large"
                    overflowBehavior="menu">
                    <PivotItem
                        key="Members"
                        headerText={StringConstants.Members}
                        alwaysRender={true}>
                        <>
                            <GroupHeader groupHeader={StringConstants.Members}></GroupHeader>
                            <GroupMembers></GroupMembers>
                        </>
                    </PivotItem>
                    <PivotItem
                        key={"Settings"}
                        headerText={StringConstants.Settings}
                        alwaysRender={true}>
                        <>
                            <GroupHeader groupHeader={StringConstants.Settings}></GroupHeader>
                            <GroupSettings></GroupSettings>
                        </>
                    </PivotItem>
                    <PivotItem
                        key={"DataFilter"}
                        headerText={StringConstants.DataFilters}
                        alwaysRender={true}>
                        <>
                            <GroupHeader groupHeader={StringConstants.DataFilters}></GroupHeader>
                            <GroupDataFilter></GroupDataFilter>
                        </>
                    </PivotItem>
                </Pivot>
            </Stack>
        </>
    );
}