import React from "react";
import { TextField, TooltipHost, useTheme } from "@fluentui/react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import { ConnectForm } from "../../../form/ConnectForm";
import { useRecoilValue } from "recoil";
import { applicationManifestState, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { getRules } from "../../../../../services/ruleService";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { ReadOnlyTextBoxField } from "../../ReadOnlyTextBoxField";
import { ReadOnlyLinkField } from "../ReadOnlyLinkField";
import { useId } from "@fluentui/react-hooks";
import { getPrefix, getSuffix } from "../../../../../helpers/TextBoxHelpers";
import { buildFormPropertyName } from "../../../../../services/fieldService";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { cloneDeep } from "lodash";

export interface TextBoxFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const TextBoxField: React.FC<TextBoxFieldProps> = (props: TextBoxFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const theme = useTheme();
    const textBoxId = useId("textBox");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${textBoxId}`
    };

    return (
        <ConnectForm>
            {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                <Controller
                    control={methods.control}
                    rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                    name={buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField)}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <>
                            {!props.readOnly &&
                                <TooltipHost
                                    content={props.control.ToolTipText}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <TextField
                                        id={textBoxId}
                                        label={props.control.LabelText}
                                        multiline={props.control.FieldType === 14 ? true : false}
                                        rows={5}
                                        prefix={getPrefix(props.control)}
                                        suffix={getSuffix(props.control)}
                                        value={value}
                                        disabled={props.disabled}
                                        readOnly={props.readOnly}
                                        required={props.isRequired}
                                        aria-describedby={tooltipId}
                                        theme={theme}
                                        errorMessage={error != null && error.message != null ? error.message : undefined}
                                        onBlur={onBlur}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                                            //React Hook Form requires you call the array Update method when editing an array as well as the onChange method.
                                            if (props.subFormField != null) {
                                                var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                var updateableField = cloneDeep(myCurrentValue);
                                                updateableField[props.control.Id!] = newValue;
                                                props.subFormField.update(props.subFormField.Index, updateableField);
                                            }
                                            onChange(newValue);
                                        }} />
                                </TooltipHost>
                            }
                            {props.readOnly &&
                                <>
                                    {props.control.FieldType === 3 || props.control.FieldType === 23 ?
                                        <ReadOnlyLinkField 
                                            control={props.control}
                                            value={value}>
                                        </ReadOnlyLinkField>
                                        :
                                        <ReadOnlyTextBoxField 
                                            control={props.control}
                                            value={value}>
                                        </ReadOnlyTextBoxField>
                                    }
                                </>
                            }
                        </>
                    )} />
            }
        </ConnectForm>
    )
}