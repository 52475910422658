import React, { SyntheticEvent, useState } from "react";
import { applicationManifestState, dialogModalWrapperPropsState, loadingModalState, shimmerNavState } from "../../../recoil/atoms";
import { useSetRecoilState } from 'recoil';
import { useId } from "@fluentui/react-hooks";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Dialog, DialogFooter, DialogType, DocumentCard, DocumentCardDetails, DocumentCardImage, DocumentCardTitle, Dropdown, IDocumentCardTitleStyles, IDropdownOption, IStackItemStyles, ImageFit, PrimaryButton, Stack, Text } from "@fluentui/react";
import { ILoadingModalProps } from "../../../interfaces/ILoadingModal";
import ReAccessIconLogo from "../../../images/reaccess-symbol-blue-background.svg";
import { CustomerDto } from "../../../dtos/Customer/CustomerDto";

export interface ITemplateCardProps {
    version: string | undefined;
    name: string;
    description: string;
    customers: CustomerDto[];
}

export const TemplateCard: React.FC<ITemplateCardProps> = (props: ITemplateCardProps) => {
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const setLoadingModalState = useSetRecoilState(loadingModalState);
    const setApplicationManifest = useSetRecoilState(applicationManifestState);
    const [hideDialog, setHideDialog] = useState<boolean>(true);
    const [selectedItem, setSelectedItem] = useState<IDropdownOption>();
    const templateCardId: string = useId('templateCard');
    const navigate = useNavigate();

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: "Select a customer",
            subtitleAriaId: "Please select a customer to create the template for.",
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
        }),
        [],
    );

    const dialogContentProps = {
        type: DialogType.normal,
        title: "Customer",
        subText: "Please select a customer to create the template for.",
    };

    const createApplicationTemplate = (customerId: string): void => {
        var loadingModalProps: ILoadingModalProps = {
            isVisible: true,
            title: "Creating Application",
            progressTitle: "Hold tight, building the next big thing!",
            progressSubText: "",
            isBlocking: true,
        };
        setLoadingModalState(loadingModalProps);
        setShimmerNav(false);
        axios.post(`/ApplicationTemplate/${props.name}/${customerId}`)
            .then((result: any) => {
                setApplicationManifest(null);
                setLoadingModalState(undefined);
                navigate(`/Application/${result.data.ApplicationId}/Home`, { replace: true });
            }).catch((error) => {
                setLoadingModalState(undefined);
                var dialogProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                    isBlocking: false,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapperPropsState(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
                setDialogModalWrapperPropsState(dialogProps);
                setShimmerNav(true);
            });
    }

    function cardClick(_ev?: SyntheticEvent<HTMLElement, Event> | undefined) {
        if (props.version == null) {
            navigate(`/NewApplication/Create`, { replace: false });
        } else if (props.customers.length === 1) {
            createApplicationTemplate(props.customers[0].Id);
        } else {
            setHideDialog(false);
        }
    }

    const getCustomerOptions = (): IDropdownOption[] => {
        if (props.customers == null || props.customers.length === 0) { return [] };

        return props.customers.map(i => ({ key: i.Id, text: i.Name }));
    }

    const onChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedItem(item);
    };

    const createAppFromModal = (): void => {
        createApplicationTemplate(selectedItem!.key.toString());
        setHideDialog(true);
    }

    const getImageSource = (): string => {
        if (props.version === undefined) {
            return ReAccessIconLogo;
        }
        return `${window.ReAccessWeb.process.env.PUBLIC_STORAGE_URL}/reaccess-templates/${props.version}/${props.name}/${props.name}.png`;
    }

    return (
        <>
            <DocumentCard
                id={templateCardId}
                aria-label={`Template Tile for ${props.name}`}
                styles={cardStyles}
                data-tut={props.name}
                onClick={(ev?: SyntheticEvent<HTMLElement, Event> | undefined) => {
                    cardClick(ev);
                }}>
                <DocumentCardDetails>
                    <DocumentCardTitle title={props.name} shouldTruncate={true} styles={cardTitleStyles} />
                </DocumentCardDetails>
                <DocumentCardImage height={100} imageFit={ImageFit.centerContain} imageSrc={getImageSource()} />
                <Stack.Item style={{ display: "flex", justifyContent: "center", padding: "15px 5px 20px" }}>
                    <Text variant="small">
                        {props.description}
                    </Text>
                </Stack.Item>
            </DocumentCard>

            <Dialog
                hidden={hideDialog}
                onDismiss={() => setHideDialog(true)}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <Dropdown
                    label="Select Customer"
                    placeholder="Select"
                    options={getCustomerOptions()}
                    onChange={onChange}
                />
                <DialogFooter>
                    <PrimaryButton onClick={createAppFromModal} text="Create" disabled={selectedItem == null} />
                </DialogFooter>
            </Dialog>
        </>
    );
}

const cardStyles: IStackItemStyles = {
    root: {
        width: "225px",
        minHeight: "225px"
    },
};

const cardTitleStyles: IDocumentCardTitleStyles = {
    root: { display: 'flex', justifyContent: "center", paddingTop: "10px" },
};