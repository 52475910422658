import { ApplicationInsights, ITelemetryItem, SeverityLevel } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
const packageJsonVersion = require("./../../package.json").version;

const release = `ReAccessWeb@${packageJsonVersion}`;

const reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {}
});

if (window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY != null && window.ReAccessWeb.process.env.APP_INSIGHTS_BLOB_STORAGE_ACCOUNT_KEY !== "") {
  appInsights = new ApplicationInsights({
    config: {
      autoExceptionInstrumented: true,
      autoTrackPageVisitTime: true,
      connectionString: window.ReAccessWeb.process.env.APPLICATIONINSIGHTS_CONNECTION_STRING,
      disableAjaxTracking: false,
      disableExceptionTracking: false,
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableUnhandledPromiseRejectionTracking: true,
      extensions: [reactPlugin],
      extensionConfig: {},
      samplingPercentage: 100,
    },
  });
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags = env.tags || [];
    env.tags["packageVersion"] = release;
  });

  // Override so we get these sent to App Insights
  const originalLog = console.log;
  console.log = function (message) {
    originalLog.apply(console, arguments as any);
    appInsights.trackTrace({ message: message });
  };

  const originalInfo = console.info;
  console.info = function (message) {
    originalInfo.apply(console, arguments as any);
    appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Information });
  };

  const originalWarn = console.warn;
  console.warn = function (message) {
    originalWarn.apply(console, arguments as any);
    appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Warning });
  };

  const originalError = console.error;
  console.error = function (message) {
    originalError.apply(console, arguments as any);
    appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Error });
  };

}
export { reactPlugin, appInsights };