import React, {  } from "react";
import ReactPlayer from "react-player";
import { AttachmentModel } from "../../../../../model/reaccessData/AttachmentModel";

export interface VideoFieldProps {
  attachmentModel: AttachmentModel;
}

export const VideoField: React.FC<VideoFieldProps> = (props: VideoFieldProps) => {

  return (
    <ReactPlayer url={props.attachmentModel.SasUri} playsinline controls={true} width="95%" height="95%" className="react-player" />
  );
};
