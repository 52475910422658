import React from "react";
import { DatePicker, TextField, IStackStyles, IStackItemStyles, Stack } from "@fluentui/react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Controller } from "react-hook-form";
import { ConnectForm } from "../../../form/ConnectForm";
import { useRecoilValue } from "recoil";
import { applicationManifestState, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { getRules } from "../../../../../services/ruleService";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { ReadOnlyTextBoxField } from "../../ReadOnlyTextBoxField";
import moment from 'moment';
import { StringConstants } from "../../../../../constants/StringConstants";
import { buildFormPropertyName } from "../../../../../services/fieldService";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { cloneDeep } from "lodash";

export interface DateTimePickerFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const DateTimePickerField: React.FC<DateTimePickerFieldProps> = (props: DateTimePickerFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);

    const getTextValue = (methods: any): string => {
        var value = methods.getValues(buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField));
        if (value !== "") {
            value = moment(value).format("dddd, MMMM Do YYYY, h:mm A");
        }
        return value;
    }

    return (
        <ConnectForm>
            {(methods: any) =>
                <>
                    <Stack styles={mainSideNavStyle}>
                        <Stack.Item styles={dateStackItemStyles}>
                            <Controller
                                control={methods.control}
                                rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                                name={buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField)}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        {!props.readOnly &&
                                            <DatePicker
                                                label={props.control.LabelText}
                                                allowTextInput={true}
                                                showGoToToday={true}
                                                value={value}
                                                disabled={props.disabled}
                                                isRequired={props.isRequired}
                                                onSelectDate={(date: Date | null | undefined) => {
                                                    var myDate: any = date == null ? "" : date;
                                                    //React Hook Form requires you call the array Update method when editing an array as well as the onChange method.
                                                    if (props.subFormField != null) {
                                                        var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                        var updateableField = cloneDeep(myCurrentValue);
                                                        updateableField[props.control.Id!] = myDate;
                                                        props.subFormField.update(props.subFormField.Index, updateableField);
                                                    }
                                                    onChange(date);
                                                }} />
                                        }
                                    </>

                                )} />
                        </Stack.Item>
                        <Stack.Item styles={timeStackItemStyles}>
                            <Controller
                                control={methods.control}
                                rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                                name={buildFormPropertyName(props.control.Id!, true, undefined, props.subFormField)}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        {!props.readOnly &&
                                            <TextField
                                                type="time"
                                                label={props.control.LabelText}
                                                value={value}
                                                disabled={props.disabled}
                                                required={props.isRequired}
                                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                    if (props.subFormField != null) {
                                                        var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                        var updateableField = cloneDeep(myCurrentValue);
                                                        updateableField[StringConstants.TimePickerId + props.control.Id!] = newValue;
                                                        props.subFormField.update(props.subFormField.Index, updateableField);
                                                    }
                                                    onChange(newValue);
                                                }} />
                                        }
                                    </>
                                )} />
                        </Stack.Item>
                    </Stack>
                    {props.readOnly &&
                        <ReadOnlyTextBoxField control={props.control} value={getTextValue(methods)}></ReadOnlyTextBoxField>
                    }
                </>
            }
        </ConnectForm>
    )
}

const mainSideNavStyle: IStackStyles = {
    root: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
};
const dateStackItemStyles: IStackItemStyles = {
    root: {
        width: "60%"
    }
}
const timeStackItemStyles: IStackItemStyles = {
    root: {
        width: "40%",
        marginLeft: "16px"
    }
}