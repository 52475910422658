import { DefaultButton } from "@fluentui/react";
import React, { useCallback } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { uploadDocument } from "../../services/documentService";
import { useRecoilState } from "recoil";
import { documentProgressSelector } from "../../recoil/atoms";
import { CancelTokenSource } from "axios";

export interface DocumentUploadProps {
    maxSizeInMb?: number;
    acceptedMimeTypes?: Accept | undefined;
    applicationId: string;
    pageId: string;
    subFormId: string | undefined;
    controlId: string | undefined;
    description: string | undefined;
    importData: boolean | undefined;
    allowMultipleDocuments: boolean;
    buttonLabel: string;
    onSuccessfulUpload: (result: any, file: File) => void;
    onFailedUpload: (validationMessages: string, fileName?: string) => void;
    startingHttpRequest: (fileName: string) => void;
    source: CancelTokenSource;
}

export const DocumentUpload: React.FC<DocumentUploadProps> = (props: DocumentUploadProps) => {
    const [, setDocumentProgresses]: [number | undefined, (documentProgresses: number | undefined) => void] = useRecoilState(documentProgressSelector(props.controlId));

    const onDrop = useCallback((acceptedFiles: File[]) => {
        var failedSizeValidation = false;
        acceptedFiles.forEach((file: File) => {
            var sizeInMb = (file.size / (1024*1024)).toFixed(2);
            if (parseFloat(sizeInMb) > 200) {
                props.onFailedUpload("File size cannot exceed 100MB", file.name);
                failedSizeValidation = true;
            }
        });
        if (failedSizeValidation) {
            return;
        }
        acceptedFiles.forEach((file: File) => {
            const reader = new FileReader();

            reader.onabort = () => console.log("File reading was aborted");
            reader.onerror = () => console.log("File reading has failed");
            reader.onload = () => {
                props.startingHttpRequest(file.name);
                uploadDocument(
                    props.applicationId, 
                    props.pageId, 
                    props.subFormId,
                    props.controlId, 
                    props.description, 
                    props.importData, 
                    file, 
                    setDocumentProgresses, 
                    props.source)
                    .then((result) => {
                        props.onSuccessfulUpload(result, file);
                    })
                    .catch((error) => {
                        //TODO: Get first error since we only display one anyway
                        props.onFailedUpload(error, file.name);
                        console.log("An File Upload Error Occurred");
                        console.log(error);
                    });
            }
            reader.readAsArrayBuffer(file);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const { getRootProps, getInputProps } = useDropzone(
        {
            onDrop,
            accept: props.acceptedMimeTypes,
            multiple: props.allowMultipleDocuments
        }
    );

    return (
        <div {...getRootProps()}>
            <input   {...getInputProps()} />
            {
                <DefaultButton
                    text={props.buttonLabel}
                    className="dropzone"
                    primary
                />
            }
        </div>
    );
}