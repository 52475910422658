import React from "react";
import { Link } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";

export const SignOut: React.FC = () => {
    const { instance } = useMsal();
    const accountInfo = instance.getActiveAccount();

    const signOut = async (): Promise<void> => {
        await instance.logoutRedirect({
            account: accountInfo,
            postLogoutRedirectUri: "/"
        });
    }

    return (
        <Link onClick={signOut}>Sign Out</Link>
    );
}