import { ITag } from "@fluentui/react";
import { ControlData } from "../model/manifest/ControlData";
import { DataSourceItem } from "../model/manifest/DataSourceItem";
import axios, { CancelToken } from "axios";

export const addDefaultSearchTextValue = (formattedTags: ITag[], control: ControlData, isSearch: boolean): ITag[] => {
    if (!isSearch) { return formattedTags; }

    //Need to add the default typed search property to the list of items if not available
    if (control.DefaultSearchTextValue != null && control.DefaultSearchTextValue !== "") {
        var foundItem = formattedTags.find(i => i.name === control.DefaultSearchTextValue);
        if (!foundItem) {
            var tag: ITag = {
                key: control.DefaultSearchTextValue,
                name: control.DefaultSearchTextValue
            }
            formattedTags.push(tag);
        }
    }
    return formattedTags;
}

export const isDropdownWithDetails = (control: ControlData): boolean => {
    if (control.ControlType === 2 && control.FieldType !== 39 && control.ShowFormDetails) {
        return true;
    }
    return false;
}

export const getDataSourceItems = async (
    applicationManifestId: string,
    formId: string,
    controlId: string,
    isSearch: boolean,
    cancelToken: CancelToken): Promise<DataSourceItem[]> => {
    return new Promise<DataSourceItem[]>((resolve, reject) => {
        axios.get(`/DataSource/Get?ApplicationId=${applicationManifestId}&PageId=${formId}&FieldId=${controlId}&UniqueTextValues=false&isSearch=${isSearch}`,
            {
                cancelToken: cancelToken
            }).then((result) => {
                var dataSourceItems: DataSourceItem[] = result.data;
                resolve(dataSourceItems);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
