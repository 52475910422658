import React, { useEffect, useState } from "react";
import { MessageBarButton, MessageBarType, Stack } from "@fluentui/react";
import axios, { AxiosRequestConfig } from "axios";
import { FormTab } from "./FormTab";
import { useForm, FormProvider } from "react-hook-form";
import { FormActions } from "./FormActions";
import { useLocation, useParams } from "react-router";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import {
  applicationManifestState,
  dialogModalWrapperPropsState,
  searchResultState,
  appEntityUpdateSignalR,
  pendingSignalRMessages,
  messageBarQueueState,
  resetSaveAddNewState,
  resetEditState,
  formSearchDataState,
  resetFieldSpecificState,
} from "../../../recoil/atoms";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { EntityNotFoundModal } from "./EntityNotFoundModal";
import { buildApplicationEntityForSave, buildDefaultApplicationEntity, buildDefaultValues, buildValues } from "../../../services/applicationEntityService";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { useNavigate } from "react-router-dom";
import { CustomFormHeader } from "./CustomFormHeader";
import { CustomFormFooter } from "./CustomFormFooter";
import { IEntityListResult } from "../../../interfaces/EntityListResult";
import { ValidationError } from "../../../model/error/ValidationError";
import { FormName } from "./FormName";
import { registerUpdateApplicationEntity, unRegisterUpdateApplicationEntity } from "../../../services/signalRService";
import { IMessageBarQueue } from "../../../interfaces/IMessageBarQueue";
import { v4 as uuidv4 } from "uuid";

export interface FormContainerProps {
  isAddingEntity: boolean;
}

export const FormContainer: React.FC<FormContainerProps> = (props: FormContainerProps) => {
  const [searchResult, setSearchResultState]: [IEntityListResult, (searchResult: IEntityListResult | undefined) => void] = useRecoilState(searchResultState);
  const setFormSearchDataState = useSetRecoilState(formSearchDataState);
  const [shimmerControlsOnSubmit, setShimmerControlsOnSubmit] = useState<boolean>(true);
  const [applicationEntity, setApplicationEntity] = useState<ApplicationEntity>(); //TODO: Kevin can I remove this eventually?
  const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
  const setResetFieldSpecificState = useSetRecoilState(resetFieldSpecificState);
  const [entityNotFoundModalHidden, setEntityNotFoundModalHidden] = useState<boolean>(true);
  const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
  const setResetSaveAddNewState = useSetRecoilState(resetSaveAddNewState);
  const [saveFormState, setSaveFormState] = useState<ISaveForm>();
  const [editMode, setEditMode] = useState<boolean>(props.isAddingEntity);
  const navigate = useNavigate();
  const location = useLocation();
  const [getAppEntityUpdateSignalR, setUpdatedAppEntitySignalR] = useRecoilState(appEntityUpdateSignalR);
  const [getPendingSignalRMessages, setPendingSignalRMessages] = useRecoilState(pendingSignalRMessages);
  const [messages, setMessages]: [IMessageBarQueue[], (messages: IMessageBarQueue[]) => void] = useRecoilState(messageBarQueueState);
  const setResetEdit = useSetRecoilState(resetEditState);
  var { appId, formId, entityId } = useParams();

  const getFormValues = (): any => {
    if (props.isAddingEntity) {
      return buildDefaultValues(formId!, applicationManifest);
    }
    if (location.state != null) {
      var ae = location.state as ApplicationEntity;
      return buildValues(formId!, applicationManifest, ae.Fields);
    }
    return buildValues(formId!, applicationManifest, []);
  }
  const methods = useForm({
    mode: "onChange",
    defaultValues: getFormValues(),
  });

  useEffect(() => {
    registerUpdateApplicationEntity();
    return () => {
      unRegisterUpdateApplicationEntity();
    };
  }, []);

  useEffect(() => {
    var dialogProps: IDialogModalWrapperProps | undefined;
    var page = applicationManifest.Pages.find((i) => i.Id === formId);
    if (page == null) {
      dialogProps = {
        isVisible: true,
        title: "Permissions",
        subText: "This page was not found.  Please make sure you are selected a page you have access to and that it still exists.",
        isBlocking: true,
        primaryButtonText: "OK",
        secondaryButtonText: undefined,
        onDismiss: () => {
          setDialogModalWrapperPropsState(undefined);
        },
        onSecondaryButtonDismiss: () => { },
      };
      setShimmerControlsOnSubmit(true);
      onEditModeChange(false);
      navigate(`/Application/${appId}/Home`, { replace: true });
      setDialogModalWrapperPropsState(dialogProps);
      return;
    }
    if (props.isAddingEntity && !page.Insert) {
      dialogProps = {
        isVisible: true,
        title: "Permissions",
        subText: "It appears you do not have the correct permission to insert records.  Please speak with your administrator.",
        isBlocking: true,
        primaryButtonText: "OK",
        secondaryButtonText: undefined,
        onDismiss: () => {
          setDialogModalWrapperPropsState(undefined);
        },
        onSecondaryButtonDismiss: () => { },
      };
      setShimmerControlsOnSubmit(true);
      onEditModeChange(false);
      navigate(`/Application/${appId}/Form/${formId}/Search`, { replace: true });
      setDialogModalWrapperPropsState(dialogProps);
      return;
    }

    //When we click save we route from NewRecord to Entity route and if this is set we use it instead of re-querying
    if (location.state != null) {
      var ae = location.state as ApplicationEntity;
      navigate(location.pathname, {});
      if (ae.EntityId === entityId) {
        setApplicationEntity(ae);
        setShimmerControlsOnSubmit(true);
        return;
      }
    }

    if (!props.isAddingEntity) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      setShimmerControlsOnSubmit(false);
      const options: AxiosRequestConfig = {
        method: "GET",
        url: "/ApplicationEntityGet",
        params: {
          applicationId: appId,
          formId: formId,
          entityId: entityId,
        },
        cancelToken: source.token,
      };
      axios(options)
        .then((result) => {
          //Set App entity state so that other places in the app can clear or update it
          var applicationEntityResult: ApplicationEntity = result.data;
          if (applicationEntityResult !== null) {
            setApplicationEntity(applicationEntityResult);
          } else {
            //modal to take user to the search page
            setEntityNotFoundModalHidden(false);
          }
          setShimmerControlsOnSubmit(true);
        })
        .catch((error) => {
          handleError(error);
        });

      return function cleanup() {
        source.cancel();
      };
    } else {
      var defaultApplicationEntity = buildDefaultApplicationEntity(page, entityId!, applicationManifest.SubForms);
      setApplicationEntity(defaultApplicationEntity);
      setShimmerControlsOnSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  useEffect(() => {
    if (saveFormState == null) {
      return;
    }
    var data = methods.getValues();
    var currentApplicationEntity: ApplicationEntity = { ...applicationEntity! };
    var ae = buildApplicationEntityForSave(data, formId!, applicationManifest, currentApplicationEntity!);
    setShimmerControlsOnSubmit(false);

    var editExisting = ae.EntityId != null;

    const options: AxiosRequestConfig = {
      method: editExisting ? "PUT" : "POST",
      data: ae,
      url: editExisting ? "/ApplicationEntityPut" : "/ApplicationEntityPost",
      params: {
        applicationId: appId,
      },
    };

    // If editing existing App Entity then a SignalR message will be sent when action is completed
    // and we need to know if it came from this SPA instance. So we are tracking it in state.
    // Sending this before the API call b/c SignlaR message may be sent before the API call is completed.
    if (editExisting) {
      setPendingSignalRMessages([...getPendingSignalRMessages, ae.EntityId]);
    }

    axios(options)
      .then((result: any) => {
        if (searchResult !== undefined) {
          var entityList: IEntityListResult = { ...searchResult };
          entityList.possibleChangesToResultSet = true;
          setSearchResultState(entityList);
        }
        if (saveFormState.IsSaveOnly) {
          var returnedApplicationEntity: ApplicationEntity = result.data;
          setShimmerControlsOnSubmit(true);
          onEditModeChange(false);
          setSaveFormState(undefined);
          if (entityId !== returnedApplicationEntity.EntityId) {
            navigate(`/Application/${appId}/Form/${formId}/Entity/${returnedApplicationEntity.EntityId}`, {
              replace: false,
              state: returnedApplicationEntity,
            });
          } else {
            setApplicationEntity(returnedApplicationEntity);
          }
        } else {
          //TODO: Kevin test save and add new
          setSaveFormState(undefined);
          if (entityId != null && entityId !== "") {
            //user was on an Entity route and clicked save and add new which takes them to the NewRecord
            navigate(`/Application/${appId}/Form/${formId}/NewRecord`, { replace: false });
          } else {
            //user was on AddNew route and clicked save and add new again - remove element and reload
            setResetSaveAddNewState(true);
          }
        }
      })
      .catch((error) => {
        setSaveFormState(undefined);
        setShimmerControlsOnSubmit(true);
        handleError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFormState]);

  useEffect(() => {
    if (applicationEntity == null) {
      return;
    }
    var myFormValues = buildValues(formId!, applicationManifest, applicationEntity.Fields);
    methods.reset(myFormValues);
    setTimeout(() => {
      setResetFieldSpecificState(true);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationEntity]);

  useEffect(() => {
    if (getAppEntityUpdateSignalR !== undefined) {
      if (getAppEntityUpdateSignalR.Id === applicationEntity?.Id) {
        var currentMessages = [...messages];
        var messageId = uuidv4();
        var message: IMessageBarQueue = {
          id: messageId,
          custumActionMessage: undefined,
          messageBarType: MessageBarType.warning,
          message: <>The record you are looking at has been updated by another user or process!</>,
          actions: (
            <div>
              <MessageBarButton
                onClick={() => {
                  setApplicationEntity(getAppEntityUpdateSignalR);
                  currentMessages = [...messages].filter((i) => i.id !== messageId);
                  setMessages(currentMessages);
                  setResetEdit(true);
                }}
              >
                Reload
              </MessageBarButton>
            </div>
          ),
          expireMilliseconds: undefined,
          expireStarted: false,
        };
        currentMessages.push(message);
        setMessages(currentMessages);
      }
      setUpdatedAppEntitySignalR(undefined);
    }
  }, [getAppEntityUpdateSignalR, applicationEntity?.Id, setApplicationEntity, setUpdatedAppEntitySignalR, messages, setMessages, setResetEdit]);

  const handleError = (error: any): void => {
    if (error.response == null) {
      console.log("Request canceled", "Form Container Component cleaned up");
    } else {
      if (error.response.status === 401 || error.response.status === 409) {
        return;
      }
      var dialogProps: IDialogModalWrapperProps | undefined;
      if (error.response.status === 404) {
        //the entity was not found
        dialogProps = {
          isVisible: true,
          title: "Uh oh",
          subText: error.response.data,
          isBlocking: true,
          primaryButtonText: "OK",
          secondaryButtonText: undefined,
          onDismiss: () => {
            setDialogModalWrapperPropsState(undefined);
            setShimmerControlsOnSubmit(true);
            onEditModeChange(false);
            navigate(`/Application/${appId}/Form/${formId}/Search`, { replace: false });
          },
          onSecondaryButtonDismiss: () => { },
        };
      } else if (error.response.status === 422 && error.response.data.error != null) {
        //Check the error codes for control issues to know if we need to supply
        //the error handling manually in the UI.  5200 level errors are API Validation errors
        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
        if (
          validationErrors.length === 1 &&
          (validationErrors[0].ErrorCode == null || parseInt(validationErrors[0].ErrorCode) < 5200 || parseInt(validationErrors[0].ErrorCode) > 5299)
        ) {
          dialogProps = {
            isVisible: true,
            title: validationErrors[0].Title,
            subText: validationErrors[0].Message,
            isBlocking: true,
            primaryButtonText: "OK",
            secondaryButtonText: undefined,
            onDismiss: () => {
              setDialogModalWrapperPropsState(undefined);
            },
            onSecondaryButtonDismiss: () => { },
          };
        } else {
          //set errors on controls
          validationErrors.forEach((validationError: ValidationError) => {
            methods.setError(validationError.GroupIdentifier, {
              type: "manual",
              message: validationError.Message,
            });
          });
        }
      } else {
        dialogProps = {
          isVisible: true,
          title: "Uh oh",
          subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
          isBlocking: true,
          primaryButtonText: "OK",
          secondaryButtonText: undefined,
          onDismiss: () => {
            setDialogModalWrapperPropsState(undefined);
          },
          onSecondaryButtonDismiss: () => { },
        };
      }
      setDialogModalWrapperPropsState(dialogProps);
    }
  };

  const onEditModeChange = (isEditMode: boolean): void => {
    setEditMode(isEditMode);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Stack>
            <Stack.Item>
              <FormName formId={formId!} entityId={entityId} variantSize="xxLarge" applicationEntity={applicationEntity}></FormName>
            </Stack.Item>
            <CustomFormHeader></CustomFormHeader>
            <Stack.Item>
              <FormTab
                formId={formId!}
                applicationEntity={applicationEntity}
                disableControls={false}
                isDetailsView={false}
                editMode={editMode}
                shimmerControlsOnSubmit={shimmerControlsOnSubmit}
                entityId={entityId} />
            </Stack.Item>
            <CustomFormFooter></CustomFormFooter>
            <Stack.Item>
              <FormActions
                applicationEntity={applicationEntity}
                formId={formId!}
                isAddingEntity={props.isAddingEntity}
                editMode={editMode}
                displaySaveAddNew={true}
                displayBackToSearchButtonIfAvailable={true}
                shimmerControlsOnSubmit={shimmerControlsOnSubmit}
                setShimmerControlsOnSubmit={setShimmerControlsOnSubmit}
                onEditModeChange={onEditModeChange}
                onSaveClicked={(isSaveOnly: boolean) => {
                  var saveForm: ISaveForm = {
                    IsSaveOnly: isSaveOnly,
                  };
                  setFormSearchDataState(undefined);
                  setSearchResultState(undefined);
                  setSaveFormState(saveForm);
                }}
              ></FormActions>
            </Stack.Item>
          </Stack>
        </form>
      </FormProvider>
      <EntityNotFoundModal isHidden={entityNotFoundModalHidden}></EntityNotFoundModal>
    </>
  );
};

interface ISaveForm {
  IsSaveOnly: boolean;
}
