import React from "react";
import { IIconProps, IconButton } from "@fluentui/react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { ConnectForm } from "../../form/ConnectForm";
import { ISubFormField } from "../../../../interfaces/ISubFormField";
import { cloneDeep } from "lodash";

export interface DeleteCommentButtonProps {
    control: ControlData;
    readOnly: boolean;
    index: number;
    update: (index: number, value: unknown) => void;
    subFormField: ISubFormField | undefined;
}

export const DeleteCommentButton: React.FC<DeleteCommentButtonProps> = (props: DeleteCommentButtonProps) => {
    const deleteIcon: IIconProps = { iconName: "Delete" };
    const newValue = "** This comment will be deleted on save **";

    return (
        <ConnectForm>
            {(methods: any) =>
                <IconButton
                    iconProps={deleteIcon}
                    aria-label="Delete comment"
                    onClick={() => {
                        if (props.subFormField != null) {
                            var myCurrentValue = methods.getValues(props.subFormField!.ParentControlId)[props.subFormField!.Index];
                            var updateableField = cloneDeep(myCurrentValue);
                            updateableField[props.control.Id!][props.index].Text = newValue;
                            updateableField[props.control.Id!][props.index].IsDeleted = true;
                            props.subFormField!.update(props.subFormField!.Index, updateableField);
                        } else {
                            var comments = methods.getValues(props.control.Id);
                            var myComment = cloneDeep(comments[props.index]);
                            myComment.Text = newValue;
                            myComment.IsDeleted = true;
                            props.update(props.index, myComment);
                        }
                    }} />
            }
        </ConnectForm>
    )
}