import React from "react";
import { Text, ITextProps } from "@fluentui/react";
import { ControlData } from "../../../../../model/manifest/ControlData";

export interface TextTitleFieldProps {
    control: ControlData;
}

export const TextTitleField: React.FC<TextTitleFieldProps> = (props: TextTitleFieldProps) => {
    return (
        <>
            <Text variant={"large" as ITextProps['variant']}>
                {props.control.TextValue}
            </Text>
        </>
    );
}