import { useSetRecoilState, useRecoilValue } from "recoil";
import { applicationManifestState, dialogModalWrapperPropsState, formSearchDataState, navPageSelectedState, searchResultState, shimmerNavState } from "../recoil/atoms";

export default function useRefreshManifestDependencies() {
    const setDialogModalWrapperProps = useSetRecoilState(dialogModalWrapperPropsState);
    const navPageSelected = useRecoilValue(navPageSelectedState);
    const setApplicationManifest = useSetRecoilState(applicationManifestState);
    const setFormSearchDataState = useSetRecoilState(formSearchDataState);
    const setSearchResultState = useSetRecoilState(searchResultState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);


    return { setDialogModalWrapperProps, navPageSelected, setApplicationManifest, setFormSearchDataState, setSearchResultState, setShimmerNav };
}