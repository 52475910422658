import React, { useState } from "react";
import { ITextProps, IStackTokens, Stack, Text } from "@fluentui/react";
import { ImportDescriptionText } from "./ImportDescriptionText";
import { ValidateAndImportButton } from "./ValidateAndImportButton";
import { IDataImportHistoryResult } from "../../../interfaces/DataImportHistoryResult";

export interface UploadHeaderProps {
    onChangeDataImportHistoryResultValue: (value: IDataImportHistoryResult) => void;
}

export const UploadHeader: React.FC<UploadHeaderProps> = (props: UploadHeaderProps) => {
    const [descriptionValue, setDescriptionValue] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const themedLargeStackTokens: IStackTokens = {
        childrenGap: 'l1',
        padding: 'l1',
    };

    const setDescription = (value: string): void => {
        setDescriptionValue(value);
    }

    const setIsUploadingFile = (isUploadingFile: boolean): void => {
        setIsLoading(isUploadingFile);
    }

    return (
        <>
            <Stack horizontal={false} tokens={themedLargeStackTokens}>
                <Stack.Item>
                    <Text variant={"large" as ITextProps['variant']}>
                        Upload
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant={"medium" as ITextProps['variant']}>
                        Once the header file is populated with data use the button below to select and upload the contents of the file.
                        You can also add a brief description of the upload up to 180 characters.
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <ImportDescriptionText
                        descriptionValue={descriptionValue}
                        isLoading={isLoading}
                        onChangeDescriptionValue={(value: string) => {
                            setDescription(value);
                        }}>
                    </ImportDescriptionText>
                </Stack.Item>
                <Stack.Item>
                    <ValidateAndImportButton
                        description={descriptionValue}
                        isLoading={isLoading}
                        importData={true}
                        isUploadingFile={(isUploadingFile: boolean) => {
                            setIsUploadingFile(isUploadingFile);
                        }}
                        onChangeDescriptionValue={(value: string) => {
                            setDescription(value);
                        }}
                        onChangeDataImportHistoryResultValue={(resultData: IDataImportHistoryResult) => {
                            props.onChangeDataImportHistoryResultValue(resultData)
                        }}>
                    </ValidateAndImportButton>
                </Stack.Item>
            </Stack>
        </>
    );
}