import React, { useEffect, useState } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { navPageSelectedState, navHeaderState, shimmerNavState, dialogModalWrapperPropsState, showNavPageMobileState, appCatalogCustomersState } from "../../recoil/atoms";
import { Stack, IStackItemStyles, IStackStyles, IStackTokens, Dropdown, IDropdownOption, IDropdownStyles, PrimaryButton } from "@fluentui/react";
import axios from 'axios';
import { StringConstants } from "../../constants/StringConstants";
import { AppCatalogCustomer } from "../../dtos/AppCatalogCustomer";
import { CustomerManifests } from "./appCatalog/CustomerManifests";
import { hideMobileNav } from "../../services/sideNavService";
import getScreenSize from "../../services/getScreenSize";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";
import { ShimmerAppCatalogPage } from "./appCatalog/ShimmerAppCatalogPage";

export const AppCatalog: React.FC = () => {
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const setNavPageSelected = useSetRecoilState(navPageSelectedState);
    const setNavHeader = useSetRecoilState(navHeaderState);
    const setShowNavPageMobile = useSetRecoilState(showNavPageMobileState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [appCatalogCustomers, setAppCatalogCustomers]: [AppCatalogCustomer[], (appCatalogCustomers: AppCatalogCustomer[]) => void] = useRecoilState(appCatalogCustomersState);
    const [appCatalogSortingSelection, setAppCatalogSortingSelection] = useState<string>(localStorage.getItem(StringConstants.AppCatalogSortingSelection) == null ? "last-accessed" : localStorage.getItem(StringConstants.AppCatalogSortingSelection)!);
    const [showCatalogShimmer, setShowCatalogShimmer] = useState(false);
    const screenSize: number = getScreenSize();
    const navigate = useNavigate();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        setNavPageSelected(StringConstants.AppCatalogMenuItem);
        setNavHeader("App Catalog");
        var shimmerTimmer = setTimeout(() => setShowCatalogShimmer(true), 1000);
        axios.get("/CustomerManifestSummary", {
            cancelToken: source.token,
        })
            .then((result) => {
                clearTimeout(shimmerTimmer);
                setShowCatalogShimmer(false);
                if (result.data != null && result.data !== "") {
                    var appCatalogCustomers: AppCatalogCustomer[] = result.data;
                    appCatalogCustomers.sort((a, b) => a.Name.localeCompare(b.Name));
                    setAppCatalogCustomers(appCatalogCustomers);
                } else {
                    //TODO: show some type of message
                }
                setShimmerNav(true);
            })
            .catch((error) => {
                setShimmerNav(true);
                if (error.response == null) {
                    console.log("Request canceled", "App Catalog Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps = {
                        isVisible: true,
                        title: "Uh oh",
                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                        isBlocking: true,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
        return function cleanup() {
            setAppCatalogCustomers([]);
            clearTimeout(shimmerTimmer);
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stackStyle: IStackStyles = {
        root: {
            display: "flex",
            paddingLeft: "50px",
            paddingTop: "10px",
            transition: "0.3s"
        },
    };

    const stackItemStyles: IStackItemStyles = {
        root: {
            display: 'flex',
            paddingTop: 5,
        },
    };

    const themedSmallStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 5,
    };

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: 'm',
        padding: 'm',
    };
    const customerStackItemStyle: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%"
        },
    };

    const getDropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: "200px" },
    };

    const getSortedCustomers = (): AppCatalogCustomer[] => {
        if (appCatalogCustomers == null || appCatalogCustomers.length === 0) {
            return [];
        }
        var sortedCustomers = cloneDeep(appCatalogCustomers);
        if (appCatalogSortingSelection === "last-accessed") {
            sortedCustomers.forEach((customer) => {
                var favorites = customer.AppCatalogTiles.filter(i => i.IsFavorite);
                var notFavorites = customer.AppCatalogTiles.filter(i => !i.IsFavorite);
                favorites.sort((a, b) => {
                    return a.LastAccessed != null && b.LastAccessed != null ? a.LastAccessed.toString().localeCompare(b.LastAccessed.toString()) : 0;
                });
                notFavorites.sort((a, b) => {
                    return a.LastAccessed != null && b.LastAccessed != null ? a.LastAccessed.toString().localeCompare(b.LastAccessed.toString()) : 0;
                });
                customer.AppCatalogTiles = notFavorites.concat(favorites);
                customer.AppCatalogTiles.reverse();
            })
        } else {
            sortedCustomers.forEach((customer) => {
                var favorites = customer.AppCatalogTiles.filter(i => i.IsFavorite);
                var notFavorites = customer.AppCatalogTiles.filter(i => !i.IsFavorite);
                favorites.sort((a, b) => a.Name.localeCompare(b.Name));
                notFavorites.sort((a, b) => a.Name.localeCompare(b.Name));
                customer.AppCatalogTiles = favorites.concat(notFavorites);
            })
        }
        return sortedCustomers;
    }

    const newApplicationClick = (): void => {
        hideMobileNav(screenSize, setShowNavPageMobile);
        setNavPageSelected(StringConstants.NewApplicationMenuItem);
        setNavHeader("New Application");
        navigate("/NewApplication/Templates");
    }

    return (
        <>
            <Stack styles={stackStyle} grow>
                <Stack.Item styles={stackItemStyles}>
                    <Stack tokens={themedMediumStackTokens} style={{ width: "100%" }} horizontal={true} horizontalAlign="end" verticalAlign="end">
                        <Stack.Item>
                            <PrimaryButton
                                onClick={newApplicationClick}>
                                New Application
                            </PrimaryButton>
                        </Stack.Item>
                        <Stack.Item>
                            <Dropdown
                                label="Customer App Sorting"
                                selectedKey={appCatalogSortingSelection}
                                options={[{ key: "last-accessed", text: "Last Accessed" }, { key: "name", text: "Name" }]}
                                placeholder="Select"
                                styles={getDropdownStyles}
                                disabled={appCatalogCustomers == null || appCatalogCustomers.length === 0}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                    localStorage.setItem(StringConstants.AppCatalogSortingSelection, option!.key.toString());
                                    setAppCatalogSortingSelection(option!.key.toString());
                                }} />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <Stack tokens={themedSmallStackTokens} horizontalAlign="start" grow>
                        {!showCatalogShimmer
                            ?
                            <>
                                {(appCatalogCustomers.length > 0) &&
                                    getSortedCustomers().map((value, index) => {
                                        return (
                                            <Stack.Item key={"appServicePrincipals" + index} styles={customerStackItemStyle}>
                                                <CustomerManifests
                                                    customer={value}>
                                                </CustomerManifests>
                                            </Stack.Item>
                                        )
                                    })
                                }
                            </>
                            :
                            <ShimmerAppCatalogPage></ShimmerAppCatalogPage>
                        }
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
}