import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { applicationManifestState, navPageSelectedState } from "../../recoil/atoms";
import { getMimeTypeByFileName, base64ToArrayBuffer } from "../../services/logoService";
import { Stack, IStackItemStyles, IStackStyles, Image, ImageFit, Text } from "@fluentui/react";
import axios from 'axios';
import { ApplicationManifest } from "../../model/manifest/ApplicationManifest";
import ReAccessIconLogo from "../../images/reaccess-symbol-blue-background.svg"
import getScreenSize from "../../services/getScreenSize";
import { StringConstants } from "../../constants/StringConstants";

export const HomePageImage: React.FC = () => {
    const [navPageSelected, setNavPageSelected]: [string, (navPageSelected: string) => void] = useRecoilState(navPageSelectedState);
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [logoImage, setLogoImage] = useState<string>();
    const [expanded,] = useState<boolean>(localStorage.getItem(StringConstants.SideNavExpanded) === "true" ? true : false);
    const screenSize: number = getScreenSize();

    useEffect(() => {
        if (applicationManifest == null) {
            return;
        }
        //if the nav page selected is not the manifest Id then set it
        if (navPageSelected !== applicationManifest.Id) {
            setNavPageSelected(applicationManifest.Id!);
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (applicationManifest.LogoFileName != null && applicationManifest.LogoFileName !== "") {
            axios({
                method: "get",
                url: `/Logo/${applicationManifest.Id}/${applicationManifest.LogoFileName}`,
                cancelToken: source.token,
            }).then((result) => {
                const mimeType = getMimeTypeByFileName(applicationManifest.LogoFileName);
                const arrayBuffer = base64ToArrayBuffer(result.data);
                var byteArray = new Uint8Array(arrayBuffer);
                var blob = new Blob([byteArray], { type: mimeType });
                var imageUrl = URL.createObjectURL(blob);
                setLogoImage(imageUrl);
            }).catch((error) => {
                setLogoImage(ReAccessIconLogo);
                if (error.response == null) {
                    console.log("Request canceled", "Home Page Component cleaned up");
                } else {
                    // handle error
                }
            });
            return function cleanup() {
                source.cancel();
            }
        } else {
            setLogoImage(ReAccessIconLogo);
        }
        // navPageSelected does not need to be in the hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationManifest, setNavPageSelected]);

    const stackStyle: IStackStyles = {
        root: {
            display: "flex",
            width: "75%",
            paddingTop: "25px",
            transition: "0.3s"
        },
    };

    const imageStackItemStyles: IStackItemStyles = {
        root: {
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            transition: "0.3s"
        },
    };

    const stackItemStyles: IStackItemStyles = {
        root: {
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            paddingTop: 50,
            transition: "0.3s"
        },
    };

    return (
        <>
            {applicationManifest != null &&
                <Stack styles={stackStyle}>
                    {logoImage != null &&
                        <Stack.Item styles={imageStackItemStyles}>
                            <Image
                                src={logoImage}
                                imageFit={ImageFit.centerContain}
                                width={expanded || screenSize >= 768 ? 400 : 200}
                                height={expanded || screenSize >= 768 ? 400 : 200}
                                alt={`${applicationManifest.ApplicationName} Logo`}
                            />
                        </Stack.Item>
                    }
                    <Stack.Item styles={stackItemStyles}>
                        <Text>
                            {applicationManifest.HomePageContent}
                        </Text>
                    </Stack.Item>
                </Stack>
            }
        </>
    );
}