import React from "react";
import { Stack, IStackTokens } from "@fluentui/react";
import { SaveFormButton } from "./SaveFormButton";
import { CancelFormButton } from "./CancelFormButton";
import { ResetFormButton } from "./ResetFormButton"
import { useRecoilValue } from "recoil";
import { applicationManifestState, searchResultState } from "../../../recoil/atoms";
import { EditFormButton } from "./EditFormButton";
import { BackToSearchButton } from "./BackToSearchButton";
import { IEntityListResult } from "../../../interfaces/EntityListResult";
import { DeleteFormButton } from "./DeleteFormButton";
import { PageData } from "../../../model/manifest/PageData";
import { PrintFormButton } from "./PrintFormButton";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { getPage } from "../../../services/manifestService";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";

export interface FormActionsProps {
    applicationEntity: ApplicationEntity | undefined;
    formId: string;
    isAddingEntity: boolean;
    editMode: boolean;
    displaySaveAddNew: boolean;
    displayBackToSearchButtonIfAvailable: boolean;
    shimmerControlsOnSubmit: boolean;
    setShimmerControlsOnSubmit: (shimmerControlsOnSubmit: boolean) => void;
    onEditModeChange: (isEditMode: boolean) => void;
    onSaveClicked: (isSaveOnly: boolean) => void;
    hideModal?: () => void;
}

export const FormActions: React.FC<FormActionsProps> = (props: FormActionsProps) => {
    const searchResult: IEntityListResult = useRecoilValue(searchResultState);
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const pageSelected: PageData = getPage(applicationManifest, props.formId);

    const stackTokens: IStackTokens = { childrenGap: 20 };

    return (
        <Stack horizontal horizontalAlign="space-between" wrap tokens={stackTokens}>
            <Stack horizontal tokens={stackTokens}>
                {(!props.isAddingEntity && (pageSelected.PageDocuments != null && pageSelected.PageDocuments.length > 0)) &&
                    <Stack.Item>
                        <PrintFormButton
                            applicationEntity={props.applicationEntity}
                            shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                            formId={props.formId} />
                    </Stack.Item>
                }
            </Stack>
            <Stack horizontal tokens={stackTokens}>
                {(!props.isAddingEntity && props.applicationEntity != null && props.applicationEntity.EntityId != null && (applicationManifest.IsAdmin || pageSelected!.Remove)) &&
                    <Stack.Item>
                        <DeleteFormButton
                            formId={props.formId}
                            entityId={props.applicationEntity.EntityId}
                            shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                            setShimmerControlsOnSubmit={props.setShimmerControlsOnSubmit}
                            hideModal={props.hideModal}
                        />
                    </Stack.Item>
                }
                {props.editMode &&
                    <>
                        {(applicationManifest.IsAdmin || pageSelected!.Insert || pageSelected!.Update) &&
                            <>
                                <Stack.Item>
                                    <CancelFormButton
                                        applicationEntity={props.applicationEntity}
                                        displayBackToSearchButtonIfAvailable={props.displayBackToSearchButtonIfAvailable}
                                        shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                        onEditModeChange={props.onEditModeChange}
                                        hideModal={props.hideModal}>
                                    </CancelFormButton>
                                </Stack.Item>
                                <Stack.Item>
                                    <ResetFormButton
                                        shimmerControlsOnSubmit={props.shimmerControlsOnSubmit} />
                                </Stack.Item>
                            </>
                        }
                        {(((props.applicationEntity == null || props.applicationEntity.EntityId == null) && (applicationManifest.IsAdmin || pageSelected!.Insert)) || 
                            (props.applicationEntity != null && props.applicationEntity.EntityId != null && (applicationManifest.IsAdmin || pageSelected!.Update))) &&
                            <Stack.Item>
                                <SaveFormButton
                                    isAddingEntity={props.isAddingEntity}
                                    displaySaveAddNew={props.displaySaveAddNew}
                                    shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                    canInsert={pageSelected.Insert}
                                    onSaveClicked={props.onSaveClicked} />
                            </Stack.Item>
                        }
                    </>
                }
                {(!props.isAddingEntity && !props.editMode) &&
                    <>
                        {(searchResult != null && props.displayBackToSearchButtonIfAvailable) &&
                            <Stack.Item>
                                <BackToSearchButton
                                    shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                    setShimmerControlsOnSubmit={props.setShimmerControlsOnSubmit} />
                            </Stack.Item>
                        }
                        {(applicationManifest.IsAdmin || pageSelected!.Update) &&
                            <Stack.Item>
                                <EditFormButton
                                    onEditModeChange={props.onEditModeChange}
                                    shimmerControlsOnSubmit={props.shimmerControlsOnSubmit} />
                            </Stack.Item>
                        }
                    </>
                }
            </Stack>
        </Stack>
    );
}