import axios from 'axios';
import React, { useState } from "react";
import { PrimaryButton, Shimmer, useTheme } from "@fluentui/react";
import { getButtonShimmer } from "../../../services/shimmerService";
import { downloadFileFromImageUrl, createImageUrlFromArray } from "../../../services/documentService";
import { useParams } from 'react-router-dom';
import { IDialogModalWrapperProps } from '../../../interfaces/DialogModal';
import { useSetRecoilState } from 'recoil';
import { dialogModalWrapperPropsState } from "../../../recoil/atoms";
import { ValidationError } from '../../../model/error/ValidationError';

export interface DownloadHeadersDefinitionsButtonProps {
    isTemplate: boolean;
}

export const DownloadHeadersDefinitionsButton: React.FC<DownloadHeadersDefinitionsButtonProps> = (props: DownloadHeadersDefinitionsButtonProps) => {
    const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const theme = useTheme();

    const getButtonText = () => {
        return props.isTemplate ? "Header File" : "Definition File";
    }

    var { appId, formId } = useParams();

    const downloadHeaderFile = (): void => {
        setIsDownloadingFile(true);
        var controller = props.isTemplate ? "FormTemplate" : "FormDefinition";
        axios.get(`/${controller}?ApplicationId=${appId}&FormId=${formId}`)
            .then((result) => {
                var imageUrl = createImageUrlFromArray(result.data.FileBytes, result.data.MimeType)
                downloadFileFromImageUrl(imageUrl, result.data.FileName);
                setIsDownloadingFile(false);
            }).catch((error) => {
                setIsDownloadingFile(false);
                if (error.response == null) {
                    console.log("Request canceled", "Form Container Component cleaned up");
                    return;
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps: IDialogModalWrapperProps | undefined;
                    if (error.response.status === 408) {
                        dialogProps = {
                            isVisible: true,
                            title: "Request timeout",
                            subText: "Please try your request again",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => { },
                            onSecondaryButtonDismiss: () => { }
                        }
                    } else if (error.response.status === 422 && error.response.data.error != null) {
                        var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                        dialogProps = {
                            isVisible: true,
                            title: validationErrors[0].Title,
                            subText: validationErrors[0].Message,
                            isBlocking: false,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                    } else {
                        dialogProps = {
                            isVisible: true,
                            title: "Uh oh",
                            subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                            isBlocking: true,
                            primaryButtonText: "OK",
                            secondaryButtonText: undefined,
                            onDismiss: () => {
                                setDialogModalWrapperPropsState(undefined);
                            },
                            onSecondaryButtonDismiss: () => { }
                        }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
    }

    return (
        <>
            <Shimmer
                customElementsGroup={getButtonShimmer()}
                width={206}
                isDataLoaded={!isDownloadingFile}
                shimmerColors={{
                    shimmer: theme.palette.themeTertiary,
                    shimmerWave: theme.palette.themeSecondary,
                    background: theme.semanticColors.bodyBackground,
                }}>
                <PrimaryButton
                    text={getButtonText()}
                    iconProps={{ iconName: "Download" }}
                    onClick={downloadHeaderFile}
                />
            </Shimmer>
        </>
    );
}