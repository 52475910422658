import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { ApplicationEntity } from "../../../../model/reaccessData/ApplicationEntity";
import { SubFormField } from "./fields/SubFormField";

export interface FormControlProps {
    control: ControlData;
    applicationEntity: ApplicationEntity | undefined;
    disableControls: boolean;
    isDetailsView: boolean;
    editMode: boolean;
    shimmerControlsOnSubmit: boolean;
    entityId: string | undefined;
}

export const FormControl: React.FC<FormControlProps> = (props: FormControlProps) => {

    return (
        <>
            {props.applicationEntity != null &&
                <SubFormField
                    control={props.control}
                    applicationEntity={props.applicationEntity}
                    disableControls={props.disableControls}
                    isDetailsView={props.isDetailsView}
                    editMode={props.editMode}
                    shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                    entityId={props.entityId} />
            }
        </>
    );
}