import React from "react";
import ReAccessIconLogo from "../../images/reaccess-symbol-blue-background.svg";
import { IButtonStyles, IIconProps, IImageProps, IconButton, Image } from "@fluentui/react";
import getScreenSize from "../../services/getScreenSize";
import { useRecoilState } from "recoil";
import { showNavPageMobileState } from "../../recoil/atoms";

export const TopLeftIcon: React.FC = () => {
    const [showNavPageMobile, setShowNavPageMobileState] = useRecoilState(showNavPageMobileState);
    const screenSize: number = getScreenSize();
    const menuIcon: IIconProps = { iconName: 'NumberedListText' };

    return (
        <>
            {screenSize >= 600
                ?
                <Image {...imageProps} style={{ paddingLeft: "6px" }} />
                :
                <IconButton
                    iconProps={menuIcon}
                    title="Menu"
                    ariaLabel="Menu"
                    styles={iconButtonStyles}
                    onClick={() => {
                        setShowNavPageMobileState(!showNavPageMobile);
                    }} />
            }
        </>
    );
}
const imageProps: IImageProps = {
    src: ReAccessIconLogo,
    height: 32
};
const iconButtonStyles: IButtonStyles = {
    root: {
        height: "46px",
    }
};