import React, { useEffect } from "react";
import { FormContainer } from "./FormContainer";
import { useRecoilState } from "recoil";
import { resetEditState } from "../../../recoil/atoms";

export const EditEntity: React.FC = () => {
    const [resetEdit, setResetEdit]: [boolean, (resetSaveAddNew: boolean) => void] = useRecoilState(resetEditState);

    useEffect(() => {
        if (!resetEdit) { return }

        setResetEdit(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetEdit]);

    return (
        <>
            {!resetEdit &&
                <FormContainer isAddingEntity={false}></FormContainer>
            }
        </>
    );
}