import React from "react";
import { Toggle, TooltipHost } from "@fluentui/react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { ConnectForm } from "../../../form/ConnectForm";
import { useRecoilValue } from "recoil";
import { applicationManifestState, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { Controller } from "react-hook-form";
import { getRules } from "../../../../../services/ruleService";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { ReadOnlyTextBoxField } from "../../ReadOnlyTextBoxField";
import { useId } from "@fluentui/react-hooks";
import { buildFormPropertyName, getBooleanTextValue } from "../../../../../services/fieldService";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { cloneDeep } from "lodash";
import { ErrorMessage } from "@hookform/error-message";

export interface ToggleFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const ToggleField: React.FC<ToggleFieldProps> = (props: ToggleFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const propertyName = buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField);
    const toggleId = useId("toggle");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${toggleId}`
    };

    return (
        <ConnectForm>
            {(methods: any) =>
                <Controller
                    control={methods.control}
                    rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                    name={propertyName}
                    render={({ field: { onChange, value } }) => (
                        <>
                            {!props.readOnly &&
                                <TooltipHost
                                    content={props.control.ToolTipText}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <Toggle
                                        id={toggleId}
                                        label={props.control.LabelText}
                                        onText="Yes"
                                        offText="No"
                                        checked={value}
                                        disabled={props.disabled}
                                        onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                            //React Hook Form requires you call the array Update method when editing an array as well as the onChange method.
                                            if (props.subFormField != null) {
                                                var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                var updateableField = cloneDeep(myCurrentValue);
                                                updateableField[props.control.Id!] = checked;
                                                props.subFormField.update(props.subFormField.Index, updateableField);
                                            }
                                            onChange(checked);
                                        }} />
                                    <div className="CustomError">
                                        <ErrorMessage errors={methods.formState.errors} name={propertyName} />
                                    </div>
                                </TooltipHost>
                            }
                            {props.readOnly &&
                                <ReadOnlyTextBoxField
                                    control={props.control}
                                    value={getBooleanTextValue(value)}>
                                </ReadOnlyTextBoxField>
                            }
                        </>
                    )} />
            }
        </ConnectForm>
    );
}
