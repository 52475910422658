import React from "react";
import { Toggle, loadTheme } from "@fluentui/react";
import { darkTheme, lightTheme } from "../../themes";
import { useRecoilState } from "recoil";
import { isDarkModeThemeState } from "../../recoil/atoms";

export const ThemeToggle: React.FC = () => {
    const [isDarkModeTheme, setIsDarkMode] = useRecoilState(isDarkModeThemeState);

    function onChange(ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined): void {
        if (checked) {
            loadTheme(darkTheme);
            localStorage.setItem("theme", "dark");
            setIsDarkMode(true);
        } else {
            loadTheme(lightTheme);
            localStorage.setItem("theme", "light");
            setIsDarkMode(false);
        }
    }

    return (
        <Toggle onText="Dark" offText="Light" defaultChecked={isDarkModeTheme} onChange={onChange} />
    );
}