import React, { useEffect } from "react";
import { IStackTokens, Pivot, PivotItem, Stack } from "@fluentui/react";
import { useSetRecoilState } from "recoil";
import { navHeaderState } from "../../../recoil/atoms";
import { CustomerInformation } from "./CustomerInfomation";
import { ReAccessApps } from "./ReAccessApps";
import { CustomerUsers } from "./CustomerUsers";

export const CustomerPortal: React.FC = () => {
    const setNavHeader = useSetRecoilState(navHeaderState);

    useEffect(() => {
        setNavHeader("Account Portal");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack tokens={themedMediumStackTokens}>
            <Stack.Item>
                <CustomerInformation></CustomerInformation>
            </Stack.Item>
            <Stack.Item>
                <Pivot
                    aria-label="Tab region for ReAccess Apps and Users"
                    linkSize="large"
                    overflowBehavior="menu"
                    style={{ paddingLeft: "10px" }}>
                    <PivotItem
                        key="MyReAccessApps"
                        headerText={"Apps"}
                        alwaysRender={true}>
                        <ReAccessApps></ReAccessApps>
                    </PivotItem>
                    <PivotItem
                        key="MyCustomerUsers"
                        headerText={"Users"}
                        alwaysRender={true}>
                        <CustomerUsers></CustomerUsers>
                    </PivotItem>
                </Pivot>
            </Stack.Item>
        </Stack>
    );
}

const themedMediumStackTokens: IStackTokens = {
    childrenGap: "m",
    padding: "m",
};