import React, { useState } from "react";
import { ITextProps, IStackTokens, Stack, Text} from "@fluentui/react";
import { ValidateAndImportButton } from "./ValidateAndImportButton";
import { IDataImportHistoryResult } from "../../../interfaces/DataImportHistoryResult";

export const ValidateImport: React.FC = () => {
    const [descriptionValue, setDescriptionValue] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const themedLargeStackTokens: IStackTokens = {
        childrenGap: 'l1',
        padding: 'l1',
    };

    const setDescription = (value: string): void => {
        setDescriptionValue(value);
    }

    const setIsUploadingFile = (isUploadingFile: boolean): void => {
        setIsLoading(isUploadingFile);
    }

    return (
        <>
            <Stack horizontal={false} tokens={themedLargeStackTokens}>
                <Stack.Item>
                    <Text variant={"large" as ITextProps['variant']}>
                        Validate
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant={"medium" as ITextProps['variant']}>
                        When ready, validate the import file. Errors will be available for download after the validation process is complete.
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <ValidateAndImportButton
                        description={descriptionValue}
                        isLoading={isLoading}
                        importData={false}
                        isUploadingFile={(isUploadingFile: boolean) => {
                            setIsUploadingFile(isUploadingFile);
                        }}
                        onChangeDescriptionValue={(value: string) => {
                            setDescription(value);
                        }}
                        onChangeDataImportHistoryResultValue={(resultData: IDataImportHistoryResult) => {
                            // do nothing, no need to update the history grid
                        }}>
                    </ValidateAndImportButton>
                </Stack.Item>
            </Stack>
        </>
    );
}