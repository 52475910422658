import React from "react";
import { ControlData } from "../../../model/manifest/ControlData";
import { TextControl } from "../controls/text/TextControl";
import { BooleanControl } from "../controls/boolean/BooleanControl";
import { ListControl } from "../controls/list/ListControl";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { useRecoilValue } from "recoil";
import { applicationManifestState } from "../../../recoil/atoms";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { FileControl } from "../controls/file/FileControl";
import { AutoGeneratedControl } from "../controls/generated/AutoGeneratedControl";
import { WebControl } from "../controls/web/WebControl";
import { GridControl } from "../controls/grid/GridControl";
import { PowerBiControl } from "../controls/powerBi/PowerBiControl";
import { CommentControl } from "../controls/comments/CommentControl";
import { FormControl } from "../controls/form/FormControls";
import { ISubFormField } from "../../../interfaces/ISubFormField";

export interface FormRowProps {
    control: ControlData;
    applicationEntity: ApplicationEntity | undefined;
    shouldValidate: boolean;
    isSearch: boolean;
    disableControls: boolean;
    isDetailsView: boolean;
    editMode: boolean;
    shimmerControlsOnSubmit: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const FormRowItem: React.FC<FormRowProps> = (props: FormRowProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);

    /*
    * Determines if the control should be disabled
    */
    const isDisabled = (): boolean => {
        //disable auto generated fields when adding an entity
        if (!props.isSearch && !props.editMode) {
            if (props.control.ControlType === 4) {
                return true;
            }
        }
        //disabled when in edit mode with view but not update privilege
        if (props.editMode) {
            if (applicationManifest.IsAdmin) {
                return false;
            }
            if (!props.control.Update) {
                return true;
            }
        }
        return false;
    }

    /*
    * Determines if the control should be read only
    */
    const isReadOnly = (): boolean => {
        if (props.isDetailsView) {
            return true;
        }
        //if search then can edit
        if (props.isSearch) {
            return false;
        }
        //if not edit mode (view) everything is read only
        if (!props.editMode) {
            return true;
        }
        //else we are in edit mode so nothing should be readonly, but possibly disabled
        return false;
    }

    /*
    * Determines if the control is required
    */
    const isRequired = (): boolean => {
        if (props.isDetailsView || props.isSearch) {
            return false;
        }
        return props.control.IsRequired;
    }

    /*
    * Determines if the control should be validated
    */
    const shouldValidate = (): boolean => {
        //if disabled then don't validate
        if (isDisabled()) {
            return false;
        }
        return props.shouldValidate;
    }

    return (
        <>
            {props.control.ControlType === 1 &&
                <TextControl
                    control={props.control}
                    shouldValidate={shouldValidate()}
                    disabled={isDisabled()}
                    readOnly={isReadOnly()}
                    isRequired={isRequired()}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </TextControl>
            }
            {props.control.ControlType === 2 &&
                <ListControl
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={isDisabled()}
                    readOnly={isReadOnly()}
                    isRequired={isRequired()}
                    isSearch={props.isSearch}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </ListControl>
            }
            {props.control.ControlType === 3 &&
                <BooleanControl
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={isDisabled()}
                    readOnly={isReadOnly()}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </BooleanControl>
            }
            {props.control.ControlType === 4 &&
                <AutoGeneratedControl
                    control={props.control}
                    shouldValidate={shouldValidate()}
                    disabled={isDisabled()}
                    readOnly={isReadOnly()}
                    isRequired={isRequired()}
                    isSearch={props.isSearch}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </AutoGeneratedControl>
            }
            {props.control.ControlType === 5 &&
                <FileControl
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={isDisabled()}
                    readOnly={isReadOnly()}
                    isRequired={isRequired()}
                    shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </FileControl>
            }
            {props.control.ControlType === 6 &&
                <WebControl
                    control={props.control}
                    applicationEntity={props.applicationEntity}
                    shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                </WebControl>
            }
            {props.control.ControlType === 7 &&
                <GridControl
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={isDisabled()}
                    readOnly={isReadOnly()}
                    isRequired={isRequired()}
                    isSearch={props.isSearch}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </GridControl>
            }
            {props.control.ControlType === 8 &&
                <PowerBiControl
                    control={props.control}>
                </PowerBiControl>
            }
            {props.control.ControlType === 9 &&
                <CommentControl
                    control={props.control}
                    shouldValidate={props.shouldValidate}
                    disabled={isDisabled()}
                    readOnly={isReadOnly()}
                    isRequired={isRequired()}
                    entityId={props.entityId}
                    subFormField={props.subFormField}>
                </CommentControl>
            }
            {props.control.ControlType === 10 &&
                <FormControl
                    control={props.control}
                    applicationEntity={props.applicationEntity}
                    disableControls={props.disableControls}
                    isDetailsView={props.isDetailsView}
                    editMode={props.editMode}
                    shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                    entityId={props.entityId}>
                </FormControl>
            }
        </>
    );
}