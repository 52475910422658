import React, { useEffect } from "react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { Dropdown, IDropdownOption, TooltipHost } from "@fluentui/react";
import { ConnectForm } from "../../../form/ConnectForm";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { applicationManifestState, dataSourceItemSelector, globalBusinessRulesState } from "../../../../../recoil/atoms";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { getRules } from "../../../../../services/ruleService";
import { BusinessRule } from "../../../../../model/manifest/BusinessRule";
import { useId } from "@fluentui/react-hooks";
import { buildFormPropertyName, getFormattedOptions, readOnlyTextForMultiSelectDataSource } from "../../../../../services/fieldService";
import axios from "axios";
import { DataSourceItem } from "../../../../../model/manifest/DataSourceItem";
import { ReadOnlyDropdownField } from "../../ReadOnlyDropdownField";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { getDataSourceItems } from "../../../../../services/dataSourceService";
import { cloneDeep } from "lodash";

export interface MultiSelectFieldProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    isSearch: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const MultiSelectField: React.FC<MultiSelectFieldProps> = (props: MultiSelectFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const globalBusinessRules: BusinessRule[] = useRecoilValue(globalBusinessRulesState);
    const [dataSourceItems, setDataSourceItems]: [DataSourceItem[], (dataSourceItems: DataSourceItem[]) => void] = useRecoilState(dataSourceItemSelector(props.control.Id));
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const dropdownId = useId("dropdown");
    const tooltipId = useId("tooltip");
    const calloutProps = {
        gapSpace: 0,
        target: `#${dropdownId}`
    };

    useEffect(() => {
        getDataSourceItems(applicationManifest.Id!, props.control.PageId, props.control.Id!, props.isSearch, source.token)
            .then((result) => {
                setDataSourceItems(result);
            })
            .catch((error) => {

            });
        return function cleanup() {
            source.cancel();
            setDataSourceItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ConnectForm>
            {(methods: UseFormReturn<FieldValues, any, undefined>) =>
                <Controller
                    control={methods.control}
                    rules={getRules(applicationManifest, props.control, globalBusinessRules, props.shouldValidate, props.entityId == null)}
                    name={buildFormPropertyName(props.control.Id!, false, undefined, props.subFormField)}
                    render={({ field: { onChange, value } }) => (
                        <>
                            {!props.readOnly &&
                                <TooltipHost
                                    content={props.control.ToolTipText}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <Dropdown
                                        id={dropdownId}
                                        label={props.control.LabelText}
                                        placeholder="Select options"
                                        multiSelect={true}
                                        selectedKeys={value}
                                        disabled={props.disabled}
                                        required={props.isRequired}
                                        options={getFormattedOptions(dataSourceItems,
                                            applicationManifest.DataSources.find(i => i.Name === props.control.DataSourceName)!,
                                            props.control.DefaultSearchTextValue,
                                            props.entityId,
                                            props.isSearch,
                                            false)}
                                        errorMessage={
                                            methods.formState.errors != null &&
                                                methods.formState.errors[props.control.Id!] != null &&
                                                methods.formState.errors[props.control.Id!]!.message != null ?
                                                methods.formState.errors[props.control.Id!]!.message!.toString() :
                                                undefined}
                                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                            const currentOption: string = option!.key as string;
                                            var myValue: string[];

                                            if (option?.selected) {
                                                if (value == null || value === "") {
                                                    value = [];
                                                }
                                                myValue = value.concat([currentOption]);
                                            } else {
                                                myValue = value.filter((i: string) => i !== currentOption);
                                            }

                                            if (props.subFormField != null) {
                                                var myCurrentValue = methods.getValues(props.subFormField.ParentControlId)[props.subFormField.Index];
                                                var updateableField = cloneDeep(myCurrentValue);
                                                updateableField[props.control.Id!] = option?.key;
                                                props.subFormField.update(props.subFormField.Index, updateableField);
                                            }

                                            onChange(myValue);
                                        }} />
                                </TooltipHost>
                            }
                            {props.readOnly &&
                                <ReadOnlyDropdownField
                                    control={props.control}
                                    textValue={readOnlyTextForMultiSelectDataSource(
                                        value,
                                        dataSourceItems
                                    )}
                                    value={value}
                                />
                            }
                        </>
                    )} />
            }
        </ConnectForm>
    );
}