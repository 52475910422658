import { IStackTokens, Panel, PanelType, SelectionMode, Stack } from "@fluentui/react";
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";
import { IApplicationEntityResult, IEntityListResult } from "../../../interfaces/EntityListResult";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { ControlData } from "../../../model/manifest/ControlData";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { applicationManifestState, halfScreenModeState, shimmerNavState } from "../../../recoil/atoms";
import { buildDefaultSearchValues, buildParamsAndSearchCall, buildSearchApplicationEntity } from "../../../services/applicationEntityService";
import { ClearSearchButton } from "./ClearSearchButton";
import { ResetSearchButton } from "./ResetSearchButton";
import { SearchButton } from "./SearchButton";
import { SearchControls } from "./SearchControls";
import { SearchHeader } from "./SearchHeader";
import { EntityListResult } from "../gridLayout/EntityListResult";
import { getFormName } from "../../../services/manifestService";
import { DialogModalWrapper } from "../DialogModalWrapper";
import { ValidationError } from "../../../model/error/ValidationError";
import getScreenSize from "../../../services/getScreenSize";

export interface PanelMultiSelectSearchContainerProps {
    formId: string;
    currentEntityIds: string[];
    onAddSelectedClicked: (applicationEntityResultsSelected: IApplicationEntityResult[]) => void;
    hidePanel: () => void;
}

export const PanelMultiSelectSearchContainer: React.FC<PanelMultiSelectSearchContainerProps> = (props: PanelMultiSelectSearchContainerProps) => {
    const [applicationManifest, setApplicationManifestState]: [ApplicationManifest, (applicationManifest: ApplicationManifest | null) => void] = useRecoilState(applicationManifestState);
    const setHalfScreenModeState = useSetRecoilState(halfScreenModeState);
    const [shimmerControlsOnSubmit, setShimmerControlsOnSubmit] = useState<boolean>(true);
    const [searchResult, setSearchResult] = useState<IEntityListResult>();
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const [applicationEntity, setApplicationEntity] = useState<ApplicationEntity>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dialogModalWrapper, setDialogModalWrapper] = useState<IDialogModalWrapperProps>();
    const methods = useForm({ 
        mode: "onChange",
        defaultValues: buildDefaultSearchValues(props.formId!, applicationManifest),
    });
    const stackTokens: IStackTokens = { childrenGap: 20 };
    var { appId } = useParams();
    const navigate = useNavigate();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const screenSize: number = getScreenSize();

    useEffect(() => {
        setHalfScreenModeState(true);
        // Used when unmounting the component.
        return () => {
            setHalfScreenModeState(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var page = applicationManifest.Pages.find(i => i.Id === props.formId)!;

        var searchControlsApplicationEntity = buildSearchApplicationEntity(page);
        setApplicationEntity(searchControlsApplicationEntity);

        if (page.ExecuteSearchOnLoad) {
            setIsLoading(true);
            var searchData: any = {};
            searchControlsApplicationEntity.Fields.forEach((field) => {
                searchData[field.Id] = field.TextValue;
            });
            var searchCall = buildParamsAndSearchCall(searchData, appId!, props.formId, applicationManifest.Version, source, true, true);
            searchCall
                .then((result) => {
                    handleSuccess(result);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    handleError(error);
                });
        }
        return function cleanup() {
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSuccess = (searchResult: AxiosResponse<any>): void => {
        if (searchResult.data.RecordCount > 0) {
            var sr: IEntityListResult = searchResult.data;
            //filter out current entities
            sr.ApplicationEntityResults = sr.ApplicationEntityResults.filter(i => !props.currentEntityIds.find(f => f === i.EntityId));
            sr.RecordCount = sr.ApplicationEntityResults.length;
            setSearchResult(sr);
        } else if (searchResult.data.RecordCount === 0) {
            var dialogProps: IDialogModalWrapperProps = {
                isVisible: true,
                title: "Search Result",
                subText: "No records found",
                isBlocking: true,
                primaryButtonText: "OK",
                secondaryButtonText: undefined,
                onDismiss: () => {
                    setDialogModalWrapper(undefined);
                },
                onSecondaryButtonDismiss: () => { }
            }
            setDialogModalWrapper(dialogProps);
            setSearchResult(undefined);
        }
    }

    const handleError = (error: any): void => {
        setShimmerControlsOnSubmit(true);
        if (error.response == null) {
            console.log("Request canceled", "Search Controls Component cleaned up");
            return;
        } else {
            if (error.response.status === 401 || error.response.status === 409) {
                return;
            }
            var dialogProps: IDialogModalWrapperProps | undefined;
            if (error.response.status === 408) {
                dialogProps = {
                    isVisible: true,
                    title: "Request timeout",
                    subText: "Please try your request again",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        refreshManifest();
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            } else if (error.response.status === 422 && error.response.data.error != null) {
                var validationErrors: ValidationError[] = JSON.parse(error.response.data.error);
                dialogProps = {
                    isVisible: true,
                    title: validationErrors[0].Title,
                    subText: validationErrors[0].Message,
                    isBlocking: false,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapper(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            } else {
                dialogProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapper(undefined);
                    },
                    onSecondaryButtonDismiss: () => { }
                }
            }
            setDialogModalWrapper(dialogProps);
        }
    }

    const submitForm = () => {
        var data = methods.getValues();
        setShimmerControlsOnSubmit(false);

        var searchCall = buildParamsAndSearchCall(
            data,
            appId!,
            props.formId,
            applicationManifest.Version,
            source,
            false,
            true);

        setIsLoading(true);
        searchCall
            .then((result) => {
                if (result.data != null) {
                    handleSuccess(result);
                }
                setIsLoading(false);
                setShimmerControlsOnSubmit(true);
            }).catch((error) => {
                handleError(error);
                setIsLoading(false);
            });
    };

    const refreshManifest = (): void => {
        setDialogModalWrapper(undefined);
        setApplicationManifestState(null);
        setShimmerNav(false);
        setTimeout(() => {
            navigate(`/Application/${appId}/Form/${props.formId}/Search`, { replace: true });
        }, 150);
    }

    const getSearchResultsColumns = (): ControlData[] => {
        var page = applicationManifest.Pages.find(i => i.Id === props.formId);
        var searchResultControls = page!.Controls.filter(i => i.SearchResultOrder != null && i.SearchResultOrder > 0);
        searchResultControls = searchResultControls!.sort((a, b) => a.SearchResultOrder! - b.SearchResultOrder!);
        return searchResultControls;
    }

    const getSearchColumnsInRows = (): Array<ControlData[]> => {
        var page = applicationManifest.Pages.find(i => i.Id === props.formId)!;

        var controls = page.Controls.filter(i => i.SearchRow != null && i.SearchColumn != null);
        controls.sort(function (a, b) {
            if (a.SearchRow != null && b.SearchRow != null && a.SearchColumn != null && b.SearchColumn != null) {
                return a.SearchRow - b.SearchRow || a.SearchColumn - b.SearchColumn;
            } else {
                return 0;
            }
        });
        var rowsOfSearchControls: Array<ControlData[]> = [];
        controls.forEach((c) => {
            if (c.SearchRow != null && c.SearchRow > 0) {
                if (rowsOfSearchControls[c.SearchRow - 1] == null) {
                    rowsOfSearchControls[c.SearchRow - 1] = [];
                }
                rowsOfSearchControls[c.SearchRow - 1].push(c);
            }
        });
        return rowsOfSearchControls;
    }

    const customPanelWidth = (): string => {
        var halfScreen = screenSize / 2;
        return halfScreen.toString() + "px";
    }

    const setSearchWarningMessage = (): string | undefined => {
        if (searchResult == null || searchResult.ApplicationEntityResults.length === searchResult.RecordCount) {
            return undefined;
        }
        return "The search results exceeded 1000 records. Please refine your search or export the data."
    }

    return (
        <Panel
            isOpen={true}
            isBlocking={false}
            onDismiss={props.hidePanel}
            onDismissed={() => {
                setHalfScreenModeState(false);
            }}
            style={{ marginTop: "48px" }}
            type={PanelType.custom}
            customWidth={customPanelWidth()}
            closeButtonAriaLabel="Close"
            headerText={getFormName(applicationManifest, props.formId)}
        >
            <FormProvider {...methods}>
                <form>
                    <Stack tokens={numericalSpacingStackTokens}>
                        <Stack.Item>
                            <SearchHeader></SearchHeader>
                        </Stack.Item>
                        <Stack.Item>
                            <SearchControls
                                searchControlsToDisplay={getSearchColumnsInRows()}
                                applicationEntitySearch={applicationEntity}
                                shimmerControlsOnSubmit={shimmerControlsOnSubmit}>
                            </SearchControls>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack horizontal wrap horizontalAlign="end" tokens={stackTokens}>
                                <Stack.Item>
                                    <ClearSearchButton
                                        shimmerControlsOnSubmit={shimmerControlsOnSubmit}
                                        formId={props.formId}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <ResetSearchButton
                                        shimmerControlsOnSubmit={shimmerControlsOnSubmit} />
                                </Stack.Item>
                                <Stack.Item>
                                    <SearchButton
                                        shimmerControlsOnSubmit={shimmerControlsOnSubmit}
                                        onSearchClicked={() => {
                                            submitForm();
                                        }}>
                                    </SearchButton>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <EntityListResult
                                grid={undefined}
                                gridName="Search Results"
                                entityListResult={searchResult}
                                columnControls={getSearchResultsColumns()}
                                isLoading={isLoading}
                                displayViewButton={true}
                                displayRemoveButton={false}
                                displayAddNewExistingButton={false}
                                disableAddNewExistingButton={false}
                                hideGridAddExistingButton={true}
                                hideGridAddNewButton={true}
                                navigateOnEditClick={false}
                                removeButtonText="Delete"
                                selectionMode={SelectionMode.multiple}
                                warningMessage={setSearchWarningMessage()}
                                removeEntity={undefined}
                                addEntity={undefined}
                                editEntity={undefined}
                                onAddSelectedClicked={props.onAddSelectedClicked}
                            />
                        </Stack.Item>
                    </Stack>
                </form>
            </FormProvider>
            {dialogModalWrapper != null &&
                <DialogModalWrapper dialogModalWrapperProps={dialogModalWrapper}></DialogModalWrapper>
            }
        </Panel>
    );
}

const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};
