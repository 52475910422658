import { Pivot, PivotItem } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { Tab } from "../../../model/manifest/Tab";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { applicationManifestState } from "../../../recoil/atoms";
import { AuditPage } from "./AuditPage";
import { FormPage } from "./FormPage";
import styles from "./FormTab.module.css";

export interface FormTabProps {
    formId: string;
    applicationEntity: ApplicationEntity | undefined;
    disableControls: boolean;
    isDetailsView: boolean;
    editMode: boolean;
    shimmerControlsOnSubmit: boolean;
    entityId: string | undefined;
}

export const FormTab: React.FC<FormTabProps> = (props: FormTabProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const [tabsForDisplay, setTabsForDisplay] = useState<Tab[]>([]);

    useEffect(() => {
        var page = applicationManifest.Pages.find(i => i.Id === props.formId);
        var tabs = page!.Tabs.filter(i => i.Name !== "Audit").sort((a, b) => a.SortOrder - b.SortOrder);
        setTabsForDisplay(tabs);
    }, [applicationManifest.Pages, props.formId]);

    return (
        <>
            {tabsForDisplay.length > 0 &&
                <Pivot
                    aria-label="Tab region for form"
                    className={styles.tabRegion}
                    linkSize="large"
                    overflowBehavior="menu">
                    {tabsForDisplay.map((value, index) => {
                        return (
                            <PivotItem
                                key={value.Name + index}
                                headerText={value.Name}
                                alwaysRender={true}>
                                <FormPage
                                    formId={props.formId}
                                    tabId={value.Id!}
                                    applicationEntity={props.applicationEntity}
                                    disableControls={props.disableControls}
                                    isDetailsView={props.isDetailsView}
                                    editMode={props.editMode}
                                    shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                    entityId={props.entityId}
                                    subFormField={undefined}>
                                </FormPage>
                            </PivotItem>
                        )
                    })}
                    {(props.applicationEntity != null && props.applicationEntity.Id != null) &&
                        <PivotItem
                            key={"AuditTab"}
                            headerText={"Audit"}
                            alwaysRender={true}>
                            <AuditPage
                                applicationEntity={props.applicationEntity}
                                shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}>
                            </AuditPage>
                        </PivotItem>
                    }
                </Pivot>
            }
        </>
    );
}