import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { ShimmeredDetailsList, IColumn, mergeStyleSets, IListProps, Shimmer, useTheme } from "@fluentui/react";
import { FileUploadField } from "./fields/FileUploadField";
import { CustomImageShimmer } from "./fields/helpers/CustomImageShimmer";
import { getLabelShimmer } from "../../../../services/shimmerService";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface FileControlProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    shimmerControlsOnSubmit: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const FileControl: React.FC<FileControlProps> = (props: FileControlProps) => {
    const theme = useTheme();

    const shimmeredDetailsListProps: IListProps = {
        renderCount: props.control.FieldType === 25 ? 5 : 1
    };

    return (
        <>
            {(props.control.FieldType === 24 || props.control.FieldType === 25) &&
                <>
                    {props.shimmerControlsOnSubmit === false ?
                        <ShimmeredDetailsList
                            items={[]}
                            compact={false}
                            columns={columns}
                            enableShimmer={true}
                            listProps={shimmeredDetailsListProps}
                            theme={theme}
                        />
                        :
                        <FileUploadField
                            control={props.control}
                            shouldValidate={props.shouldValidate}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            isRequired={props.isRequired}
                            entityId={props.entityId}
                            subFormField={props.subFormField}>
                        </FileUploadField>
                    }
                </>
            }
            {(props.control.FieldType === 32 || props.control.FieldType === 40) &&
                <>
                    {props.shimmerControlsOnSubmit === false ?
                        <>
                            <Shimmer
                                customElementsGroup={getLabelShimmer(theme)}
                                isDataLoaded={false}
                                shimmerColors={{
                                    shimmer: theme.palette.themeTertiary,
                                    shimmerWave: theme.palette.themeSecondary,
                                    background: theme.semanticColors.bodyStandoutBackground,
                                }}>
                            </Shimmer>
                            <CustomImageShimmer></CustomImageShimmer>
                        </>
                        :
                        <FileUploadField
                            control={props.control}
                            shouldValidate={props.shouldValidate}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            isRequired={props.isRequired}
                            entityId={props.entityId}
                            subFormField={props.subFormField}>
                        </FileUploadField>
                    }
                </>
            }
        </>
    );
}

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    }
});

const columns: IColumn[] = [
    {
        key: 'FileTypeDocumentListField',
        name: 'File Type',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'fileType',
        minWidth: 16,
        maxWidth: 16
    },
    {
        key: 'NameDocumentListField',
        name: 'Name',
        fieldName: 'name',
        minWidth: 75,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'DateModifiedTypeDocumentListField',
        name: 'Date Modified',
        fieldName: 'dateModifiedValue',
        minWidth: 75,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: false,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'ModifiedByDocumentListField',
        name: 'Modified By',
        fieldName: 'modifiedBy',
        minWidth: 75,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: false,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'FileSizeDocumentListField',
        name: 'File Size',
        fieldName: 'fileSizeRaw',
        minWidth: 75,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'number'
    },
    {
        key: 'EditDocumentListField',
        name: '',
        fieldName: 'edit',
        minWidth: 75,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: false,
        data: 'button'
    },
    {
        key: 'DeleteDocumentListField',
        name: '',
        fieldName: 'delete',
        minWidth: 75,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: false,
        data: 'button'
    }
];