import React, { useEffect } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { customerSummariesState, dialogModalWrapperPropsState, navPageSelectedState, shimmerNavState } from "../../../recoil/atoms";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { CustomerSummaryDto } from "../../../dtos/User/CustomerSummaryDto";
import { IStackItemStyles, Stack, useTheme } from "@fluentui/react";
import { IDialogModalWrapperProps } from "../../../interfaces/DialogModal";

export const OwnerPortalIndex: React.FC = () => {
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const setNavPageSelected = useSetRecoilState(navPageSelectedState);
    const setShimmerNav = useSetRecoilState(shimmerNavState);
    const [customerSummaries, setCustomerSummaries]: [CustomerSummaryDto[], (customerSummaries: CustomerSummaryDto[]) => void] = useRecoilState(customerSummariesState);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const theme = useTheme();

    useEffect(() => {
        setNavPageSelected(undefined);
        setShimmerNav(true);
        getCustomerSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCustomerSummary = (): void => {
        axios.get("/CustomerSummary", { cancelToken: source.token })
            .then((result) => {
                var customerSummaryDtos: CustomerSummaryDto[] = result.data;
                setCustomerSummaries(customerSummaryDtos);
            }).catch((error) => {
                var dialogProps: IDialogModalWrapperProps = {
                    isVisible: true,
                    title: "Uh oh",
                    subText: "Something went wrong.  Please try your request again.",
                    isBlocking: true,
                    primaryButtonText: "OK",
                    secondaryButtonText: undefined,
                    onDismiss: () => {
                        setDialogModalWrapperPropsState(undefined);
                        getCustomerSummary();
                    },
                    onSecondaryButtonDismiss: () => { }
                };
                setDialogModalWrapperPropsState(dialogProps);
            });
    }

    const bodyStackItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            color: theme.semanticColors.bodyText
        }
    };

    return (
        <Stack styles={bodyStackItemStyles}>
            {customerSummaries != null && customerSummaries.length > 0 &&
                <Outlet></Outlet>
            }
        </Stack>
    );
}