import React from "react";
import { ControlData } from "../../../../model/manifest/ControlData";
import { StandardGridField } from "./fields/StandardGridField";
import { TypeAndSearchOnServerField } from "../list/fields/TypeAndSearchOnServerField";
import { ConnectForm } from "../../form/ConnectForm";
import { ISubFormField } from "../../../../interfaces/ISubFormField";

export interface GridControlProps {
    control: ControlData;
    shouldValidate: boolean;
    disabled: boolean;
    readOnly: boolean;
    isRequired: boolean;
    isSearch: boolean;
    entityId: string | undefined;
    subFormField: ISubFormField | undefined;
}

export const GridControl: React.FC<GridControlProps> = (props: GridControlProps) => {
    const displayGridArea = (): boolean => {
        //We do not want to show the child grid when addinga record.
        if (props.control.IsGridChild && props.entityId == null) {
            return false;
        }
        return true;
    }


    return (
        <>
            {props.control.FieldType === 37 &&
                <>
                    {displayGridArea() &&
                        <>
                            {!props.isSearch
                                ?
                                <ConnectForm>
                                    {(methods: any) =>
                                        <>
                                            <StandardGridField
                                                control={props.control}
                                                shouldValidate={props.shouldValidate}
                                                disabled={props.disabled}
                                                readOnly={props.readOnly}
                                                isRequired={props.isRequired}
                                                methods={methods}
                                                entityId={props.entityId}
                                                subFormField={props.subFormField}
                                            />
                                        </>
                                    }
                                </ConnectForm>
                                :
                                <>
                                    <TypeAndSearchOnServerField
                                        control={props.control}
                                        shouldValidate={props.shouldValidate}
                                        disabled={props.disabled}
                                        readOnly={props.readOnly}
                                        isRequired={props.isRequired}
                                        isDropDown={false}
                                        isSearch={props.isSearch}
                                        entityId={props.entityId}
                                        subFormField={props.subFormField}>
                                    </TypeAndSearchOnServerField>
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    );
}