import React, { useEffect, useState } from "react";
import { Checkbox, DefaultButton, DetailsList, DetailsListLayoutMode, DocumentCard, IColumn, IDocumentCardStyles, IIconProps, IStackStyles, IStackTokens, PrimaryButton, SearchBox, SelectionMode, Stack, Text, useTheme } from "@fluentui/react";
import axios, { AxiosRequestConfig } from "axios";
import { useParams } from "react-router-dom";
import { applicationManifestState, dialogModalWrapperPropsState, groupSettingsState } from "../../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ApplicationManifest } from "../../../model/manifest/ApplicationManifest";
import { IGroupMemberGridItem } from "../../../interfaces/users/IGroupMemberGridItem";
import { NewGroupMemberPanel } from "./NewGroupMemberPanel";
import { removeButtonStyles } from "../../../services/styleService";
import { Group } from "../../../model/manifest/Group";
import { Pagination } from "@fluentui/react-experiments";
import { UserPersonaGridItem } from "../gridLayout/UserPersonaGridItem";

export const GroupMembers: React.FC = () => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const groups: Group[] = useRecoilValue(groupSettingsState);
    const setDialogModalWrapperPropsState = useSetRecoilState(dialogModalWrapperPropsState);
    const [groupMemberGridItems, setGroupMemberGridItems] = useState<IGroupMemberGridItem[]>([]);
    const [filteredGroupMemberGridItems, setFilteredGroupMemberGridItems] = useState<IGroupMemberGridItem[]>([]);
    const [filteredGroupMemberGridItemsToDisplay, setFilteredGroupMemberGridItemsToDisplay] = useState<IGroupMemberGridItem[]>([]);
    const [isAddGroupUserOpen, setAddGroupUserOpen] = useState(false);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const [searchTextFilter, setSearchTextFilter] = useState<string>("");
    var { groupId } = useParams();
    const theme = useTheme();
    const itemsPerPage = 10;
    const filterIcon: IIconProps = { iconName: "Filter" };

    useEffect(() => {
        var groupMembers: IGroupMemberGridItem[] = [];
        var group = groups.find(i => i.GroupId === groupId);
        if (group == null) {
            var dialogProps = {
                isVisible: true,
                title: "Not Found",
                subText: "We could not find this group.  Please navigate back to the group settings page and search for the specific group.",
                isBlocking: true,
                primaryButtonText: "OK",
                secondaryButtonText: undefined,
                onDismiss: () => {
                    setDialogModalWrapperPropsState(undefined);
                },
                onSecondaryButtonDismiss: () => { }
            }
            setDialogModalWrapperPropsState(dialogProps);
            return;
        }
        group.GroupUsers.forEach((item) => {
            var groupMember: IGroupMemberGridItem = {
                Avatar: item.Avatar,
                EmailAddress: item.EmailAddress,
                FullName: item.FullName,
                GroupId: item.GroupId,
                Disabled: false,
                Selected: false
            };
            groupMembers.push(groupMember);
        })
        groupMembers = sortGroupUsers(groupMembers);
        setGroupMemberGridItems(groupMembers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //text search on items.
        var matchingItems: any[] = [];
        var items = [...groupMemberGridItems];
        for (var item of items) {
            let textMatch = false;
            if (searchTextFilter === "" ||
                (item.EmailAddress != null && item.EmailAddress.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1) ||
                (item.FullName != null && item.FullName.toString().toUpperCase().indexOf(searchTextFilter.toUpperCase()) > -1)) {
                textMatch = true;
            }
            if (textMatch) {
                matchingItems.push(item);
            }
        }
        setFilteredGroupMemberGridItems(matchingItems);

        //calculate which items to display
        var skipToIndex = selectedPageIndex * itemsPerPage;
        var takeToIndex = skipToIndex + itemsPerPage;
        setFilteredGroupMemberGridItemsToDisplay(matchingItems.slice(skipToIndex, takeToIndex));

        //check to see if page count is less than current selection.  if so adjust it down accordingly
        var pageCount = Math.ceil(matchingItems.length / itemsPerPage);
        var pageCountIndex = pageCount - 1;
        if (pageCountIndex < 0) {
            setSelectedPageIndex(0);
        } else if (selectedPageIndex + 1 > pageCountIndex) {
            setSelectedPageIndex(pageCountIndex);
        }
    }, [groupMemberGridItems, selectedPageIndex, searchTextFilter]);

    const documentCardStyles: IDocumentCardStyles = {
        root: {
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            maxWidth: "",
            minWidth: ""
        }
    };

    const themedSmallStackTokens: IStackTokens = {
        childrenGap: "s1",
        padding: "s1",
    };

    const sortGroupUsers = (groupUserArray: IGroupMemberGridItem[]): IGroupMemberGridItem[] => {
        return groupUserArray.sort((a, b) => a.EmailAddress.localeCompare(b.EmailAddress));
    }

    const addedUser = (user: IGroupMemberGridItem): void => {
        var groupMembersArray = [...groupMemberGridItems];
        groupMembersArray.push(user);
        groupMembersArray = sortGroupUsers(groupMembersArray);
        setGroupMemberGridItems(groupMembersArray);
        setAddGroupUserOpen(false);
    };

    const anySelected = (): boolean => {
        return groupMemberGridItems.findIndex(i => i.Selected) === -1 ? false : true;
    };

    const deleteSelected = (): void => {
        setIsDeleteButtonDisabled(true);
        var selected = groupMemberGridItems.filter(i => i.Selected);
        var emailAddresses = selected.map(i => i.EmailAddress);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const options: AxiosRequestConfig = {
            method: "delete",
            url: "/GroupUser",
            params: {
                applicationId: applicationManifest.Id,
                groupId: groupId
            },
            data: emailAddresses,
            cancelToken: source.token
        };

        axios(options)
            .then((result) => {
                var groupMembers = [...groupMemberGridItems];
                emailAddresses.forEach(emailAddress => {
                    groupMembers = groupMembers.filter(i => i.EmailAddress !== emailAddress);
                });
                setGroupMemberGridItems(groupMembers);
                setIsDeleteButtonDisabled(false);
            })
            .catch((error) => {
                setIsDeleteButtonDisabled(false);
                if (error.response == null) {
                    console.log("Request canceled", "Group Members Component cleaned up");
                } else {
                    if (error.response.status === 401 || error.response.status === 409) {
                        return;
                    }
                    var dialogProps = {
                        isVisible: true,
                        title: "Uh oh",
                        subText: "Something went wrong, and we couldn't complete your request.  Please try it again.",
                        isBlocking: true,
                        primaryButtonText: "OK",
                        secondaryButtonText: undefined,
                        onDismiss: () => {
                            setDialogModalWrapperPropsState(undefined);
                        },
                        onSecondaryButtonDismiss: () => { }
                    }
                    setDialogModalWrapperPropsState(dialogProps);
                }
            });
    };

    const columns: IColumn[] = [
        {
            key: "Selected",
            name: "",
            fieldName: "selected",
            minWidth: 25,
            maxWidth: 25,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "boolean",
            onRender: (item: IGroupMemberGridItem) => {
                return <Stack horizontal={false} verticalAlign="center" styles={columnStackStyles}>
                    <Stack.Item>
                        <Checkbox
                            checked={item.Selected}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                var groupMembers = [...groupMemberGridItems];
                                var rowItem = groupMembers.find(i => i.EmailAddress === item.EmailAddress)!;
                                rowItem.Selected = isChecked ? true : false;
                                setGroupMemberGridItems(groupMembers);
                            }} />
                    </Stack.Item>
                </Stack>
            },
            isPadded: true,
        },
        {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 225,
            maxWidth: 525,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            data: "string",
            onRender: (item: IGroupMemberGridItem) => {
                return <UserPersonaGridItem
                    avatar={item.Avatar}
                    fullName={item.FullName}
                    emailAddress={item.EmailAddress}
                />
            },
            isPadded: true,
        },
    ];

    const getPageCount = (): number => {
        return Math.ceil(filteredGroupMemberGridItems.length / itemsPerPage);
    }

    const pageChange = (index: number): void => {
        setSelectedPageIndex(index);
    }

    return (
        <Stack tokens={themedSmallStackTokens} horizontal={false}>
            <Stack.Item>
                <DocumentCard styles={documentCardStyles}>
                    <Stack tokens={themedSmallStackTokens}>
                        <Stack.Item>
                            <Stack horizontal={true} horizontalAlign="space-between" tokens={themedSmallStackTokens} wrap>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <Text variant={"mediumPlus"}>
                                            Total
                                        </Text>
                                        <div className="bolt-pill">
                                            <div className="bolt-pill-content">
                                                {groupMemberGridItems != null ? groupMemberGridItems.length : ""}
                                            </div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal={true} tokens={themedSmallStackTokens}>
                                        <Stack.Item>
                                            <SearchBox
                                                placeholder="Filter"
                                                iconProps={filterIcon}
                                                value={searchTextFilter}
                                                onChange={(_, newValue) => {
                                                    setSearchTextFilter(newValue || "");
                                                }}
                                            />
                                        </Stack.Item>
                                        {anySelected()
                                            ?
                                            <DefaultButton
                                                text="Remove"
                                                ariaDescription="Remove user button."
                                                styles={removeButtonStyles(theme)}
                                                onClick={deleteSelected}
                                                disabled={isDeleteButtonDisabled}
                                                allowDisabledFocus />
                                            :
                                            <PrimaryButton
                                                text="Add"
                                                ariaDescription="New Group User button."
                                                onClick={() => { setAddGroupUserOpen(true) }}
                                                allowDisabledFocus />
                                        }
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <DetailsList
                                items={filteredGroupMemberGridItemsToDisplay != null ? filteredGroupMemberGridItemsToDisplay : []}
                                compact={true}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                //getKey={getKey}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Pagination
                                selectedPageIndex={selectedPageIndex}
                                pageCount={getPageCount()}
                                itemsPerPage={itemsPerPage}
                                totalItemCount={filteredGroupMemberGridItems.length}
                                format={"buttons"}
                                previousPageAriaLabel={"previous page"}
                                nextPageAriaLabel={"next page"}
                                firstPageAriaLabel={"first page"}
                                lastPageAriaLabel={"last page"}
                                pageAriaLabel={"page"}
                                selectedAriaLabel={"selected"}
                                onPageChange={pageChange} />
                        </Stack.Item>
                    </Stack>
                </DocumentCard>
            </Stack.Item>
            {isAddGroupUserOpen &&
                <NewGroupMemberPanel
                    appId={applicationManifest.Id!}
                    groupId={groupId!}
                    groupMembers={groupMemberGridItems}
                    addedUser={addedUser}
                    dismissPanel={() => { setAddGroupUserOpen(false) }}>
                </NewGroupMemberPanel>
            }
        </Stack>
    );
}

const columnStackStyles: IStackStyles = {
    root: {
        height: "100%"
    }
};