import React from "react";
import { ITextProps, Text } from "@fluentui/react";

export const SearchHeader: React.FC = () => {

    return (
        <Text variant={"xLarge" as ITextProps['variant']}>
            Search
        </Text>
    );
}