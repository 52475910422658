import React, { useState } from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, IButtonStyles, IIconProps, PrimaryButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { useRecoilState } from "recoil";
import { signInButtonDisabledState } from "../../recoil/atoms";

export const RegisterButton: React.FC = () => {
  const [signInButtonDisabled, setSignInButtonDisabled]: [boolean, (signInButtonDisabled: boolean) => void] =
    useRecoilState(signInButtonDisabledState);
  const [securityPopupHidden, setSecurityPopupHidden] = useState<boolean>(true);
  const { instance } = useMsal();

  const dialogContentProps = {
    type: DialogType.largeHeader,
    title: "Heads Up!",
  };

  const modalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
  };

  const registerClick = (): any => {
    setSecurityPopupHidden(false);
  };

  const register = (): any => {
    setSignInButtonDisabled(true);
    setTimeout(async () => {
      setSecurityPopupHidden(true);
      await instance.loginRedirect();
    });
  };

  const microsoftIcon: IIconProps = { iconName: "microsoft-logo-svg" };

  const registerButtonStyles: IButtonStyles = {
    root: {
      width: "275px",
      height: "50px",
    },
  };

  return (
    <>
      <DefaultButton
        text="Register with Microsoft"
        ariaDescription="Register button."
        onClick={registerClick}
        allowDisabledFocus
        iconProps={microsoftIcon}
        styles={registerButtonStyles}
        disabled={signInButtonDisabled}
      />
      <Dialog hidden={securityPopupHidden} onDismiss={register} dialogContentProps={dialogContentProps} modalProps={modalProps}>
        <div>
          <div>
            Security is a top priority for us. By using Microsoft’s secure authentication process, we keep your data safe. If you don’t already have a
            Microsoft account, you can click the Sign-in options at the bottom of the next dialog and use a different Social Provider. If you wish to
            create a new account, the process might take a few extra steps!
          </div>
          <br />
          <div>*Intact does not share your information.</div>
        </div>
        <DialogFooter>
          <PrimaryButton onClick={register} text="OK" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
