import React from "react";
import { IStackTokens, Shimmer, Stack, TextField, useTheme } from "@fluentui/react";
import { ApplicationEntity } from "../../../model/reaccessData/ApplicationEntity";
import { getDefaultFieldShimmer } from "../../../services/shimmerService";
import moment from 'moment';

export interface AuditProps {
    applicationEntity: ApplicationEntity | undefined;
    shimmerControlsOnSubmit: boolean;
}

export const AuditPage: React.FC<AuditProps> = (props: AuditProps) => {
    const theme = useTheme();

    const themedMediumStackTokens: IStackTokens = {
        childrenGap: 'm',
        padding: 'm',
    };

    return (
        <>
            <Stack horizontal wrap horizontalAlign="space-between" tokens={themedMediumStackTokens}>
                <Stack.Item
                    grow
                    disableShrink
                    key={"FormRowItemCreatedBy"}>
                    <Shimmer
                        customElementsGroup={getDefaultFieldShimmer(theme)}
                        isDataLoaded={props.shimmerControlsOnSubmit === false ? false : props.applicationEntity != null}
                        shimmerColors={{
                            shimmer: theme.palette.themeTertiary,
                            shimmerWave: theme.palette.themeSecondary,
                            background: theme.semanticColors.bodyStandoutBackground,
                        }}>
                        {props.applicationEntity != null &&
                            <TextField
                                label="Created By"
                                multiline={false}
                                borderless
                                value={props.applicationEntity!.InsertedByName!}
                                readOnly={true} />
                        }
                    </Shimmer>
                </Stack.Item>
            </Stack>
            <Stack horizontal wrap horizontalAlign="space-between" tokens={themedMediumStackTokens}>
                <Stack.Item
                    grow
                    disableShrink
                    key={"FormRowItemCreatedDate"}>
                    <Shimmer
                        customElementsGroup={getDefaultFieldShimmer(theme)}
                        isDataLoaded={props.shimmerControlsOnSubmit === false ? false : props.applicationEntity != null}
                        shimmerColors={{
                            shimmer: theme.palette.themeTertiary,
                            shimmerWave: theme.palette.themeSecondary,
                            background: theme.semanticColors.bodyStandoutBackground,
                        }}>
                        {props.applicationEntity != null &&
                            <TextField
                                label="Created Date"
                                multiline={false}
                                borderless
                                value={moment(props.applicationEntity!.InsertedUtc!).format("M/D/YYYY h:mm A")}
                                readOnly={true} />
                        }
                    </Shimmer>
                </Stack.Item>
            </Stack>
            <Stack horizontal wrap horizontalAlign="space-between" tokens={themedMediumStackTokens}>
                <Stack.Item
                    grow
                    disableShrink
                    key={"FormRowItemModifiedBy"}>
                    <Shimmer
                        customElementsGroup={getDefaultFieldShimmer(theme)}
                        isDataLoaded={props.shimmerControlsOnSubmit === false ? false : props.applicationEntity != null}
                        shimmerColors={{
                            shimmer: theme.palette.themeTertiary,
                            shimmerWave: theme.palette.themeSecondary,
                            background: theme.semanticColors.bodyStandoutBackground,
                        }}>
                        {props.applicationEntity != null &&
                            <TextField
                                label="Modified By"
                                multiline={false}
                                borderless
                                value={props.applicationEntity!.UpdatedByName!}
                                readOnly={true} />
                        }
                    </Shimmer>
                </Stack.Item>
            </Stack>
            <Stack horizontal wrap horizontalAlign="space-between" tokens={themedMediumStackTokens}>
                <Stack.Item
                    grow
                    disableShrink
                    key={"FormRowItemModifiedDate"}>
                    <Shimmer
                        customElementsGroup={getDefaultFieldShimmer(theme)}
                        isDataLoaded={props.shimmerControlsOnSubmit === false ? false : props.applicationEntity != null}
                        shimmerColors={{
                            shimmer: theme.palette.themeTertiary,
                            shimmerWave: theme.palette.themeSecondary,
                            background: theme.semanticColors.bodyStandoutBackground,
                        }}>
                        {props.applicationEntity != null &&
                            <TextField
                                label="Modified Date"
                                multiline={false}
                                borderless
                                value={moment(props.applicationEntity!.UpdatedUtc!).format("M/D/YYYY h:mm A")}
                                readOnly={true} />
                        }
                    </Shimmer>
                </Stack.Item>
            </Stack>
        </>
    )
}