import React, {  } from "react";
import { ActionButton, DefaultButton, DocumentCard, IDocumentCardStyles, IIconProps, IStackItemStyles, ITextProps, Stack, Text } from "@fluentui/react";
import { ControlData } from "../../../../../model/manifest/ControlData";
import { useRecoilValue } from "recoil";
import { applicationManifestState } from "../../../../../recoil/atoms";
import { ApplicationEntity } from "../../../../../model/reaccessData/ApplicationEntity";
import { ApplicationManifest } from "../../../../../model/manifest/ApplicationManifest";
import { FormPage } from "../../../form/FormPage";
import { useFieldArray } from "react-hook-form";
import { SubFormTab } from "../../../form/SubFormTab";
import { ISubFormField } from "../../../../../interfaces/ISubFormField";
import { buildDefaultValues } from "../../../../../services/applicationEntityService";

export interface SubFormFieldProps {
    control: ControlData;
    applicationEntity: ApplicationEntity;
    disableControls: boolean;
    isDetailsView: boolean;
    editMode: boolean;
    shimmerControlsOnSubmit: boolean;
    entityId: string | undefined;
}

export const SubFormField: React.FC<SubFormFieldProps> = (props: SubFormFieldProps) => {
    const applicationManifest: ApplicationManifest = useRecoilValue(applicationManifestState);
    const subForm = applicationManifest.SubForms.find(i => i.Id === props.control.SubFormId)!;
    const deleteIcon: IIconProps = { iconName: "Delete" };
    const numberOfSubForms = props.applicationEntity.Fields.find(i => i.Id === props.control.Id)!.SubForms.length as number;
    const { fields, append, remove, replace, update } = useFieldArray({
        name: props.control.Id!
    });
    
    const documentCardStyles: IDocumentCardStyles = {
        root: {
            boxShadow: "0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)",
            borderRadius: "4px",
            maxWidth: "",
            minWidth: "",
            background: props.editMode ? "transparent" : ""
        }
    };

    /**
     * Builds a new subform with default values and appends it to the array.
     */
    const addNewSubForm = (): void => {
        var defaultValues = buildDefaultValues(props.control.SubFormId!, applicationManifest);
        append(defaultValues);
    };

    /**
     * Removes the subform from the array.
     * @param index The index of the subform to remove.
     */
    const deleteSubForm = (index: number): void => {
        remove(index);
    };

    /*
     * Determines if the subform has tabs.
     */
    const hasTabs = (): boolean => {
        return subForm.Tabs != null && subForm.Tabs.length > 0 && subForm.Controls.every(i => i.TabId != null && i.TabId !== "") ? true : false;
    }

    return (
        <Stack>
            <Stack horizontal={true} horizontalAlign="space-between">
                <Stack.Item>
                    <Text variant={"large" as ITextProps['variant']}>
                        {props.control.LabelText}
                    </Text>
                </Stack.Item>
                {(subForm.AllowDataRepeating && props.editMode) &&
                    <Stack.Item>
                        <DefaultButton
                            text={`Add ${props.control.LabelText}`}
                            onClick={() => { addNewSubForm(); }}
                        />
                    </Stack.Item>
                }
            </Stack>
            <Stack.Item styles={stackItemStyles}>
                <Text variant={"mediumPlus" as ITextProps['variant']}>
                    {subForm!.HeaderText}
                </Text>
            </Stack.Item>
            {(fields != null && fields.length > 0 && numberOfSubForms != null) &&
                <>
                    {fields.map((_field, index) => {
                        const subFormField: ISubFormField = {
                            ParentControlId: props.control.Id!,
                            ParentFormId: props.control.PageId,
                            Index: index,
                            isNew: numberOfSubForms < index + 1 ? true : false,
                            update: update,
                            replace: replace
                        };
                        return (
                            <div key={"SubForm-" + props.control.Id + "-" + index}>
                                <DocumentCard styles={documentCardStyles}>
                                    {(subForm.AllowDataRepeating && props.editMode) &&
                                        <Stack horizontalAlign="end">
                                            <Stack.Item style={{ paddingRight: "5px " }}>
                                                <ActionButton iconProps={deleteIcon} onClick={() => { deleteSubForm(index) }} />
                                            </Stack.Item>
                                        </Stack>
                                    }
                                    {hasTabs()
                                        ?
                                        <SubFormTab
                                            formId={subForm.Id}
                                            applicationEntity={props.applicationEntity}
                                            disableControls={props.disableControls}
                                            isDetailsView={props.isDetailsView}
                                            editMode={props.editMode}
                                            shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                            entityId={props.entityId}
                                            subFormField={subFormField}
                                        ></SubFormTab>
                                        :
                                        <FormPage
                                            formId={subForm.Id}
                                            tabId={undefined}
                                            applicationEntity={props.applicationEntity}
                                            disableControls={props.disableControls}
                                            isDetailsView={props.isDetailsView}
                                            editMode={props.editMode}
                                            shimmerControlsOnSubmit={props.shimmerControlsOnSubmit}
                                            entityId={props.entityId}
                                            subFormField={{
                                                ParentControlId: props.control.Id!,
                                                ParentFormId: props.control.PageId,
                                                Index: index,
                                                isNew: subFormField.isNew,
                                                update: update,
                                                replace: replace
                                            }}>
                                        </FormPage>
                                    }
                                </DocumentCard>
                            </div>)
                    })}
                </>
            }
        </Stack>
    )
}
const stackItemStyles: IStackItemStyles = {
    root: {
        paddingTop: "10px",
        paddingBottom: "15px"
    }
};